import { useRef, useState } from "react";

export const useDefferedCallback = (timeout?: number) => {
    const [loading, setLoading] = useState(false);
    const timeoutRef = useRef<any>();
    const defaultTimeout = 500;
    const defferedCallback = (func: () => Promise<void> | void) => {
        setLoading(true);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            if (Object.prototype.toString.call(func) === "[object Promise]") {
                (func() as Promise<void>).finally(() => {
                    setLoading(false);
                });
            } else {
                func();
                setLoading(false);
            }
        }, timeout ?? defaultTimeout);
    };
    return {
        defferedCallback,
        loading,
    };
};
