import { DatePickerProps } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { customViewModes } from "../constants";
import { addOneToUnitInDate, getDateText, subtractOneToUnitInDate } from "../lib";

export const useCustomToolbar = (
    viewMode: TScheduleViewMode,
    date: Date,
    entityUuid: string,
    setDate: (item: Date) => void
) => {
    const [isOpenConfigure, setIsOpenConfigure] = useState<boolean>(false);
    const pickerFormat: undefined | "week" | "month" | "year" =
        viewMode === customViewModes.DAY || viewMode === customViewModes.AGENDA
            ? undefined
            : viewMode;

    const dateText = getDateText(viewMode, date);

    useEffect(() => {
        if (!localStorage.getItem(`${entityUuid}_schedule`)) onOpenConfigure();
    }, []);

    const onTodayClick = () => {
        const newDate = dayjs(dayjs(Date.now()).format("YYYY-MM-DD HH:mm"));
        setDate(newDate.toDate());
    };

    const onNextClick = () => {
        const newDate = addOneToUnitInDate(
            date,
            viewMode === "agenda" ? "day" : viewMode
        );
        setDate(newDate.toDate());
    };

    const onPrevClick = () => {
        const newDate = subtractOneToUnitInDate(
            date,
            viewMode === "agenda" ? "day" : viewMode
        );
        setDate(newDate.toDate());
    };

    const onChange: DatePickerProps["onChange"] = (date, dateString) => {
        if (!date) onTodayClick();
        else setDate(dayjs(date).toDate());
    };

    const onOpenConfigure = () => {
        setIsOpenConfigure(true);
    };

    const onCloseConfigure = () => {
        setIsOpenConfigure(false);
    };

    return {
        isOpenConfigure,
        pickerFormat,
        dateText,
        onTodayClick,
        onNextClick,
        onPrevClick,
        onChange,
        onOpenConfigure,
        onCloseConfigure,
    };
};
