import { userApi, userModel } from "@entities/User";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useUserProfile = (userId: number) => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState<TUser | null>(null);
    const [error, setError] = useState(false);
    const currentUser = useSelector(userModel.selectUser);
    const isMe = currentUser.id === user?.id;
    const loadUserInfo = (userId: number) => {
        setIsLoading(true);
        userApi
            .getUser(userId)
            .then((response) => {
                setUser(response.data.data);
            })
            .catch((error) => {
                setError(true);
                notificationEmit({
                    error: error,
                    title: "Не удалось загрузить информацию",
                    type: "error",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        loadUserInfo(userId);
    }, [userId]);

    return {
        user,
        isMe,
        isLoading,
        error,
    };
};
