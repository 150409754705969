import { FillActionFormBuilder } from "@features/BizProc/FillActionForm";
import { Button, Drawer, Space } from "antd";
import { useEditEntity } from "./model";
import AlertOnClose from "./AlertOnClose";

interface IEditEntity {
    open: boolean;
    onClose: () => void;
    error: any;
    initialValue: any | null;
    onSubmitValue: (value: any) => void;
    initialFields: TBizProcFormField[];
}

const EditEntity = ({
    open,
    initialValue,
    initialFields,
    error,
    onClose,
    onSubmitValue,
}: IEditEntity) => {
    const {
        isOpen,
        fields,
        alertIsOpen,
        setAlertIsOpen,
        onCloseDrawer,
        onSubmit,
        onChangeFormFields,
    } = useEditEntity({
        initialValue,
        initialFields,
        open,
        onSubmitValue,
        onClose,
    });
    return (
        <>
            <Drawer
                destroyOnClose
                //maskClosable={isEqual}
                zIndex={12233}
                extra={[
                    <Space key={"1"} style={{ marginRight: 5 }}>
                        <Button onClick={onCloseDrawer}>Отмена</Button>
                    </Space>,
                    <Space key={"2"}>
                        <Button type="primary" onClick={onSubmit}>
                            Сохранить
                        </Button>
                    </Space>,
                ]}
                onClose={onCloseDrawer}
                open={open}
            >
                <AlertOnClose
                    isOpen={alertIsOpen}
                    setIsOpen={setAlertIsOpen}
                    onOk={onClose}
                />
                {isOpen && (
                    <FillActionFormBuilder
                        error={error}
                        ignoreUnwritable
                        onlyRead={false}
                        onChangeFormFields={onChangeFormFields}
                        fields={fields}
                    />
                )}
            </Drawer>
        </>
    );
};

export default EditEntity;
