export const parseFormResponse = (response: any) => {
    const isError = response?.payload.success === false || response.error;
    if (isError) {
        throw response?.payload.data;
    } else {
        return response?.payload;
    }
};

export const parseErrorMessageByKey = (error: any, key: string) => {
    if (error.data?.[key] === undefined) return undefined;
    return Array.isArray(error.data?.[key]) ? error.data?.[key][0] : error.data?.[key];
};
