import { Button, Input, Result, Typography } from "antd";
import "./StartRecoverPasswordForm.scss";
import { useStartRecoverPasswordForm } from "./UseStartRecoverPasswordForm";
import { ErrorText } from "@shared/ui/Text";
interface IRecoverPasswordForm {}

const StartRecoverPasswordForm = ({}: IRecoverPasswordForm) => {
    const { email, loading, error, isSuccess, onChangeEmail, onClickBack, onSubmit } =
        useStartRecoverPasswordForm();
    return (
        <div className="recover-form">
            {isSuccess ? (
                <Result
                    status="success"
                    title="Информация о восстановлении пароля отправлена на почту!"
                />
            ) : (
                <>
                    <Typography.Text className="start-recover-page__desc">
                        Для восстановления пароля введите свой e-mail адресс, на него
                        будут отправлены необходимые инструкции
                    </Typography.Text>
                    <Input
                        status={error ? "error" : undefined}
                        value={email}
                        onChange={onChangeEmail}
                        placeholder="E-mail"
                    />
                    <div style={{ width: "100%" }}>
                        {error && <ErrorText text={error} />}
                    </div>
                    <div className="recover-form__buttons">
                        <Button
                            loading={loading}
                            onClick={onSubmit}
                            type="primary"
                            className="recover-form__buttons__submit"
                        >
                            Восстановить
                        </Button>
                        <Button
                            className="recover-form__buttons__back"
                            type="link"
                            onClick={onClickBack}
                        >
                            Назад
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default StartRecoverPasswordForm;
