import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { IViewPatternItem } from "./ViewPatternItemSlice";
import {
    createViewPatternItemThunk,
    deleteViewPatternItemThunk,
    loadViewPatternItemsThunk,
    updateViewPatternItemThunk,
} from "./ViewPatternItemThunk";

const viewPatternItemsLoadBuilder = (
    builder: ActionReducerMapBuilder<IViewPatternItem>
) => {
    builder.addCase(loadViewPatternItemsThunk.fulfilled, (state, action) => {
        state.viewPatternItems[action.meta.arg.viewPatternId] = action.payload.data;
        state.getLoading = false;
    });
    builder.addCase(loadViewPatternItemsThunk.pending, (state, action) => {
        state.getLoading = true;
    });
    builder.addCase(loadViewPatternItemsThunk.rejected, (state, rejectedValue) => {
        state.getLoading = false;
        notificationEmit({
            title: "Не удалось загрузить элемент",
            type: "error",
        });
    });
};
const viewPatternItemCreateBuilder = (
    builder: ActionReducerMapBuilder<IViewPatternItem>
) => {
    builder.addCase(createViewPatternItemThunk.fulfilled, (state, action) => {
        state.viewPatternItems[action.payload.data.view_pattern_id] = [
            ...(state.viewPatternItems[action.payload.data.view_pattern_id] ?? []),
            action.payload.data,
        ];
        state.createLoading = false;
    });
    builder.addCase(createViewPatternItemThunk.pending, (state, action) => {
        state.createLoading = true;
    });
    builder.addCase(createViewPatternItemThunk.rejected, (state, rejectedValue) => {
        state.createLoading = false;
        notificationEmit({
            title: "Не удалось создать элемент",
            type: "error",
        });
    });
};

const viewPatternItemUpdateBuilder = (
    builder: ActionReducerMapBuilder<IViewPatternItem>
) => {
    builder.addCase(updateViewPatternItemThunk.fulfilled, (state, action) => {
        const id = action.meta.arg.id;
        state.viewPatternItems[action.payload.data.view_pattern_id] = updateArrByItemId(
            state.viewPatternItems[action.payload.data.view_pattern_id],
            action.payload.data
        );
        state.updateLoading = state.updateLoading.filter((item) => item !== id);
    });
    builder.addCase(updateViewPatternItemThunk.pending, (state, action) => {
        state.updateLoading.push(action.meta.arg.id);
    });
    builder.addCase(updateViewPatternItemThunk.rejected, (state, rejectedValue) => {
        state.updateLoading = state.updateLoading.filter(
            (item) => item !== rejectedValue.meta.arg.id
        );
        notificationEmit({
            title: "Не удалось обновить элемент",
            type: "error",
        });
    });
};

const viewPatternItemDeleteBuilder = (
    builder: ActionReducerMapBuilder<IViewPatternItem>
) => {
    builder.addCase(deleteViewPatternItemThunk.fulfilled, (state, action) => {
        const id = Number(action.meta.arg.id);
        const viewPatternId = action.meta.arg.viewPatternId;
        state.viewPatternItems[viewPatternId] = state.viewPatternItems[
            viewPatternId
        ].filter((item) => item.id !== id);

        state.deleteLoading = state.deleteLoading.filter((item) => item !== id);
    });
    builder.addCase(deleteViewPatternItemThunk.pending, (state, action) => {
        state.deleteLoading.push(action.meta.arg.id);
    });
    builder.addCase(deleteViewPatternItemThunk.rejected, (state, rejectedValue) => {
        state.deleteLoading = state.deleteLoading.filter(
            (item) => item !== rejectedValue.meta.arg.id
        );
        notificationEmit({
            title: "Не удалось удалить элемент",
            type: "error",
        });
    });
};

export const viewPatternItemBuilder = (
    builder: ActionReducerMapBuilder<IViewPatternItem>
) => {
    viewPatternItemsLoadBuilder(builder);
    viewPatternItemCreateBuilder(builder);
    viewPatternItemUpdateBuilder(builder);
    viewPatternItemDeleteBuilder(builder);
};
