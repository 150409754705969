import { ReactNode } from "react";
import ErrorBoundary from "./ErrorBoundary";

interface IErrorBoundaryNoInfo {
    children: ReactNode;
}

const ErrorBoundaryNoInfo = ({ children }: IErrorBoundaryNoInfo) => {
    return (
        <ErrorBoundary errorNode={<div style={{ color: "red", fontWeight: 500 }}>-</div>}>
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryNoInfo;
