import { Drawer } from "antd";
import { useUserProfileDrawer } from "../model";
import UserProfile from "./UserProfile/UserProfile";

interface IUserProfileDrawer {}

const UserProfileDrawer = ({}: IUserProfileDrawer) => {
    const { openedUserId, isOpen, onClose } = useUserProfileDrawer();
    const windowWidth = window.innerWidth;

    return (
        <Drawer
            destroyOnClose
            open={isOpen}
            closable={false}
            width={windowWidth > 900 ? "60vw" : "100vw"}
            styles={{
                body: {
                    background: "rgb(143,133,232, 0.1)",
                    padding: 0,
                },
            }}
            onClose={onClose}
        >
            {openedUserId && <UserProfile userId={Number(openedUserId)} />}
        </Drawer>
    );
};

export default UserProfileDrawer;
