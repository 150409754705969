import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectEstimations, selectLoading } from "./EstimationSelectors";
import { setEstimations } from "./EstimationSlice";
import { loadEntitySumTemplatesThunk } from "./EstimationThunk";

export const useLoadEstimationTemplates = (entityId: number, preload?: boolean) => {
    const estimations = useSelector(selectEstimations);
    const isLoading = useSelector(selectLoading);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (preload) {
            loadEstimationTemplates();
        } else
            return () => {
                setEstimations([]);
            };
    }, [preload]);

    const loadEstimationTemplates = () => {
        dispatch<AppDispatch>(loadEntitySumTemplatesThunk(entityId));
    };

    return {
        estimations,
        isLoading,
    };
};
