import { Space } from "antd";
import { ReactNode } from "react";
import BizProcTemplateBottomActions from "./BizProcTemplateBottomActions";
import "./BizProcTemplateItemList.scss";
import BizProcTemplateNormalItem from "./BizProcTemplateNormalItem";
interface BizProcTemplateItemListInterface {
    bottomActions?: (bizProcTemplateItem: TBizProcTemplateItem) => ReactNode;
    bizProcTemplates: TBizProcTemplateItem[];
    onDeleted?: (id: number) => void;
}
const BizProcTemplateItemList = ({
    bizProcTemplates,
    onDeleted,
    bottomActions,
}: BizProcTemplateItemListInterface) => {
    return (
        <div className="bizproc-templates-list">
            {bizProcTemplates.map((bizProcTemplateItem) => {
                return (
                    <div
                        key={bizProcTemplateItem.id}
                        className="bizproc-templates-list__item"
                    >
                        <BizProcTemplateNormalItem
                            onDeleted={onDeleted}
                            bottomActions={
                                <Space
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                    }}
                                >
                                    {bottomActions && bottomActions(bizProcTemplateItem)}
                                    <BizProcTemplateBottomActions
                                        bizProcTemplateItem={bizProcTemplateItem}
                                    />
                                </Space>
                            }
                            //exampleRender={<BizProcBizProcTemplateItemItem entity={entity.type as TBizProcBizProcTemplateItemType} />}
                            bizProcTemplateItem={bizProcTemplateItem}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default BizProcTemplateItemList;
