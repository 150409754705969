import { bizProcTemplateApi } from "@entities/BizProcTemplate";
import { Button, Flex, Input, Select } from "antd";
import { ChangeEvent, useEffect, useState } from "react";

interface IViewPatternItemCreateBizProcButton {
    entityId: number;
    onCreate: (
        label: string,
        type: TViewPatternItemType,
        type_value: string | number | null
    ) => void;
}

const ViewPatternItemCreateBizProcButton = ({
    entityId,
    onCreate,
}: IViewPatternItemCreateBizProcButton) => {
    const [label, setLabel] = useState<string>("");
    const [bizProcTemplates, setBizProcTemplates] = useState<TBizProcTemplateItem[]>([]);
    const [bizProcTemplate, setBizProcTemplate] = useState<TBizProcTemplateItem | null>(
        null
    );
    const [error, setError] = useState({
        bizProc: false,
    });

    const bizProcTemplatesOptions = bizProcTemplates.map((template) => ({
        value: template.id,
        label: template.name,
        template,
    }));
    useEffect(() => {
        getBizProcTempaltes();
    }, []);

    const getBizProcTempaltes = () => {
        bizProcTemplateApi.getBizProcEntityTemplates(entityId).then((response) => {
            const templates = response.data.data;
            setBizProcTemplates(templates);
        });
    };
    const onChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setLabel(event.target.value);
    };

    const onChangeTemplate = (value: number | null, option: any) => {
        setBizProcTemplate(option.template);
        setError({
            ...error,
            bizProc: false,
        });
    };
    const onClick = () => {
        if (bizProcTemplate == null) {
            setError({
                bizProc: bizProcTemplate == null,
            });
            return;
        }
        setLabel("");
        setBizProcTemplate(null);
        onCreate(
            label,
            "start_biz_proc",
            JSON.stringify({
                bizProcTemplateId: bizProcTemplate.id,
                bizProcTemplateName: bizProcTemplate.name,
            })
        );
    };
    return (
        <Flex wrap="wrap" gap={8}>
            <Input
                placeholder="Имя кнопки"
                style={{ flex: "1 1 25%" }}
                value={label}
                onChange={onChangeLabel}
            />
            <Select
                status={error.bizProc ? "error" : undefined}
                onMouseEnter={(e) => e?.stopPropagation()}
                value={bizProcTemplate?.id ?? null}
                onChange={onChangeTemplate}
                options={bizProcTemplatesOptions}
                style={{ flex: "1 1 25%" }}
            />
            <Button onClick={onClick}>Создать</Button>
        </Flex>
    );
};

export default ViewPatternItemCreateBizProcButton;
