export const getStringBetween = (str: string, start: string, end: string): string[] => {
    const regExp = new RegExp(`(?<=${start})[^]*?(?=${end})`, "g");
    const result = str.match(regExp);
    if (!result) return [];
    return result;
};

export const getInputVariablePreffix = (item: TDropdownItem) => {
    switch (item.variable_type) {
        case "calculated_value":
            return "Calculated";
        case "entity":
            return `Entities:${item.entity_uuid}`;
        case "variable":
            return "Variable";
        default:
            return "";
    }
};
export const getInputVariableKey = (item: TDropdownItem, isSimpleVariable: boolean) => {
    switch (item.variable_type) {
        case "calculated_value":
            return item.type;
        case "entity":
        case "variable":
            return item.key;
        default:
            return "";
    }
};

export const getIsSimpleVariable = (item: TDropdownItem) => {
    if (item.variable_type === "calculated_value") return !Boolean(item?.props_count);
    return item.related_entity_uuid === null || item.related_entity_uuid === undefined;
};

export const getValueWithInjectedKey = (
    value: string,
    variableKey: string,
    isSimpleVariable: boolean,
    lastItem: TDropdownItem | null,
    calculatedValueType: string | null
) => {
    const beforeNewValueString = value.split("={").reverse().slice(1).reverse().join("}");
    const afterNewValue = value
        .split("={")
        .slice(1)
        .join("")
        .split(" ")
        .slice(1)
        .join(" ");

    const beforeKey = lastItem || calculatedValueType ? "" : "{{";

    const afterKey =
        lastItem?.variable_type === "calculated_value"
            ? ")"
            : lastItem != null || calculatedValueType
              ? ""
              : "}}";

    let newValue =
        `${beforeNewValueString}${beforeKey}${variableKey}${afterKey} ${afterNewValue}`
            .replace(/ +/g, " ")
            .trim();
    if (lastItem && isSimpleVariable) {
        newValue = newValue.replaceAll(" ", "");
    }
    return newValue;
};

export const getStringInputVariable = (
    item: TDropdownItem,
    isSimpleVariable: boolean,
    lastItem: TDropdownItem | null,
    calculatedValueType: string | null
) => {
    const prefix =
        lastItem?.variable_type === "entity" || lastItem?.variable_type === "variable"
            ? undefined
            : getInputVariablePreffix(item);
    const key = getInputVariableKey(item, isSimpleVariable);
    return `${prefix ? prefix + ":" : ""}${key}${
        isSimpleVariable
            ? ""
            : item.variable_type === "calculated_value"
              ? ":(={ "
              : ":={ "
    }`;
};
