import { Tag } from "antd";
import Markdown from "react-markdown";
import "./FormListItem.scss";

interface IFormListItem {
    form: TBizProcAction<TBizProcFormParameters>;
    onClick?: (form: TBizProcAction<TBizProcFormParameters>) => void;
    isMyAgreements?: boolean;
}

const FormListItem = (props: IFormListItem) => {
    const { form, onClick } = props;
    const onClick_ = () => {
        onClick && onClick(form);
    };

    const parameters = form.collected_parameters;
    return (
        <div className="form-list-item">
            <div className="form-list-item__header">
                <span className="form-list-item__header__text">{parameters.header}</span>
                <div className="form-list-item__header__tag">
                    <Tag
                        style={{ fontWeight: 600 }}
                        color={`${form.status === "done" ? "green" : "processing"}`}
                    >
                        {`${form.status === "done" ? "Отправлена" : "Ожидает заполнения"}`}
                    </Tag>
                </div>
            </div>

            <div className="form-list-item__footer">
                <div className="form-list-item__footer__body">
                    <Markdown>{parameters?.body ?? ""}</Markdown>
                </div>
            </div>
            <div className="form-list-item__additional">
                <span onClick={onClick ? onClick_ : undefined}>
                    {`${form.status === "executing" ? "Перейти к заполнению" : "см. подробнее"}`}
                </span>
            </div>
        </div>
    );
};

export default FormListItem;
