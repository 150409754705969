import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { categoryBuilder } from "./CategoryBuilder";

export interface ICategory {
    categories: TCategory[];
    getLoading: boolean;
    updateLoading: boolean;
    createLoading: boolean;
    deleteLoading: boolean;
}

const initialState: ICategory = {
    categories: [],
    getLoading: false,
    updateLoading: false,
    createLoading: false,
    deleteLoading: false,
};

export const categorySlice = createSlice({
    name: "CategorySlice",
    initialState,
    reducers: {
        setCategories: (state, action: PayloadAction<TCategory[]>) => {
            state.categories = action.payload;
        },
        updateCategory: (state, action: PayloadAction<TCategory>) => {
            state.categories = updateArrByItemId(state.categories, action.payload);
        },
        deleteCategory: (state, action: PayloadAction<TCategory>) => {
            state.categories = state.categories.filter(
                (item) => item.id !== action.payload.id
            );
        },
    },
    extraReducers: (builder) => {
        categoryBuilder(builder);
    },
});

export const { setCategories, updateCategory, deleteCategory } = categorySlice.actions;
export default categorySlice.reducer;
