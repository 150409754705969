import { useDispatch, useSelector } from "react-redux";
import { selectEntities, selectIsLoading } from "./EntitySelectors";
import { useEffect } from "react";
import { loadEntitiesThunk } from "./EntityThunk";

export const useLoadEntities = (preload?: boolean) => {
    const entities = useSelector(selectEntities);
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector(selectIsLoading);
    useEffect(() => {
        if (preload) {
            loadEntities();
        }
    }, []);

    const loadEntities = () => {
        dispatch(loadEntitiesThunk({}));
    };
    return {
        isLoading,
        entities,
        loadEntities,
    };
};
