import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { EntityDefaultCardList } from "../../EntityCard/EntityDefaultCard";
import "./EntityListView.scss";
import EntityListViewActions from "./EntityListViewActions";

interface IEntityListView {
    entityRowsOrIds: { [key: string | number]: string }[];
    onlyRead?: boolean;
    fields: (TBizProcTemplateActionField & TEntityFieldWithValue)[];
    onEdit: (item: any) => void;
    onDelete: (item: any) => void;
    onClick: (item: any) => void;
}

const EntityListView = ({
    entityRowsOrIds,
    onlyRead,
    fields,
    onEdit,
    onDelete,
    onClick,
}: IEntityListView) => {
    if (entityRowsOrIds.length === 0)
        return (
            <FlexContainer>
                <div
                    style={{
                        fontSize: 15,
                    }}
                >
                    Нет добавленных записей
                </div>
            </FlexContainer>
        );

    const nameField = fields.find((field) => field.is_name);
    const descriptionFields = fields.filter((field) => field.is_description);

    const descriptionItems =
        descriptionFields.length > 0
            ? descriptionFields
            : fields.filter((field) => field.view);

    return (
        <EntityDefaultCardList
            onlyRead={onlyRead}
            actions={(dataSourceItem) => {
                return (
                    <EntityListViewActions
                        dataSourceItem={dataSourceItem}
                        onlyRead={onlyRead}
                        onEdit={onEdit}
                        onDelete={onDelete}
                    />
                );
            }}
            onClick={onClick}
            nameField={nameField}
            descriptionFields={descriptionItems}
            entityRowsOrIds={entityRowsOrIds}
        />
    );
};

export default EntityListView;
