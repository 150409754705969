import { entityRowModel, getParametersForRequest } from "@entities/EntityRow";
import { estimationLib, estimationModel } from "@entities/Estimations";
import { userModel } from "@entities/User";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEstimationTemplatesList = (
    entityId: number,
    entityUuid: string,
    onClickItem?: () => void
) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch<AppDispatch>();
    const filters = useSelector(entityRowModel.selectFilters);
    const [filterValue, setFilterValue] = useState<string>(
        "" // localStorage.getItem(`${entityId}_estimation-templates-search`) ??
    );
    const { estimations, isLoading } = estimationModel.useLoadEstimationTemplates(
        entityId,
        true
    );
    const createEstimationTemplate = () => {
        dispatch(
            estimationModel.setCreatingEditingEstimation(
                estimationLib.getCreatingEstimationTemplate(entityUuid, user.id)
            )
        );
    };

    const onDelete = (item: TEstimationTemplate) => {
        dispatch(estimationModel.deleteSumEstimationTemplateThunk(item.id))
            .then(parseFormResponse)
            .then(() => {
                notificationEmit({
                    type: "success",
                    title: "Удалено",
                    description: `Шаблон ${item.name} удалён`,
                });
            })
            .catch((e: any) => {
                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: `Не удалось удалить шаблон ${item.name}`,
                });
            });
    };
    const onChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setFilterValue(value);
    };
    const onEdit = (item: TEstimationTemplate) => {
        dispatch(
            estimationModel.setCreatingEditingEstimation({
                ...item,
                fields_ids: item.sum_view_fields
                    ? item.sum_view_fields.map(({ id }: { id: number }) => id)
                    : [],
                users_ids: item.users.map(({ id }: { id: number }) => id),
            })
        );
    };

    const chooseTemplate = (item: TEstimationTemplate) => {
        localStorage.setItem(
            estimationLib.getEstimationStorageKey(entityId),
            JSON.stringify({ id: item.id, name: item.name })
        );
        const parameters = getParametersForRequest(filters);

        dispatch(
            estimationModel.applySumEstimationTemplateThunk({
                id: item.id,
                name: item.name,
                filters: parameters,
            })
        );
        onClickItem && onClickItem();
    };

    return {
        user,
        estimations: estimations.filter((item) =>
            item.name.toLocaleLowerCase().includes(filterValue.toLocaleLowerCase())
        ),
        isLoading,
        filterValue,
        onEdit,
        onDelete,
        chooseTemplate,
        onChangeFilterValue,
        createEstimationTemplate,
    };
};
