import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
    createEntityRowObserverThunk,
    deleteEntityRowObserverThunk,
    loadEntityRowObservers,
} from "./EntityRowObserverThunk";
import { IEntitySlice } from "./EntitySlice";

const loadBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(loadEntityRowObservers.fulfilled, (state, action) => {
        state.entityRowObservers = action.payload.data;

        localStorage.setItem(
            "entityRowObserversLength",
            state.entityRowObservers.length.toString()
        );
        // state.isLoading = false;
    });
    builder.addCase(loadEntityRowObservers.pending, (state) => {
        // state.isLoading = true;
        // state.error = null;
    });
    builder.addCase(loadEntityRowObservers.rejected, (state, rejectedValue) => {
        // state.error = rejectedValue;
        // state.isLoading = false;
        // state.error = rejectedValue;
        // state.isLoading = false;
    });
};

const createBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(createEntityRowObserverThunk.fulfilled, (state, action) => {
        state.entityRowObservers.unshift(action.payload.data);
        localStorage.setItem(
            "entityRowObserversLength",
            state.entityRowObservers.length.toString()
        );
    });
};

const deleteBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(deleteEntityRowObserverThunk.fulfilled, (state, action) => {
        state.entityRowObservers = state.entityRowObservers.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem(
            "entityRowObserversLength",
            state.entityRowObservers.length.toString()
        );
    });
};

export const entityRowObserversBuilders = (
    builder: ActionReducerMapBuilder<IEntitySlice>
) => {
    loadBuilder(builder);
    createBuilder(builder);
    deleteBuilder(builder);
};
