import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Card } from "antd";
import "./CardError.scss";

interface ICardError {
    message: string;
    status?: any;
}
const CardError = ({ message, status }: ICardError) => {
    if (!status) {
        return (
            <div className="card-error">
                <div className="card-error__title">
                    Ошибка {message} <ExclamationCircleOutlined />
                </div>
                <div className="card-error__content">
                    <p>Неизвестная ошибка</p>
                </div>
            </div>
        );
    }
    return (
        <Card title={`Ошибка ${message}`} bordered={false} style={{ width: 300 }}>
            <p>Статус ошибки: {status}</p>
        </Card>
    );
};

export default CardError;
