export const selectViewPatterns = (state: RootState): TViewPattern[] =>
    state.viewPattern.viewPatterns;
export const selectCurrentPatternId = (
    state: RootState,
    tabMode: string
): number | undefined => state.viewPattern.currentViewPatternId[tabMode];
export const selectCurrentViewPattern = (
    state: RootState,
    tabMode: string
): TViewPattern | undefined => {
    return state.viewPattern.viewPatterns.find(
        (pattern: TViewPattern) =>
            pattern.id === state.viewPattern.currentViewPatternId[tabMode]
    );
};
export const selectEditingViewPattern = (state: RootState): TViewPattern | null =>
    state.viewPattern.editingViewPattern;
export const selectGetLoading = (state: RootState): boolean =>
    state.viewPattern.getLoading;
export const selectUpdateLoading = (state: RootState, viewPatternId: number): boolean =>
    state.viewPattern.updateLoading.includes(viewPatternId);
export const selectCreateLoading = (state: RootState): boolean =>
    state.viewPattern.createLoading;
export const selectDeleteLoading = (state: RootState, viewPatternId: number): boolean =>
    state.viewPattern.deleteLoading.includes(viewPatternId);
