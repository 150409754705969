import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";

interface IEntityListViewActions {
    dataSourceItem: any;
    onlyRead?: boolean;
    onEdit: (item: any) => void;
    onDelete: (item: any) => void;
}

const EntityListViewActions = ({
    dataSourceItem,
    onlyRead,
    onEdit,
    onDelete,
}: IEntityListViewActions) => {
    return (
        <div style={{}}>
            {!onlyRead && (
                <>
                    <Space size="small">
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => onEdit(dataSourceItem)}
                        />
                        <Button
                            danger
                            type="link"
                            icon={<DeleteOutlined />}
                            onClick={() => onDelete(dataSourceItem)}
                        />
                    </Space>
                </>
            )}
        </div>
    );
};

export default EntityListViewActions;
