export const getFullValue = (value: any, options: any[]) => {
    if (!value) return null;

    return options
        .filter((option) => {
            if (typeof value === "object") {
                return value.some((item: any) => item == option.value);
            }
            return option.value == value;
        })
        .map((option) => option.option);
};
