import { useHeaderLayout } from "@shared/ui/Header";
import { BizProcGroups } from "@widgets/BizProcWidgets/BizProcGroups";
import { BizProcFilters } from "@widgets/Filters/BizProcFilters";

const BizProcsPage = () => {
    useHeaderLayout({
        title: "Бизнес-процессы",
    });

    return (
        <div className="biz-procs">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: 10 }}>
                    <BizProcFilters />
                </div>
                <BizProcGroups />
            </div>
        </div>
    );
};

export default BizProcsPage;
