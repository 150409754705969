import { parseFormResponse } from "@shared/api/ParseResponse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PORTAL_SETTINGS_STORE_KEY } from "../constants";
import { selectPortalSettings } from "./PortalSettingSelectors";
import { setPortalSettings } from "./PortalSettingSlice";
import { getPortalSettingsThunk } from "./PortalSettingThunk";

export const usePortalSettingsLoad = () => {
    const portalSettings = useSelector(selectPortalSettings);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(
            setPortalSettings(
                JSON.parse(
                    localStorage.getItem(PORTAL_SETTINGS_STORE_KEY) === "undefined"
                        ? "[]"
                        : localStorage.getItem(PORTAL_SETTINGS_STORE_KEY) ?? "[]"
                )
            )
        );
        dispatch(getPortalSettingsThunk())
            .then(parseFormResponse)
            .finally(() => {
                setLoading(false);
            });
    }, [dispatch]);

    return {
        portalSettings,
        loading,
    };
};
