import { Button, Flex } from "antd";
import { useCapabilitiesRolesConstraintsForm } from "../../model";
import CapabilitiesRolesConstraintsFormItem from "./CapabilitiesRolesConstraintsFormItem";
import GroupConditionActions from "./GroupConditionActions";

interface ICapabilitiesRolesConstraintsForm {
    entityCapabilitiesRolesId: number;
    entityRow: TCapabilitiesRolesRow;
    value: string | null;
    onChangeRow: (capType: TCapType, value: any) => void;
    additionalAction?: () => void;
    isCreating?: boolean;
}

const CapabilitiesRolesConstraintsForm = ({
    entityRow,
    onChangeRow,
    additionalAction,
    isCreating,
}: ICapabilitiesRolesConstraintsForm) => {
    const {
        isLoading,
        constraints,
        onAddCondition,
        entityFieldsOptions,
        onChangeConstraintsItem,
        onDeleteConstraintsItem,
        onSubmit,
        onCancel,
        onChangeGroupCondition,
    } = useCapabilitiesRolesConstraintsForm(
        entityRow,
        onChangeRow,
        additionalAction,
        isCreating
    );
    return (
        <div style={{ width: "100%" }}>
            <GroupConditionActions
                depth={0}
                onAddConditionGroup={() => onAddCondition("multiple")}
                onAddConditionSingle={() => onAddCondition("single")}
                onChangeConditionOfGroup={onChangeGroupCondition}
                conditionGroupValue={constraints.condition_type}
            />

            {constraints.conditions.map(
                (currentCondition: any, indexOfCurrentCondition: any) => {
                    const onChangeConditionItem = (
                        condition: any,
                        name: string,
                        value: string,
                        indexOfEditingCondition: number
                    ) => {
                        if (name === "key") {
                        }
                        onChangeConstraintsItem(
                            name === "key"
                                ? {
                                      ...condition,
                                      [name]: value,
                                      value: undefined,
                                      condition: undefined,
                                  }
                                : { ...condition, [name]: value },
                            indexOfEditingCondition
                        );
                    };

                    const onAddConditionGroup = () => {
                        const newCondition = {
                            ...currentCondition,
                            conditions: [
                                ...currentCondition.conditions,
                                { condition_type: "AND", conditions: [] },
                            ],
                        };
                        onChangeConstraintsItem(newCondition, indexOfCurrentCondition);
                    };
                    const onAddConditionSingle = () => {
                        const newCondition = {
                            ...currentCondition,
                            conditions: [
                                ...currentCondition.conditions,
                                {
                                    key: undefined,
                                    value: undefined,
                                    condition: undefined,
                                },
                            ],
                        };
                        onChangeConstraintsItem(newCondition, indexOfCurrentCondition);
                    };

                    const onDeleteCondition = () => {
                        const newConditions = constraints.conditions.filter(
                            (_: any, index: number) => index !== indexOfCurrentCondition
                        );
                        onDeleteConstraintsItem(newConditions);
                    };

                    return (
                        <CapabilitiesRolesConstraintsFormItem
                            onDeleteCondition={onDeleteCondition}
                            key={indexOfCurrentCondition}
                            onChangeConditionItem={onChangeConditionItem}
                            onAddConditionGroup={onAddConditionGroup}
                            onAddConditionSingle={onAddConditionSingle}
                            parentIndex={indexOfCurrentCondition}
                            entityFieldsOptions={entityFieldsOptions}
                            parentCondition={currentCondition}
                            onChangeConstraintsItem={onChangeConstraintsItem}
                            depthLvl={1}
                            isLoading={isLoading}
                        />
                    );
                }
            )}
            {!isCreating && (
                <Flex gap={8} style={{ marginTop: 8 }} justify="end">
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button type="primary" onClick={onSubmit}>
                        Сохранить
                    </Button>
                </Flex>
            )}
        </div>
    );
};

export default CapabilitiesRolesConstraintsForm;
