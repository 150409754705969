import ActionCard from "./ActionCard";

interface IIfElseActionBranch {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const IfElseActionBranch = ({ onDeleteItem, templateItem }: IIfElseActionBranch) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="RoyalBlue"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Ветвь условия"}
        ></ActionCard>
    );
};

export default IfElseActionBranch;
