import { Divider } from "antd";
import { UsersAssygnment } from "../BizProcsUsers";
import "./EmailMessageAction.scss";
interface IEmailMessageAction {
    action: TBizProcAction;
    onUserClick?: (user: TUser) => void;
}
const EmailMessageAction = ({ action, onUserClick }: IEmailMessageAction) => {
    return (
        <div>
            <div className="bizproc-action__content">
                <span style={{ fontWeight: 500 }}>Текст сообщения: </span>
                {action.collected_parameters.header}{" "}
                <Divider
                    style={{ marginTop: 10, fontSize: 14, color: "#555555" }}
                    orientation="center"
                ></Divider>
                <div className="assygnement">
                    <span>Кому:{"  "}</span>
                    <UsersAssygnment
                        customLabel=""
                        users={action.collected_parameters.users}
                        onUserClick={onUserClick}
                        status={action.status}
                    />
                </div>
            </div>
        </div>
    );
};

export default EmailMessageAction;
