import moment from "moment";
import { newConditionGroup } from "../constants";

interface IUseEditIfElseBranchAction {
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;
    setItem: (templateItem: TBizProcTemplateAction) => void;
    updateItemParameters: (parameters: TBizProcParameters) => void;
}

export const useEditIfElseBranchAction = ({
    bizProcTemplate,
    templateItem,
    setItem,
    updateItemParameters,
}: IUseEditIfElseBranchAction) => {
    const parametres = templateItem.parameters as TBizProcIfElseBranchParameters;
    const conditions = parametres.conditions ?? [];
    const updateConditionsParamters = (
        conditions: (TIfElseBranchConditionGroup | TIfElseBranchCondition)[]
    ) => {
        updateItemParameters({ conditions });
    };
    const onAddGroup = () => {
        const id = `id_${moment().valueOf()}`;
        updateConditionsParamters([...conditions, { ...newConditionGroup, id: id }]);
    };
    const onAddCondtitional = () => {
        const id = `id_${moment().valueOf()}`;
        updateConditionsParamters([
            ...conditions,
            {
                ...newConditionGroup,
                id: id,
                comparison: "=",
                type: "conditional",
                value: null,
                variable: null,
            },
        ]);
    };

    const onChange = (group: TIfElseBranchConditionGroup | TIfElseBranchCondition) => {
        const newConditions = conditions.map((condition) =>
            condition.id === group.id ? group : condition
        );
        updateConditionsParamters(newConditions);
    };
    const onDelete = (group: TIfElseBranchConditionGroup | TIfElseBranchCondition) => {
        const newConditions = conditions.filter((condition) => condition.id !== group.id);
        updateConditionsParamters(newConditions);
    };
    return {
        onAddGroup,
        onAddCondtitional,
        onChange,
        onDelete,
        conditions,
    };
};
