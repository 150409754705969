import { Collapse, Switch } from "antd";
import { useState } from "react";
import EditFormAction from "./EditFormAction/EditFormAction";
import { EditIfElseBranchAction } from "./EditIfElseBranchAction";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";

interface IEditInitialAction {
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    setItem: (templateItem: TBizProcTemplateAction) => void;
    updateItemParameters: (parameters: TBizProcParameters) => void;
}

const EditInitialAction = ({
    templateItem,
    setItem,
    bizProcTemplate,
    disabled,
    updateItemParameters,
}: IEditInitialAction) => {
    const [startTriggerType, setStartTriggerType] = useState<TInitialActionTriggerType>(
        templateItem.parameters?.triggerType ?? "auto"
    );
    const [isCreatingWithCondition, setIsCreatingWithCondition] = useState<boolean>(
        templateItem.parameters?.isCreatingWithCondition ?? false
    );
    const onChange = () => {
        const newValue = startTriggerType === "form" ? "auto" : "form";
        setStartTriggerType(newValue);
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem?.parameters ?? {}),
                triggerType: newValue,
            },
        });
    };
    const onChangeCreationConditions = () => {
        const newValue = !isCreatingWithCondition;

        setIsCreatingWithCondition(newValue);
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem?.parameters ?? {}),
                isCreatingWithCondition: newValue,
            },
        });
    };

    const isStartByForm = startTriggerType === "form";
    return (
        <div>
            <IndentContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>Начинается с заполнения формы</div>
                    <Switch
                        disabled={disabled}
                        onChange={onChange}
                        style={{ marginLeft: 10 }}
                        checked={isStartByForm}
                    />
                </div>
            </IndentContainer>

            {isStartByForm && (
                <IndentContainer>
                    <Collapse>
                        <Collapse.Panel header="Настройки формы" key="1">
                            <EditFormAction
                                disabled={disabled}
                                setItem={setItem}
                                bizProcTemplate={bizProcTemplate}
                                templateItem={templateItem}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </IndentContainer>
            )}
            <IndentContainer>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <div>Установить условия создания записи</div>
                    <Switch
                        disabled={disabled}
                        onChange={onChangeCreationConditions}
                        style={{ marginLeft: 10 }}
                        checked={isCreatingWithCondition}
                    />
                </div>
            </IndentContainer>

            {isCreatingWithCondition && (
                <>
                    <Collapse>
                        <Collapse.Panel
                            header="Настройка условий создания записи"
                            key="1"
                        >
                            <EditIfElseBranchAction
                                disabled={disabled}
                                notAllowGroupAdding={true}
                                updateItemParameters={updateItemParameters}
                                setItem={setItem}
                                bizProcTemplate={bizProcTemplate}
                                templateItem={templateItem}
                            />
                        </Collapse.Panel>
                    </Collapse>
                </>
            )}
        </div>
    );
};

export default EditInitialAction;
