import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
import EntityCardItem from "./EntityCardItem";
import { ReactNode } from "react";

interface IEntityDefaultCard {
    nameField?: TEntityField;
    descriptionFields?: TEntityField[];
    entityRowOrId: any;
    onClick?: (item: any) => void;
    actions?: ReactNode;
}

const EntityDefaultCard = ({
    actions,
    nameField,
    descriptionFields,
    entityRowOrId,
    onClick,
}: IEntityDefaultCard) => {
    return (
        <CardWithAvatar
            actions={actions}
            onClick={
                entityRowOrId.id && onClick
                    ? (e) => {
                          e?.stopPropagation();
                          onClick(entityRowOrId.id);
                      }
                    : undefined
            }
            hideAvatar
            header={
                nameField &&
                entityRowOrId[nameField.key] && (
                    <EntityCardItem
                        isTitle
                        field={nameField}
                        entityRowOrId={entityRowOrId}
                    />
                )
            }
            description={
                descriptionFields &&
                descriptionFields.map((field) => {
                    return (
                        <EntityCardItem
                            isDescription
                            key={field.key}
                            field={field}
                            entityRowOrId={entityRowOrId}
                        />
                    );
                })
            }
        />
    );
};

export default EntityDefaultCard;
