import { CardWithActions } from "@shared/ui/Cards/CardWithActions";
import { TextWithLabel } from "@shared/ui/Text";
import { useDatatypesListItem } from "../model";

interface IDatatypeListItem {
    datatype: TDatatype;
}

const DatatypeListItem = ({ datatype }: IDatatypeListItem) => {
    const { deleteLoading, onEdit, onSubmitDelete } = useDatatypesListItem(datatype);

    return (
        <CardWithActions
            onSubmitDelete={onSubmitDelete}
            deleteLoading={deleteLoading}
            onEdit={onEdit}
        >
            <TextWithLabel label={"Наименование"} text={datatype.name} />
            <TextWithLabel label={"Описание"} text={datatype.description ?? "-"} />
        </CardWithActions>
    );
};

export default DatatypeListItem;
