export const parseHexString = (str: string) => {
    var result = [];
    while (str.length >= 2) {
        result.push(parseInt(str.substring(0, 2), 16));
        str = str.substring(2, str.length);
    }

    return result;
};
export const contrastColor = (bgColor: string) => {
    try {
        const hexValues = parseHexString(bgColor.replace("#", ""));

        const luminance =
            (0.299 * hexValues[0] + 0.587 * hexValues[1] + 0.114 * hexValues[1]) / 255;
        if (luminance > 0.5) return `#000000`;
        else return `#ffffff`;
    } catch (e) {
        return `#000000`;
    }
};
