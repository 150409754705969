import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Button, Input, Space } from "antd";
import { ChangeEvent, useState } from "react";

interface IViewPatternItemCreateGroup {
    onCreate: (
        label: string,
        type: TViewPatternItemType,
        type_value: string | number | null
    ) => void;
}

const ViewPatternItemCreateGroup = ({ onCreate }: IViewPatternItemCreateGroup) => {
    const [groupName, setGroupName] = useState("");
    const [error, setError] = useState(false);
    const onClick = () => {
        if (groupName.length === 0) {
            setError(true);
            return;
        }
        onCreate(groupName, "group", null);
        setGroupName("");
    };
    const onChangeGroupName = (event: ChangeEvent<HTMLInputElement>) => {
        setError(false);
        setGroupName(event.target.value);
    };
    return (
        <FlexContainer>
            <Space>
                <Input
                    placeholder="Имя группы"
                    status={error ? "error" : undefined}
                    style={{ width: "100%" }}
                    value={groupName}
                    onChange={onChangeGroupName}
                />
                <Button onClick={onClick}>Создать</Button>
            </Space>
        </FlexContainer>
    );
};

export default ViewPatternItemCreateGroup;
