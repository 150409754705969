import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { navigationRouteBuilder } from "./NavigationRouteBuilder";
import { updateArrByItemId } from "@shared/lib/object-helper";

export interface INavigationRoute {
    navigationRoutes: TNavigationRoute[];
    isLoadingRoutes: boolean;
    editingMode: boolean;
}

const initialState: INavigationRoute = {
    navigationRoutes: [],
    isLoadingRoutes: false,
    editingMode: false,
};
// action: PayloadAction<HomeLayout>
export const navigationRouteSlice = createSlice({
    name: "navigationRoute",
    initialState,
    reducers: {
        setEditingMode: (state, action: PayloadAction<boolean>) => {
            state.editingMode = action.payload;
        },
        setRoutes: (state, action: PayloadAction<TNavigationRoute[]>) => {
            state.navigationRoutes = action.payload;
        },
        deleteRoute: (state, action: PayloadAction<number>) => {
            state.navigationRoutes = state.navigationRoutes.filter(
                (route) => route.id !== action.payload
            );
        },
        updateRoute: (state, action: PayloadAction<TNavigationRoute>) => {
            state.navigationRoutes = updateArrByItemId(
                state.navigationRoutes,
                action.payload
            );
        },
        createRoute: (state, action: PayloadAction<TNavigationRoute>) => {
            state.navigationRoutes = [...state.navigationRoutes, action.payload];
        },
    },
    extraReducers: (builder) => {
        navigationRouteBuilder(builder);
    },
});

export const { setEditingMode, deleteRoute, createRoute, setRoutes, updateRoute } =
    navigationRouteSlice.actions;

export default navigationRouteSlice.reducer;
