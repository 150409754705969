import { FormSkeletonItem } from "@entities/BizProc";
import React from "react";

interface IBizProcsFormsSkeletonList {
    isMyForms?: boolean;
}

const BizProcsFormsSkeletonList = ({ isMyForms }: IBizProcsFormsSkeletonList) => {
    const bizProcsFormsCount = localStorage.getItem("MyFormsCount");

    const parsedCounts =
        bizProcsFormsCount && bizProcsFormsCount != "{}"
            ? JSON.parse(bizProcsFormsCount)
            : { "": 1 };
    return (
        <>
            {Object.entries(parsedCounts).map(([name, count]: any, index) => {
                return (
                    <div key={index}>
                        <div className="form-header">{name}</div>
                        <div className="forms-list">
                            {Array.from({ length: count }).map((_, index) => {
                                return (
                                    <div className="forms-list__item" key={index}>
                                        <FormSkeletonItem />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
};
export default BizProcsFormsSkeletonList;
