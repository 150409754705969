import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import EditTemplateBar from "../EditTemplateBar";

interface IRejectBranchAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const RejectBranchAction = ({ onDeleteItem, templateItem }: IRejectBranchAction) => {
    return (
        <CardWithHead
            backgroundColor="red"
            noColor
            title={
                <EditTemplateBar
                    textColor="white"
                    title={templateItem?.name ?? "Отклонить"}
                />
            }
        />
    );
};

export default RejectBranchAction;
