import { Button } from "antd";
import { useUserPassword } from "../model";
import UserPasswordModal from "./UserPasswordModal";

interface IUserPassword {
    userId: number;
}

const UserPassword = ({ userId }: IUserPassword) => {
    const { isOpen, setIsOpen, onClick } = useUserPassword(userId);

    return (
        <>
            <Button
                style={{
                    padding: 0,
                    textAlign: "left",
                }}
                type="link"
                onClick={onClick}
            >
                Изменить пароль
            </Button>
            <UserPasswordModal userId={userId} isOpen={isOpen} setIsOpen={setIsOpen} />
        </>
    );
};

export default UserPassword;
