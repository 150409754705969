import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { entityFieldBuilder } from "./EntityFieldBuilder";

export interface IEntityFieldSlice {
    entityFields: TEntityField[];
    editingEntityField: TEntityField | null;
}

const initialState: IEntityFieldSlice = {
    entityFields: [],
    editingEntityField: null,
};

export const entityFieldSlice = createSlice({
    name: "EntityFieldSlice",
    initialState,
    reducers: {
        setEntityFields: (state, action: PayloadAction<TEntityField[]>) => {
            state.entityFields = action.payload;
            localStorage.setItem(
                "entityFieldsLength",
                state.entityFields.length.toString()
            );
        },
        setEditingEntityField: (state, action: PayloadAction<TEntityField | null>) => {
            state.editingEntityField = action.payload;
        },
        addEntityField: (state, action: PayloadAction<TEntityField>) => {
            state.entityFields.unshift(action.payload);
            localStorage.setItem(
                "entityFieldsLength",
                state.entityFields.length.toString()
            );
        },
        updateEntityField: (state, action: PayloadAction<TEntityField>) => {
            state.entityFields = state.entityFields.map((item: any) => {
                if (item.id === action.payload.id) return action.payload;
                return item;
            });
        },
        deleteEntityField: (state, action: PayloadAction<TEntityField>) => {
            state.entityFields = state.entityFields.filter(
                (item) => item.id !== action.payload.id
            );
            localStorage.setItem(
                "entityFieldsLength",
                state.entityFields.length.toString()
            );
        },
        reorderEntityFields: (state, action: PayloadAction<TEntityFieldOrder[]>) => {
            state.entityFields = state.entityFields.map((entityField: TEntityField) => {
                const order = action.payload.find((item) => item.id === entityField.id);
                return {
                    ...entityField,
                    order: order?.order ?? 0,
                };
            });
        },
    },
    extraReducers: (builder) => {
        entityFieldBuilder(builder);
    },
});

export const {
    setEntityFields,
    setEditingEntityField,
    updateEntityField,
    deleteEntityField,
    reorderEntityFields,
    addEntityField,
} = entityFieldSlice.actions;
export default entityFieldSlice.reducer;
