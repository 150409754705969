import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IPortalSetting, preparePortalSettings } from "./PortalSettingSlice";
import { getPortalSettingsThunk } from "./PortalSettingThunk";
import { PORTAL_SETTINGS_STORE_KEY } from "../constants";

const loadBuilder = (builder: ActionReducerMapBuilder<IPortalSetting>) => {
    builder.addCase(getPortalSettingsThunk.fulfilled, (state, action) => {
        state.isLoadingPortalSettings = false;
        state.portalSettings = action.payload;
        localStorage.setItem(
            PORTAL_SETTINGS_STORE_KEY,
            JSON.stringify(state.portalSettings ?? [])
        );
        preparePortalSettings(state);
    });
    builder.addCase(getPortalSettingsThunk.pending, (state) => {
        state.isLoadingPortalSettings = true;
    });
    builder.addCase(getPortalSettingsThunk.rejected, (state, rejectedValue) => {
        state.isLoadingPortalSettings = false;
    });
};
// const createBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(createFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions.unshift(action.payload.data);
//         localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
//     });
// };
// const updateBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(updateFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions = updateArrByItemId(state.fieldOptions, action.payload.data);
//     });
// };
// const deleteBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(deleteFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions = state.fieldOptions.filter(
//             (item) => item.id !== action.payload.data.id
//         );
//         localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
//     });
// };

export const portalSettingBuilder = (
    builder: ActionReducerMapBuilder<IPortalSetting>
) => {
    loadBuilder(builder);
};
