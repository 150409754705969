import { Button } from "antd";

interface ITelegramLinkButton {
    action: () => void;
    text: string;
    icon?: React.ReactNode;
}

const TelegramLinkButton = ({ action, text, icon }: ITelegramLinkButton) => {
    return (
        <div style={{ marginTop: 2 }}>
            {icon && icon}
            <Button
                size="small"
                onClick={action}
                style={{
                    padding: 0,
                    textAlign: "left",
                }}
                type="link"
            >
                {text}
            </Button>
        </div>
    );
};

export default TelegramLinkButton;
