import { Button, Flex, Modal, Space } from "antd";
import StageEntityRowFinalButton from "./StageEntityRowFinalButton";
import "./StagesEntityRow.scss";
interface IStageEntityRowFinalStageModal {
    finalStages: TStage[];
    isOpen: boolean;
    onClose: () => void;
    onClickStage: (stageId: number) => void;
}

const StageEntityRowFinalStageModal = ({
    finalStages,
    isOpen,
    onClose,
    onClickStage,
}: IStageEntityRowFinalStageModal) => {
    const _onClickStage = (stageId: number) => {
        onClickStage(stageId);
        onClose();
    };
    return (
        <Modal
            title="Выберите завершающую стадию?"
            open={isOpen}
            zIndex={99999}
            footer={[<Button onClick={onClose}>Закрыть</Button>]}
            cancelText="Нет"
            onCancel={onClose}
            className="final-stages-modal"
        >
            <Flex>
                <div style={{ width: "100%", marginRight: 10 }}>
                    <StageEntityRowFinalButton
                        finalStages={finalStages}
                        onClickStage={_onClickStage}
                        type="successful"
                    />
                </div>
                <StageEntityRowFinalButton
                    finalStages={finalStages}
                    onClickStage={_onClickStage}
                    type="unsuccessful"
                />
            </Flex>
        </Modal>
    );
};

export default StageEntityRowFinalStageModal;
