import { useState } from "react";

export const useCategoryCreate = (entityId: number) => {
    const [isOpen, setIsOpen] = useState(false);
    const onCancel = () => {
        setIsOpen(false);
    };

    const onOpen = () => {
        setIsOpen(true);
    };
    return {
        isOpen,
        onOpen,
        onCancel,
    };
};
