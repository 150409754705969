import { DeleteOutlined } from "@ant-design/icons";
import TextEditable from "@shared/ui/Inputs/TextEditable";
import { Button, Flex } from "antd";
import { ReactNode } from "react";
import { useViewPatternItem } from "../model";
import "./ViewPatternItem.scss";
import ViewPatternItemGroup from "./ViewPatternItemGroup";

interface IViewPatternItem {
    viewPatternItem: TViewPatternItem;
    viewPattern: TViewPattern;
    entityFields: TEntityField[];
    additionalItem?: (
        viewPattern: TViewPattern,
        onCreate: (
            label: string,
            type: TViewPatternItemType,
            type_value: string | number | null
        ) => void
    ) => ReactNode;
    onReorderItems: (items: TViewPatternItem[]) => void;
}

const ViewPatternItem = ({
    viewPatternItem,
    viewPattern,
    entityFields,
    additionalItem,
    onReorderItems,
}: IViewPatternItem) => {
    const type = viewPatternItem.type;
    const { deleteLoading, onDelete, onUpdateLabel } =
        useViewPatternItem(viewPatternItem);
    return (
        <div className="view-pattern-item">
            <div className="view-pattern-item__title">
                <Flex vertical style={{ width: "90%" }}>
                    <TextEditable text={viewPatternItem.label} onSave={onUpdateLabel} />
                </Flex>
                <Button
                    loading={deleteLoading}
                    onClick={onDelete}
                    type="link"
                    danger
                    icon={<DeleteOutlined />}
                />
            </div>
            {type === "group" && viewPatternItem.children && (
                <ViewPatternItemGroup
                    additionalItem={additionalItem}
                    onReorderItems={onReorderItems}
                    viewPattern={viewPattern}
                    viewPatternItemGroup={viewPatternItem}
                    entityFields={entityFields}
                />
            )}
            {(type === "start_child_biz_proc" || type === "start_biz_proc") &&
                `Запуск: ${
                    JSON.parse((viewPatternItem.type_value ?? {}).toString())
                        ?.bizProcTemplateName ?? "-"
                }`}
        </div>
    );
};

export default ViewPatternItem;
