import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { TimeInput } from "@shared/ui/TimeInput";
import { Checkbox, Input, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ReactNode, useState } from "react";
import BizProcEditAction from "./BizProcEditAction";
import BizProcItemEditForm from "./BizProcItemEditForm";

interface IBizProcItemEditModal {
    additionalNode?: ReactNode;
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;

    isOpen: boolean;
    onSubmit: (templateItem: TBizProcTemplateAction) => void;
    disabled?: boolean;
    submitLoading?: boolean;
    okText?: ReactNode;
    setIsOpen: (isOpen: boolean) => void;
}

const BizProcItemEditModal = ({
    templateItem,
    disabled,
    isOpen,
    okText,
    bizProcTemplate,
    additionalNode,
    onSubmit,
    setIsOpen,
}: IBizProcItemEditModal) => {
    const onCancel = () => {
        setIsOpen(false);
    };
    return (
        <Modal
            title="Редактирование"
            open={isOpen}
            destroyOnClose
            okText={okText}
            onCancel={onCancel}
            // okButtonProps={{
            //     loading: submitLoading,
            // }}
            width={
                templateItem.action === "InitialAction" ||
                templateItem.action === "IfElseActionBranch"
                    ? 650
                    : undefined
            }
            footer={[]}
        >
            <BizProcItemEditForm
                onCancel={onCancel}
                onSubmit={onSubmit}
                additionalNode={additionalNode}
                templateItem={templateItem}
                bizProcTemplate={bizProcTemplate}
                disabled={disabled}
            />
        </Modal>
    );
};

export default BizProcItemEditModal;
