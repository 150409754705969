import { ReactNode } from "react";

type TFlexType =
    | "center"
    | "row-end"
    | "row-space-between"
    | "row-flex-end"
    | "row-start";
interface IFlexContainer {
    type?: TFlexType;
    customStyle?: React.CSSProperties;
    children: ReactNode;
}

const FlexContainer = ({ customStyle, type, children }: IFlexContainer) => {
    const getStyle = (type?: TFlexType) => {
        switch (type) {
            case "center":
                return {
                    justifyContent: "center",
                    alignItems: "center",
                };
            case "row-end":
                return {
                    justifyContent: "flex-end",
                    alignItems: "center",
                };
            case "row-start":
                return {
                    justifyContent: "flex-start",
                    alignItems: "center",
                };
            case "row-flex-end":
                return {
                    alignItems: "flex-end",
                };
            case "row-space-between":
                return {
                    justifyContent: "space-between",
                    alignItems: "center",
                };
        }
        return {
            justifyContent: "flex-start",
            alignItems: "flex-start",
        };
    };

    const style = getStyle(type);
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "100%",
                ...style,
                ...(customStyle ?? {}),
            }}
        >
            {children}
        </div>
    );
};

export default FlexContainer;
