import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fieldOptionBuilder } from "./FieldOptionBuilder";

export interface IFieldOptionSlice {
    fieldOptions: TFieldOption[];
    editingFieldOption: TFieldOption | null;
}

const initialState: IFieldOptionSlice = {
    fieldOptions: [],
    editingFieldOption: null,
};

export const fieldOptionSlice = createSlice({
    name: "FieldOptionSlice",
    initialState,
    reducers: {
        setFieldOptions: (state, action: PayloadAction<TFieldOption[]>) => {
            state.fieldOptions = action.payload;
            localStorage.setItem(
                "fieldOptionsLength",
                state.fieldOptions.length.toString()
            );
        },
        setEditingFieldOption: (state, action: PayloadAction<TFieldOption | null>) => {
            state.editingFieldOption = action.payload;
        },
        addFieldOption: (state, action: PayloadAction<TFieldOption>) => {
            state.fieldOptions.unshift(action.payload);
            localStorage.setItem(
                "fieldOptionsLength",
                state.fieldOptions.length.toString()
            );
        },
        updateFieldOption: (state, action: PayloadAction<TFieldOption>) => {
            state.fieldOptions = state.fieldOptions.map((item: any) => {
                if (
                    action.payload.id
                        ? item.id !== action.payload.id
                        : item.value !== action.payload.value
                )
                    return action.payload;
                return item;
            });
        },
        deleteFieldOption: (state, action: PayloadAction<TFieldOption>) => {
            state.fieldOptions = state.fieldOptions.filter((item) =>
                action.payload.id
                    ? item.id !== action.payload.id
                    : item.value !== action.payload.value
            );
            localStorage.setItem(
                "fieldOptionsLength",
                state.fieldOptions.length.toString()
            );
        },
    },
    extraReducers: (builder) => {
        fieldOptionBuilder(builder);
    },
});

export const {
    setFieldOptions,
    setEditingFieldOption,
    updateFieldOption,
    deleteFieldOption,
    addFieldOption,
} = fieldOptionSlice.actions;
export default fieldOptionSlice.reducer;
