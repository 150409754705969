import { Button, Spin } from "antd";
import { FillActionFormBuilder } from "../FillActionForm";
import { useBizProcFillVariables } from "./model";
import { UserAccess } from "@entities/User";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";

interface IBizProcFillVariables {
    bizProc: TBizProc;
    setBizProc: (bizProc: TBizProc) => void;
}

const BizProcFillVariables = ({ bizProc, setBizProc }: IBizProcFillVariables) => {
    const {
        onChangeFormFields,
        onSubmit,
        onStartEdit,
        loading,
        onCancel,
        isEdit,
        variables,
        loadingVariables,
        fields,
    } = useBizProcFillVariables(bizProc, setBizProc);

    return (
        <>
            <div className="bizproc-info__fields__inputs">
                {loadingVariables ? (
                    <div style={{ margin: "30px 50%" }}>
                        <Spin />
                    </div>
                ) : (
                    <>
                        <div className="bizproc-info__fields__inputs__actions">
                            <UserAccess.AdminAccess>
                                {isEdit ? (
                                    <div>
                                        <Button type="link" onClick={onCancel}>
                                            Отмена
                                        </Button>
                                        <Button
                                            loading={loading}
                                            type="primary"
                                            onClick={onSubmit}
                                        >
                                            Сохранить
                                        </Button>
                                    </div>
                                ) : (
                                    <Button type="link" onClick={onStartEdit}>
                                        Редактировать
                                    </Button>
                                )}
                            </UserAccess.AdminAccess>
                        </div>
                        <FillActionFormBuilder
                            ignoreUnwritable
                            onlyRead={!isEdit}
                            onChangeFormFields={onChangeFormFields}
                            fields={fields}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default BizProcFillVariables;
