interface IUseEntityFieldItem {
    entityField: TEntityField;
    onEdit: (item: TEntityField) => void;
    onDelete: (item: TEntityField) => void;
}

export const useEntityFieldItem = ({
    entityField,
    onEdit,
    onDelete,
}: IUseEntityFieldItem) => {
    const key = entityField.key;
    const name = entityField.name;
    const datatype = entityField.datatype;

    const onClickEdit = () => {
        onEdit(entityField);
    };

    const onDeleteClick = () => {
        onDelete(entityField);
    };

    return {
        name,
        key,
        datatype,
        onDeleteClick,
        onClickEdit,
    };
};
