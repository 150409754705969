import { useState } from "react";
import {
    maximumHorizontalQrCodeSize,
    maximumVerticalQrCodeSize,
    minimalQrCodeSize,
} from "../constants";

export const useGenerateQRCodeConfig = (
    pdfOrientation: "p" | "l",
    setPdfOrientation: (item: "p" | "l") => void,
    setQrCodeSize: (item: number) => void,
    setIsOpenDrawer: (item: boolean) => void
) => {
    const [isError, setIsError] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>("");

    const onSelectPdfOrientation = (value: "p" | "l") => {
        setPdfOrientation(value);
        setIsError(false);
        setQrCodeSize(minimalQrCodeSize);
        setErrorText("");
    };
    const changeQrCodeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if (!reg.test(value) && value != "") return;
        const numberValue = +value;
        if (pdfOrientation === "p" && numberValue > maximumVerticalQrCodeSize) {
            setQrCodeSize(numberValue);
            setIsError(true);
            setErrorText(`Размер не должен превышать ${maximumVerticalQrCodeSize}`);
            return;
        }
        if (pdfOrientation === "l" && numberValue > maximumHorizontalQrCodeSize) {
            setErrorText(`Размер не должен превышать ${maximumHorizontalQrCodeSize}`);
            setQrCodeSize(numberValue);
            setIsError(true);
            return;
        }
        if (numberValue < minimalQrCodeSize) {
            setErrorText(`Размер должен быть больше ${minimalQrCodeSize}`);
            setQrCodeSize(numberValue);
            setIsError(true);
            return;
        }
        setIsError(false);
        setQrCodeSize(numberValue);
    };

    const onCloseDrawer = () => {
        if (errorText.includes(`${minimalQrCodeSize}`)) setQrCodeSize(minimalQrCodeSize);
        if (errorText.includes(`${maximumHorizontalQrCodeSize}`))
            setQrCodeSize(maximumHorizontalQrCodeSize);
        if (errorText.includes(`${maximumVerticalQrCodeSize}`))
            setQrCodeSize(maximumVerticalQrCodeSize);
        setErrorText("");
        setIsOpenDrawer(false);
        setIsError(false);
    };

    return {
        isError,
        errorText,
        onSelectPdfOrientation,
        changeQrCodeSize,
        onCloseDrawer,
    };
};
