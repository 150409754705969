import { setEntityFields } from "@entities/EntityField/model";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_PER_PAGE } from "../constants";
import { getValidParams } from "../lib";
import {
    selectEntityFields,
    selectEntityRowsWithPage,
    selectFilters,
    selectGroupingKeys,
    selectTextFilterValue,
} from "./EntityRowSelectors";
import { deleteEntityRowsWithPage, setIsLoading } from "./EntityRowSlice";
import { getEntityRowsWithPageThunk } from "./EntityRowThunk";
interface IUseLoadEntityRowsWithPage {
    entityId: number;
    level?: number;
    levelId?: number | string;
    additionalFilters?: TFilterValue[];
}
export const useLoadEntityRowsWithPage = ({
    entityId,
    level,
    levelId,
    additionalFilters,
}: IUseLoadEntityRowsWithPage) => {
    const levelKey = level === undefined ? 0 : `${levelId ?? ""}_${level}`;

    const entityRowsWithPage = useSelector((state: RootState) =>
        selectEntityRowsWithPage(state, levelKey)
    );
    const entityRows = entityRowsWithPage?.entityRows ?? [];
    const filters = useSelector(selectFilters);
    const textFilterValue = useSelector(selectTextFilterValue);
    const entityFields = entityRowsWithPage?.entityFields ?? [];
    const currentPage = entityRowsWithPage?.currentPage ?? 1;
    const initialPerPage = Number(
        localStorage.getItem(`entity_per_page`) ?? DEFAULT_PER_PAGE
    );
    const [perPage, setPerPage] = useState(initialPerPage);
    const lastPage = entityRowsWithPage?.lastPage ?? 1;
    const isLoading = entityRowsWithPage?.isLoading;

    const dispatch = useDispatch<AppDispatch>();
    const sendTimeout = useRef<any>();
    const groupingKeys = useSelector(selectGroupingKeys);
    useEffect(() => {
        return () => {
            dispatch(deleteEntityRowsWithPage(levelKey));

            //if (!level) dispatch(setEntityFields([]));

            setPerPage(initialPerPage);
        };
    }, []);

    useEffect(() => {
        loadEntityRows(currentPage, perPage, filters, textFilterValue);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, perPage, filters, textFilterValue, groupingKeys.length]);

    const loadEntityRows = (
        page: number,
        perPage: number,
        filters: TFilterValue[],
        textFilterValue: string | undefined
    ) => {
        const conditionsParams = [
            ...getValidParams([...(filters ?? []), ...(additionalFilters ?? [])]),
        ];

        const parameters: TMultipleCondition | undefined =
            conditionsParams.length > 0
                ? {
                      condition_type: "AND",
                      conditions: conditionsParams,
                  }
                : undefined;

        dispatch(setIsLoading(true));
        if (sendTimeout.current) clearTimeout(sendTimeout.current);

        sendTimeout.current = setTimeout(() => {
            dispatch(
                getEntityRowsWithPageThunk({
                    key: levelKey,
                    id: entityId,
                    page: page,
                    perPage: perPage,
                    groupKey: groupingKeys[level ?? 0],
                    filter: parameters,
                    textFilter: !!textFilterValue ? textFilterValue : undefined,
                })
            );
        }, 500);
    };

    const onChangePerPage = (perPage: number) => {
        localStorage.setItem(`entity_per_page`, perPage.toString());
        setPerPage(perPage);
    };
    return {
        isLoading,
        entityFields,
        currentPage,
        perPage,
        lastPage,
        entityRows,
        groupingKey: groupingKeys[level ?? 0],
        setPerPage: onChangePerPage,
        loadEntityRows,
    };
};
