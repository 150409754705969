import { useSelector } from "react-redux";
import { selectCategories, selectGetLoading } from "./CategorySelectors";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { loadCategoriesThunk } from "./CategoryThunk";
import { setCategories } from "./CategorySlice";

export const useCategoryLoad = (entityId: number) => {
    const loading = useSelector(selectGetLoading);
    const dispatch = useDispatch<AppDispatch>();
    const categories = useSelector(selectCategories);
    useEffect(() => {
        dispatch(loadCategoriesThunk({ entityId }));
        return () => {
            dispatch(setCategories([]));
        };
    }, []);

    return {
        loading,
        categories,
    };
};
