import { userModel } from "@entities/User";
import { apiUrl } from "@shared/api/api";
import { useState } from "react";
import { useSelector } from "react-redux";

export const useImport = () => {
    const accessToken = useSelector(userModel.selectAccessToken);
    const [isLoading, setIsLoading] = useState(false);
    const onImport = (options: any) => {
        const url = `${apiUrl}/api/import`; // File upload web service path
        const file = options.file;
        const formData = new FormData();

        formData.append("file", file);
        formData.append("filename", file.name);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        setIsLoading(true);
        xhr.upload.onprogress = ({ total, loaded }) => {};

        xhr.onload = () => {};
        xhr.onerror = function () {};
        xhr.onloadstart = function () {
            setIsLoading(true);
        };
        xhr.onloadend = function () {
            setIsLoading(false);
        };
        xhr.setRequestHeader("authorization", `Bearer ${accessToken}`);
        xhr.send(formData);
    };

    return {
        onImport,
        isLoading,
    };
};
