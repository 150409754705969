import { InputWithVariables } from "@entities/BizProcTemplate";
import { FormItem } from "@shared/ui/Form";
import UserSelectWithCalc from "../../UserSelectWithCalc";
import EditFormActionBuilder from "./EditFormActionBuilder";

interface IEditFormAction {
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}

const EditFormAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditFormAction) => {
    const header = templateItem.parameters?.header;
    const body = templateItem.parameters?.body;
    const buttonText = templateItem.parameters?.button_submit_text;
    //const fields = templateItem.parameters?.fields ?? [];

    const onChangeInputs = (name: string, value: string) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                [name]: value,
            },
        });
    };

    return (
        <>
            <FormItem label="Кто заполняет">
                <UserSelectWithCalc
                    multiple
                    bizProcTemplateId={bizProcTemplate.id!}
                    disabled={disabled}
                    templateItem={templateItem}
                    onChangeItem={setItem}
                />
            </FormItem>
            <FormItem label="Заголовок">
                <InputWithVariables
                    disabled={disabled}
                    itemId={bizProcTemplate.id!}
                    isTextArea={true}
                    onChange={onChangeInputs}
                    name="header"
                    value={header}
                />
            </FormItem>
            <FormItem label="Описание">
                <InputWithVariables
                    disabled={disabled}
                    itemId={bizProcTemplate.id!}
                    isTextArea={true}
                    onChange={onChangeInputs}
                    name="body"
                    value={body}
                />
            </FormItem>
            <FormItem label="Текст кнопки">
                <InputWithVariables
                    disabled={disabled}
                    itemId={bizProcTemplate.id!}
                    isTextArea={true}
                    onChange={onChangeInputs}
                    name="button_submit_text"
                    value={buttonText}
                />
            </FormItem>
            <EditFormActionBuilder
                disabled={disabled}
                templateItem={templateItem}
                setItem={setItem}
                bizProcTemplate={bizProcTemplate}
            />
        </>
    );
};

export default EditFormAction;
