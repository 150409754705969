import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import { Skeleton } from "antd";

const EntitySkeletonItem = () => {
    return (
        <div
            style={{
                background: "white",
                borderRadius: 8,
                width: "100%",
                marginRight: 24,
                paddingBottom: 16,
            }}
        >
            <CardWithHead
                children={
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Skeleton.Input
                                active
                                size="small"
                                style={{ height: 14, marginTop: 8 }}
                            />
                            <div>
                                <Skeleton.Avatar
                                    style={{ width: 16, height: 16, marginRight: 16 }}
                                    shape="square"
                                />
                                <Skeleton.Avatar
                                    style={{ width: 16, height: 16 }}
                                    shape="square"
                                />
                            </div>
                        </div>
                        <Skeleton.Input
                            active
                            size="small"
                            style={{ height: 16, marginTop: 8 }}
                        />
                    </div>
                }
                noColor
            />
        </div>
    );
};

export default EntitySkeletonItem;
