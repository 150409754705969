import { $api } from "@shared/api/api";
const route = "/api/bizproc-template";
const templateRoute = "/api/template-item";

export const getBizProcAllTemplates = () => {
    return $api.get<TResponseList<TBizProcTemplateItem>>(`${route}`);
};
export const getBizProcEntityTemplatesByUuid = (entityUuid: string) => {
    return $api.get<TResponseList<TBizProcTemplateItem>>(`${route}`, {
        params: {
            filter: {
                condition_type: "AND",
                conditions: [
                    {
                        key: "entity_uuid",
                        condition: "=",
                        value: `${entityUuid}`,
                    },
                ],
            },
        },
    });
};
export const getBizProcCategoryTemplates = (entityUuid: string, categoryUuid: string) => {
    return $api.get<TResponseList<TBizProcTemplateItem>>(`${route}`, {
        params: {
            filter: {
                condition_type: "AND",
                conditions: [
                    {
                        key: "category_uuid",
                        condition: "=",
                        value: `${categoryUuid}`,
                    },
                    {
                        key: "entity_uuid",
                        condition: "=",
                        value: `${entityUuid}`,
                    },
                ],
            },
        },
    });
};

export const getBizProcEntityTemplates = (entityId: number) => {
    return $api.get<TResponseList<TBizProcTemplateItem>>(`${route}/entity/${entityId}`);
};
export const getBizProcTemplateDocuments = () => {
    return $api.get<TResponseList<TDocument>>(`${route}/documents`);
};
export const getBizProcTemplateVersions = (id: number) => {
    return $api.get<TResponseList<TBizProcTemplateVersion>>(`${route}/${id}/version`);
};
export const getBizProcTemplateItem = (id: number, params?: Object) => {
    return $api.get<
        TResponse<
            TBizProcTemplateAction & {
                biz_proc_template?: TBizProcTemplateItem;
            }
        >
    >(`${templateRoute}/${id}`, {
        params,
    });
};
export const updateBizProcTemplateItem = (templateItem: TBizProcTemplateAction) => {
    return $api.put<TResponse<TBizProcTemplateAction>>(
        `${templateRoute}/${templateItem.id!}`,
        templateItem
    );
};
export const getBizProcUserTemplates = () => {
    return $api.get<TResponse<TUserTemplate[]>>(`${route}/user-templates`);
};
export const getBizProcTemplate = (
    id: number,
    withTemplateActions?: boolean,
    version?: string
) => {
    return $api.get<TResponse<TBizProcTemplateItem>>(`${route}/${id}`, {
        params: { withTemplateActions, specific_version: version },
    });
};
export const getBizProcTemplateVariables = (
    id: number,
    params?: TGetBizProcTemplateVariablesParams
) => {
    return $api.get<TResponseList<TDropdownVariablesGroup>>(`${route}/${id}/variables`, {
        params,
    });
};
export const getEntityVariables = (
    entityUuid: string,
    params?: TGetBizProcTemplateVariablesParams
) => {
    return $api.get<TResponseList<TDropdownVariablesGroup>>(
        `/api/entity/${entityUuid}/variables`,
        {
            params,
        }
    );
};
export const getBizProcTemplateActions = (id: number) => {
    return $api.get<TResponseList<TBizProcTemplateAction>>(`${route}/${id}/actions`);
};

export const createBizProcTemplateItem = (
    bizProcTemplateItem: TCreateBizProcTemplateItem
) => {
    return $api.post<TResponse<TBizProcTemplateItem>>(route, bizProcTemplateItem);
};
export const updateBizProcEntityTemplateItem = (
    bizProcTemplateItem: TBizProcTemplateItem
) => {
    return $api.put<TResponse<TBizProcTemplateItem>>(
        `${route}/${bizProcTemplateItem.id}`,
        bizProcTemplateItem
    );
};
export const deleteBizProcTemplateItem = (id: number) => {
    return $api.delete<TResponse<TBizProcTemplateItem>>(`${route}/${id}`);
};
export const startBizProcByQrCode = (link: string) => {
    return $api.get(`${route}/start/${link}`);
};
