import { FormReadValue } from "@shared/ui/Form";
import { Switch } from "antd";

interface IBooleanInput {
    value: boolean;
    onlyRead?: boolean;
    onChangeValue: (value: boolean) => void;
    disabled?: boolean;
}

const BooleanInput = ({ value, onlyRead, onChangeValue, disabled }: IBooleanInput) => {
    const onChange = (checked: boolean) => {
        onChangeValue(checked);
    };
    if (onlyRead) return <FormReadValue value={value ? "Да" : "Нет"} />;
    return (
        <>
            Выберите значение:
            <Switch
                style={{ marginLeft: 8 }}
                checked={value}
                checkedChildren="Да"
                unCheckedChildren="Нет"
                onChange={onChange}
            />
        </>
    );
};

export default BooleanInput;
