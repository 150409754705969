import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    loadCalculatedValuesThunk,
    updateCalculatedValueThunk,
    createCalculatedValueThunk,
    deleteCalculatedValueThunk,
} from "./CalculatedValuesThunk";
import { updateArrByItemId } from "@shared/lib/object-helper";

export interface ICalculatedValueSlice {
    calculatedValues: TCalculatedValue[];
    editingCalculatedValue: TCalculatedValue | TCreatingCalculatedValue | null;
    isLoading: boolean;
    error: any;
}

const initialState: ICalculatedValueSlice = {
    calculatedValues: [],
    editingCalculatedValue: null,
    isLoading: false,
    error: null,
};

export const calculatedValueSlice = createSlice({
    name: "CalculatedValueSlice",
    initialState,
    reducers: {
        setCalculatedValues: (state, action: PayloadAction<TCalculatedValue[]>) => {
            state.calculatedValues = action.payload;
            localStorage.setItem(
                "calculatedValuesLength",
                state.calculatedValues.length.toString()
            );
        },
        setEditingCalculatedValue: (
            state,
            action: PayloadAction<TCalculatedValue | TCreatingCalculatedValue | null>
        ) => {
            state.editingCalculatedValue = action.payload;
        },
        addCalculatedValue: (state, action: PayloadAction<TCalculatedValue>) => {
            state.calculatedValues.unshift(action.payload);
            localStorage.setItem(
                "calculatedValuesLength",
                state.calculatedValues.length.toString()
            );
        },
        updateCalculatedValue: (state, action: PayloadAction<TCalculatedValue>) => {
            state.calculatedValues = updateArrByItemId(
                state.calculatedValues,
                action.payload
            );
        },
        deleteCalculatedValue: (state, action: PayloadAction<TCalculatedValue>) => {
            state.calculatedValues = state.calculatedValues.filter(
                (item) => item.id !== action.payload.id
            );
            localStorage.setItem(
                "calculatedValuesLength",
                state.calculatedValues.length.toString()
            );
        },
    },
    extraReducers: (builder) => {
        loadBuilder(builder);
        createBuilder(builder);
        updateBuilder(builder);
        deleteBuilder(builder);
    },
});

const loadBuilder = (builder: ActionReducerMapBuilder<ICalculatedValueSlice>) => {
    builder.addCase(loadCalculatedValuesThunk.fulfilled, (state, action) => {
        state.calculatedValues = action.payload.data;
        localStorage.setItem(
            "calculatedValuesLength",
            state.calculatedValues.length.toString()
        );
        state.isLoading = false;
    });
    builder.addCase(loadCalculatedValuesThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
    });
    builder.addCase(loadCalculatedValuesThunk.rejected, (state, rejectedValue) => {
        state.error = rejectedValue;
        state.isLoading = false;
    });
};
const createBuilder = (builder: ActionReducerMapBuilder<ICalculatedValueSlice>) => {
    builder.addCase(createCalculatedValueThunk.fulfilled, (state, action) => {
        state.calculatedValues.unshift(action.payload.data);
        localStorage.setItem(
            "calculatedValuesLength",
            state.calculatedValues.length.toString()
        );
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<ICalculatedValueSlice>) => {
    builder.addCase(updateCalculatedValueThunk.fulfilled, (state, action) => {
        state.calculatedValues = updateArrByItemId(
            state.calculatedValues,
            action.payload.data
        );
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<ICalculatedValueSlice>) => {
    builder.addCase(deleteCalculatedValueThunk.fulfilled, (state, action) => {
        state.calculatedValues = state.calculatedValues.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem(
            "calculatedValuesLength",
            state.calculatedValues.length.toString()
        );
    });
};

export const {
    setCalculatedValues,
    updateCalculatedValue,
    deleteCalculatedValue,
    setEditingCalculatedValue,
    addCalculatedValue,
} = calculatedValueSlice.actions;
