import { navigationRouteApi, navigationRouteModel } from "@entities/NavigationRoute";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEntityNavigationRoute } from "../lib";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const usePinEntityToNavigation = (
    entityId: number,
    entityName: string,
    entityTableName: string
) => {
    const routes = useSelector(navigationRouteModel.selectNavigationRoutes);

    const [loading, setLoading] = useState(false);
    const route = routes.find((route) => route.route === `/erp/${entityTableName}`);
    const isPinned = route !== undefined;
    const dispatch = useDispatch();

    const pinEntityRoute = () => {
        const order = routes[routes.length - 1]?.order ?? 0;
        setLoading(true);
        navigationRouteApi
            .createNavigationRoute(
                getEntityNavigationRoute(entityTableName, entityName, order)
            )
            .then((response) => {
                dispatch(navigationRouteModel.createRoute(response.data.data));
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: error.message ?? "Ошибка",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const unpinEntityRoute = () => {
        if (route?.id) {
            setLoading(true);
            navigationRouteApi
                .deleteNavigationRoute(route.id)
                .then((response) => {
                    dispatch(navigationRouteModel.deleteRoute(route.id!));
                })
                .catch((error) => {
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Ошибка",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const onClick = () => {
        isPinned ? unpinEntityRoute() : pinEntityRoute();
    };
    return {
        onClick,
        loading,
        isPinned,
    };
};
