import { useState } from "react";

export const useFilterKit = (onChange: (name: string, value: any) => void) => {
    const isModal = window.innerWidth < 620;

    const [open, setOpen] = useState(false);

    const onChangeNumberInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;
        if (!/^\d*$/g.test(value)) {
            return;
        }
        onChange(name, value);
    };

    const onChangeInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;
        onChange(name, value);
    };

    const onSelect = (name: string, value: any) => {
        onChange(name, value);
    };

    const onClose = () => {
        setOpen(false);
    };

    return {
        open,
        isModal,
        onChangeInput,
        onChangeNumberInput,
        onSelect,
        setOpen,
        onClose,
    };
};
