import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { BrowserQRCodeReader, Result as TResult } from "@zxing/library";
import { bizProcTemplateApi } from "@entities/BizProcTemplate";
import { useEffect, useState } from "react";
import { getAccessMediaDevices } from "./lib";
import { soundSuccess } from "@assets/sound";
import { soundError } from "@assets/sound";
import { useSelector } from "react-redux";
import { userModel } from "@entities/User";

export const useScannerQRCode = () => {
    const isCanReadQr = useSelector(userModel.selectUserIsCanReadQr);
    const [qrDecodeResult, setQrDecodeResult] = useState<string>("");
    const [selectedDeviceId, setSelectedDeviceId] = useState<string>();
    const codeReader = new BrowserQRCodeReader(2000);
    const [statusScanner, setStatusScanner] = useState<"success" | "failed" | "pending">(
        "pending"
    );
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const [isCollapsed, setIsCollapced] = useState<boolean>(false);
    const [devices, setDevices] = useState<any[]>([]);

    useEffect(() => {
        getAccessMediaDevices(setIsAllowed, codeReader, setDevices, setSelectedDeviceId);
    }, []);

    useEffect(() => {
        navigator.mediaDevices.ondevicechange = () => {
            window.location.reload();
        };
    }, [navigator.mediaDevices]);

    useEffect(() => {
        if (statusScanner === "pending" && isAllowed) {
            decodeOnce(codeReader, selectedDeviceId);
        }
    }, [statusScanner, selectedDeviceId]);

    useEffect(() => {
        if (statusScanner !== "pending") {
            setTimeout(() => {
                setStatusScanner("pending");
                setQrDecodeResult("");
            }, 5000);
        }
    }, [statusScanner]);

    const selectDevice = (value: string) => {
        setSelectedDeviceId(value);
    };

    const collapseWindow = () => {
        setIsCollapced(!isCollapsed);
    };

    const decodeOnce = (codeReader: BrowserQRCodeReader, selectedDeviceId: any) => {
        codeReader
            .decodeOnceFromVideoDevice(selectedDeviceId, "video")
            .then((result: TResult) => {
                const qrCodeText = result.getText();
                setQrDecodeResult(qrCodeText);
                bizProcTemplateApi
                    .startBizProcByQrCode(qrCodeText)
                    .then((result: any) => {
                        new Audio(soundSuccess).play();
                        setStatusScanner("success");
                    })
                    .catch((e) => {
                        new Audio(soundError).play();
                        setStatusScanner("failed");
                        notificationEmit({
                            type: "error",
                            title: "Ошибка отправки запроса",
                            description: e.message,
                        });
                    });
            })
            .catch((err) => {
                notificationEmit({ type: "error", title: "Ошибка сканирования QR кода" });
            });
    };
    return {
        selectedDeviceId,
        isAllowed,
        isCollapsed,
        devices,
        qrDecodeResult,
        isCanReadQr,
        statusScanner,
        selectDevice,
        collapseWindow,
    };
};
