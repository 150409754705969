import { PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { useBizProcActionAdd } from "../model";

interface IBizProcActionAddButton {
    templateItemAction: TBizProcActionType;
    isFixedAction?: boolean;
    disabled?: boolean;
    parentItem: TBizProcTemplateAction;
    onAdd: (templateItem: TBizProcTemplateAction) => void;
}

const BizProcActionAddButton = (props: IBizProcActionAddButton) => {
    const { isFixedAction, disabled } = props;

    const { onClickAddButton, onClickAddFixedButton, dropdownItems } =
        useBizProcActionAdd(props);

    if (isFixedAction)
        return (
            <Button
                onClick={onClickAddFixedButton}
                shape="round"
                icon={<PlusOutlined />}
            />
        );
    return (
        <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            disabled={disabled}
            menu={{
                onClick: onClickAddButton,
                items: dropdownItems,
            }}
        >
            <Button shape="round" icon={<PlusOutlined />} disabled={disabled} />
        </Dropdown>
    );
};

export default BizProcActionAddButton;
