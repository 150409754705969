export const newConditionGroup: TIfElseBranchConditionGroup = {
    type: "group",
    logicalComparison: "and",
    id: "123",
    conditions: [],
};

export const conditionTypes = [
    {
        label: "Равно",
        value: "=",
    },

    {
        label: "Больше",
        value: ">",
    },
    {
        label: "Меньше",
        value: "<",
    },
    {
        label: "Включает",
        value: "in",
    },
    {
        label: "Вхождение",
        value: "like",
    },
];
