import { navigationRouteModel } from "@entities/NavigationRoute";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
export const useToggleEditingMode = () => {
    const dispatch = useDispatch();
    const editingNavigationMode = useSelector(navigationRouteModel.selectIsInEditingMode);
    const onToggle = () => {
        dispatch(navigationRouteModel.setEditingMode(!editingNavigationMode));
    };

    return {
        editingNavigationMode,
        onToggle,
    };
};
