import { useRef, useState } from "react";

export const useFilter = (
    initialState: any,
    request: (values: any, reset: boolean) => void
) => {
    const [values, setValues] = useState<any>(initialState);

    const timer = useRef<any>();
    const onChange = (name: string, value: any, skipRequest?: boolean) => {
        const newValues = { ...values, [name]: value };
        if (newValues)
            setValues({
                ...values,
                [name]: value,
            });
        if (!skipRequest) {
            if (timer.current) clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                request(newValues, false);
            }, 400);
        }
    };

    const onReset = () => {
        request(initialState, true);
        setValues(initialState);
    };

    const onSubmit = () => {
        request(values, false);
    };

    return {
        onChange,
        onReset,
        setValues,
        values,
        onSubmit,
    };
};
