import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectBizProcs } from "./BizProcSelectors";
import { loadBizProcsThunk } from "./BizProcThunk";
import { usePostLoadBizProcs } from "./UsePostLoadBizProcs";

export const useLoadBizProcs = (preload: boolean, preloadStatus?: TBizProcStatus) => {
    const [loading, setLoading] = useState(false);
    const bizProcs = useSelector(selectBizProcs);
    const dispatch = useDispatch<AppDispatch>();
    usePostLoadBizProcs();

    useEffect(() => {
        if (preload) {
            LoadBizProcs();
        }
    }, []);

    const LoadBizProcs = () => {
        setLoading(true);
        dispatch(
            loadBizProcsThunk({
                filter: preloadStatus
                    ? {
                          condition_type: "AND",
                          conditions: [
                              {
                                  key: "status",
                                  condition: "LIKE",
                                  value: `%${preloadStatus}%`,
                              },
                          ],
                      }
                    : undefined,
            })
        ).then(() => {
            setLoading(false);
        });
    };
    return {
        loading,
        bizProcs,
        LoadBizProcs,
    };
};
