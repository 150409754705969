import { CardWithHead } from "@shared/ui/Cards/CardWithHead";
import EditTemplateBar from "../EditTemplateBar";
import ActionCard from "./ActionCard";

interface IEmailMessageAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const EmailMessageAction = ({ onDeleteItem, templateItem }: IEmailMessageAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="DarkOrange"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Письмо на почту"}
        ></ActionCard>
    );
};

export default EmailMessageAction;
