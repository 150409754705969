export const selectIsAuthorizated = (state: RootState) => state.user.authorizated;
export const selectUser = (state: RootState): TUser => state.user.user;
export const selectUserId = (state: RootState): number | undefined => state.user.user?.id;
export const selectOpenedUserId = (state: RootState): number | null =>
    state.user.openedUserId;
export const selectAccessToken = (state: RootState): string | null =>
    state.user.access_token;
export const selectUserIsAdmin = (state: RootState): boolean => state.user.user.isAdmin;
export const selectUserIsCanReadQr = (state: RootState): TUser =>
    state.user.user.isCanReadQr;
export const selectTgAuthKey = (state: RootState): string | null => state.user.tgAuthKey;
export const selectTelegramUsername = (state: RootState): string | null =>
    state.user.telegramUsername;
export const selectIsTgLinkCreated = (state: RootState): boolean =>
    state.user.isTgLinkCreated;
