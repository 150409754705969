import ActionCard from "./ActionCard";

interface IInitialAction {
    templateItem?: TBizProcTemplateAction;
}
{
    /* <EditableCard
    onSubmitDelete={onSubmitDelete}
    deleteLoading={deleteLoading}
    onEdit={onEdit}
    onSubmit={onSubmit}
    onClose={onClose}
    isEditing={isEditing}
> */
}
const InitialAction = ({ templateItem }: IInitialAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="#1677ff"
            title={templateItem?.name ?? "Начало"}
        >
            {templateItem && templateItem.parameters?.triggerType === "form" && (
                <div style={{ color: "silver" }}>Начинается с заявки</div>
            )}
        </ActionCard>
    );
};

export default InitialAction;
