import { SettingOutlined } from "@ant-design/icons";
import { Button, Divider, Dropdown, Flex, MenuProps, theme } from "antd";
import React, { ReactNode } from "react";
import { tabModeIds } from "../constants";
import { useViewPatternSelect } from "../model";
import ViewPatternActions from "./ViewPatternActions";
import "./ViewPatternSelect.scss";

interface IViewPatternSelect {
    entityId: number;
    additionalNode?: ReactNode;
    tabMode: string;
}

const ViewPatternSelect = ({ entityId, additionalNode, tabMode }: IViewPatternSelect) => {
    const { userId, userIsAdmin, viewPatterns, currentViewPatternId, onClickPattern } =
        useViewPatternSelect(entityId, tabMode);
    const mainTheme = theme.useToken();

    const contentStyle: React.CSSProperties = {
        backgroundColor: mainTheme.token.colorBgElevated,
        borderRadius: mainTheme.token.borderRadiusLG,
        boxShadow: mainTheme.token.boxShadowSecondary,
        zIndex: "999 !important",
    };

    const items: MenuProps["items"] = [
        ...viewPatterns
            .filter(
                (pattern) =>
                    (pattern.creator_id === userId || pattern.is_public || userIsAdmin) &&
                    tabModeIds[tabMode as keyof typeof tabModeIds] === pattern.view_type
            )
            .map((viewPattern) => {
                return {
                    key: viewPattern.id,
                    style: { padding: 2 },
                    label: (
                        <Flex
                            align="center"
                            justify="space-between"
                            style={{
                                display: "flex",
                                padding: 0,
                                color:
                                    viewPattern.id === currentViewPatternId
                                        ? mainTheme.token.colorPrimary
                                        : undefined,
                            }}
                        >
                            <span
                                style={{
                                    paddingLeft: 2,
                                    maxWidth: 300,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textWrap: "nowrap",
                                }}
                            >
                                {viewPattern.name}
                            </span>
                            <Flex style={{ flex: "0 1 fit-content" }}>
                                {(userIsAdmin || userId === viewPattern.creator_id) && (
                                    <ViewPatternActions viewPattern={viewPattern} />
                                )}
                            </Flex>
                        </Flex>
                    ),
                };
            }),
    ];
    return (
        <Dropdown
            rootClassName="view-pattern-select"
            menu={{ items, onClick: onClickPattern }}
            trigger={["click"]}
            dropdownRender={(menu) =>
                additionalNode ? (
                    <div style={contentStyle}>
                        {React.cloneElement(menu as React.ReactElement, {
                            style: { boxShadow: "none", zIndex: "999 !important" },
                        })}
                        <Divider style={{ margin: 0 }} />
                        {additionalNode}
                    </div>
                ) : (
                    <></>
                )
            }
        >
            <Button icon={<SettingOutlined />} type="link" />
        </Dropdown>
    );
};

export default ViewPatternSelect;
