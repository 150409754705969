import ActionCard from "./ActionCard";

interface IDelayAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const DelayAction = ({ onDeleteItem, templateItem }: IDelayAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="Silver"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Задержка"}
        ></ActionCard>
    );
};

export default DelayAction;
