import { useEffect, useState } from "react";
import { entityFieldApi } from "@entities/EntityField";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

type TParametersValueItem = {
    key: string;
    is_calculation: boolean;
    value: any;
};

export const useEditEntityRowActions = (
    entityUuid: string,
    templateItem: TBizProcTemplateAction,
    setItem: (templateItem: TBizProcTemplateAction) => void
) => {
    const [entityFields, setEntityFields] = useState<TEntityField[]>([]);
    const [error, setError] = useState<any>(null);
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const templateValues = templateItem.parameters?.values ?? [];

    const onChangeTemplateItem = (value: any, key: string) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                values: templateValues.map(
                    (parametersValueItem: TParametersValueItem) => {
                        if (parametersValueItem.key === key) {
                            return {
                                key,
                                is_calculation:
                                    parametersValueItem.is_calculation ?? false,
                                value: value,
                            };
                        } else return parametersValueItem;
                    }
                ),
            },
        });
    };
    const onChangeEntityFields = (value: string[]) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                values: [
                    ...value.map((valueKey: string) => {
                        const existedValue = templateItem.parameters.values.find(
                            ({ key }: { key: string }) => key === valueKey
                        );
                        if (existedValue) return existedValue;
                        else
                            return {
                                key: valueKey,
                                value: undefined,
                                is_calculation: false,
                            };
                    }),
                ],
            },
        });
    };
    const onChangeField = (value: any, key: string) => {
        onChangeTemplateItem(value, key);
    };

    const onChangeCalculation = (value: any, key: string) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                values: templateValues.map(
                    (parametersValueItem: TParametersValueItem) => {
                        if (parametersValueItem.key === key) {
                            return {
                                key,
                                is_calculation: value,
                                value: undefined,
                            };
                        }
                        return parametersValueItem;
                    }
                ),
            },
        });
    };

    const onChangeCalculationInput = (value: any, key: string) => {
        onChangeTemplateItem(value, key);
    };

    const childEntityFieldsOptions = entityFields.map(
        (field): { label: string; value: string; item: TEntityField } => ({
            label: field.name,
            value: field.key,
            item: field,
        })
    );

    const choosenChildEntityFieldsKeys = templateItem.parameters?.values?.map(
        ({ key }: { key: string }) => key
    );

    const allFieldsIsSelected =
        choosenChildEntityFieldsKeys?.length === entityFields?.length;

    useEffect(() => {
        if (entityUuid) {
            setFormLoading(true);
            entityFieldApi
                .getEntityFields(entityUuid)
                .then((response) => {
                    setEntityFields(response.data.data);
                    setItem({
                        ...templateItem,
                        parameters: {
                            ...(templateItem.parameters ?? {}),
                            values: templateItem.parameters.values
                                ? templateItem.parameters.values
                                : response.data.data.map((field) => ({
                                      key: field.key,
                                      is_calculation: false,
                                      value: undefined,
                                  })),
                        },
                    });
                })
                .catch((e) => {
                    setError(e);
                    notificationEmit({
                        type: "error",
                        title: "Ошибка загрузки полей",
                        description: "Ошибка загрузки полей выбранной сущности",
                    });
                })
                .finally(() => setFormLoading(false));
        }
        return () => {
            setError(null);
            setEntityFields([]);
            setFormLoading(false);
        };
    }, [entityUuid]);

    const onToggleFields = () => {
        if (allFieldsIsSelected) {
            onChangeEntityFields([]);
        } else {
            onChangeEntityFields(entityFields.map((field) => field.key));
        }
    };

    return {
        templateValues,
        error,
        entityFields,
        formLoading,
        choosenChildEntityFieldsKeys,
        childEntityFieldsOptions,
        allFieldsIsSelected,
        onToggleFields,
        onChangeField,
        onChangeEntityFields,
        onChangeCalculation,
        onChangeCalculationInput,
    };
};
