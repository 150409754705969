import {
    FullscreenExitOutlined,
    FullscreenOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { FormItem } from "@shared/ui/Form";
import { Button, Drawer, Select } from "antd";
import { useEntityRowsKanbanConfigure } from "../model";
import "./EntityRowsKanbanConfigure.scss";
interface IEntityRowsKanbanConfigure {
    entityUuid: string;
    choosenEntityField: TEntityField | null;
    setChoosenEntityFieldId: (item: number | null) => void;
    choosenVisibleFieldsOptions: TEntityField[] | null;
    setChoosenVisibleFieldsOptionsKeys: (item: string[]) => void;
    onOpenFullscreen?: () => void;
    onCloseFullscreen?: () => void;
    isFullscreen?: boolean;
}

const EntityRowsKanbanConfigure = ({
    entityUuid,
    choosenEntityField,
    setChoosenEntityFieldId,
    choosenVisibleFieldsOptions,
    setChoosenVisibleFieldsOptionsKeys,
    onOpenFullscreen,
    onCloseFullscreen,
    isFullscreen,
}: IEntityRowsKanbanConfigure) => {
    const {
        isOpenDrawer,
        options,
        groupOptions,
        onSelectFilteringField,
        onSelectVisibleFields,
        openDrawer,
        closeDrawer,
    } = useEntityRowsKanbanConfigure(
        entityUuid,
        setChoosenEntityFieldId,
        setChoosenVisibleFieldsOptionsKeys
    );

    return (
        <>
            <Drawer onClose={closeDrawer} open={isOpenDrawer}>
                <FormItem label="Поле для группировки">
                    <Select
                        value={choosenEntityField?.key}
                        allowClear
                        placeholder="Выберите поле для группировки"
                        options={groupOptions}
                        style={{ width: "100%" }}
                        onChange={onSelectFilteringField}
                    />
                </FormItem>

                {choosenEntityField?.key && (
                    <FormItem label="Отображаемые поля">
                        <Select
                            value={
                                choosenVisibleFieldsOptions?.map(
                                    (option) => option.key
                                ) ?? undefined
                            }
                            allowClear
                            placeholder="Выберите видимые поля"
                            style={{ width: "100%" }}
                            options={options}
                            maxTagCount={4}
                            mode="multiple"
                            onChange={onSelectVisibleFields}
                        />
                    </FormItem>
                )}
            </Drawer>
            <div
                className="kanban-toolbar"
                style={{ display: "flex", justifyContent: "flex-end" }}
            >
                <Button
                    icon={
                        isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
                    }
                    style={{ marginRight: 8 }}
                    onClick={!isFullscreen ? onOpenFullscreen : onCloseFullscreen}
                >
                    {isFullscreen ? "Свернуть" : "Во весь экран"}
                </Button>
                <Button onClick={openDrawer} icon={<SettingOutlined />} />
            </div>
        </>
    );
};

export default EntityRowsKanbanConfigure;
