import { stageModel } from "@entities/Stage";
import { deleteStageThunk } from "@entities/Stage/model";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useStageDelete = (entityId: number, categoryId: number, stageId: number) => {
    const deleteLoading = useSelector((state: RootState) =>
        stageModel.selectDeleteLoading(state, stageId)
    );
    const dispatch = useDispatch<AppDispatch>();

    const deleteStage = () => {
        dispatch(
            deleteStageThunk({
                entityId,
                stageId,
                categoryId,
            })
        );
    };

    return {
        deleteLoading,
        deleteStage,
    };
};
