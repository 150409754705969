export const getValidParams = (values: any) => {
    const conditions: any[] = [];
    Object.entries(values).forEach(([key, value]: [key: string, value: any]) => {
        if (value !== null && value !== "" && value.length > 0) {
            const buff: any = {};
            buff.key = key;
            buff.condition = key === "assigned_id" ? "IN" : "LIKE";
            buff.value =
                buff.condition === "LIKE" ? `%${value}%` : `(${value.join(",")})`;
            conditions.push(buff);
        }
    });

    return conditions;
};
