import { Modal } from "antd";
import { useEstimationTemplateEditModal } from "../model";
import EstimationTemplateEditForm from "./EstimationTemplateEditForm";

interface IEstimationTemplateEditModal {}

const EstimationTemplateEditModal = ({}: IEstimationTemplateEditModal) => {
    const { creatingEditingEstimation, isOpen, closeModal } =
        useEstimationTemplateEditModal();
    return (
        <Modal
            open={isOpen}
            destroyOnClose
            title={<></>}
            footer={[]}
            onCancel={closeModal}
        >
            <EstimationTemplateEditForm initialEstimation={creatingEditingEstimation} />
        </Modal>
    );
};

export default EstimationTemplateEditModal;
