const defaultTransition = {
    duration: 0.1,
    delay: 0,
    type: "tween",
};

export const collapseVariants = {
    closed: {
        height: 0,
        transition: defaultTransition,
        overflow: "hidden",
    },
    open: {
        height: "fit-content",
        transition: defaultTransition,
        transitionEnd: {
            overflow: "hidden",
        },
    },
};

export const variants = {
    closed: {
        overflow: "hidden",
        width: "100%",
        height: 20,
        transition: defaultTransition,
    },
    open: {
        width: "100%",
        height: 110,
        transition: defaultTransition,
        transitionEnd: {
            overflow: "auto",
        },
    },
};

export const arrowVariants = {
    closed: {
        rotate: 0,
        transition: defaultTransition,
    },
    open: {
        rotate: 180,
        transition: defaultTransition,
    },
};

export const textVariants = {
    closed: {
        color: "#000",
        transition: defaultTransition,
    },
    open: {
        color: "#808080",
        transition: defaultTransition,
    },
};
