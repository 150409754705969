import { FormReadValue } from "@shared/ui/Form";
import { Input } from "antd";
import { CSSProperties } from "react";

interface INumberInput {
    value: number;
    onlyRead?: boolean;
    onChangeValue: (value: any) => void;
    disabled?: boolean;
    readValueTextStyle?: CSSProperties;
}

const NumberInput = ({
    value,
    onlyRead,
    readValueTextStyle,
    onChangeValue,
    disabled,
}: INumberInput) => {
    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value;

        const reg = /^-?\d*(\.\d*)?$/;
        if (!reg.test(value) && value !== "") {
            return;
        }

        onChangeValue(
            value === "" ? (null as any as number) : value === "-" ? "-" : value
        );
    };
    if (onlyRead)
        return (
            <FormReadValue readValueTextStyle={readValueTextStyle} value={value ?? "-"} />
        );
    return (
        <Input disabled={onlyRead || disabled} onChange={onChange} value={value ?? ""} />
    );
};

export default NumberInput;
