import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVariables, createVariable, updateVariable, deleteVariable } from "../api";

export const loadVariablesThunk = createAsyncThunk(
    "VariableSlice/loadVariables",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getVariables();
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateVariableThunk = createAsyncThunk(
    "VariableSlice/updateVariable",
    async (variable: TVariable, { rejectWithValue }) => {
        try {
            const response = await updateVariable(variable);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createVariableThunk = createAsyncThunk(
    "VariableSlice/createVariable",
    async (variable: TVariable, { rejectWithValue }) => {
        try {
            const response = await createVariable(variable);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const deleteVariableThunk = createAsyncThunk(
    "VariableSlice/deleteVariable",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteVariable(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
