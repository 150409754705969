import {
    getTimestampFormattedValue,
    getTimestampPickerHumanFormat,
    getTimestampToLocalTimePickerFormat,
} from "@shared/lib/date";
import { FormReadValue } from "@shared/ui/Form";
import { DatePicker } from "antd";

interface ITimestampInput {
    value: any;
    onChangeValue: (value: any | null) => void;
    additionalParameters: TAdditionalParameters;
    disabled?: boolean;
    onlyRead?: boolean;
}

const TimestampInput = ({
    value,
    onlyRead,
    additionalParameters,
    disabled,
    onChangeValue,
}: ITimestampInput) => {
    const onChange = (value: any | null, dateString: string) => {
        onChangeValue(getTimestampFormattedValue(value));
    };
    const onChangeBetween = (values: any, formatString: [string, string]) => {
        onChangeValue([
            getTimestampFormattedValue(values?.[0]),
            getTimestampFormattedValue(values?.[1]),
        ]);
    };
    const isBetween = additionalParameters?.isBetween;
    if (onlyRead) {
        if (isBetween) {
            return (
                <div style={{ display: "flex" }}>
                    <FormReadValue
                        value={value ? getTimestampPickerHumanFormat(value?.[0]) : "-"}
                    />
                    {`->`}
                    <FormReadValue
                        value={value ? getTimestampPickerHumanFormat(value?.[1]) : "-"}
                    />
                </div>
            );
        } else {
            return (
                <FormReadValue
                    value={value ? getTimestampPickerHumanFormat(value) : "-"}
                />
            );
        }
    }
    if (isBetween) {
        return (
            <DatePicker.RangePicker
                disabled={disabled}
                style={{ width: "100%" }}
                format={"DD.MM.YYYY HH:mm"}
                value={[
                    getTimestampToLocalTimePickerFormat(value?.[0]),
                    getTimestampToLocalTimePickerFormat(value?.[1]),
                ]}
                picker="date"
                changeOnBlur
                // placeholder={field.name}
                onChange={onChangeBetween}
            />
        );
    }

    return (
        <DatePicker
            style={{ width: 180 }}
            format={"DD.MM.YYYY HH:mm"}
            value={getTimestampToLocalTimePickerFormat(value)}
            picker="date"
            showTime
            changeOnBlur
            // placeholder={field.name}
            onChange={onChange}
        />
    );
};

export default TimestampInput;
