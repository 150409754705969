import { Modal } from "antd";
import CategoryCreateUpdateForm from "./CategoryCreateUpdateForm";

interface ICategoryCreateUpdateModal {
    category: TCategory | TCategoryCreating;
    entityId: number;
    isOpen: boolean;
    onCancel: () => void;
}

const CategoryCreateUpdateModal = ({
    category,
    entityId,
    isOpen,
    onCancel,
}: ICategoryCreateUpdateModal) => {
    return (
        <Modal
            title={`${"id" in category ? "Редактирование" : "Создание"} категории`}
            open={isOpen}
            onCancel={onCancel}
            destroyOnClose
            footer={[]}
        >
            <CategoryCreateUpdateForm
                entityId={entityId}
                category={category}
                onCancel={onCancel}
                onSave={onCancel}
            />
        </Modal>
    );
};

export default CategoryCreateUpdateModal;
