import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";

interface IStageSelect {
    multiple?: boolean;
    onlyRead?: boolean;
    categoryValue?: number | string | null;
    value: number | string | null | number[] | string[];
    entityId: number;
    initialOptions?: TOption[];
    onChangeValue: (value: any) => void;
}

const StageSelect = ({
    multiple,
    onlyRead,
    categoryValue,
    value,
    initialOptions,
    entityId,
    onChangeValue,
}: IStageSelect) => {
    return (
        <PaginatedSelect
            valueToString
            onlyRead={onlyRead}
            placeholder="Стадия"
            multiple={multiple}
            requestUrl={
                categoryValue
                    ? `${apiUrl}/api/entity/${entityId}/categories/${categoryValue}/stages`
                    : `${apiUrl}/api/entity/${entityId}/all-categories/all-stages`
            }
            value={value}
            valueKey="id"
            labelKey="label"
            onChangeValue={onChangeValue}
            initialOptions={initialOptions ?? []}
        />
    );
};

export default StageSelect;
