import { QRCode } from "antd";
import "./QRCodeGenerator.scss";

type TQRCodeWithName = {
    link: string;
    name: string;
};
interface IQRCodeGenerator {
    orientation: "p" | "l";
    qrCodeSize: number;
    qrCodesWithNames: TQRCodeWithName[];
    qrCodeId: string;
}

const QRCodeGenerator = ({
    orientation,
    qrCodeSize,
    qrCodesWithNames,
    qrCodeId,
}: IQRCodeGenerator) => {
    return (
        <div className="qr-codes-chunk-container" id={`${qrCodeId}`}>
            <div
                className="qr-codes-chunk-container__body"
                style={{
                    lineHeight: `${qrCodeSize * 0.18}px`,
                    fontSize: Math.floor(qrCodeSize * 0.13),
                    width: orientation === "p" ? 800 : 1064,
                    height: orientation === "p" ? "fit-content" : 800,
                }}
            >
                {qrCodesWithNames.map((qrCodeWithName, index) => {
                    return (
                        <div
                            className="qr-codes-chunk-container__item"
                            key={index}
                            style={{
                                width: qrCodeSize + 16,
                            }}
                        >
                            <QRCode
                                size={qrCodeSize}
                                type="svg"
                                value={`${qrCodeWithName.link}`}
                                bgColor="#fff"
                                style={{ marginBottom: 0 }}
                            />
                            <span className="qr-codes-chunk-container__item-label">
                                {qrCodeWithName.name}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default QRCodeGenerator;
