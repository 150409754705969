import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getCalculatedValues,
    createCalculatedValue,
    updateCalculatedValue,
    deleteCalculatedValue,
} from "../api";

export const loadCalculatedValuesThunk = createAsyncThunk(
    "CalculatedValueSlice/loadCalculatedValues",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getCalculatedValues();
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateCalculatedValueThunk = createAsyncThunk(
    "CalculatedValueSlice/updateCalculatedValue",
    async (CalculatedValue: TCalculatedValue, { rejectWithValue }) => {
        try {
            const response = await updateCalculatedValue(CalculatedValue);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createCalculatedValueThunk = createAsyncThunk(
    "CalculatedValueSlice/createCalculatedValue",
    async (CalculatedValue: TCalculatedValue, { rejectWithValue }) => {
        try {
            const response = await createCalculatedValue(CalculatedValue);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const deleteCalculatedValueThunk = createAsyncThunk(
    "CalculatedValueSlice/deleteCalculatedValue",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteCalculatedValue(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
