import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Dropdown } from "antd";
import { MenuProps } from "antd/lib";
import dayjs from "dayjs";
import { formatOfChoosingDate, schedulerFormats } from "../constants";
import { useCustomToolbar } from "../model";
import ConfigureSchedulerModal from "./ConfigureSchedulerModal";
import "./CustomToolbar.scss";
interface ICustomToolbar {
    viewMode: TScheduleViewMode;
    date: Date;
    entityFields: TEntityField[];
    entityUuid: string;
    dateStartKey: string | undefined;
    dateEndKey: string | undefined;
    eventLabel: string | undefined;
    eventDescription: string | undefined;
    setDateStartKey: (item: any) => void;
    setDateEndKey: (item: any) => void;
    setEventLabel: (item: any) => void;
    setEventDescription: (item: any) => void;
    setViewMode: (item: TScheduleViewMode) => void;
    setDate: (item: Date) => void;
}

const CustomToolbar = ({
    viewMode,
    date,
    entityFields,
    dateStartKey,
    dateEndKey,
    eventLabel,
    eventDescription,
    entityUuid,
    setDateStartKey,
    setDateEndKey,
    setEventLabel,
    setEventDescription,
    setViewMode,
    setDate,
}: ICustomToolbar) => {
    const {
        isOpenConfigure,
        pickerFormat,
        dateText,
        onTodayClick,
        onNextClick,
        onPrevClick,
        onChange,
        onOpenConfigure,
        onCloseConfigure,
    } = useCustomToolbar(viewMode, date, entityUuid, setDate);

    const windowWidth = window.innerWidth;

    const items: MenuProps["items"] = Object.entries(schedulerFormats).map(
        ([key, value]) => ({
            key,
            label: value,
            style: { padding: 4, borderRadius: 4 },
            onClick: () => setViewMode(key as typeof viewMode),
        })
    );
    return (
        <div style={{ position: "sticky", left: 0 }}>
            <ConfigureSchedulerModal
                entityFields={entityFields}
                isOpen={isOpenConfigure}
                dateStartKey={dateStartKey}
                dateEndKey={dateEndKey}
                eventLabel={eventLabel}
                eventDescription={eventDescription}
                viewMode={viewMode}
                setViewMode={setViewMode}
                date={date}
                onClose={onCloseConfigure}
                setDateStartKey={setDateStartKey}
                setDateEndKey={setDateEndKey}
                setEventLabel={setEventLabel}
                setEventDescription={setEventDescription}
                setDate={setDate}
            />

            <div className="calendar-toolbar">
                <div className="calendar-toolbar__button-group">
                    <div className="calendar-toolbar__current-date-nav">
                        <Button
                            type="text"
                            onClick={onPrevClick}
                            icon={<ArrowLeftOutlined />}
                        />
                        <div className="calendar-toolbar__current-date">{dateText}</div>
                        <Button
                            type="text"
                            onClick={onNextClick}
                            icon={<ArrowRightOutlined />}
                        />
                    </div>
                    <div
                        className="calendar-toolbar__wrapper-items"
                        style={{
                            marginBottom: 8,
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            className="calendar-toolbar__today-button"
                            type="text"
                            onClick={onTodayClick}
                        >
                            Сегодня
                        </Button>
                        {windowWidth >= 1420 ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "fit-content",
                                    flexWrap: "wrap",
                                    gap: 8,
                                    marginLeft: 8,
                                }}
                            >
                                <div className="calendar-toolbar__format-picker">
                                    <Dropdown
                                        overlayStyle={{ zIndex: 1000 }}
                                        menu={{ items }}
                                        trigger={["click"]}
                                    >
                                        <Button
                                            style={{
                                                width: 200,
                                            }}
                                        >
                                            Формат: {schedulerFormats[viewMode]}
                                        </Button>
                                    </Dropdown>
                                </div>
                                <div className="calendar-toolbar__date-picker">
                                    Выбрать {formatOfChoosingDate[viewMode]}
                                    :
                                    <DatePicker
                                        style={{ width: 200, marginLeft: 8 }}
                                        onChange={onChange}
                                        value={dayjs(date)}
                                        picker={pickerFormat}
                                    />
                                </div>
                                <div>
                                    <Button onClick={onOpenConfigure}>
                                        Конфигуратор календаря
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <Button
                                style={{ marginLeft: 8 }}
                                icon={<SettingOutlined />}
                                onClick={onOpenConfigure}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomToolbar;
