import { DragAndDropList } from "@shared/ui/DragAndDropList";
import FieldSelect from "./FieldSelect";
import "./FormFieldsListCreator.scss";
import FormListCreatorItem from "./FormListCreatorItem";

interface IFormFieldsCreator {
    fields: TBizProcTemplateField[];
    onClickCreateVariable?: () => void;
    onChangeSelectedFields: (fields: TBizProcTemplateField[]) => void;
    onChangeFields: (fields: TBizProcTemplateActionField[]) => void;
    selectedFields: any[];
    valueKey: string;
    disabled?: boolean;
    labelKey: string;
}

const FormFieldsListCreator = ({
    fields,
    selectedFields,
    onChangeFields,
    onChangeSelectedFields,
    onClickCreateVariable,
    labelKey,
    disabled,
    valueKey,
}: IFormFieldsCreator) => {
    return (
        <div className="fields-list-creator">
            <FieldSelect
                disabled={disabled}
                onChangeFields={onChangeSelectedFields}
                selectedFields={selectedFields}
                onClickCreateVariable={onClickCreateVariable}
                fields={fields}
            />
            <DragAndDropList
                disabled={disabled}
                keyName="unique_id"
                items={selectedFields}
                onChangeItems={onChangeFields}
                draggableItem={(dragHandleProps, item) => (
                    <FormListCreatorItem
                        disabled={disabled}
                        dragHandleProps={dragHandleProps}
                        field={item}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        selectedFields={selectedFields}
                        onChangeFields={onChangeFields}
                    />
                )}
            />
        </div>
    );
};

export default FormFieldsListCreator;
