import { DownloadOutlined } from "@ant-design/icons";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Button, QRCode } from "antd";

interface IQrCodeTemplateGroup {
    qrCodeTemplateGroup: TQrCodeTemplateGroup;
}

const QrCodeTemplateGroup = ({ qrCodeTemplateGroup }: IQrCodeTemplateGroup) => {
    const downloadQRCode = (qrCode: TQrCodeLink, templateName: string) => {
        const canvas = document
            .getElementById(qrCode.id.toString())
            ?.querySelector<HTMLCanvasElement>("canvas");

        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement("a");
            a.download = `${templateName}.jpg`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };
    return (
        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            {qrCodeTemplateGroup.items.map((qrCode) => {
                return (
                    <IndentContainer
                        type={["marginRight", "marginBottom"]}
                        key={qrCode.id}
                    >
                        <div
                            onClick={() =>
                                downloadQRCode(
                                    qrCode,
                                    qrCodeTemplateGroup.bizProcTemplateName
                                )
                            }
                            id={`${qrCode.id}`}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    cursor: "pointer",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                }}
                            >
                                <QRCode
                                    size={200}
                                    value={`${qrCode.link}`}
                                    bgColor="white"
                                    color="black"
                                    style={{ marginBottom: 0 }}
                                />
                                <Button type="link" icon={<DownloadOutlined />}>
                                    {qrCodeTemplateGroup.bizProcTemplateName}
                                </Button>
                            </div>
                        </div>
                    </IndentContainer>
                );
            })}
        </div>
    );
};

export default QrCodeTemplateGroup;
