import { Card, Statistic, Typography } from "antd";
import "./EstimationValuesListItem.scss";

interface IEstimationValuesListItem {
    value: any;
    datatype?: TFieldDatatype;
    name?: string;
    extraInput?: React.ReactNode;
    extendingClassName?: string;
}

const EstimationValuesListItem = ({
    value,
    datatype,
    name,
    extraInput,
    extendingClassName,
}: IEstimationValuesListItem) => {
    return (
        <Card
            className={`estimation-card ${extendingClassName ? extendingClassName : ""}`}
            bordered={false}
        >
            <Statistic
                groupSeparator={undefined}
                title={
                    extraInput ? (
                        extraInput
                    ) : (
                        <Typography.Text className="estimation-title">
                            {name}
                        </Typography.Text>
                    )
                }
                value={value}
                precision={datatype === "float" ? 2 : 0}
            />
        </Card>
    );
};

export default EstimationValuesListItem;
