// import { ArcherContainer, ArcherElement } from 'react-archer';
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Button, Input } from "antd";
import { Xwrapper } from "react-xarrows";
import { useBizProcEditor } from "../model";
import ArcherElement from "./ArcherElement";
import "./BizProcTemplate.scss";
import BizProcTemplateItem from "./BizProcTemplateItem";
import { BizProcTemplateSelectVersion } from "@entities/BizProcTemplate";
// import { ConnectProvider, Connect } from 'react-connect-lines';

interface IBizProcTemplateEditor {
    initialBizProcTemplateItem: TBizProcTemplateItem;
}

const BizProcTemplateEditor = ({
    initialBizProcTemplateItem,
}: IBizProcTemplateEditor) => {
    const {
        bizProcTemplate,
        name,
        loading,
        disabled,
        version,
        onChangeName,
        onSave,
        onChangeTemplateItem,
        onChangeVersion,
    } = useBizProcEditor(initialBizProcTemplateItem);

    return (
        <>
            <IndentContainer type={["marginBottom", "marginTop"]}>
                <div className="template-editor--head">
                    <div>
                        <Input
                            style={{
                                width: 250,
                            }}
                            disabled={disabled}
                            value={name}
                            onChange={onChangeName}
                        />
                        <Button
                            loading={loading}
                            type="primary"
                            onClick={onSave}
                            style={{ marginLeft: 10 }}
                            disabled={disabled}
                        >
                            Сохранить
                        </Button>
                    </div>
                    <div className="template-editor--head__version">
                        <BizProcTemplateSelectVersion
                            bizProcTemplateId={initialBizProcTemplateItem.id!}
                            currentVersion={initialBizProcTemplateItem.version}
                            onChangeVersion={onChangeVersion}
                            value={version}
                        />
                    </div>
                </div>
            </IndentContainer>
            <div className="template-editor">
                {/* <ArcherContainer
                    strokeWidth={1}
                    ref={archerRef}
                    endMarker={false}
                    lineStyle="angle"
                    strokeColor="black"> */}
                <Xwrapper>
                    {bizProcTemplate.map((templateItem, index) => {
                        const onAddTemplateItem_ = (
                            prevTemplateItem: TBizProcTemplateAction,
                            newTemplateItem: TBizProcTemplateAction
                        ) => {
                            const index = templateItem.children.findIndex(
                                (item) => item.key === prevTemplateItem.key
                            );
                            templateItem.children.splice(index + 1, 0, newTemplateItem);
                            onChangeTemplateItem({
                                ...templateItem,
                                children: templateItem.children,
                            });
                        };

                        const onDeleteTemplateItemParent = (
                            deletedTemplateItem: TBizProcTemplateAction
                        ) => {
                            onChangeTemplateItem({
                                ...templateItem,
                                children: templateItem.children.filter(
                                    (item) => item.key !== deletedTemplateItem.key
                                ),
                            });
                        };

                        return (
                            <BizProcTemplateItem
                                index={index}
                                disabled={disabled}
                                key={templateItem.key}
                                parentItem={templateItem}
                                isParentWithRowChild={true}
                                onDeleteTemplateItem={onDeleteTemplateItemParent}
                                onAddTemplateItem={onAddTemplateItem_}
                                onChangeTemplateItem={onChangeTemplateItem}
                                parentNextItem={bizProcTemplate?.[index + 1]}
                                templateGroupItems={bizProcTemplate}
                                templateItem={templateItem}
                            />
                        );
                    })}
                </Xwrapper>
                <ArcherElement relations={[]} id={`end`}>
                    <div
                        style={{
                            marginTop: 20,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        Конец
                    </div>
                </ArcherElement>
                {/* </ArcherContainer> */}
            </div>
        </>
    );
};

export default BizProcTemplateEditor;
