import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
    applyAdditionalSumEstimationTemplateThunk,
    applySumEstimationTemplateThunk,
    createSumEstimationTemplateThunk,
    deleteSumEstimationTemplateThunk,
    loadEntitySumTemplatesThunk,
    updateSumEstimationTemplateThunk,
} from "./EstimationThunk";
import { IEstimationSlice } from "./EstimationSlice";

const loadBuilder = (builder: ActionReducerMapBuilder<IEstimationSlice>) => {
    builder.addCase(loadEntitySumTemplatesThunk.fulfilled, (state, action) => {
        state.estimations = action.payload.data;
        state.loading = false;
    });
    builder.addCase(loadEntitySumTemplatesThunk.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(loadEntitySumTemplatesThunk.rejected, (state, rejectedValue) => {
        state.loading = false;
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IEstimationSlice>) => {
    builder.addCase(updateSumEstimationTemplateThunk.fulfilled, (state, action) => {
        const updatedEstimationTemplate = action.payload.data;
        state.estimations = state.estimations.map((item) => {
            if (item.id === updatedEstimationTemplate.id)
                return updatedEstimationTemplate;
            return item;
        });
        state.savingLoading = false;
    });
    builder.addCase(updateSumEstimationTemplateThunk.pending, (state) => {
        state.savingLoading = true;
    });
    builder.addCase(updateSumEstimationTemplateThunk.rejected, (state, rejectedValue) => {
        state.savingLoading = false;
    });
};
const createBuilder = (builder: ActionReducerMapBuilder<IEstimationSlice>) => {
    builder.addCase(createSumEstimationTemplateThunk.fulfilled, (state, action) => {
        const createdEstimationTemplate = action.payload.data;
        state.estimations = [...state.estimations, createdEstimationTemplate];
        state.savingLoading = false;
    });
    builder.addCase(createSumEstimationTemplateThunk.pending, (state) => {
        state.savingLoading = true;
    });
    builder.addCase(createSumEstimationTemplateThunk.rejected, (state, rejectedValue) => {
        state.savingLoading = false;
    });
};
const applyBuilder = (builder: ActionReducerMapBuilder<IEstimationSlice>) => {
    builder.addCase(applySumEstimationTemplateThunk.fulfilled, (state, action) => {
        const appliedEstimationTemplate = action.payload.data.data;
        const nameOfAppliedEstimationTemplate = action.payload.name;
        const idOfAppliedEstimationTemplate = action.payload.id;
        state.appliedEstimation = {
            name: nameOfAppliedEstimationTemplate,
            values: appliedEstimationTemplate,
            id: idOfAppliedEstimationTemplate,
        };
        state.applyingLoading = false;
    });
    builder.addCase(applySumEstimationTemplateThunk.pending, (state) => {
        state.applyingLoading = true;
    });
    builder.addCase(applySumEstimationTemplateThunk.rejected, (state, rejectedValue) => {
        state.applyingLoading = false;
    });
};
const applyAdditionalBuilder = (builder: ActionReducerMapBuilder<IEstimationSlice>) => {
    builder.addCase(
        applyAdditionalSumEstimationTemplateThunk.fulfilled,
        (state, action) => {
            const appliedEstimationTemplate = action.payload.data;
            state.additionalAppliedEstimation = appliedEstimationTemplate;
        }
    );
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IEstimationSlice>) => {
    builder.addCase(deleteSumEstimationTemplateThunk.fulfilled, (state, action) => {
        const deletedTemplateId = action.payload;
        state.estimations = state.estimations.filter(
            (template) => template.id !== deletedTemplateId
        );
        state.loading = false;
    });
    builder.addCase(deleteSumEstimationTemplateThunk.pending, (state) => {
        state.loading = true;
    });
    builder.addCase(deleteSumEstimationTemplateThunk.rejected, (state, rejectedValue) => {
        state.loading = false;
    });
};

export const estimationBuilders = (
    builder: ActionReducerMapBuilder<IEstimationSlice>
) => {
    loadBuilder(builder);
    updateBuilder(builder);
    createBuilder(builder);
    applyBuilder(builder);
    applyAdditionalBuilder(builder);
    deleteBuilder(builder);
};
