import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCreateAction } from "../model";
import "./ActionCreateButton.scss";

interface IActionCreateButton {}

const ActionCreateButton = ({}: IActionCreateButton) => {
    const { onAdd } = useCreateAction();

    return (
        <div className="action-create-button">
            <Button onClick={onAdd} icon={<PlusOutlined />}>
                Создать блок
            </Button>
        </div>
    );
};

export default ActionCreateButton;
