import { Modal, Select } from "antd";
import { useConfigureFilterInputsModal } from "../model/UseConfigureFilterInputsModal";

interface IConfigureFilterInputsModal {
    entityId: number;
    isOpenConfiguringModal: boolean;
    entityFields: TEntityField[];
    currentFilters: TFilterValue[];
    visibleInputs: TBizProcFormField[];
    onSubmitFilterFieldsModal: (fieldsKeys: string[]) => void;
    closeConfiguringModal: () => void;
}

const ConfigureFilterInputsModal = ({
    isOpenConfiguringModal,
    entityFields,
    visibleInputs,
    closeConfiguringModal,
    onSubmitFilterFieldsModal,
}: IConfigureFilterInputsModal) => {
    const {
        choosenFilterInputs,
        filterInputsOptions,
        onSelectNewVisibleInputs,
        onSubmitNewFilterInputs,
        onCancelConfiguringModal,
    } = useConfigureFilterInputsModal(
        entityFields,
        visibleInputs,
        closeConfiguringModal,
        onSubmitFilterFieldsModal
    );

    return (
        <div onClick={(event) => event.stopPropagation()}>
            <Modal
                onCancel={onCancelConfiguringModal}
                title={"Конфигурация полей фильтрации"}
                open={isOpenConfiguringModal}
                onOk={onSubmitNewFilterInputs}
            >
                <Select
                    value={choosenFilterInputs}
                    allowClear
                    filterOption={(input: any, option) => {
                        return option?.label?.includes(input) === true;
                    }}
                    mode="multiple"
                    options={filterInputsOptions}
                    style={{ width: "100%" }}
                    onChange={onSelectNewVisibleInputs}
                />
            </Modal>
        </div>
    );
};

export default ConfigureFilterInputsModal;
