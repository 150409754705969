import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    deleteEntityRow,
    deleteEntityRows,
    getEntityRowsFiltered,
    getEntityRowsWithPage,
    saveEntityRow,
} from "../api";

export const getEntityRowsWithPageThunk = createAsyncThunk(
    "EntityRowSlice/loadEntityRowWithPage",
    async (params: TGetEntityRowsParamsWithPage, { rejectWithValue }) => {
        try {
            const response = await getEntityRowsWithPage(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const getEntityRowsByDatesThunk = createAsyncThunk(
    "EntityRowSlice/loadEntityRowWithDates",
    async (
        {
            id,
            filter,
            textFilter,
        }: {
            id: number;
            filter: TSingleCondition | TMultipleCondition;
            textFilter?: string | undefined | null;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await getEntityRowsFiltered(id, { filter, textFilter });
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const saveEntityRowsThunk = createAsyncThunk(
    "EntityRowSlice/saveEntityRow",
    async (params: TSaveEntityRowParams, { rejectWithValue }) => {
        try {
            const response = await saveEntityRow(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const deleteEntityRowThunk = createAsyncThunk(
    "EntityRowSlice/deleteEntityRow",
    async (
        {
            entityId,
            entityRowId,
            key,
        }: { entityId: number; entityRowId: number; key?: number | string },
        { rejectWithValue }
    ) => {
        try {
            await deleteEntityRow(entityId, entityRowId);
            return entityRowId;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const deleteEntityRowsThunk = createAsyncThunk(
    "EntityRowSlice/deleteEntityRows",
    async (
        {
            entityId,
            entityRowIds,
            key,
        }: { entityId: number; entityRowIds: number[]; key?: string | number },
        { rejectWithValue }
    ) => {
        try {
            const response = await deleteEntityRows(entityId, entityRowIds);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
