import { Modal } from "antd";
import ViewPatternEditForm from "./ViewPatternEditForm";
import { useSelector } from "react-redux";
import { viewPatternModel } from "@entities/ViewPattern";
import { useDispatch } from "react-redux";

interface IViewPatternEditModal {
    entityId: number;
}

const ViewPatternEditModal = ({ entityId }: IViewPatternEditModal) => {
    const editingViewPattern = useSelector(viewPatternModel.selectEditingViewPattern);
    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(viewPatternModel.setEditingViewPattern(null));
    };
    return (
        <Modal
            title={`${editingViewPattern && "id" in editingViewPattern ? "Редактирование" : "Создание"} шаблона просмотра`}
            open={editingViewPattern != null}
            onCancel={onCancel}
            destroyOnClose
            width={"90%"}
            footer={[]}
        >
            {editingViewPattern && (
                <ViewPatternEditForm
                    entityId={entityId}
                    initialViewPattern={editingViewPattern}
                />
            )}
        </Modal>
    );
};

export default ViewPatternEditModal;
