import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createFieldOption,
    deleteFieldOption,
    getFieldOptions,
    updateFieldOption,
} from "../api";

export const loadFieldOptionsThunk = createAsyncThunk(
    "FieldOptionSlice/loadFieldOptionFields",
    async ({ fieldId, params, type }: TFieldOptionLoadParams, { rejectWithValue }) => {
        try {
            const response = await getFieldOptions(fieldId, type, params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateFieldOptionThunk = createAsyncThunk(
    "FieldOptionSlice/saveFieldOption",
    async (
        { fieldId, fieldOption, type }: TFieldOptionUpdateParams,
        { rejectWithValue }
    ) => {
        try {
            const response = await updateFieldOption(fieldId, fieldOption, type);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);

export const createFieldOptionThunk = createAsyncThunk(
    "FieldOptionSlice/createFieldOption",
    async (
        { fieldId, fieldOption, type }: TFieldOptionCreateParams,
        { rejectWithValue }
    ) => {
        try {
            const response = await createFieldOption(fieldId, fieldOption, type);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteFieldOptionThunk = createAsyncThunk(
    "FieldOptionSlice/deleteFieldOption",
    async (
        { fieldId, fieldOptionId, type }: TFieldOptionDeleteParams,
        { rejectWithValue }
    ) => {
        try {
            const response = await deleteFieldOption(fieldId, fieldOptionId, type);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
