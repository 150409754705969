import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";

interface IListAnimation<T> {
    classNameList?: string;
    classNameItem?: string;
    layoutId: string;
    items: T[];
    isMounted: boolean;
    itemRenderItem: (item: T) => ReactNode;
}

const ListAnimation = <T extends object>({
    classNameList,
    classNameItem,
    layoutId,
    items,
    isMounted,
    itemRenderItem,
}: IListAnimation<T>) => {
    return (
        <motion.div layout layoutId={layoutId} className={classNameList}>
            <AnimatePresence>
                {items.map((item: any, index) => {
                    return (
                        <motion.div
                            initial={{ x: isMounted ? -200 : 0, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            // whileHover={{ scale: 1.2 }}
                            // whileTap={{ scale: 0.8 }}
                            transition={
                                isMounted
                                    ? {
                                          type: "spring",
                                          damping: 20,
                                          stiffness: 100,
                                      }
                                    : undefined
                            }
                            exit={{ opacity: 0 }}
                            key={item?.id ?? index}
                            className={classNameItem}
                        >
                            {itemRenderItem(item)}
                        </motion.div>
                    );
                })}
            </AnimatePresence>
        </motion.div>
    );
};

export default ListAnimation;
