import { entityFieldApi } from "@entities/EntityField";
import { userModel } from "@entities/User";
import { viewPatternModel } from "@entities/ViewPattern";
import {
    viewPatternItemApi,
    viewPatternItemLib,
    viewPatternItemModel,
} from "@entities/ViewPatternItem";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useViewPatternEdit = (
    entityId: number,
    initialViewPattern: TViewPattern
) => {
    const dispatch = useDispatch<AppDispatch>();
    const userIsAdmin = useSelector(userModel.selectUserIsAdmin);
    const userId = useSelector(userModel.selectUserId);
    const viewPatternItems = useSelector((state) =>
        viewPatternItemModel.selectorViewPatternItems(
            state,
            initialViewPattern && "id" in initialViewPattern
                ? initialViewPattern?.id
                : null
        )
    );
    const [editableViewPattern, setEditableViewPattern] =
        useState<TViewPattern>(initialViewPattern);
    const viewPatternName = editableViewPattern.name;
    const viewPatternViewTypes = editableViewPattern.view_type;
    const viewPatternIsDefault = editableViewPattern.type === "default";
    const viewPatternIsPublic = editableViewPattern.is_public;
    const viewPatternRolesIds = editableViewPattern.roles_ids;
    const viewPatternUsersIds = editableViewPattern.users_ids;
    const viewPatternDepartmentsIds = editableViewPattern.departments_ids;

    const [createLoading, setCreateLoading] = useState<{
        [key: string | number]: boolean;
    }>({});

    const [entityFields, setEntityFields] = useState<TEntityField[]>([]);

    const { defferedCallback } = useDefferedCallback();
    const { defferedCallback: defferedCallbackPattern } = useDefferedCallback();

    useEffect(() => {
        dispatch(
            viewPatternItemModel.loadViewPatternItemsThunk({
                viewPatternId: initialViewPattern?.id,
            })
        );
        entityFieldApi
            .getEntityFields(undefined, entityId)
            .then((response) => {
                setEntityFields(response.data.data);
            })
            .catch(() => {})
            .finally(() => {});
        return () => {
            // dispatch(viewPatternItemModel.setViewPatternItems([]));
        };
    }, []);

    const onReorderItems = (items: TViewPatternItem[]) => {
        if (!initialViewPattern) return;
        const newItems = items.map((item, index) => ({
            ...item,
            order: index,
        }));
        const latestItems = viewPatternItems;
        dispatch(
            viewPatternItemModel.massUpdateViewPatternItem({
                viewPatternId: initialViewPattern.id,
                viewPatternItems: newItems,
            })
        );
        defferedCallback(() =>
            viewPatternItemApi
                .massUpdateViewPatternItems(
                    newItems.map((item) => ({
                        id: item.id,
                        order: item.order,
                    }))
                )
                .then(() => {})
                .catch(() => {
                    dispatch(
                        viewPatternItemModel.massUpdateViewPatternItem({
                            viewPatternId: initialViewPattern.id,
                            viewPatternItems: latestItems,
                        })
                    );
                    notificationEmit({
                        type: "error",
                        title: "Не удалось изменить порядок!",
                    });
                })
        );
    };
    const viewPatternItemsPrepared =
        viewPatternItemLib.prepareViewPatternItems(viewPatternItems);

    const onChangePattern = (newPattern: TViewPattern, onError: () => void) => {
        defferedCallbackPattern(() =>
            dispatch(viewPatternModel.updateViewPatternThunk(newPattern))
                .then(parseFormResponse)
                .catch(onError)
        );
    };
    const onChangeEditableViewPattern = (value: any, key: keyof TViewPattern) => {
        const newPattern = {
            ...editableViewPattern,
            [key]: value,
        };

        setEditableViewPattern(newPattern);
        onChangePattern(newPattern, () => {
            setEditableViewPattern(initialViewPattern);
        });
    };
    const onChangeViewTypes = (type: number, options: any) => {
        onChangeEditableViewPattern(type, "view_type");
    };

    const onChangeName = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeEditableViewPattern(event.target.value, "name");
    };
    const onChangeIsPublic = () => {
        onChangeEditableViewPattern(!viewPatternIsPublic, "is_public");
    };
    const onChangeIsDefault = () => {
        if (initialViewPattern) {
            const newPattern = {
                ...editableViewPattern,
                type: viewPatternIsDefault ? "view" : "default",
                is_public: !viewPatternIsDefault ? true : viewPatternIsPublic,
            } as TViewPattern;
            setEditableViewPattern(newPattern);
            onChangePattern(newPattern, () => {
                setEditableViewPattern(initialViewPattern);
            });
        }
    };
    const onChangeRolesIds = (value: number[]) => {
        onChangeEditableViewPattern(value, "roles_ids");
    };
    const onChangeDepartmentsIds = (value: number[]) => {
        onChangeEditableViewPattern(value, "departments_ids");
    };
    const onChangeUsersIds = (value: number[]) => {
        onChangeEditableViewPattern(value, "users_ids");
    };
    const allowEdit = userIsAdmin || userId === initialViewPattern?.creator_id;
    return {
        viewPatternName,
        viewPatternViewTypes,
        viewPatternIsDefault,
        viewPatternIsPublic,
        viewPatternRolesIds,
        viewPatternDepartmentsIds,
        viewPatternUsersIds,
        viewPattern: initialViewPattern,
        entityFields,
        viewPatternItems: viewPatternItemsPrepared,
        userIsAdmin,
        allowEdit,
        createLoading,
        setCreateLoading,
        onReorderItems,
        onChangeViewTypes,
        onChangeName,
        onChangeIsDefault,
        onChangeIsPublic,
        onChangeRolesIds,
        onChangeDepartmentsIds,
        onChangeUsersIds,
    };
};
