import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useAddEntityRowObserver } from "../model";
import EntityRowObserverForm from "./EntityRowObserverForm";

interface IAddEntityRowObserver {
    entityUuid: string;
    small?: boolean;
    bizProcTemplates: TBizProcTemplateItem[];
}

const AddEntityRowObserver = ({
    entityUuid,
    bizProcTemplates,
    small,
}: IAddEntityRowObserver) => {
    const { isOpen, onAdd, onClose } = useAddEntityRowObserver(entityUuid);
    return (
        <>
            <Modal destroyOnClose open={isOpen} onCancel={onClose} footer={[]}>
                <EntityRowObserverForm
                    onCancel={onClose}
                    entityUuid={entityUuid}
                    bizProcTemplates={bizProcTemplates}
                />
            </Modal>
            {small ? (
                <Button
                    icon={<PlusCircleOutlined />}
                    onClick={onAdd}
                    shape="circle"
                    style={{ border: "none", boxShadow: "none" }}
                />
            ) : (
                <Button type="primary" onClick={onAdd}>
                    Добавить слушатель
                </Button>
            )}
        </>
    );
};

export default AddEntityRowObserver;
