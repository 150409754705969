import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { useCreateButton } from "../model";
import "./CalculatedValueCreateButton.scss";

const CalculatedValueCreateButton = () => {
    const { onClick } = useCreateButton();

    return (
        <div className="calculated-value-create-button">
            <Button onClick={onClick} icon={<PlusOutlined />}>
                Создать переменную
            </Button>
        </div>
    );
};

export default CalculatedValueCreateButton;
