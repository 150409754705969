import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDragAndDropList } from "./model";
import { ReactNode } from "react";
import { getItemStyle, getListStyle } from "./lib";

interface IDragAndDropList<T> {
    items: T[];
    keyName: keyof T;
    direction?: "horizontal" | "vertical";
    disabled?: boolean;
    className?: string;
    onChangeItems: (items: T[], moveIndex: number, targetIndex: number) => void;
    draggableItem: (dragHandleProps: any, item: T, index: number) => ReactNode;
}

const DragAndDropList = <T extends object>({
    items,
    keyName,
    disabled,
    direction,
    className,
    draggableItem,
    onChangeItems,
}: IDragAndDropList<T>) => {
    const { onDragEnd } = useDragAndDropList({
        onChangeItems,
        items,
    });
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction={direction}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={className}
                        style={getListStyle(snapshot.isDraggingOver)}
                    >
                        {items.map((item, index) => {
                            return (
                                <Draggable
                                    isDragDisabled={disabled}
                                    key={item[keyName]?.toString() as string}
                                    draggableId={item[keyName]?.toString() as string}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {draggableItem(
                                                provided.dragHandleProps,
                                                item,
                                                index
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDropList;
