export const prepareDataForDndList = <T>(
    data: T[],
    columnsTypes: Array<{
        label: string;
        value: string;
        key: string;
        isEndedPagination?: boolean;
    }>,
    columnValue: keyof T,
    unicKey: keyof T
) => {
    const columns = columnsTypes.map((columnType) => {
        return prepareAndFillColumn(data, columnValue, unicKey, columnType);
    });
    return columns;
};

const prepareAndFillColumn = <T>(
    data: T[],
    columnValue: keyof T,
    unicKey: keyof T,
    columnType: TColumnType
) => {
    const preparedColumn: TPreparedData = {
        ...columnType,
        items: [],
    };
    data.forEach((el) => {
        const id = String(el[unicKey]);
        if (el[columnValue] === columnType.value) {
            preparedColumn.items.push({
                key: id,
                pureItem: el,
            });
        }
    });

    return preparedColumn;
};
