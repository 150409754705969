interface IUseConditionItem {
    condition: TIfElseBranchCondition;
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    onChange: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
    onDelete: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
}

export const useConditionItem = ({
    condition,
    bizProcTemplate,
    templateItem,
    onChange,
    onDelete,
}: IUseConditionItem) => {
    const onSelectVariable = (
        item: string,
        option: {
            value: string;
            label: string;
            variable: TEntityField | TVariable;
        }
    ) => {
        const variable = option.variable;
        onChange({
            ...condition,
            variable: variable,
            value: null,
        });
    };
    const onSelectCondition = (
        item: string,
        option: {
            value: string;
            label: string;
        }
    ) => {
        onChange({
            ...condition,
            comparison: item,
        });
    };
    const onChangeValue = (value: any, inputId?: any, option?: any) => {
        if (option?.option?.variable_type === "calculated_value") {
            const newValue = value;
            onChange({
                ...condition,
                value: newValue,
                isCalculatedValue: true,
            });

            return;
        }

        onChange({
            ...condition,
            value: value,
            isCalculatedValue: false,
        });
    };
    const onChangeLogicalComparison = () => {
        onChange({
            ...condition,
            logicalComparison: condition.logicalComparison === "and" ? "or" : "and",
        });
    };
    const onDeleteItem = () => {
        onDelete(condition);
    };

    return {
        onChangeLogicalComparison,
        onChangeValue,
        onDeleteItem,
        onSelectCondition,
        onSelectVariable,
    };
};
