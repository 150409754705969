import { entityRowModel } from "@entities/EntityRow";
import { estimationLib, estimationModel } from "@entities/Estimations";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { localStorageDataSaveOrDelete } from "../lib";

export const useEntityRowsActions = (
    entityId: number,
    selectedRows: any[],
    onSelectRows: (rows: any[]) => void,
    levelKey?: number | string
) => {
    const isCalculateAdditionalEstimation = useSelector(
        estimationModel.selectIsCalculateAdditional
    );
    const choosenEntityFieldsKeysForEstimation = useSelector(
        estimationModel.selectChoosenEntityFieldsKeys
    );

    const choosenEstimation = useSelector(estimationModel.selectAppliedEstimation);

    const capabilities = useSelector(entityRowModel.selectEntityUserCapabilities);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const allowUpdate = capabilities.update;
    const allowDelete = capabilities.delete;
    const dispatch = useDispatch<AppDispatch>();
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const entityFieldsOptions =
        estimationLib.getSummableEntityFieldsOptions(entityFields);
    const onDelete = () => {
        setDeleteLoading(true);
        dispatch(
            entityRowModel.deleteEntityRowsThunk({
                key: levelKey,
                entityId,
                entityRowIds: selectedRows.map((row) => row.id),
            })
        )
            .then(() => {
                onSelectRows([]);
            })
            .catch((e: any) => {})
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    const onChangeAdditionalCalculation = (event: CheckboxChangeEvent) => {
        localStorageDataSaveOrDelete(event.target.checked, entityId);
        if (choosenEstimation) {
            const choosenEstimationFieldsKeys = choosenEstimation?.values.map(
                ({ key }) => key
            );
            dispatch(
                estimationModel.setChoosenEntityFieldsKeysForEstimations(
                    choosenEstimationFieldsKeys
                )
            );
        }
        dispatch(estimationModel.setIsCalculateAdditional(event.target.checked));
    };

    const onChooseEntityFieldsKeysForEstimation = (value: string[]) => {
        if (value.length > 0)
            localStorage.setItem(
                estimationLib.getAdditionalSumStorageKey(entityId),
                JSON.stringify(value)
            );
        else localStorage.removeItem(estimationLib.getAdditionalSumStorageKey(entityId));
        dispatch(estimationModel.setChoosenEntityFieldsKeysForEstimations(value));
    };

    return {
        entityFieldsOptions,
        allowDelete,
        allowUpdate,
        deleteLoading,
        isCalculateAdditionalEstimation,
        choosenEntityFieldsKeysForEstimation,
        onDelete,
        onChangeAdditionalCalculation,
        onChooseEntityFieldsKeysForEstimation,
    };
};
