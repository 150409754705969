import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { FormItem } from "@shared/ui/Form";
import { Select } from "antd";
import CommonCreateEntityRowForm from "../CommonCreateEntityRowForm";

interface IEditCreateChildEntityRowAction {
    bizProcTemplate: TBizProcTemplateItem;
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}
const EditCreateChildEntityRowAction = ({
    templateItem,
    bizProcTemplate,
    disabled,
    setItem,
}: IEditCreateChildEntityRowAction) => {
    const entityFields = bizProcTemplate.fields as Omit<
        TBizProcTemplateField,
        "id"
    >[] as TEntityField[];
    const entityFieldsOptions = entityFields
        .filter((item) => item.datatype === "childEntity")
        .map((field): { label: string; value: string; item: TEntityField } => ({
            label: field.name,
            value: field.key,
            item: field,
        }));
    const choosenEntityFieldKey: string = templateItem.parameters?.entity_field_key;
    const choosenEntityFieldKeyRealatedUuid = bizProcTemplate.fields?.find(
        (field) => choosenEntityFieldKey === field.key
    )?.related_entity_uuid;

    const onChangeEntityField = (value: string, option: any) => {
        setItem({
            ...templateItem,
            parameters: value
                ? {
                      entity_field_key: value,
                      values: null,
                  }
                : {},
        });
    };

    return (
        <div>
            <IndentContainer>
                <FormItem label="Выберите поля сущности">
                    <Select
                        allowClear
                        style={{ width: "100%" }}
                        disabled={disabled}
                        value={choosenEntityFieldKey}
                        onChange={onChangeEntityField}
                        options={entityFieldsOptions}
                    />
                </FormItem>
            </IndentContainer>
            {choosenEntityFieldKeyRealatedUuid && (
                <CommonCreateEntityRowForm
                    disabled={disabled}
                    entityUuid={choosenEntityFieldKeyRealatedUuid}
                    templateItem={templateItem}
                    bizProcTemplateId={bizProcTemplate.id!}
                    setItem={setItem}
                />
            )}
        </div>
    );
};

export default EditCreateChildEntityRowAction;
