import { actionModel } from "@entities/Action";
import { bizProcTemplateModel } from "@entities/BizProcTemplate";
import { BizProcTemplateEditor } from "@features/BizProcTemplate/BizProcTemplateEditor";
import { useHeaderLayout } from "@shared/ui/Header";
import { Spin } from "antd";
import { useParams } from "react-router-dom";

interface IBizProcEditorPage {}

const BizProcEditorPage = (props: IBizProcEditorPage) => {
    const params = useParams();
    const bizprocId = params.bizprocId as string;
    const { bizProcTemplateItem, loading } =
        bizProcTemplateModel.useLoadBizProcTemplateItem(+bizprocId, true, true);
    useHeaderLayout({
        title: "Редактор бизнес-процессов",
    });
    actionModel.useLoadActions(true);
    if (loading)
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white",
                }}
            >
                <Spin />
            </div>
        );
    if (bizProcTemplateItem === null) return <></>;

    return (
        <div style={{ width: "100%", height: "90%" }}>
            <BizProcTemplateEditor initialBizProcTemplateItem={bizProcTemplateItem} />
        </div>
    );
};

export default BizProcEditorPage;
