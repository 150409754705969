import { CreateEditEntityRow } from "@features/CreateEditEntityRow";
import {
    StagesEntityRow,
    StagesEntityRowSkeleton,
} from "@features/StageFeatures/StagesEntityRow";
import { Drawer } from "antd";
import "./CreateEditEntityRowDrawer.scss";
import EntityNameBookmark from "./EntityNameBookmark";
import { useCreateEditEntityRowDrawer } from "./UseCreateEditEntityRowDrawer";
interface ICreateEditEntityRowDrawer {
    isOpenDrawer: boolean;
    entityId?: number;
    level?: number;
    hideMask?: boolean;
    entityTableName?: string;
    entityRowId?: number | null;
    initialValues?: { [key: string]: any } | null;
    hideHistory?: boolean;
    history?: (props: {
        entityRowId: number;
        entityId?: number;
        entityTableName?: string;
    }) => React.ReactNode | null;
    openCloseDrawerWithNavigate: (
        isOpenDrawer: boolean,
        entityRowId?: number | null
    ) => void;
}

const CreateEditEntityRowDrawer = ({
    isOpenDrawer,
    level,
    entityId,
    entityTableName,
    entityRowId,
    initialValues,
    hideMask,
    hideHistory,
    history,
    openCloseDrawerWithNavigate,
}: ICreateEditEntityRowDrawer) => {
    const { onlyReadMode, setOnlyReadMode, onCloseDrawer, afterOpenChange } =
        useCreateEditEntityRowDrawer({
            openCloseDrawerWithNavigate,
        });
    const windowWidth = window.innerWidth;
    const shortVariant = windowWidth <= 900;
    const drawerLevel = level ?? 0;
    return (
        <Drawer
            destroyOnClose={true}
            onClose={onCloseDrawer}
            width={!shortVariant ? "fit-content" : "100vw"}
            styles={{
                body: {
                    background: "rgb(143,133,232, 0.1)",
                    padding: 0,
                    overflow: "visible",
                },
                content: {
                    overflow: "visible",
                },
                mask: {
                    backdropFilter: "blur(1px)",
                },
            }}
            closeIcon={shortVariant}
            zIndex={1000}
            maskClosable={onlyReadMode}
            mask={!hideMask}
            open={isOpenDrawer}
            afterOpenChange={afterOpenChange}
        >
            <div
                className="entity-row-content"
                style={{
                    width: `calc(${
                        hideHistory
                            ? "560px"
                            : !shortVariant
                              ? entityRowId
                                  ? "60vw"
                                  : "526px"
                              : "100vw"
                    } + ${drawerLevel * 30}px)`,
                }}
            >
                <CreateEditEntityRow
                    title={({
                        entityRow,
                        entity,
                        isLoading,
                        entityRowCapabilities,
                        editableEntityFieldsWithValues,
                        onChangeEntityRow,
                    }) => {
                        const canUpdateStage =
                            entityRowCapabilities?.update &&
                            editableEntityFieldsWithValues.some(
                                (field) => field.key === "stage"
                            );
                        return (
                            <>
                                <>
                                    {!shortVariant && entity && (
                                        <EntityNameBookmark
                                            entity={entity}
                                            zIndex={(1 + drawerLevel) * 2000}
                                            top={30 + drawerLevel * 20}
                                            onClose={onCloseDrawer}
                                        />
                                    )}
                                </>
                                {isLoading ? (
                                    <StagesEntityRowSkeleton withMargins />
                                ) : (
                                    entityRowId &&
                                    entity?.id &&
                                    entityRow?.["category"] && (
                                        <StagesEntityRow
                                            onlyRead={!canUpdateStage}
                                            entityRow={entityRow}
                                            categoryId={entityRow?.["category"]}
                                            withMargins
                                            entityId={entity.id}
                                            onChangeEntityRow={onChangeEntityRow}
                                        />
                                    )
                                )}
                            </>
                        );
                    }}
                    entityId={entityId}
                    //setEntity={setEntity}
                    entityTableName={entityTableName}
                    entityRowId={entityRowId}
                    onlyReadMode={entityRowId ? onlyReadMode : false}
                    setOnlyReadMode={setOnlyReadMode}
                    additionalActions={onCloseDrawer}
                    history={
                        entityRowId &&
                        history &&
                        history({ entityRowId, entityId, entityTableName })
                    }
                    initialValues={initialValues}
                    hideHistory={hideHistory}
                />
            </div>
        </Drawer>
    );
};

export default CreateEditEntityRowDrawer;
