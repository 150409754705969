import { createSelector } from "@reduxjs/toolkit";

export const selectorViewPatternItems = createSelector(
    [
        (state: RootState) => state.viewPatternItem.viewPatternItems,
        (_: any, viewPatternId: number | null) => viewPatternId,
    ],
    (
        viewPatternItems: { [key: number]: TViewPatternItem[] },
        viewPatternId: number | null
    ) => (viewPatternId ? viewPatternItems[viewPatternId] ?? [] : [])
);

export const selectGetLoading = (state: RootState): boolean =>
    state.viewPatternItem.getLoading;
export const selectUpdateLoading = (
    state: RootState,
    viewPatternItemId: number
): boolean => state.viewPatternItem.updateLoading.includes(viewPatternItemId);
export const selectCreateLoading = (state: RootState): boolean =>
    state.viewPatternItem.createLoading;
export const selectDeleteLoading = (
    state: RootState,
    viewPatternItemId: number
): boolean => state.viewPatternItem.deleteLoading.includes(viewPatternItemId);
