import { Tooltip } from "antd";
import { View } from "react-big-calendar";
import CustomPopup from "./CustomPopup";
import { groupColors } from "./CustomYearView";
import { getColor } from "@shared/lib/colorizing";

const CustomTileContent = ({
    date,
    findedEvents,
    events,
    onView,
    onNavigate,
}: {
    date: Date;
    findedEvents: EventItem[];
    events: any;
    onView: ((view: View) => void) | undefined;
    onNavigate: any;
}) => {
    return (
        <Tooltip
            color="white"
            destroyTooltipOnHide={true}
            getPopupContainer={(triggerNode) => {
                return document.querySelector(".calendar-popup-container")!;
            }}
            trigger="click"
            title={
                <CustomPopup
                    date={date}
                    events={events}
                    onNavigate={onNavigate}
                    onView={onView}
                />
            }
            zIndex={110}
            overlayInnerStyle={{
                color: "black",
                padding: 0,
                width: "fit-content",
                height: "fit-content",
            }}
            overlayStyle={{
                color: "black",
                padding: 0,
                width: "fit-content",
                height: "fit-content",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 104,
                }}
            >
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        zIndex: 105,
                    }}
                >
                    {date.getDate()}
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 104,
                    }}
                >
                    {findedEvents.map((event, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    top: (100 / findedEvents.length) * index + "%",
                                    left: 0,
                                    right: 0,
                                    height: 101 / findedEvents.length + "%",
                                    background: getColor(
                                        event.data.resource[event.data.eventLabelKey],
                                        groupColors
                                    ),
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </Tooltip>
    );
};

export default CustomTileContent;
