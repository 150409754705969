import { PaginatedSelect } from "@shared/ui/PaginatedSelect";
import { useEntityRowCategorySelect } from "../model";
import { apiUrl } from "@shared/api/api";

interface IEntityRowCategorySelect {
    entityId: number;
}

const EntityRowCategorySelect = ({ entityId }: IEntityRowCategorySelect) => {
    const { currentCategory, onChangeCategory } = useEntityRowCategorySelect(entityId);
    return (
        <PaginatedSelect
            onlyRead={false}
            multiple={false}
            requestUrl={`${apiUrl}/api/entity/${entityId}/categories`}
            value={currentCategory}
            placeholder="Категория"
            valueKey="id"
            labelKey="label"
            onChangeValue={onChangeCategory}
            initialOptions={[]}
        />
    );
};

export default EntityRowCategorySelect;
