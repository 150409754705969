import { bizProcApi, bizProcModel } from "@entities/BizProc";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { Button, Popconfirm } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { useState } from "react";
import { useDispatch } from "react-redux";

interface IFillActionFormDeleteBizProc {
    triggerButtonProps?: BaseButtonProps;
    triggerText?: string;
    targetForm: TBizProcAction<TBizProcFormParameters>;
    onDeleted?: () => void;
}

const FillActionFormDeleteBizProc = ({
    triggerButtonProps,
    triggerText,
    targetForm,
    onDeleted,
}: IFillActionFormDeleteBizProc) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch();

    const onDeleteBizProc = () => {
        setDeleteLoading(true);
        bizProcApi
            .deleteBizProc(targetForm!.biz_proc_id)
            .then(() => {
                dispatch(bizProcModel.deleteUserAction(targetForm!));
                onDeleted && onDeleted();
            })
            .catch(() => {
                notificationEmit({
                    title: "Не удалось завершить бизнес-процесс",
                    type: "error",
                });
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    return (
        <Popconfirm
            title="Завершить бизнес-процесс?"
            okText="Завершить"
            okButtonProps={{
                danger: true,
            }}
            onConfirm={onDeleteBizProc}
            cancelText="Отмена"
        >
            <Button danger loading={deleteLoading} type="link" {...triggerButtonProps}>
                {triggerText ?? "Завершить"}
            </Button>
        </Popconfirm>
    );
};

export default FillActionFormDeleteBizProc;
