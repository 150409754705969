import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createViewPattern,
    deleteViewPattern,
    getViewPatterns,
    updateViewPattern,
} from "../api";

export const loadViewPatternsThunk = createAsyncThunk(
    "ViewPatternSlice/loadViewPatternsThunk",
    async (props: { params?: TParams }, { rejectWithValue }) => {
        try {
            const response = await getViewPatterns(props.params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateViewPatternThunk = createAsyncThunk(
    "ViewPatternSlice/updateViewPatternThunk",
    async (viewPattern: TViewPattern, { rejectWithValue }) => {
        try {
            const response = await updateViewPattern(viewPattern);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createViewPatternThunk = createAsyncThunk(
    "ViewPatternSlice/createViewPatternThunk",
    async (viewPattern: TViewPatternCreating, { rejectWithValue }) => {
        try {
            const response = await createViewPattern(viewPattern);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteViewPatternThunk = createAsyncThunk(
    "ViewPatternSlice/deleteViewPatternThunk",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteViewPattern(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
