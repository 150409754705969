import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userModel } from "@entities/User";

export const useUserTelegramLink = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch<AppDispatch>();
    const telegramUsername = useSelector(userModel.selectTelegramUsername);
    const isTgLinkCreated = useSelector(userModel.selectIsTgLinkCreated);

    useEffect(() => {
        dispatch(userModel.isLinkedUserTelegramThunk()).then(() => setLoading(false));
        return () => {
            // if (isTgLinkCreated && telegramUsername === null)
            //     dispatch(unlinkUserTelegramThunk());
        };
    }, []);

    const unlinkTg = () => {
        dispatch(userModel.unlinkUserTelegramThunk());
    };

    const linkTg = () => {
        dispatch(userModel.linkUserTelegramThunk()).then(() => setLoading(true));
    };

    return {
        loading,
        telegramUsername,
        isTgLinkCreated,
        unlinkTg,
        linkTg,
    };
};
