import { CopyOutlined, QrcodeOutlined } from "@ant-design/icons";
import { FormItem } from "@shared/ui/Form";
import { Drawer, Input, Select } from "antd";
import { useGenerateQRCodeConfig } from "../model";
import GenerateQRCodeDrawerActions from "./GenerateQRCodeDrawerActions";

interface IGenerateQRCodeConfig {
    pdfOrientation: "p" | "l";
    isOpenDrawer: boolean;
    qrCodeSize: number;
    setPdfOrientation: (item: "p" | "l") => void;
    setQrCodeSize: (item: number) => void;
    setIsOpenDrawer: (item: boolean) => void;
    onPreload: () => void;
}

const GenerateQRCodeConfig = ({
    pdfOrientation,
    isOpenDrawer,
    qrCodeSize,
    setPdfOrientation,
    setQrCodeSize,
    setIsOpenDrawer,
    onPreload,
}: IGenerateQRCodeConfig) => {
    const {
        isError,
        errorText,
        onSelectPdfOrientation,
        changeQrCodeSize,
        onCloseDrawer,
    } = useGenerateQRCodeConfig(
        pdfOrientation,
        setPdfOrientation,
        setQrCodeSize,
        setIsOpenDrawer
    );

    return (
        <Drawer
            extra={
                <GenerateQRCodeDrawerActions
                    onPreload={onPreload}
                    onOkDrawer={onCloseDrawer}
                />
            }
            onClose={onCloseDrawer}
            open={isOpenDrawer}
            getContainer={false}
        >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <FormItem
                    label={
                        <div>
                            <CopyOutlined /> Ориентация PDF документа:
                        </div>
                    }
                >
                    <Select
                        value={pdfOrientation}
                        onChange={onSelectPdfOrientation}
                        style={{ width: "100%" }}
                        options={[
                            { value: "l", label: "Горизонтальная" },
                            { value: "p", label: "Вертикальная" },
                        ]}
                    />
                </FormItem>
                <FormItem
                    label={
                        <div>
                            <QrcodeOutlined /> Размер QR кода:
                        </div>
                    }
                >
                    <>
                        <Input value={qrCodeSize} onChange={changeQrCodeSize} />
                        {isError && (
                            <span style={{ color: "red", fontSize: 12 }}>
                                {errorText}
                            </span>
                        )}
                    </>
                </FormItem>
            </div>
        </Drawer>
    );
};

export default GenerateQRCodeConfig;
