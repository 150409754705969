import { StageSelect } from "@entities/Stage";
import { useEntityRowsActionsChangeStage } from "../model";
import { Button } from "antd";
import { CategorySelect } from "@entities/Category";

interface IEntityRowsActionsChangeStage {
    entityId: number;
    selectedRows: any[];
    onSuccess?: () => void;
}

const EntityRowsActionsChangeStage = ({
    entityId,
    selectedRows,
    onSuccess,
}: IEntityRowsActionsChangeStage) => {
    const {
        stage,
        category,
        categories,
        isSingleCategory,
        disabled,
        loading,
        onChangeStage,
        onChangeCategory,
        onSubmit,
    } = useEntityRowsActionsChangeStage(entityId, selectedRows, onSuccess);
    return (
        <div style={{ width: "100%", display: "flex" }}>
            {!disabled && !isSingleCategory && (
                <div style={{ width: "100%", maxWidth: 250, marginLeft: 15 }}>
                    <CategorySelect
                        entityId={entityId}
                        multiple={false}
                        value={category}
                        onChangeValue={onChangeCategory}
                    />
                </div>
            )}
            {!disabled && (
                <div
                    style={{
                        width: "100%",
                        maxWidth: 250,
                        marginRight: 15,
                    }}
                >
                    <StageSelect
                        entityId={entityId}
                        multiple={false}
                        value={stage}
                        onChangeValue={onChangeStage}
                        categoryValue={isSingleCategory ? categories[0] : category}
                    />
                </div>
            )}
            <Button type="link" onClick={onSubmit} disabled={disabled} loading={loading}>
                Перевести в стадию
            </Button>
        </div>
    );
};

export default EntityRowsActionsChangeStage;
