import { FormItem } from "@shared/ui/Form";
import { ErrorText } from "@shared/ui/Text";
import { Input, Modal, Select } from "antd";
import { useCreateUpdateVariable } from "../model/UseCreateUpdateVariable";
import { ReactNode } from "react";
import { parseErrorMessageByKey } from "@shared/api/ParseResponse";
import { variableParentsOptions } from "../constants";
interface IVariableModifyModal {
    editingVariable: TVariable | null;
    datatypeDependingInputs?: <T>(props: {
        editingField: T;
        error?: TValidationError;
        setEditingField: (editingField: T) => void;
    }) => ReactNode;
    formInputItem: (props: TFieldInput) => React.ReactNode;
    onSuccess?: (variable: TVariable, isCreating?: boolean) => void;
}
const VariableModifyModal = ({
    onSuccess,
    editingVariable,
    formInputItem,
    datatypeDependingInputs,
}: IVariableModifyModal) => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    const {
        onClose,
        onChangeName,
        onOk,
        onSelectParentType,
        onSelectParentId,
        onChangeType,
        onChangeVariableValue,
        setVariable,
        types,
        options,
        error,
        isOpen,
        variable,
    } = useCreateUpdateVariable(editingVariable, onSuccess);

    return (
        <Modal
            title={`${variable?.is_creating ? "Создание" : "Редактирование"} переменной`}
            open={isOpen}
            onOk={onOk}
            onCancel={onClose}
            width={"600px"}
            style={{
                top: windowHeight <= 700 ? "10%" : undefined,
                padding: windowWidth <= 700 ? 0 : undefined,
            }}
        >
            <FormItem label="Наименование">
                <>
                    <Input
                        name="name"
                        status={error.data?.["name"] ? "error" : undefined}
                        value={variable?.name}
                        onChange={onChangeName}
                        placeholder="Наименование"
                    />
                    <ErrorText absolute text={parseErrorMessageByKey(error, "name")} />
                </>
            </FormItem>
            <FormItem label="Ключ">
                <>
                    <Input
                        name="key"
                        status={error.data?.["key"] ? "error" : undefined}
                        value={variable?.key}
                        onChange={onChangeName}
                        placeholder="Ключ"
                    />
                    <ErrorText absolute text={parseErrorMessageByKey(error, "key")} />
                </>
            </FormItem>

            <FormItem label="Тип переменной">
                <>
                    <Select
                        options={types}
                        value={variable?.datatype ?? null}
                        onChange={onChangeType}
                        style={{ width: "100%" }}
                    />
                    <ErrorText
                        absolute
                        text={parseErrorMessageByKey(error, "datatype")}
                    />
                </>
            </FormItem>

            {datatypeDependingInputs &&
                datatypeDependingInputs({
                    error,
                    editingField: variable,
                    setEditingField: setVariable,
                })}

            {variable?.datatype && (
                <FormItem label="Значение переменной">
                    <>
                        {formInputItem({
                            onChange: onChangeVariableValue,
                            value: variable.value,
                            datatype: variable.datatype,
                            onlyRead: false,
                            field: variable,
                        })}
                    </>
                </FormItem>
            )}
            <FormItem label="Тип родителя">
                <>
                    <Select
                        style={{ width: "100%" }}
                        options={variableParentsOptions}
                        value={variable?.parent_type}
                        onSelect={onSelectParentType}
                    />
                    {error.data?.["parent_type"] && (
                        <ErrorText text={error.data?.["parent_type"][0]} />
                    )}
                </>
            </FormItem>
            {variable?.parent_type === "global" ? (
                <FormItem label="Идентификатор родителя">
                    <>
                        <Input
                            name="parent_id"
                            status={error.data?.["parent_id"] ? "error" : undefined}
                            value={""}
                            onChange={onChangeName}
                            disabled={true}
                            placeholder="Выбран глобальный тип родителя"
                        />
                        {error.data?.["parent_id"] && (
                            <ErrorText text={error.data?.["parent_id"][0]} />
                        )}
                    </>
                </FormItem>
            ) : (
                <FormItem label="Идентификатор родителя">
                    <Select
                        style={{ width: "100%" }}
                        options={options}
                        value={variable?.parent_id && variable.parent_id}
                        placeholder={"Выберите идентификатор"}
                        onSelect={onSelectParentId}
                    />
                </FormItem>
            )}
        </Modal>
    );
};

export default VariableModifyModal;
