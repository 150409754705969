import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { ReactNode } from "react";
import "./CardWithActions.scss";
interface ICardWithActions {
    children: ReactNode;
    onClick?: () => void;
    onSubmitDelete: () => void;
    onEdit?: () => void;
    deleteLoading?: boolean;
    extraNode?: React.ReactNode;
}

const CardWithActions = ({
    children,
    onClick,
    onSubmitDelete,
    deleteLoading,
    onEdit,
    extraNode,
}: ICardWithActions) => {
    return (
        <div className={`actions-card ${onClick ? "clickable" : ""}`} onClick={onClick}>
            <div>{children}</div>
            <div className="actions-card__buttons" onClick={(e) => e.stopPropagation()}>
                {extraNode && extraNode}
                {onEdit && (
                    <Button type="link" onClick={onEdit} icon={<EditOutlined />} />
                )}
                <Popconfirm
                    title="Удалить элемент?"
                    okText="Удалить"
                    onConfirm={onSubmitDelete}
                    cancelText="Отмена"
                >
                    <Button
                        loading={deleteLoading}
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            </div>
        </div>
    );
};

export default CardWithActions;
