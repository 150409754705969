import { PlusOutlined } from "@ant-design/icons";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Button, Checkbox } from "antd";

interface ICreateUpdateDefaultCheckbox {
    disabled: boolean;
    value: boolean;
    visible: boolean;
    isMultiple: boolean;
    name: "on_create_default" | "on_update_default";
    label: string;
    buttonLabel: string;
    onChangeIsCreateUpdateCheckbox: (
        item: any,
        name: string,
        is_multiple?: boolean
    ) => void;
    onAddCreateUpdateValue: (name: "on_create_default" | "on_update_default") => void;
}

const CreateUpdateDefaultCheckbox = ({
    disabled,
    value,
    isMultiple,
    visible,
    name,
    label,
    buttonLabel,
    onChangeIsCreateUpdateCheckbox,
    onAddCreateUpdateValue,
}: ICreateUpdateDefaultCheckbox) => {
    if (visible)
        return (
            <FlexContainer
                customStyle={{
                    height: 24,
                    padding: 0,
                    justifyContent: "space-between",
                    marginBottom: 16,
                }}
            >
                <Checkbox
                    checked={value}
                    disabled={disabled}
                    onChange={(value) =>
                        onChangeIsCreateUpdateCheckbox(value, name, isMultiple)
                    }
                >
                    {label}
                </Checkbox>
                {isMultiple && (
                    <Button
                        type="link"
                        disabled={disabled}
                        icon={<PlusOutlined />}
                        onClick={() => onAddCreateUpdateValue(name)}
                    >
                        {buttonLabel}
                    </Button>
                )}
            </FlexContainer>
        );
    else return <></>;
};

export default CreateUpdateDefaultCheckbox;
