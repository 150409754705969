import { $api } from "@shared/api/api";
import { useEffect, useRef, useState } from "react";
interface IUsePaginatedSelect {
    requestUrl: string;
    initialOptions?: TPaginatedSelectOption[];
    valueKey: string;
    coloredKey?: string;
    value: any;
    valueToString?: boolean;
    onlyRead?: boolean;
    extraParams?: any;
    labelKey: string;
}

export const usePaginatedSelect = ({
    requestUrl,
    initialOptions,
    value,
    valueToString,
    onlyRead,
    valueKey,
    labelKey,
    coloredKey,
    extraParams,
}: IUsePaginatedSelect) => {
    const [loading, setLoading] = useState(false);
    const isLoaded = useRef(false);
    const [searchValue, setSearchValue] = useState("");
    const timer = useRef<any>();
    const [options, setOptions] = useState<TPaginatedSelectOption[]>(
        initialOptions ?? []
    );
    const loadOptions = (params?: TParams) => {
        setLoading(true);
        $api.get("", {
            baseURL: requestUrl,
            params: { ...params, ...(extraParams ?? {}) },
        })
            .then((response) => {
                setOptions(response.data.data);
            })
            .catch(() => {})
            .finally(() => {
                isLoaded.current = true;
                setLoading(false);
            });
    };
    const loadOnFocus = () => {
        if (!isLoaded.current) {
            loadOptions();
        }
    };

    useEffect(() => {
        let valuesIsInOptions = true;
        const valuesArr = Array.isArray(value) ? value : [value];

        if (value) {
            if (onlyRead || value != null) {
                for (let i = 0; i < valuesArr.length; i++) {
                    const valueIsInOptions = options.some(
                        (option) =>
                            option[valueKey]?.toString() === valuesArr[i]?.toString()
                    );

                    if (!valueIsInOptions && valuesArr[i] != null) {
                        valuesIsInOptions = false;
                        break;
                    }
                }
            }
        }

        if (!valuesIsInOptions) {
            loadOptions({
                [valueKey]: valuesArr,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        if (isLoaded.current) {
            const valuesArr = Array.isArray(value) ? value : [value];
            loadOptions({
                [valueKey]: valuesArr,
            });
        }
    }, [requestUrl]);
    const onSearch = (searchValue: string) => {
        setSearchValue(searchValue);
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            loadOptions({
                [valueKey]: Array.isArray(value) ? value : [value],
                [labelKey]: searchValue,
            });
        }, 500);
    };
    const preparedOptions = options.map((option) => {
        const value = option?.[valueKey ?? "value"];
        return {
            label: option?.[labelKey ?? "label"],
            value: valueToString ? (value ? value.toString() : value) : value,
            option: option,
        };
    });

    return {
        options: preparedOptions,
        searchValue,
        loading,
        onSearch,
        loadOnFocus,
    };
};
