import {
    AuditOutlined,
    BellOutlined,
    HistoryOutlined,
    MessageOutlined,
    NodeIndexOutlined,
} from "@ant-design/icons";

const BizProcTimelineItemDot = ({ action }: { action: TBizProcActionType }) => {
    switch (action) {
        case "AgreementAction":
            return (
                <AuditOutlined
                    style={{
                        marginLeft: 2,
                        fontSize: 20,
                    }}
                />
            );
        case "EmailMessageAction":
            return (
                <MessageOutlined
                    style={{
                        marginLeft: 2,
                        fontSize: 20,
                    }}
                />
            );
        case "InitialAction":
            return (
                <NodeIndexOutlined
                    style={{
                        marginLeft: 2,
                        fontSize: 20,
                    }}
                />
            );
        case "NotificationAction":
            return (
                <BellOutlined
                    style={{
                        marginLeft: 2,
                        fontSize: 20,
                    }}
                />
            );
        default:
            return (
                <HistoryOutlined
                    style={{
                        marginLeft: 2,
                        fontSize: 20,
                    }}
                />
            );
    }
};

export default BizProcTimelineItemDot;
