import { FormReadValue } from "@shared/ui/Form";
import { Select, Skeleton } from "antd";
import { useRequestedSelect } from "../model";
import "./RequestedSelectSkeletons.scss";
import { Fragment } from "react";

export interface IRequestedSelect {
    mode?: "multiple" | "tags" | undefined;
    values: any | any[] | undefined;
    onSelect?: (choosenKeys: any | any[], fullItem: any) => void;
    requestUrl: string;
    disabled?: boolean;
    allowClear?: boolean;
    extraKey?: string;
    onClear?: () => void;
    extraСondition?: (item: any) => boolean;
    onlyRead?: boolean;
    queryTypedParam: string;
    queryChoosenParams: string;
    valueLabel: string;
    valueKey: string;
    customClassParentName?: string;
    customClassChildrenName?: string;
    skeletonWidth?: string;
}

const RequestedSelect = (props: IRequestedSelect) => {
    const {
        disabled,
        onlyRead,
        customClassParentName,
        customClassChildrenName,
        allowClear,
        skeletonWidth,
        onClear,
    } = props;
    const { id, valuesOptions, loading, values, onChangeTypedKey, onSelectKey } =
        useRequestedSelect(props);

    return !loading ||
        valuesOptions.length > 0 ||
        (typeof values === "object" && values?.length === 0) ||
        values === undefined ? (
        <>
            {onlyRead ? (
                <div
                    className={`${
                        onlyRead
                            ? `select-value ${customClassParentName ?? ""}`
                            : `select-skeleton ${customClassParentName ?? ""}`
                    }`}
                >
                    {typeof values === "object" ? (
                        values?.map((value, index) => {
                            const label =
                                valuesOptions.find((option) => option.value === value)
                                    ?.label ?? value;
                            return (
                                <Fragment key={value}>
                                    <FormReadValue
                                        value={label}
                                        customClassName={customClassChildrenName}
                                    />
                                    {index < values.length - 1 && (
                                        <span style={{ marginRight: 4 }}>,</span>
                                    )}
                                </Fragment>
                            );
                        })
                    ) : (
                        <FormReadValue
                            customClassName={customClassChildrenName}
                            value={
                                valuesOptions.find((option) => option.value === values)
                                    ?.label ?? values
                            }
                        />
                    )}
                </div>
            ) : (
                <Select
                    showSearch
                    optionFilterProp="children"
                    onSearch={onChangeTypedKey}
                    onChange={onSelectKey}
                    searchValue={id}
                    allowClear={allowClear}
                    onClear={onClear}
                    value={values}
                    disabled={disabled}
                    mode={props.mode}
                    loading={loading}
                    filterOption={() => true}
                    style={{ width: "100%" }}
                    options={valuesOptions}
                />
            )}
        </>
    ) : (
        <div
            className={`${
                onlyRead
                    ? "select-value"
                    : `select-skeleton ${customClassParentName ?? ""}`
            }`}
        >
            {typeof values !== "object" ? (
                <Skeleton
                    title={false}
                    paragraph={{ rows: 1, width: 100 + "%" }}
                    style={{
                        fontSize: 5,
                        width: skeletonWidth ? skeletonWidth : "32.5%",
                    }}
                    active={true}
                />
            ) : (
                values?.map((_, index) => {
                    return (
                        <Skeleton
                            key={index}
                            title={false}
                            paragraph={{ rows: 1, width: 100 + "%" }}
                            style={{
                                fontSize: 5,
                                width: skeletonWidth ? skeletonWidth : "32.5%",
                                marginRight: 0.8 + "%",
                                height: 22,
                            }}
                            active={true}
                        />
                    );
                })
            )}
        </div>
    );
};

export default RequestedSelect;
