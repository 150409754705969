import { CloseOutlined } from "@ant-design/icons";
import { Button, Typography, theme } from "antd";
import { Fragment } from "react";
import { useAlerNotification } from "../model";
import "./AlertNotification.scss";
// interface IAlertNotification {}

const AlertNotification = () => {
    const { token } = theme.useToken();
    const { notifications, clearAllNotifications, removeNotification } =
        useAlerNotification();
    return (
        <div
            className="alert-notifications--overlay"
            style={{
                opacity: notifications.length === 0 ? 0 : 1,
                visibility: notifications.length === 0 ? "hidden" : "visible",
            }}
            onClick={clearAllNotifications}
        >
            <div className="alert-notifications">
                {notifications.map((notification) => {
                    const textWithBreaks = notification?.description
                        ?.split("\n")
                        .map((text, index) => (
                            <Fragment key={index}>
                                {text}
                                <br />
                            </Fragment>
                        ));

                    return (
                        <div
                            key={notification.id}
                            className="alert-notifications__item"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography.Text className="alert-notifications__item__title">
                                        {notification.title}
                                    </Typography.Text>
                                    <Button
                                        onClick={() =>
                                            removeNotification(notification.id!)
                                        }
                                        style={{
                                            marginLeft: 10,
                                            color: token.colorTextSecondary,
                                        }}
                                        type="text"
                                        icon={<CloseOutlined />}
                                    />
                                </div>
                                <div className="alert-notifications__item__description">
                                    {textWithBreaks}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default AlertNotification;
