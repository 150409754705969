import { Tabs, TabsProps } from "antd";
import React from "react";
import CreateEditEntityRowForm from "./CreateEditEntityRowForm";
import CreateEditEntityRowHistory from "./CreateEditEntityRowHistory";

interface ICreateEditEntityRowTabs {
    error: any;
    isLoading: boolean;
    setIsDisabledActions: (item: boolean) => void;
    fields: TBizProcFormField[] | undefined;
    onlyReadMode: boolean;
    entityRowId?: number | null;
    history?: React.ReactNode | null;
    onChangeFormFields: (value: any, inputId: string | number | undefined) => void;
}
const CreateEditEntityRowTabs = ({
    error,
    isLoading,
    setIsDisabledActions,
    fields,
    onlyReadMode,
    entityRowId,
    history,
    onChangeFormFields,
}: ICreateEditEntityRowTabs) => {
    const onChange = (key: string) => {
        if (key === "2") setIsDisabledActions(true);
        else setIsDisabledActions(false);
    };

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "Форма",
            children: (
                <CreateEditEntityRowForm
                    error={error}
                    isLoading={isLoading}
                    fields={fields}
                    onlyReadMode={onlyReadMode}
                    onChangeFormFields={onChangeFormFields}
                    entityRowId={entityRowId}
                />
            ),
        },
        {
            key: "2",
            label: "История",
            children: (
                <CreateEditEntityRowHistory entityRowId={entityRowId} history={history} />
            ),
        },
    ];

    return <Tabs defaultActiveKey="1" items={items} onChange={onChange} />;
};

export default CreateEditEntityRowTabs;
