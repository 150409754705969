export const selectEntities = (state: RootState): TEntity[] => state.entity.entities;
export const selectIsLoading = (state: RootState): boolean => state.entity.isLoading;
export const selectError = (state: RootState): any => state.entity.error;
export const selectRelationsId = (state: RootState): number[] | null =>
    state.entity.relations_id;
export const selectEditingEntity = (state: RootState): TEntity | null =>
    state.entity.editingEntity;
export const selectClickedEntityForQRCode = (state: RootState): TEntity | null =>
    state.entity.clickedEntityForQRCode;
export const selectClickedEntityForAddEntityValue = (
    state: RootState
): TEntityWithRow | null => state.entity.clickedEntityForAddEntityValue;

export const selectEntityByUuid = (state: RootState, uuid: string): TEntity =>
    state.entity.entities.find((entity: TEntity) => entity.uuid === uuid);
export const selectEntityById = (state: RootState, id: number): TEntity =>
    state.entity.entities.find((entity: TEntity) => entity.id === id);
export const selectEntityValues = (state: RootState): any[] => state.entity.entityValues;
export const selectEntityRowObservers = (state: RootState): TEntityRowObserver[] =>
    state.entity.entityRowObservers;
