import { bizProcTemplateApi } from "@entities/BizProcTemplate";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useXarrow } from "react-xarrows";

export const useBizProcEditor = (initialBizProcTemplateItem: TBizProcTemplateItem) => {
    const [bizProcTemplate, setBizProcTemplate] = useState<TBizProcTemplate>(
        JSON.parse(JSON.stringify(initialBizProcTemplateItem.template ?? []))
    );
    const [name, setName] = useState(initialBizProcTemplateItem.name);
    const [version, setVersion] = useState(initialBizProcTemplateItem.version);
    const [loading, setLoading] = useState(false);
    const navigation = useNavigate();
    const updateXArrows = useXarrow();

    const onChangeVersion = (version: string) => {
        setVersion(version);
        bizProcTemplateApi
            .getBizProcTemplate(initialBizProcTemplateItem.id!, true, version)
            .then((response) => {
                setBizProcTemplate(response.data.data.template!);
            })
            .catch((error: any) => {});
    };
    const onSave = () => {
        setLoading(true);
        if (initialBizProcTemplateItem.id) {
            bizProcTemplateApi
                .updateBizProcEntityTemplateItem({
                    ...initialBizProcTemplateItem,
                    name: name,
                    template: bizProcTemplate,
                })
                .then((response) => {
                    navigation(-1);

                    // navigation(
                    //     `/bizproces-templates/${initialBizProcTemplateItem.entity_uuid}`
                    // );
                })
                .catch((e) => {
                    notificationEmit({
                        title:
                            e.response?.data?.message ?? "Не удалось сохранить изменения",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            bizProcTemplateApi
                .createBizProcTemplateItem({
                    category_uuid: initialBizProcTemplateItem.category_uuid,
                    entity_uuid: initialBizProcTemplateItem.entity_uuid,
                    name: name,
                    template: bizProcTemplate,
                })
                .then((response) => {
                    navigation(-1);
                    // navigation(
                    //     `/bizproces-templates/${initialBizProcTemplateItem.entity_uuid}`
                    // );
                })
                .catch(() => {
                    notificationEmit({
                        title: "Не удалось создать бизнес-процесс",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleScroll = (event: any) => {
        updateXArrows();
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeTemplate = (bizProcTemplate: TBizProcTemplate) => {
        setTimeout(() => {
            updateXArrows();
        });
        setBizProcTemplate(bizProcTemplate);
    };
    const archerRef = useRef() as any;

    const onChangeTemplateItem = (templateItem: TBizProcTemplateAction) => {
        const newTemplate = bizProcTemplate.map((item) =>
            item.key === templateItem.key ? templateItem : item
        );
        updateXArrows();
        onChangeTemplate(newTemplate);
        // archerRef.current.refreshScreen();
    };

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };
    const disabled = version !== initialBizProcTemplateItem.version;
    return {
        name,
        loading,
        archerRef,
        bizProcTemplate,
        version,
        disabled,
        onSave,
        onChangeName,
        onChangeVersion,
        onChangeTemplateItem,
        onChangeTemplate,
    };
};
