import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ICategory } from "./CategorySlice";
import {
    createCategoryThunk,
    deleteCategoryThunk,
    loadCategoriesThunk,
    updateCategoryThunk,
} from "./CategoryThunk";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { updateArrByItemId } from "@shared/lib/object-helper";

const categoryLoadBuilder = (builder: ActionReducerMapBuilder<ICategory>) => {
    builder.addCase(loadCategoriesThunk.fulfilled, (state, action) => {
        state.categories = action.payload.data;
        state.getLoading = false;
    });
    builder.addCase(loadCategoriesThunk.pending, (state) => {
        state.getLoading = true;
    });
    builder.addCase(loadCategoriesThunk.rejected, (state, rejectedValue) => {
        state.getLoading = false;
        notificationEmit({
            title: "Не удалось загрузить категории",
            type: "error",
        });
    });
};
const categoryCreateBuilder = (builder: ActionReducerMapBuilder<ICategory>) => {
    builder.addCase(createCategoryThunk.fulfilled, (state, action) => {
        state.categories = [action.payload.data, ...state.categories];
        state.createLoading = false;
    });
    builder.addCase(createCategoryThunk.pending, (state) => {
        state.createLoading = true;
    });
    builder.addCase(createCategoryThunk.rejected, (state, rejectedValue) => {
        state.createLoading = false;
        notificationEmit({
            title: "Не удалось создать категорию",
            type: "error",
        });
    });
};
const categoryUpdateBuilder = (builder: ActionReducerMapBuilder<ICategory>) => {
    builder.addCase(updateCategoryThunk.fulfilled, (state, action) => {
        state.categories = updateArrByItemId(state.categories, action.payload.data);
        state.updateLoading = false;
    });
    builder.addCase(updateCategoryThunk.pending, (state) => {
        state.updateLoading = true;
    });
    builder.addCase(updateCategoryThunk.rejected, (state, rejectedValue) => {
        state.updateLoading = false;
        notificationEmit({
            title: "Не удалось обновить категорию",
            type: "error",
        });
    });
};
const categoryDeleteBuilder = (builder: ActionReducerMapBuilder<ICategory>) => {
    builder.addCase(deleteCategoryThunk.fulfilled, (state, action) => {
        state.categories = state.categories.filter(
            (item) => item.id !== action.meta.arg.id
        );
        state.deleteLoading = false;
    });
    builder.addCase(deleteCategoryThunk.pending, (state) => {
        state.deleteLoading = true;
    });
    builder.addCase(deleteCategoryThunk.rejected, (state, rejectedValue) => {
        state.deleteLoading = false;
        notificationEmit({
            title: "Не удалось удалить категорию",
            type: "error",
        });
    });
};

export const categoryBuilder = (builder: ActionReducerMapBuilder<ICategory>) => {
    categoryLoadBuilder(builder);
    categoryCreateBuilder(builder);
    categoryUpdateBuilder(builder);
    categoryDeleteBuilder(builder);
};
