import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Spin } from "antd";
import "./TablePageErp.scss";
import TablePageErpLoaded from "./TablePageErpLoaded";
import { useTablePageErp } from "./UseTablePageErp";

const TablePageErp = () => {
    const { entity, loading } = useTablePageErp();

    return (
        <>
            {loading ? (
                <FlexContainer customStyle={{ height: 300 }}>
                    <Spin />
                </FlexContainer>
            ) : (
                <>{entity && <TablePageErpLoaded entity={entity} />}</>
            )}
        </>
    );
};

export default TablePageErp;
