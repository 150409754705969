import { Spin } from "antd";
import { memo } from "react";
import { Calendar, EventProps } from "react-big-calendar";
import { customMessages, customViews } from "../constants";

import { useEntityRowsScheduler } from "../model";
import CustomEventWrapper from "./CustomEventWrapper";
import CustomToolbar from "./CustomToolbar";
import { groupColors } from "./CustomYearView";
import "./EntityRowsScheduler.scss";
import { getColor } from "@shared/lib/colorizing";
interface IEntityRowsScheduler {
    entityUuid: string;
    entityId: number;
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void;
}

const EntityRowsScheduler = ({
    entityUuid,
    entityId,
    openCloseDrawerWithNavigate,
}: IEntityRowsScheduler) => {
    const {
        isLoading,
        entityFields,
        dateStartKey,
        dateEndKey,
        eventLabel,
        eventDescription,
        date,
        localizer,
        events,
        viewMode,
        setDate,
        setDateStartKey,
        setDateEndKey,
        setEventLabel,
        setEventDescription,
        setViewMode,
    } = useEntityRowsScheduler(entityId, entityUuid, openCloseDrawerWithNavigate);
    const components: any = {
        event: ({ event }: EventProps<EventItem>) => {
            return (
                <CustomEventWrapper
                    backgroundColor={getColor(
                        event.data.resource[eventLabel],
                        groupColors
                    )}
                    data={event.data}
                    allDay={event.allDay}
                    openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
                />
            );
        },
        eventWrapper: ({ children, event }: any) => {
            return (
                <div
                    style={{
                        height: "fit-content",
                        background: getColor(
                            event.data.resource[eventLabel],
                            groupColors
                        ),
                    }}
                >
                    {children}
                </div>
            );
        },
        agenda: ({ event }: EventProps<EventItem>) => (
            <CustomEventWrapper
                backgroundColor={getColor(event.data.resource[eventLabel], groupColors)}
                data={event.data}
                allDay={event.allDay}
                openCloseDrawerWithNavigate={openCloseDrawerWithNavigate}
            />
        ),

        timeGutterHeader: () => {
            return (
                <div
                    style={{
                        width: "48.375px",
                        minWidth: "48.375px",
                        maxWidth: "48.375px",
                        whiteSpace: "pretty",
                        padding: 0,
                        margin: 0,
                        borderCollapse: "collapse",
                        boxSizing: "border-box",
                    }}
                ></div>
            );
        },
    };

    return (
        <div className="my-calendar" style={{ height: "fit-content", width: "100%" }}>
            <CustomToolbar
                viewMode={viewMode}
                date={date}
                entityUuid={entityUuid}
                dateStartKey={dateStartKey}
                dateEndKey={dateEndKey}
                eventLabel={eventLabel}
                setDateStartKey={setDateStartKey}
                setDateEndKey={setDateEndKey}
                setEventLabel={setEventLabel}
                setViewMode={setViewMode}
                setDate={setDate}
                entityFields={entityFields}
                eventDescription={eventDescription}
                setEventDescription={setEventDescription}
            />
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "10%",
                        width: "100%",
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : (
                <div style={{ position: "relative" }}>
                    {/* @ts-ignore */}
                    <Calendar
                        dayLayoutAlgorithm="no-overlap"
                        events={events}
                        allDayMaxRows={2}
                        components={components}
                        style={{
                            padding: "0 0 16px 0",
                            margin: "0 0 16px 0",
                            minWidth: `${viewMode === "year" ? undefined : "1120px"}`,
                        }}
                        enableAutoScroll={true}
                        localizer={localizer}
                        startAccessor="start"
                        endAccessor="end"
                        views={customViews}
                        messages={customMessages}
                        toolbar={false}
                        view={viewMode}
                        date={date}
                        onSelectEvent={(event: EventItem) => {
                            openCloseDrawerWithNavigate(true, event.data.id);
                        }}
                        popup
                    />
                </div>
            )}
        </div>
    );
};

// export default EntityRowsScheduler;
export default memo(EntityRowsScheduler, (prev, next) => {
    const isEqual =
        prev.entityId === next.entityId && prev.entityUuid === next.entityUuid;
    return isEqual;
});
