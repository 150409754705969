import ActionCard from "./ActionCard";

interface IChangeStageAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const ChangeStageAction = ({ onDeleteItem, templateItem }: IChangeStageAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="LightBlue"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Изменение стадии БП"}
        ></ActionCard>
    );
};

export default ChangeStageAction;
