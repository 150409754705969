import { useSelector } from "react-redux";
import { selectGetLoading, selectStages } from "./StageSelectors";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadStagesThunk } from "./StageThunk";

export const useStagesLoad = (entityId: number, categoryId: number) => {
    const loading = useSelector((state: RootState) =>
        selectGetLoading(state, categoryId)
    );
    const stages =
        useSelector((state: RootState) => selectStages(state, categoryId)) ?? [];
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(
            loadStagesThunk({
                entityId,
                categoryId,
            })
        );
    }, [dispatch, entityId, categoryId]);

    return {
        stages,
        loading,
    };
};
