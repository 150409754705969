import { EntityFieldItem, EntityFieldListItem } from "@entities/Entity";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Spin } from "antd";
import { useEntityFieldItemList } from "../model";
import "./EntityFieldItemList.scss";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { entityFieldLib } from "@entities/EntityField";
interface IEntityFieldItemList {
    entityId?: number | undefined;
    entityUuid?: string | undefined;
    isColored?: (key: string) => boolean;
    getCoincidences?: (item: TEntity, key: string) => any[];
    view?: "card" | "list";
}

const EntityFieldItemList = ({
    entityId,
    entityUuid,
    isColored,
    view = "card",
}: IEntityFieldItemList) => {
    const { fields, loading, onEdit, onDelete, reorderFields } = useEntityFieldItemList(
        entityId,
        entityUuid
    );

    const fullFieldsList = entityFieldLib.getOrderedEntityFields(fields);
    const onChangeFields = (items: TEntityField[]) => {
        reorderFields(items);
    };
    return (
        <div
            className="entities-row"
            style={
                view === "card"
                    ? {
                          display: "flex",
                          flexWrap: "wrap",
                          marginTop: "20px",
                          justifyContent: "stretch",
                      }
                    : {}
            }
        >
            {loading ? (
                <FlexContainer>
                    <Spin />
                </FlexContainer>
            ) : (
                <>
                    {view === "card" ? (
                        fullFieldsList.map((entityField: TEntityField, index: number) => {
                            return (
                                <EntityFieldItem
                                    key={entityField.id ?? index}
                                    isColored={isColored}
                                    entityField={entityField}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                />
                            );
                        })
                    ) : (
                        <DragAndDropList
                            disabled={false}
                            keyName="id"
                            items={fullFieldsList}
                            onChangeItems={onChangeFields}
                            draggableItem={(dragHandleProps, entityField, index) => (
                                <EntityFieldListItem
                                    dragHandleProps={dragHandleProps}
                                    key={entityField.id ?? index}
                                    isColored={isColored}
                                    entityField={entityField}
                                    onEdit={onEdit}
                                    onDelete={onDelete}
                                />
                            )}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default EntityFieldItemList;
