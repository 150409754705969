import { entityFieldApi } from "@entities/EntityField";
import { entityRowModel } from "@entities/EntityRow";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { dayjsLocalizer } from "react-big-calendar";
import { useDispatch, useSelector } from "react-redux";
import { customViewModes } from "../constants";
import { getEntityFieldBasedOnTime, getEvents } from "../lib";
import { useEntityRowsSchedulerWS } from "./UseEntityRowsSchedulerWs";

export const useEntityRowsScheduler = (
    entityId: number,
    entityUuid: string,
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void
) => {
    const dispatch = useDispatch<AppDispatch>();
    useEntityRowsSchedulerWS(entityId);
    const [viewMode, setViewMode] = useState<TScheduleViewMode>(
        (localStorage.getItem(`${entityUuid}_view_mode`) ??
            customViewModes.WEEK) as TScheduleViewMode
    );

    const [date, setDate] = useState(
        dayjs(dayjs(Date.now()).format("YYYY-MM-DD HH:mm")).toDate()
    );
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const [dateStartKey, setDateStartKey] = useState<string>(
        JSON.parse(localStorage.getItem(`${entityUuid}_schedule`) ?? "{}")[
            "dateStartKey"
        ] ?? getEntityFieldBasedOnTime(entityFields)?.key
    );

    const [dateEndKey, setDateEndKey] = useState<string>(
        JSON.parse(localStorage.getItem(`${entityUuid}_schedule`) ?? "{}")[
            "dateEndKey"
        ] ?? undefined
    );
    const [eventLabel, setEventLabel] = useState<string>(
        JSON.parse(localStorage.getItem(`${entityUuid}_schedule`) ?? "{}")[
            "eventLabel"
        ] ?? undefined
    );
    const [eventDescription, setEventDescription] = useState<string>(
        JSON.parse(localStorage.getItem(`${entityUuid}_schedule`) ?? "{}")[
            "eventDescription"
        ] ?? undefined
    );

    const setViewMode_ = (viewMode: TScheduleViewMode) => {
        setViewMode(viewMode);
        localStorage.setItem(`${entityUuid}_view_mode`, viewMode);
    };

    const { isLoading, entityRows } = entityRowModel.useLoadEntityRowsByDates(
        entityId,
        entityUuid,
        date,
        dateEndKey,
        dateStartKey,
        eventLabel,
        eventDescription,
        viewMode
    );

    useEffect(() => {
        entityFieldApi.getEntityFields(entityUuid).then((response) => {
            dispatch(entityRowModel.setEntityFields(response.data.data));
        });
    }, []);

    const events = getEvents(
        entityFields,
        entityRows,
        dateStartKey,
        dateEndKey,
        eventLabel,
        eventDescription,
        openCloseDrawerWithNavigate
    );

    const localizer = {
        ...dayjsLocalizer(dayjs),
        formats: {
            ...dayjsLocalizer(dayjs).formats,
            dayFormat: "DD dd",
            weekdayFormat: "dd",
        },
    };

    const onNavigate = (date: Date) => {
        setViewMode_("day");
        setDate(
            dayjs(date)
                .add(dayjs(date).utcOffset() / 60, "h")
                .toDate()
        );
    };

    const onView = (viewMode: TScheduleViewMode) => {
        setViewMode_(viewMode);
    };

    return {
        isLoading,
        entityFields,
        dateStartKey,
        dateEndKey,
        eventLabel,
        eventDescription,
        date,
        localizer,
        events,
        viewMode,
        setDate,
        setDateStartKey,
        setDateEndKey,
        setEventLabel,
        setEventDescription,
        setViewMode: setViewMode_,
        onNavigate,
        onView,
    };
};
