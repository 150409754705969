import { datatypeModel } from "@entities/Datatype";
import { deleteDatatypesThunk } from "@entities/Datatype/model";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useDatatypesListItem = (datatype: TDatatype) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const onSubmitDelete = () => {
        setDeleteLoading(true);
        dispatch(deleteDatatypesThunk(datatype.id)).finally(() => {
            setDeleteLoading(false);
        });
    };

    const onEdit = () => {
        dispatch(datatypeModel.setEditingDatatype(datatype));
    };
    return {
        deleteLoading,
        onSubmitDelete,
        onEdit,
    };
};
