import { bizProcModel } from "@entities/BizProc";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useBiProcFormsList = () => {
    const isFormsLoading = useSelector(bizProcModel.isFormsLoading);
    const bizProcForms = useSelector(bizProcModel.selectMyForms);
    const [isMounted, setIsMounted] = useState(false);

    const dispatch = useDispatch();

    const onClick = (form: TBizProcAction) => {
        dispatch(bizProcModel.setTargetForm(form));
    };

    useEffect(() => {
        if (!isFormsLoading) {
            setIsMounted(true);
        }
        return () => {
            setIsMounted(false);
        };
    }, [isFormsLoading]);

    return {
        onClick,
        bizProcForms,
        isFormsLoading,
        isMounted,
    };
};
