import { UserAgreementListItem } from "@entities/BizProc";
import { CardWithScroll } from "@shared/ui/Cards/CardWithScroll";
import { Divider } from "antd";
import { Fragment } from "react";
import { useUserAgreementsList } from "../model";
import "./UserAgreementsList.scss";

interface IUserAgreementsList {
    containerStyle?: React.CSSProperties;
}

const UserAgreementsList = ({ containerStyle }: IUserAgreementsList) => {
    const { onClick, agreements, loading } = useUserAgreementsList();

    return (
        <div style={containerStyle ? containerStyle : {}}>
            <CardWithScroll
                loading={loading}
                isEmpty={agreements.length === 0}
                header="Ожидаю действий"
            >
                <>
                    {agreements.map((agreement) => {
                        return (
                            <Fragment key={agreement.id}>
                                <UserAgreementListItem
                                    onClick={onClick}
                                    isMyAgreements={true}
                                    agreement={agreement}
                                />
                                <Divider style={{ margin: 5 }} />
                            </Fragment>
                        );
                    })}
                </>
            </CardWithScroll>
        </div>
    );
};

export default UserAgreementsList;
