import { datatypeApi } from "@entities/Datatype";
import { useEffect, useState } from "react";

export const UseCalculatedValueComparisonTypes = () => {
    const [comparisonTypes, setComparisonTypes] = useState<TFieldComparisonType[]>([]);

    useEffect(() => {
        datatypeApi
            .getComparisonTypes()
            .then((response) => {
                setComparisonTypes(response.data.data);
            })
            .catch((error) => {})
            .finally(() => {});
    }, []);

    const preparedComparisonTypes = comparisonTypes.map((comparison) => ({
        value: comparison.id as any,
        label: comparison.label,
        item: comparison,
    }));
    return {
        comparisonTypes: preparedComparisonTypes,
    };
};
