import { actionModel } from "@entities/Action";
import { bizProcLib } from "@entities/BizProcTemplate";
import { useSelector } from "react-redux";
import { getDropdownItemsFromActions } from "./lib";

interface IUseBizProcActionAdd {
    templateItemAction: TBizProcActionType;
    onAdd: (templateItem: TBizProcTemplateAction) => void;
    parentItem: TBizProcTemplateAction;
}
interface MenuInfo {
    key: string;
    keyPath: string[];
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}
export const useBizProcActionAdd = ({
    templateItemAction,
    parentItem,
    onAdd,
}: IUseBizProcActionAdd) => {
    const actions = useSelector(actionModel.selectActions);
    const dropdownItems = getDropdownItemsFromActions(actions);

    const onClickAddButton = (event: MenuInfo) => {
        const action = actions.find((item) => item.id === +event.key);
        const parameters = action?.parameters;
        if (action) {
            const newItem = {
                ...bizProcLib.getBizProcNewAction(action.type, action.name, parameters),
                parent_key: parentItem.key ?? null,
            };
            onAdd(newItem);
        }
    };
    const onClickAddFixedButton = () => {
        const newItem = {
            ...bizProcLib.getBizProcNewFixedAction(templateItemAction),
            parent_key: parentItem.key ?? null,
        };
        onAdd(newItem);
    };
    return {
        onClickAddButton,
        onClickAddFixedButton,
        dropdownItems,
    };
};
