import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { MenuProps } from "antd/lib";

interface IUserAssygnmentDropdownList {
    users: TUser[];
    onUserClick?: (user: TUser) => void;
}

const UserAssygnmentDropdownList = ({
    users,
    onUserClick,
}: IUserAssygnmentDropdownList) => {
    const userItems: MenuProps["items"] = users.map((user, index) => {
        const onUserAssygnmentClick = onUserClick ? () => onUserClick(user) : undefined;
        return {
            label: <div onClick={onUserAssygnmentClick}>{user.name}</div>,
            key: index,
            style: { padding: 4, borderRadius: 4 },
        };
    });
    return (
        <div className="assigned-user__user">
            <Dropdown menu={{ items: userItems }} trigger={["click"]}>
                <span>
                    Список согласующих{" "}
                    {<DownOutlined style={{ fontSize: 12, marginLeft: 4 }} />}
                </span>
            </Dropdown>
        </div>
    );
};

export default UserAssygnmentDropdownList;
