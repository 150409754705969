import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { portalSettingBuilder } from "./PortalSettingBuilder";
import { updateArrByItemId } from "@shared/lib/object-helper";
import { PORTAL_SETTINGS_STORE_KEY } from "../constants";

export interface IPortalSetting {
    portalSettings: TPortalSetting[];
    portalSettingsPrepared: TPortalSettingPrepared;
    isLoadingPortalSettings: boolean;
}

const initialState: IPortalSetting = {
    portalSettings: [],
    portalSettingsPrepared: {},
    isLoadingPortalSettings: false,
};

export const preparePortalSettings = (state: IPortalSetting) => {
    state.portalSettingsPrepared = state.portalSettings.reduce((prev, current) => {
        return {
            ...prev,
            [current.setting]: current.value,
        };
    }, {});
};
// action: PayloadAction<HomeLayout>
export const portalSettingSlice = createSlice({
    name: "portalSetting",
    initialState,
    reducers: {
        setPortalSettings: (state, action: PayloadAction<TPortalSetting[]>) => {
            state.portalSettings = action.payload;
            localStorage.setItem(
                PORTAL_SETTINGS_STORE_KEY,
                JSON.stringify(state.portalSettings ?? [])
            );
            preparePortalSettings(state);
        },
        deletePortalSetting: (state, action: PayloadAction<number>) => {
            state.portalSettings = state.portalSettings.filter(
                (route) => route.id !== action.payload
            );
            preparePortalSettings(state);
        },
        updatePortalSetting: (state, action: PayloadAction<TPortalSetting>) => {
            const portalSetting = action.payload;
            state.portalSettings = updateArrByItemId(state.portalSettings, portalSetting);

            if (portalSetting.setting === "title") {
                document.title = portalSetting.value;
            }
            preparePortalSettings(state);
        },
        createPortalSetting: (state, action: PayloadAction<TPortalSetting>) => {
            state.portalSettings = [...state.portalSettings, action.payload];
            preparePortalSettings(state);
        },
    },
    extraReducers: (builder) => {
        portalSettingBuilder(builder);
    },
});

export const {
    deletePortalSetting,
    createPortalSetting,
    setPortalSettings,
    updatePortalSetting,
} = portalSettingSlice.actions;

export default portalSettingSlice.reducer;
