import { UserInputWithVariables } from "@entities/BizProcTemplate";

interface IUserSelectWithCalc {
    multiple?: boolean;
    disabled?: boolean;
    bizProcTemplateId: number;
    templateItem: TBizProcTemplateAction;
    onChangeItem: (templateItem: TBizProcTemplateAction) => void;
}

const UserSelectWithCalc = ({
    multiple,
    bizProcTemplateId,
    templateItem,
    disabled,
    onChangeItem,
}: IUserSelectWithCalc) => {
    const usersIds = templateItem.parameters?.usersIds;
    const usersIsCalculation = templateItem.parameters?.usersIsCalculation;
    const usersCalcInput = templateItem.parameters?.usersCalcInput;
    const onChangeUsers = (usersIds: number[] | number, item: any) => {
        const newValue = item.variable_type
            ? {
                  usersIds: item.type,
              }
            : { usersIds };
        onChangeItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                ...newValue,
            },
        });
    };
    const onChangeCalculationValue = (value: string) => {
        onChangeItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                usersCalcInput: value,
            },
        });
    };
    const onChangeIsCalculation = (isCalculation: boolean) => {
        onChangeItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                usersIsCalculation: isCalculation,
            },
        });
    };

    return (
        <UserInputWithVariables
            multiple={multiple}
            isCalculation={usersIsCalculation}
            calculationValue={usersCalcInput}
            disabled={disabled}
            values={usersIds}
            bizProcTemplateId={bizProcTemplateId}
            onChangeIsCalculation={onChangeIsCalculation}
            onChangeCalculationValue={onChangeCalculationValue}
            onChangeUsers={onChangeUsers}
        />
    );
};

export default UserSelectWithCalc;
