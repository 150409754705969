export const useCapabilitiesRolesConstraintsFormItem = (
    onChangeCondition: (
        condition: any,
        name: string,
        value: string,
        indexOfEditingCondition: number
    ) => void,
    parentCondition: any,
    parentIndex: number,
    entityFieldsOptions: { label: string; value: string; option: TEntityField }[],
    onChangeConstraintsItem: (constraintItem: any, indexOfItem: number) => void
) => {
    const isSingleCondition = !parentCondition.condition_type;
    const field = entityFieldsOptions.find(
        ({ value }) => value === parentCondition.key
    )?.option;

    const comparsionTypesOptions = field?.comparison_types?.map(({ label, value }) => ({
        label,
        value,
    }));
    const onChangeConstraintsItem_ = (
        updatedCondition: any,
        updatedConditionIndex: number
    ) => {
        onChangeConstraintsItem(
            {
                ...parentCondition,
                conditions: parentCondition.conditions.map((item: any, index: number) =>
                    index === updatedConditionIndex ? updatedCondition : item
                ),
            },
            parentIndex
        );
    };

    const onChangeEntityField = (_: string, { option }: any) => {
        onChangeCondition(parentCondition, "key", option.key, parentIndex);
    };

    const onChangeConditionItem_ = (
        condition: any,
        name: string,
        value: string,
        indexOfEditingCondition: number
    ) => {
        onChangeConstraintsItem_(
            name === "key"
                ? { ...condition, [name]: value, value: undefined, condition: null }
                : { ...condition, [name]: value },
            indexOfEditingCondition
        );
    };

    const onChangeConditionOfGroup = (value: string) => {
        onChangeConstraintsItem(
            { ...parentCondition, condition_type: value },
            parentIndex
        );
    };

    return {
        isSingleCondition,
        field,
        comparsionTypesOptions,
        onChangeConstraintsItem_,
        onChangeEntityField,
        onChangeConditionItem_,
        onChangeConditionOfGroup,
    };
};
