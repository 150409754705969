import ActionCard from "./ActionCard";

interface ICreateEntityRowAction {
    onDeleteItem?: () => void;
    templateItem?: TBizProcTemplateAction;
}

const CreateEntityRowAction = ({
    onDeleteItem,
    templateItem,
}: ICreateEntityRowAction) => {
    return (
        <ActionCard
            templateItem={templateItem}
            borderColor="darksalmon"
            onDeleteItem={onDeleteItem}
            title={templateItem?.name ?? "Создание записи сущности"}
        ></ActionCard>
    );
};

export default CreateEntityRowAction;
