import { DeleteOutlined } from "@ant-design/icons";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Button } from "antd";

interface IEditTemplateBar {
    title?: string;
    onDeleteItem?: () => void;
    textColor?: string;
}

const EditTemplateBar = ({ title, onDeleteItem, textColor }: IEditTemplateBar) => {
    return (
        <FlexContainer
            type={title ? (onDeleteItem ? "row-space-between" : "center") : "row-end"}
        >
            {title && (
                <div
                    style={{
                        color: textColor ?? "white",
                        padding: onDeleteItem ? undefined : "5px 0px",
                    }}
                >
                    {title}
                </div>
            )}
            {onDeleteItem && (
                <Button
                    type="text"
                    size="middle"
                    onClick={onDeleteItem}
                    shape="circle"
                    icon={<DeleteOutlined style={{ color: textColor ?? "white" }} />}
                />
            )}
        </FlexContainer>
    );
};

export default EditTemplateBar;
