import { portalSettingApi, portalSettingModel } from "@entities/PortalSetting";
import { useDefferedCallback } from "@shared/hooks/UseDefferedCallback";
import { useDispatch } from "react-redux";

export const usePortalSettingsFormItem = (portalSetting: TPortalSetting) => {
    const { defferedCallback } = useDefferedCallback();
    const dispatch = useDispatch();

    const updatePortalSetting = (updatedPortalSetting: TPortalSetting) => {
        return portalSettingApi
            .updatePortalSetting(updatedPortalSetting)
            .then(() => {})
            .catch(() => {
                dispatch(portalSettingModel.updatePortalSetting(portalSetting));
            });
    };
    const onChange = (value: string, key: string) => {
        const updatedPortalSetting = {
            ...portalSetting,
            value,
        };
        dispatch(portalSettingModel.updatePortalSetting(updatedPortalSetting));
        defferedCallback(() => updatePortalSetting(updatedPortalSetting));
    };
    return {
        onChange,
    };
};
