import { Input } from "antd";
import "./TextStyles.scss";
import { ChangeEvent, useState } from "react";
type TValueType = "json" | "string";
const parseJson = (value: any) => {
    return JSON.stringify(value, null, 1);
};
interface ITextWithLabel {
    text: string;
    error?: string | null | undefined;
    label?: string;
    onChange?: (value: string) => void;
    allowEdit?: boolean;
    editing?: boolean;
    area?: boolean;
    type?: TValueType;
    placeholder?: string;
    noMargin?: boolean;
    icon?: React.ReactNode;
}
const TextWithLabel = ({
    text,
    placeholder,
    allowEdit,
    onChange,
    error,
    editing,
    area,
    noMargin,
    type,
    label,
    icon,
}: ITextWithLabel) => {
    const [parsedValue, setParsedValue] = useState(
        type === "json" ? parseJson(text) : text
    );

    const [localError, setLocalError] = useState<null | string>(null);
    const onChangeInput = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        if (type === "json") {
            setParsedValue(event.target.value === "" ? "{}" : event.target.value);
        } else {
            onChange && onChange(event.target.value);
        }
    };

    const onBlur = () => {
        if (type === "json") {
            setLocalError(null);
            try {
                const parsedJson = JSON.parse(parsedValue);
                onChange && onChange(parsedJson);
                setParsedValue(parseJson(parsedJson));
            } catch {
                setLocalError("Не удалось обработать JSON");
            }
        }
    };
    const inputError = error ? error : localError ? localError : null;

    if (allowEdit)
        return (
            <div className="text-with-label">
                <div className="text-with-label__label">{label}</div>
                {area ? (
                    <Input.TextArea
                        onBlur={onBlur}
                        rows={4}
                        status={inputError ? "error" : undefined}
                        placeholder={placeholder}
                        onChange={onChangeInput}
                        disabled={!editing}
                        autoSize={!editing}
                        bordered={editing ? editing : false}
                        className={
                            editing ? "text-with-label__input" : "text-with-label__text"
                        }
                        value={type === "json" ? parsedValue : text}
                    />
                ) : (
                    <Input
                        onBlur={onBlur}
                        status={inputError ? "error" : undefined}
                        placeholder={placeholder}
                        onChange={onChangeInput}
                        disabled={!editing}
                        bordered={editing ? editing : false}
                        className={
                            editing ? "text-with-label__input" : "text-with-label__text"
                        }
                        value={type === "json" ? parsedValue : text}
                    />
                )}
                {inputError && <div className="text-with-label__error">{inputError}</div>}
            </div>
        );

    return (
        <div
            className="text-with-label"
            style={{
                marginBottom: noMargin ? 0 : undefined,
            }}
        >
            <div className="text-with-label__label">
                {icon && icon}
                {label}
            </div>
            <div className="text-with-label__text">
                {text === "" && placeholder !== undefined ? placeholder : text}
            </div>
        </div>
    );
};

export default TextWithLabel;
