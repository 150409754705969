export const sidebar = {
    closed: {
        width: 0,
        transition: {
            duration: 0.1,
            delay: 0,
            type: "tween",
        },
    },
    open: {
        width: 316,
        transition: {
            duration: 0.1,
            delay: 0,
            type: "tween",
        },
    },
};
