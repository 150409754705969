import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { MenuProps } from "antd/lib";
import { Fragment } from "react";
import "./BizProcTemplateStartMenu.scss";
import StartMenuItem from "./StartMenuItem";
import { useBizProcTemplateStartMenu } from "./model";
interface IBizProcTemplateStartMenu {
    bizProcTemplateId: string | null;
}

const BizProcTemplateStartMenu = ({ bizProcTemplateId }: IBizProcTemplateStartMenu) => {
    const { onStart, loading, dashboardItems } = useBizProcTemplateStartMenu(bizProcTemplateId);

    const windowWidth = window.innerWidth;
    return (
        <>
            {windowWidth > 1024 &&
            window.matchMedia("(orientation: landscape)").matches ? (
                <div className="start-actions">
                    {dashboardItems.map((item) => {
                        return (
                            <Fragment key={item.id}>
                                <StartMenuItem
                                    disabled={loading !== null}
                                    loading={loading === item.biz_proc_template_id}
                                    onStart={onStart}
                                    item={item}
                                />
                            </Fragment>
                        );
                    })}
                </div>
            ) : (
                <Dropdown
                    menu={{
                        items: dashboardItems.map((item, index) => {
                            return {
                                label: (
                                    <Fragment key={item.id}>
                                        <StartMenuItem
                                            disabled={loading !== null}
                                            loading={
                                                loading === item.biz_proc_template_id
                                            }
                                            onStart={onStart}
                                            item={item}
                                        />
                                    </Fragment>
                                ),
                                key: index,
                            };
                        }) as MenuProps["items"],
                    }}
                    trigger={["click"]}
                >
                    <div className="start-actions__statements">
                        <Space>
                            Заявления
                            <DownOutlined />
                        </Space>
                    </div>
                </Dropdown>
            )}
        </>
    );
};

export default BizProcTemplateStartMenu;
