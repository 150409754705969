import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useUserProfileDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get("user");
    const [openedUserId, setOpenedUserId] = useState<string | null>(null);
    useEffect(() => {
        setIsOpen(userId ? true : false);
        if (userId) {
            setOpenedUserId(userId);
        } else {
            setTimeout(() => {
                setOpenedUserId(null);
            }, 300);
        }
    }, [userId]);

    const onClose = () => {
        queryParams.delete("user");
        navigate({
            search: queryParams.toString(),
        });
    };
    return {
        openedUserId,
        isOpen,
        onClose,
    };
};
