import { entityRowModel } from "@entities/EntityRow";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allowedDatatypes } from "../constants";
import { getGroupingKeysFromStorage, setGroupingKeysFromStorage } from "../lib";

export const useEntityRowTableGroupSelect = (entityId: number) => {
    const groupingKeys = useSelector(entityRowModel.selectGroupingKeys);
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const dispatch = useDispatch();
    const leaveTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
        const keys = getGroupingKeysFromStorage(entityId);
        dispatch(entityRowModel.setGroupingKeys(keys));
        return () => {
            dispatch(entityRowModel.setGroupingKeys([]));
        };
        // eslint-disable-next-line
    }, [entityId]);

    const handleOpenChange = (open: boolean) => {
        setOpen(open);
    };
    const handleFieldClick = (key: string) => {
        const index = groupingKeys.findIndex((item) => item === key);
        const keys =
            index !== -1
                ? groupingKeys.filter((item) => item !== key)
                : [...groupingKeys, key];
        if (index !== -1) {
            dispatch(entityRowModel.deleteEntityRowsWithPage(index));
        }
        setGroupingKeysFromStorage(entityId, keys);
        dispatch(entityRowModel.setGroupingKeys(keys));
    };
    const removeAllKeys = () => {
        dispatch(entityRowModel.setGroupingKeys([]));
        setGroupingKeysFromStorage(entityId, []);
    };

    const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const onLeave = () => {
        leaveTimerRef.current = setTimeout(() => {
            handleOpenChange(false);
        }, 500);
    };
    const onEnter = () => {
        if (leaveTimerRef.current) clearTimeout(leaveTimerRef.current);
    };

    const preparedEntityFields = entityFields
        .filter(
            (field) =>
                !field.is_multiple &&
                allowedDatatypes.includes(field.datatype) &&
                field.name.toLowerCase().includes(search.toLowerCase())
        )
        .map((field) => ({
            ...field,
            isAdded: groupingKeys.includes(field.key),
        }));
    const addedFields = preparedEntityFields.filter((field) => field.isAdded);

    return {
        groupingKeys,
        addedFields,
        entityFields: preparedEntityFields,
        open,
        search,
        handleFieldClick,
        handleOpenChange,
        removeAllKeys,
        onChangeSearch,
        onLeave,
        onEnter,
    };
};
