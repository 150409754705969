export const getReorderedItems = (
    navigationRoutes: TNavigationRoute[],
    newNavigationRoutes: TNavigationRoute[]
) => {
    const reorderedItems: TNavigationRoute[] = [];
    navigationRoutes.forEach((route) => {
        const newRoute = newNavigationRoutes.find((newRoute) => newRoute.id === route.id);
        if (newRoute && newRoute?.order !== route.order) reorderedItems.push(newRoute);
    });

    return reorderedItems;
};
