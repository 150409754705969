import { useDispatch } from "react-redux";
import { deleteBizProcThunk } from "./BizProcThunk";

export const useDeleteBizProc = () => {
    const dispatch = useDispatch<AppDispatch>();

    const deleteBizProc = async (id: number) => {
        dispatch(deleteBizProcThunk(id));
    };
    return {
        deleteBizProc,
    };
};
