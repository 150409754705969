import { Button } from "antd";
import ConditionGroup from "./ConditionGroup";
import { useEditIfElseBranchAction } from "../model/UseEditIfElseBranchAction";
import { Fragment } from "react";
import ConditionItem from "./ConditionItem";

interface IEditIfElseBranchAction {
    notAllowGroupAdding?: boolean;
    templateItem: TBizProcTemplateAction;
    bizProcTemplate: TBizProcTemplateItem;
    disabled?: boolean;
    setItem: (templateItem: TBizProcTemplateAction) => void;
    updateItemParameters: (parameters: TBizProcParameters) => void;
}

const EditIfElseBranchAction = ({
    bizProcTemplate,
    notAllowGroupAdding,
    templateItem,
    disabled,
    updateItemParameters,
    setItem,
}: IEditIfElseBranchAction) => {
    const { conditions, onAddGroup, onAddCondtitional, onDelete, onChange } =
        useEditIfElseBranchAction({
            bizProcTemplate,
            templateItem,
            updateItemParameters,
            setItem,
        });

    return (
        <div style={{ width: "100%" }}>
            {!notAllowGroupAdding && (
                <Button type="link" onClick={onAddGroup} disabled={disabled}>
                    Добавить группу
                </Button>
            )}
            <Button type="link" onClick={onAddCondtitional} disabled={disabled}>
                Добавить условие
            </Button>
            {conditions.map((condition) => {
                return (
                    <Fragment key={condition.id}>
                        {condition.type === "group" ? (
                            <ConditionGroup
                                bizProcTemplate={bizProcTemplate}
                                templateItem={templateItem}
                                onDeleteParentGroup={onDelete}
                                onChangeParentGroup={onChange}
                                conditionGroup={condition}
                                disabled={disabled}
                            />
                        ) : (
                            <ConditionItem
                                onDelete={onDelete}
                                onChange={onChange}
                                templateItem={templateItem}
                                bizProcTemplate={bizProcTemplate}
                                condition={condition}
                                disabled={disabled}
                            />
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default EditIfElseBranchAction;
