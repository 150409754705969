import { FormItem } from "@shared/ui/Form";
import { QRCodeGenerator } from "@shared/ui/QRCodeGenerator";
import { Checkbox, Modal, Select } from "antd";
import { useGenerateQRCode } from "../model";
import "./GenerateQRCode.scss";
import GenerateQRCodeConfig from "./GenerateQRCodeConfig";
import GenerateQRCodeFooter from "./GenerateQRCodeFooter";
import GenerateQRCodePreviewConfig from "./GenerateQRCodePreviewConfig";

const GenerateQRCode = () => {
    const {
        choosenBizProcTemplateId,
        bizProcTemplatesOptions,
        entityFieldsOptions,
        chunkedPdfPages,
        departmentsOptions,
        selectedDepartmentsIds,
        selectedEntityFieldId,
        loading,
        clickedEntityForQRCode,
        qrCodeSize,
        pdfOrientation,
        isOpenDrawer,
        withGeneratePdf,
        onChangeWithGeneratePdf,
        setQrCodeSize,
        setPdfOrientation,
        setIsOpenDrawer,
        onSelectBizProcTemplateId,
        onPreload,
        onOpenDrawer,
        onOk,
        onCancel,
        onSelectEntityField,
        onSelectDepartmentsIds,
    } = useGenerateQRCode();
    return (
        <Modal
            className="modal-qr-code"
            title={`${clickedEntityForQRCode?.name}`}
            onOk={onOk}
            okText="Сгенерировать QR коды"
            open={!!clickedEntityForQRCode}
            onCancel={onCancel}
            destroyOnClose
            footer={
                <GenerateQRCodeFooter
                    loading={loading}
                    onOpenDrawer={onOpenDrawer}
                    onCancel={onCancel}
                    onOk={onOk}
                />
            }
        >
            <FormItem label="Выбрать пользовательское поле">
                <Select
                    allowClear
                    value={selectedEntityFieldId}
                    style={{ width: "100%" }}
                    placeholder="Выберите пользовательское поле"
                    options={entityFieldsOptions}
                    onSelect={onSelectEntityField}
                />
            </FormItem>
            <FormItem label="Выбор департамента">
                <Select
                    mode="multiple"
                    value={selectedDepartmentsIds}
                    options={departmentsOptions}
                    style={{ width: "100%" }}
                    placeholder="Выберите департамент"
                    onChange={onSelectDepartmentsIds}
                />
            </FormItem>
            <FormItem label="Выбор бизнес-процесса">
                <Select
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Выберите бизнес-процесс"
                    options={bizProcTemplatesOptions}
                    value={choosenBizProcTemplateId}
                    onSelect={onSelectBizProcTemplateId}
                />
            </FormItem>
            {chunkedPdfPages.map((group, index) => {
                return (
                    <QRCodeGenerator
                        orientation={pdfOrientation}
                        qrCodeSize={+qrCodeSize}
                        key={index}
                        qrCodesWithNames={group}
                        qrCodeId={`key_${index}`}
                    />
                );
            })}
            <Checkbox onChange={onChangeWithGeneratePdf} checked={withGeneratePdf}>
                Сгенерировать PDF
            </Checkbox>
            <GenerateQRCodeConfig
                pdfOrientation={pdfOrientation}
                setIsOpenDrawer={setIsOpenDrawer}
                isOpenDrawer={isOpenDrawer}
                qrCodeSize={qrCodeSize}
                setPdfOrientation={setPdfOrientation}
                setQrCodeSize={setQrCodeSize}
                onPreload={onPreload}
            />
            <GenerateQRCodePreviewConfig
                pdfOrientation={pdfOrientation}
                qrCodeSize={qrCodeSize}
            />
        </Modal>
    );
};

export default GenerateQRCode;
