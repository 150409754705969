import { Image } from "antd";
import React from "react";
import "./EmptyPage.scss";
import searchIcon from "./search-svgrepo-com.svg";
interface IEmptyPage {
    text: string;
    button?: React.ReactNode;
}

const EmptyPage = (props: IEmptyPage) => {
    const { text, button } = props;
    return (
        <div className="empty-page">
            <div className="empty-page__header">
                <Image
                    preview={false}
                    src={searchIcon}
                    alt="search-icon"
                    width={100}
                    height={100}
                />
                {text}
            </div>
            {button && <div className="empty-page__button">{button}</div>}
        </div>
    );
};

export default EmptyPage;
