import { CapabilitiesRolesDataController } from "@features/CapabilitiesRoles/CapabilitiesRolesDataController";
import { CapabilitiesRolesTable } from "@features/CapabilitiesRoles/CapabilitiesRolesRow";
import { Flex } from "antd";
import "./CapabilitiesRolesTableWithCreating.scss";
import CreateCapabilitiesRoles from "./CreateCapabilitiesRoles";

interface ICapabilitiesRolesTableWithCreating {
    entityCapabilitiesRolesId: number | undefined;
    role: TUserRole | undefined;
}

const CapabilitiesRolesTableWithCreating = ({
    entityCapabilitiesRolesId,
    role,
}: ICapabilitiesRolesTableWithCreating) => {
    return (
        <Flex vertical className="capabilities-roles-table-with-creating">
            <Flex vertical style={{ flex: 1 }}>
                <CapabilitiesRolesTable
                    createButton={
                        <CreateCapabilitiesRoles
                            entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                            role={role}
                        />
                    }
                    entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                    role={role}
                    footer={
                        <CapabilitiesRolesDataController
                            entityCapabilitiesRolesId={entityCapabilitiesRolesId!}
                        />
                    }
                />
            </Flex>
        </Flex>
    );
};

export default CapabilitiesRolesTableWithCreating;
