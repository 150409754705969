import { useState } from "react";

export const useTabMode = (entityId: number) => {
    const [tabMode, setTabMode] = useState<string>(
        localStorage.getItem(`${entityId}_tab-mode`) ?? "table"
    );

    const onChangeTabMode = (tabMode: string) => {
        localStorage.setItem(`${entityId}_tab-mode`, tabMode);
        setTabMode(tabMode);
    };
    return {
        tabMode,
        onChangeTabMode,
    };
};
