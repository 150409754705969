import { SyncOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { useBottomActions } from "../UseBottomActions";
import "./BizProcTemplateItemList.scss";
interface IBizProcTemplateBottomActions {
    compact?: boolean;
    startText?: string;
    bizProcTemplateItem: TBizProcTemplateItem;
    updateBizProcTemplate?: (bizProcTemplateItem: TBizProcTemplateItem) => void;
    onStart?: (bizProc: TBizProcTemplateItem) => void;
}

const BizProcTemplateBottomActions = ({
    bizProcTemplateItem,
    compact,
    startText,
    onStart,
    updateBizProcTemplate,
}: IBizProcTemplateBottomActions) => {
    const { onStart_, loading } = useBottomActions(
        bizProcTemplateItem,
        updateBizProcTemplate,
        onStart
    );

    const navigate = useNavigate();

    return (
        <div className="bottom-actions">
            {false &&
            bizProcTemplateItem.is_started &&
            bizProcTemplateItem?.started_biz_proc_id ? (
                <>
                    <Tag
                        color="default"
                        style={{
                            height: 32,
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            navigate(
                                `/bizproc-dashboard/${bizProcTemplateItem?.started_biz_proc_id}`
                            )
                        }
                    >
                        Перейти
                    </Tag>
                    <Tag
                        icon={<SyncOutlined spin />}
                        color="processing"
                        style={{ height: 32, alignItems: "center", display: "flex" }}
                    >
                        В процессе
                    </Tag>
                </>
            ) : (
                <Button
                    type={compact ? "link" : undefined}
                    loading={loading}
                    onClick={onStart_}
                    //	icon={<PlayCircleOutlined />}
                >
                    {startText ? startText : "Запустить"}
                </Button>
            )}
        </div>
    );
};

export default BizProcTemplateBottomActions;
