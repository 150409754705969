const checkIsValid = (field: TBizProcFormField) => {
    return !(
        !field.is_readonly &&
        field.is_required &&
        (field?.value === undefined ||
            field.value === null ||
            field.value === "" ||
            field.value?.length === 0)
    );
};
const getInnerErrorText = (errorFieldsNames: string[]) => {
    const isMoreThenOne = errorFieldsNames?.length > 1;
    return `Пол${isMoreThenOne ? "я" : "e"} ${errorFieldsNames?.join(", ")} обязательн${isMoreThenOne ? "ы" : "о"} к заполнению`;
};
// TODO refactor
export const checkRequiredFields = (
    fields: TBizProcFormField[],
    parentFieldId?: string | number
) => {
    let isValid = true;
    let errorFields: any = {};
    if (!fields)
        return {
            isValid,
            errorFields,
        };

    fields.forEach((field) => {
        if (!checkIsValid(field)) {
            isValid = false;
            errorFields[field.unique_id] = true;
            errorFields[parentFieldId + "_error_fields"] = [
                ...(errorFields[parentFieldId + "_error_fields"] ?? []),
                field.name,
            ];
        }

        if (field.datatype === "entity" && field.additional_parameters?.fields) {
            if (field.is_multiple) {
                if (field.value) {
                    let errorKeys: any[] = [];
                    field.value.forEach((value: any) => {
                        const { isValid: isValidInner, errorFields: errorFieldsInner } =
                            checkRequiredFields(
                                field.additional_parameters.fields.map(
                                    (innerField: TBizProcFormField) => {
                                        return {
                                            ...innerField,
                                            value: value?.[innerField.key],
                                        };
                                    }
                                ),
                                field.unique_id
                            );
                        if (!isValidInner) {
                            errorFields[field.unique_id] = true;
                            errorKeys = [
                                ...(errorFieldsInner[
                                    field.unique_id + "_error_fields"
                                ].filter((key: any) => !errorKeys.includes(key)) ?? []),
                                ...errorKeys,
                            ];
                            isValid = false;
                        }
                    });

                    errorFields[field.unique_id + "_err"] = getInnerErrorText(errorKeys);
                }
            } else {
                const { isValid: isValidInner, errorFields: errorFieldsInner } =
                    checkRequiredFields(
                        field.additional_parameters.fields.map(
                            (innerField: TBizProcFormField) => {
                                return {
                                    ...innerField,
                                    value: field.value?.[innerField.key],
                                };
                            }
                        ),
                        field.unique_id
                    );
                if (!isValidInner) {
                    errorFields[field.unique_id] = true;
                    errorFields[field.unique_id + "_err"] = getInnerErrorText(
                        errorFieldsInner[field.unique_id + "_error_fields"]
                    );
                    isValid = false;
                }
            }
        }
    });

    // if (!result) {
    //     setError(errorFields);
    // }
    return {
        isValid,
        errorFields,
    };
};
