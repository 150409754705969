import { apiUrl } from "@shared/api/api";
import { RequestedSelect } from "@shared/ui/RequestedSelect";

interface ISelectDocument {
    document: number;
    disabled?: boolean;
    onSelectDocument: (document: number) => void;
}

const SelectDocument = ({ document, disabled, onSelectDocument }: ISelectDocument) => {
    return (
        <RequestedSelect
            disabled={disabled}
            onSelect={onSelectDocument}
            values={document}
            requestUrl={`${apiUrl}/api/bizproc-template/documents`}
            queryTypedParam="name"
            queryChoosenParams="userIds"
            valueLabel="name"
            valueKey="id"
        />
    );
};

export default SelectDocument;
