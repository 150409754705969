import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { ChangeEvent, useEffect, useState } from "react";

interface ITextEditable {
    text: string;
    onSave: (text: string) => void;
}

const TextEditable = ({ text, onSave }: ITextEditable) => {
    const [textEdited, setTextEdited] = useState(text);
    const [editing, setEditing] = useState(false);

    // TODO
    useEffect(() => {
        setTextEdited(text);
    }, [text]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTextEdited(event.target.value);
    };

    const onStart = () => {
        setEditing(true);
    };

    const onEnd = () => {
        setEditing(false);
        onSave(textEdited);
    };

    const onClick = () => {
        if (editing) onEnd();
        else onStart();
    };
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {editing ? (
                <Input value={textEdited} onChange={onChange} />
            ) : (
                <div
                    style={{
                        padding: editing ? "4px 11px" : "4px 5px",
                    }}
                    onClick={onClick}
                >
                    {textEdited}
                </div>
            )}
            <a style={{ padding: "2px 5px" }} onClick={onClick}>
                {editing ? (
                    <SaveOutlined style={{ fontSize: 15 }} />
                ) : (
                    <EditOutlined style={{ fontSize: 15 }} />
                )}
            </a>
        </div>
    );
};

export default TextEditable;
