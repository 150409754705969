export const locales = {
    createLink: {
        url: "URL",
        urlPlaceholder: "Вставьте URL",
        title: "Название",
        saveTooltip: "Сохранить",
        cancelTooltip: "Отмена",
    },
    dialogControls: {
        save: "Сохранить",
        cancel: "Отменить",
    },
    toolbar: {
        blockTypes: {
            paragraph: "Параграф",
            quote: "Цитирование",
            heading: "Заголовок {{level}}",
        },
        blockTypeSelect: {
            selectBlockTypeTooltip: "Выберите тип блока",
            placeholder: "Тип блока",
        },
        removeBold: "Убрать жирное начертание",
        bold: "Жирное начертание",
        removeItalic: "Убрать курсив",
        italic: "Курсив",
        underline: "Подчеркивание",
        removeUnderline: "Убрать подчеркивание",
        link: "Создать ссылку",
        richText: "Насыщенный текст",
        admonition: "Вставить напутствие",
        table: "Вставить таблицу",
        thematicBreak: "Тематическое разделение",
        bulletedList: "Список",
        numberedList: "Нумерованный список",
        checkList: "Перечень",
        undo: "Назад {{shortcut}}",
        redo: "Вперед {{shortcut}}",
    },
    admonitions: {
        note: "Примечание",
        tip: "Совет",
        danger: "Опасность",
        info: "Информация",
        caution: "Внимание",
        changeType: "Выберите тип напутствия",
        placeholder: "Тип напутствия",
    },
    table: {
        deleteTable: "Удалить таблицу",
        columnMenu: "Конфигурация колонки",
        textAlignment: "Выравнивание текста",
        alignLeft: "Выравнивание по левому краю",
        alignCenter: "Выравнивание по центру",
        alignRight: "Выравнивание по правому краю ",
        insertColumnLeft: "Вставить колонку слева",
        insertColumnRight: "Вставить колонку справа",
        deleteColumn: "Удалить колонку",
        rowMenu: "Конфигурация строки",
        insertRowAbove: "Вставить строку выше",
        insertRowBelow: "Вставить строку ниже",
        deleteRow: "Удалить строку",
    },
};
