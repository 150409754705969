import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    createEntityRowObserver,
    deleteEntityRowObserver,
    getEntityRowObservers,
} from "../EntityRowObserverApi";

export const loadEntityRowObservers = createAsyncThunk(
    "EntitySlice/loadEntityRowObservers",
    async (entityUuid: string, { rejectWithValue }) => {
        try {
            const response = await getEntityRowObservers(entityUuid);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const createEntityRowObserverThunk = createAsyncThunk(
    "EntitySlice/createEntityRowObservers",
    async (entityRowObserver: TCreatingEntityRowObserver, { rejectWithValue }) => {
        try {
            const response = await createEntityRowObserver(entityRowObserver);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteEntityRowObserverThunk = createAsyncThunk(
    "EntitySlice/deleteEntityRowObserverThunk",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteEntityRowObserver(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
