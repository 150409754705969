import { useState } from "react";

export const useUserPassword = (userId: number) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
        setIsOpen(true);
    };
    return { isOpen, setIsOpen, onClick };
};
