import { CheckOutlined, FormOutlined, SyncOutlined } from "@ant-design/icons";
import { UserAssygnment } from "@entities/BizProc";
import { bizProcLib } from "@entities/BizProc";
import { CardWithAside } from "@shared/ui/Cards/CardWithAside";
import { Tooltip, theme } from "antd";
import { useNavigate } from "react-router-dom";
import BizProcListActions from "./BizProcListActions/BizProcListActions";

interface IBizProcListItem {
    bizProc: TBizProc;
}

const BizProcListItem = ({ bizProc }: IBizProcListItem) => {
    const navigate = useNavigate();
    const { token } = theme.useToken();

    return (
        <div key={bizProc.id} className="bizprocs__item">
            <CardWithAside
                asideOrientation="left"
                asideWidth={8}
                actions={
                    <span
                        style={{
                            marginRight: 8,
                        }}
                    >
                        <BizProcListActions bizProc={bizProc} />
                    </span>
                }
                title={
                    <div className="bizprocs__item-title">
                        <div
                            className="bizprocs__item-icon-status"
                            style={{
                                color:
                                    bizProc.status === "executing"
                                        ? token.colorInfo
                                        : bizProc.status === "done"
                                          ? "#389e0d"
                                          : "gray",
                                fontSize: 16,
                            }}
                        >
                            {bizProc.status === "executing" ? (
                                <SyncOutlined spin color="white" />
                            ) : bizProc.status === "done" ? (
                                <CheckOutlined color="white" />
                            ) : (
                                <FormOutlined color="white" />
                            )}
                        </div>
                        <span
                            className="bizprocs__item-title-link"
                            onClick={() => navigate(`/bizproc-dashboard/${bizProc.id}`)}
                        >
                            <Tooltip
                                placement="topLeft"
                                title={bizProc.name}
                                overlayInnerStyle={{ color: "black" }}
                                color="white"
                            >
                                {bizProc.name}
                            </Tooltip>
                        </span>
                    </div>
                }
                description={
                    <>
                        <span>
                            Статус: {bizProcLib.getBizProcStatusName(bizProc.status)}
                        </span>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <span style={{ marginRight: 5 }}>Запущен: </span>
                            <UserAssygnment user={bizProc.assigned_user} />
                        </div>
                    </>
                }
                asideContent={
                    <div
                        style={{
                            height: "100%",
                            // marginLeft: 8,
                            borderBottomLeftRadius: 8,
                            borderTopLeftRadius: 8,
                            borderLeft: `4px solid ${
                                bizProc.status === "executing"
                                    ? token.colorInfo
                                    : bizProc.status === "done"
                                      ? "#389e0d"
                                      : "gray"
                            }`,
                        }}
                    ></div>
                }
                asideContentDirection={"row"}
            />
        </div>
    );
};

export default BizProcListItem;
