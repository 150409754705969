import { Select } from "antd";
import { useComparisonTypeSelect } from "./UseComparisonTypeSelect";

interface IFieldTypeComparisonSelect {
    values: number[];
    onChange: (value: number[], option: TComparisonOption | TComparisonOption[]) => void;
}

const ComparisonTypeSelect = ({ values, onChange }: IFieldTypeComparisonSelect) => {
    const { comparisonTypes } = useComparisonTypeSelect();

    return (
        <Select
            onChange={onChange}
            mode="multiple"
            style={{ width: "100%" }}
            options={comparisonTypes}
            value={values}
        />
    );
};

export default ComparisonTypeSelect;
