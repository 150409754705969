import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { selectAgreements, selectLoadBizProcAgreementsIds } from "./BizProcSelectors";
import { loadBizProcsAgreementsThunk } from "./BizProcThunk";
import { usePostLoadAgreements } from "./UsePostLoadAgreements";

export const useLoadAgreements = (preload?: boolean) => {
    const agreements = useSelector(selectAgreements);
    const dispatch = useDispatch<AppDispatch>();
    usePostLoadAgreements(false);

    useEffect(() => {
        if (preload) {
            loadAgreements();
        }
    }, []);

    const loadAgreements = () => {
        dispatch(loadBizProcsAgreementsThunk({ isMy: false }));
    };
    return {
        agreements,
        loadAgreements,
    };
};
