import { actionModel } from "@entities/Action";
import { BizProcActionItem } from "@entities/BizProcTemplate";
import { useSelector } from "react-redux";
import ActionCreatedItem from "./ActionCreatedItem";
import ActionNormalItem from "./ActionNormalItem";
import "./ActionsList.scss";
import ActionsSkeletonList from "./ActionsSkeletonList";
import { CardError } from "@shared/ui/Cards/CardError";
import { EmptyPage } from "@shared/EmptyPage";
import { ActionCreateButton } from "@features/Action/CreateAction";

const ActionsList = () => {
    const actions = useSelector(actionModel.selectActions);
    const isLoading = useSelector(actionModel.selectIsLoading);
    const error = useSelector(actionModel.selectError);

    if (error) {
        const { message } = error?.error;
        const { status } = error?.payload;
        return <CardError message={message} status={status} />;
    }

    if (actions.length > 0) {
        return (
            <div className="actions-list">
                {actions.map((action) => {
                    return (
                        <div key={action.id} className="actions-list__item">
                            {action.is_creating ? (
                                <ActionCreatedItem action={action} />
                            ) : (
                                <ActionNormalItem
                                    exampleRender={
                                        <BizProcActionItem
                                            action={action.type as TBizProcActionType}
                                        />
                                    }
                                    action={action}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
    if (isLoading && actions.length < 1) {
        return <ActionsSkeletonList />;
    }
    return <EmptyPage text="Блоки не найдены" button={<ActionCreateButton />} />;
};

export default ActionsList;
