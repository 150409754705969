import { Views } from "react-big-calendar";
import CustomYearView from "./ui/CustomYearView";

export const customViews = {
    agenda: true,
    day: true,
    week: true,
    month: true,
    year: CustomYearView,
} as any;

export const customViewModes: {
    DAY: "day";
    WEEK: "week";
    MONTH: "month";
    YEAR: "year";
    AGENDA: "agenda";
} = { ...Views, YEAR: "year" };

export const customMessages = {
    year: "Год",
    month: "Месяц",
    week: "Неделя",
    day: "День",
    agenda: "Расписание",
    next: "Дальше",
    previous: "Назад",
    today: "Сегодня",
    showMore: () => "Другие события",
    allDay: "Весь день",
    event: "Событие",
    time: "Время",
    date: "Дата",
    noEventsInRange: "На эту дату ничего не найдено",
} as any;

export const schedulerFormats = {
    day: "День",
    week: "Неделя",
    month: "Месяц",
    year: "Год",
    agenda: "Расписание",
};

export const darkColors = [
    "#E6A8E1", // Бледно-сиреневый
    "#AFA9CF", // Пастельно-фиолетовый
    "#FFC4E2", // Светло-розовый
    "#B1D9E3", // Мятно-голубой
    "#F3D9E3", // Пудровый
    "#D4A8A3", // Бледно-коралловый
    "#A6C7E2", // Светло-голубой
    "#E9CBBF", // Персиковый
    "#B9A9CE", // Лавандовый
    "#C8B8D0", // Пастельно-лиловый
    "#ECD4D2", // Розово-персиковый
    "#8BB8D4", // Бледно-синий
    "#EBC9C0", // Розово-персиковый
    "#B5A7E2", // Фиолетово-голубой
    "#AAC1E2", // Серо-голубой
    "#CAD8E6", // Светло-серый
    "#D9D2E6", // Лавандово-серый
    "#A4C4E5", // Цианово-голубой
    "#CCD7EA", // Серо-синий
    "#E2DCF2", // Бледно-серый
];

export const formatOfChoosingDate = {
    day: "день",
    agenda: "день",
    week: "неделю",
    month: "месяц",
    year: "год",
};
