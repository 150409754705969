import { apiUrl } from "@shared/api/api";
import { FormItem } from "@shared/ui/Form";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { Checkbox } from "antd";
interface IEditStartAnotherBizProcAction {
    templateItem: TBizProcTemplateAction;
    disabled?: boolean;
    bizProcTemplate: TBizProcTemplateItem;
    setItem: (templateItem: TBizProcTemplateAction) => void;
}
const EditStartAnotherBizProcAction = ({
    templateItem,
    setItem,
    bizProcTemplate,
    disabled,
}: IEditStartAnotherBizProcAction) => {
    const needCreateNewEntity = templateItem.parameters.needCreateNewEntity ?? false;
    const onSelectBizProc = (value: number) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                bizProcTemplateId: value,
            },
        });
    };
    const onChangeFlag = () => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                needCreateNewEntity: !needCreateNewEntity,
            },
        });
    };
    const onClear = () => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                bizProcTemplateId: null,
            },
        });
    };
    return (
        <>
            <FormItem label="Выберите запускаемый бизнес-процесс">
                <RequestedSelect
                    disabled={disabled}
                    allowClear
                    onClear={onClear}
                    values={templateItem.parameters.bizProcTemplateId}
                    onSelect={onSelectBizProc}
                    requestUrl={`${apiUrl}/api/bizproc-template`}
                    queryTypedParam="name"
                    queryChoosenParams="ids"
                    valueLabel="name"
                    valueKey="id"
                />
            </FormItem>
            <Checkbox
                checked={needCreateNewEntity}
                onChange={onChangeFlag}
                disabled={disabled}
            >
                Необходимо создать новую запись в БД
            </Checkbox>
        </>
    );
};

export default EditStartAnotherBizProcAction;
