import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    isLinkedUserTelegramThunk,
    linkUserTelegramThunk,
    unlinkUserTelegramThunk,
} from "./UserThunk";

export interface IUser {
    authorizated: boolean;
    user: TUser | null;
    users: TUser[];
    access_token: string | null;
    openedUserId: number | null;
    isAuthChecking: boolean;
    isTgLinkCreated: boolean;
    telegramUsername: string | null;
    tgAuthKey: string | null;
}

const initialState: IUser = {
    authorizated: false,
    isAuthChecking: true,
    users: [],
    access_token: null,
    user: null,
    openedUserId: null,
    isTgLinkCreated: false,
    telegramUsername: null,
    tgAuthKey: "null",
};
// action: PayloadAction<HomeLayout>
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.access_token = action.payload;
            localStorage.setItem("access_token", action.payload);
            // cookies.set("accessToken", action.payload, { domain: ".vakio.ru" });
            // cookies.set("accessToken", action.payload);
        },
        setOpenedUserId: (state, action: PayloadAction<number | null>) => {
            state.openedUserId = action.payload;
            // cookies.set("accessToken", action.payload, { domain: ".vakio.ru" });
            // cookies.set("accessToken", action.payload);
        },
        // setUsers: (state, action: PayloadAction<TUser[]>) => {
        //     state.users = action.payload;
        // },
        setUser: (state, action: PayloadAction<TUser>) => {
            state.user = action.payload;
            state.isAuthChecking = false;
            state.authorizated = true;
            if (state.user?.timezone?.offset) window.Offset = state.user.timezone.offset;
            state.user.isAdmin = state.user?.roles?.some(
                (role) => role.id === 1 || role.id === 2
            );
            state.user.isCanReadQr =
                state.user.isAdmin || state.user?.roles?.some((role) => role.id === 3);
            localStorage.setItem("user", JSON.stringify(action.payload));
        },
        setUserTimezone: (state, action: PayloadAction<TUserTimezone>) => {
            if (state.user) {
                state.user.timezone_id = action.payload.id.toString();
                state.user.timezone = action.payload;
                if (state.user?.timezone?.offset)
                    window.Offset = state.user.timezone.offset;
                localStorage.setItem("user", JSON.stringify(state.user));
            }
        },
        signOut: (state) => {
            localStorage.removeItem("user");
            localStorage.removeItem("access_token");
            state.isAuthChecking = false;
            state.authorizated = false;
            state.access_token = null;
            state.user = null;
        },
    },
    extraReducers: (builder) => {
        linkUserTelegramBuilder(builder);
        isLinkedUserTelegramBuilder(builder);
        unlinkUserTelegramBuilder(builder);
    },
});
const linkUserTelegramBuilder = (builder: ActionReducerMapBuilder<IUser>) => {
    builder.addCase(linkUserTelegramThunk.fulfilled, (state, action) => {
        state.tgAuthKey = action.payload;
        state.isTgLinkCreated = true;
        window.open(state.tgAuthKey as string);
    });
};

const isLinkedUserTelegramBuilder = (builder: ActionReducerMapBuilder<IUser>) => {
    builder.addCase(isLinkedUserTelegramThunk.fulfilled, (state, action) => {
        state.isTgLinkCreated = action.payload.isTgLinkCreated;
        state.telegramUsername = action.payload.telegramUsername;
    });
};
const unlinkUserTelegramBuilder = (builder: ActionReducerMapBuilder<IUser>) => {
    builder.addCase(unlinkUserTelegramThunk.fulfilled, (state) => {
        state.isTgLinkCreated = false;
        state.telegramUsername = null;
    });
};

export const {
    setUser,
    // setUsers,
    setToken,
    setOpenedUserId,
    setUserTimezone,
    // setAuthChekingState,
    signOut,
} = userSlice.actions;

export default userSlice.reducer;
