import {
    actionNotAllowAddAfter,
    actionsAllowChilds,
    bizProcRowChild,
} from "../constants";

export const isBizProcWithRowChild = (bizProcType: TBizProcActionType) => {
    return bizProcRowChild.includes(bizProcType);
};
export const isBizProcWithSelfChilds = (bizProcType: TBizProcActionType) => {
    return actionsAllowChilds.includes(bizProcType);
};
export const isBizProcNotAllowAddAfter = (bizProcType: TBizProcActionType) => {
    return actionNotAllowAddAfter.includes(bizProcType);
};
