import { useDispatch } from "react-redux";
import { userModel } from "@entities/User";
import { useNavigate } from "react-router-dom";

export const useSignOutButton = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onSignOut = () => {
        dispatch(userModel.signOut());
        navigate("/login");
    };
    return {
        onSignOut,
    };
};
