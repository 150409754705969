import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    linkUserTelegram,
    isLinkedUserTelegram,
    unlinkUserTelegram,
    updateUserTimezone,
} from "../api";

export const linkUserTelegramThunk = createAsyncThunk(
    "UserSlice/linkUserTelegramThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await linkUserTelegram();
            return response.data.data.url;
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const isLinkedUserTelegramThunk = createAsyncThunk(
    "UserSlice/isLinkedUserTelegramThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await isLinkedUserTelegram();
            // return response.data;
            return {
                isTgLinkCreated: response.data.success,
                telegramUsername: response.data.data.telegram_username,
            };
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const unlinkUserTelegramThunk = createAsyncThunk(
    "UserSlice/unlinkUserTelegramThunk",
    async (_, { rejectWithValue }) => {
        try {
            const response = await unlinkUserTelegram();
            return response.data;
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const updateUserTimezoneThunk = createAsyncThunk(
    "UserSlice/updateUserTimezoneThunk",
    async (
        {
            userId,
            timezone,
        }: {
            userId: number;
            timezone: number | string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await updateUserTimezone(userId, timezone);
            return response.data;
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
