interface IDropdownGroup {
    group: TDropdownVariablesGroup;
    onSelectItem: (item: TDropdownItem) => void;
}

const DropdownGroup = ({ group, onSelectItem }: IDropdownGroup) => {
    return (
        <>
            {group.items.map((item) => {
                const onClick = () => {
                    onSelectItem(item);
                };
                return (
                    <div
                        key={`${item.variable_type}-${item.id}`}
                        className="input-dropdown__item"
                        onClick={onClick}
                    >
                        {item.name}
                    </div>
                );
            })}
        </>
    );
};

export default DropdownGroup;
