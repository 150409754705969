import { Button, Input, Progress, Result, Slider, Spin, Typography } from "antd";
import { useRecoverPasswordForm } from "./UseRecoverPasswordForm";
import { ErrorText } from "@shared/ui/Text";

interface IRecoverPasswordForm {
    code: string;
}

const RecoverPasswordForm = ({ code }: IRecoverPasswordForm) => {
    const {
        error,
        isChecked,
        checkLoading,
        checkError,
        passwords,
        loading,
        success,
        onChange,
        onSubmit,
        navigateToStartRecoverPassword,
    } = useRecoverPasswordForm(code);

    if (checkError || (!checkLoading && !isChecked)) {
        return (
            <Result
                status={"error"}
                subTitle="Недействительная ссылка"
                extra={[
                    <Button id="1" type="link" onClick={navigateToStartRecoverPassword}>
                        Запросить новую
                    </Button>,
                ]}
            />
        );
    }
    if (checkLoading) {
        return (
            <div
                style={{
                    minHeight: 180,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spin />
            </div>
        );
    }
    if (success) {
        return (
            <>
                <Result
                    status="success"
                    subTitle="Пароль успешно изменён!"
                    extra={[<Button type="link">Авторизироваться</Button>]}
                />
            </>
        );
    }
    return (
        <>
            <div style={{ margin: "10px 0px", width: "100%" }}>
                <Typography.Text
                    style={{
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    Введите новый пароль
                </Typography.Text>
            </div>
            <div style={{ marginBottom: 15, width: "100%" }}>
                <Input
                    value={passwords.password}
                    status={error ? "error" : undefined}
                    onChange={onChange}
                    placeholder="Пароль"
                    name="password"
                />
                {error && <ErrorText text={error} absolute />}
            </div>
            <div style={{ marginBottom: 15, width: "100%" }}>
                <Input
                    status={error ? "error" : undefined}
                    value={passwords.confirmPassword}
                    onChange={onChange}
                    placeholder="Повторите пароль"
                    name="confirmPassword"
                />
                {error && <ErrorText text={error} absolute />}
            </div>
            <Button loading={loading} onClick={onSubmit} type="primary">
                Сохранить пароль
            </Button>
        </>
    );
};

export default RecoverPasswordForm;
