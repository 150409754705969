import { $api } from "@shared/api/api";
const route = "/api/view-pattern";

export const getViewPatterns = (params?: TParams) => {
    return $api.get<TResponseList<TViewPattern>>(route, { params: params });
};
export const updateViewPattern = (viewPattern: TViewPattern) => {
    return $api.put<TResponse<TViewPattern>>(`${route}/${viewPattern.id}`, viewPattern);
};
export const createViewPattern = (viewPattern: TViewPatternCreating) => {
    return $api.post<TResponse<TViewPattern>>(route, viewPattern);
};
export const deleteViewPattern = (id: number) => {
    return $api.delete<TResponse<TViewPattern>>(`${route}/${id}`);
};
