import Echo from "laravel-echo";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { userModel } from "@entities/User";

export const useWebsockets = () => {
    const accessToken = useSelector(userModel.selectAccessToken);

    window.Pusher = require("pusher-js");
    window.Echo = useMemo(() => (new Echo({
        broadcaster: "reverb",
        key: "qrvcxakvm9kczogqbkt6",
        wsHost: process.env.REACT_APP_WS_API_URL,
        wsPort: process.env.REACT_APP_WS_PORT,
        wssPort: process.env.REACT_APP_WS_PORT,
        forceTLS: process.env.REACT_APP_FORCED_TLS === "true",
        enabledTransports: ["ws", "wss"],
        authEndpoint: `${process.env.REACT_APP_API_URL}/api/broadcasting/auth`,
        auth: {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    })), [accessToken]);
};