import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import {
    createEntityThunk,
    deleteEntityThunk,
    loadEntitiesThunk,
    updateEntityThunk,
} from "./EntityThunk";
import { entityRowObserversBuilders } from "./EntityRowObserversBuilder";

export interface IEntitySlice {
    entities: TEntity[];
    entityRowObservers: TEntityRowObserver[];
    editingEntity: TEntity | TCreatingEntity | null;
    isLoading: boolean;
    error: any;
    clickedEntityForQRCode: TEntity | null;
    clickedEntityForAddEntityValue: TEntity | null;
    entityValues: any[];
}

const initialState: IEntitySlice = {
    entities: [],
    entityRowObservers: [],
    editingEntity: null,
    isLoading: true,
    error: null,
    clickedEntityForQRCode: null,
    clickedEntityForAddEntityValue: null,
    entityValues: [],
};

export const entitySlice = createSlice({
    name: "EntitySlice",
    initialState,
    reducers: {
        setEntities: (state, action: PayloadAction<TEntity[]>) => {
            state.entities = action.payload;
            localStorage.setItem("entitiesLength", state.entities.length.toString());
        },
        setEditingEntity: (
            state,
            action: PayloadAction<TEntity | TCreatingEntity | null>
        ) => {
            state.editingEntity = action.payload;
        },
        setClickedEntityForQRCode: (state, action: PayloadAction<TEntity | null>) => {
            state.clickedEntityForQRCode = action.payload;
        },
        setClickedEntityForAddEntityValue: (
            state,
            action: PayloadAction<TEntityWithRow | null>
        ) => {
            state.clickedEntityForAddEntityValue = action.payload;
        },
        addEntity: (state, action: PayloadAction<TEntity>) => {
            state.entities.unshift(action.payload);
            localStorage.setItem("entitiesLength", state.entities.length.toString());
        },
        updateEntity: (state, action: PayloadAction<TEntity>) => {
            state.entities = updateArrByItemId(state.entities, action.payload);
        },
        deleteEntity: (state, action: PayloadAction<TEntity>) => {
            state.entities = state.entities.filter(
                (item) => item.id !== action.payload.id
            );
            localStorage.setItem("entitiesLength", state.entities.length.toString());
        },
        setEntityValues: (state, action: PayloadAction<any[]>) => {
            state.entityValues = action.payload;
        },
    },
    extraReducers: (builder) => {
        loadBuilder(builder);
        createBuilder(builder);
        updateBuilder(builder);
        deleteBuilder(builder);
        entityRowObserversBuilders(builder);
    },
});

const loadBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(loadEntitiesThunk.fulfilled, (state, action) => {
        state.entities = action.payload.data;
        localStorage.setItem("entitiesLength", state.entities.length.toString());
        state.isLoading = false;
    });
    builder.addCase(loadEntitiesThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
    });
    builder.addCase(loadEntitiesThunk.rejected, (state, rejectedValue) => {
        state.error = rejectedValue;
        state.isLoading = false;
    });
};
const createBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(createEntityThunk.fulfilled, (state, action) => {
        state.entities.unshift(action.payload.data);
        localStorage.setItem("entitiesLength", state.entities.length.toString());
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(updateEntityThunk.fulfilled, (state, action) => {
        state.entities = updateArrByItemId(state.entities, action.payload.data);
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IEntitySlice>) => {
    builder.addCase(deleteEntityThunk.fulfilled, (state, action) => {
        state.entities = state.entities.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem("entitiesLength", state.entities.length.toString());
    });
};
export const {
    setEntities,
    setEditingEntity,
    setClickedEntityForQRCode,
    setClickedEntityForAddEntityValue,
    addEntity,
    updateEntity,
    deleteEntity,
    setEntityValues,
} = entitySlice.actions;
export default entitySlice.reducer;
