import { reorder } from "./lib";

interface IUseDragAndDropList<T> {
    items: T[];
    onChangeItems: (items: T[], moveIndex: number, targetIndex: number) => void;
}

export const useDragAndDropList = <T extends object>({
    items,
    onChangeItems,
}: IUseDragAndDropList<T>) => {
    const onDragEnd = (result: any) => {
        const moveIndex = result.source.index;
        if (!result.destination) return;
        const targetIndex = result.destination.index;
        const newItems = [...items];
        onChangeItems(reorder(newItems, moveIndex, targetIndex), moveIndex, targetIndex);
    };
    return {
        onDragEnd,
    };
};
