export const bizProcStatusNames = {
    done: "Завершен",
    executing: "Ожидает",
    reject: "Отклонён",
    approve: "Согласован",
    expired: "Просрочен",
    waiting: "Ожидает",
};
export const bizProcStatusColors = {
    expired: "error",
    done: "success",
    executing: "processing",
    reject: "error",
    approve: "success",
    waiting: "processing",
};
