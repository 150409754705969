import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { Collapse, Empty } from "antd";
import { useQrCodeList } from "../model";
import QrCodeListSkeleton from "./QrCodeListSkeleton";
import QrCodeTemplateGroup from "./QrCodeTemplateGroup";

interface IQrCodeList {
    userId: number;
}

const QrCodeList = ({ userId }: IQrCodeList) => {
    const { loading, qrCodes } = useQrCodeList(userId);

    const items = qrCodes.map((qrCodeEntityGroup) => {
        return {
            id: qrCodeEntityGroup.entityName,
            label: qrCodeEntityGroup.entityName,
            children: (
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {qrCodeEntityGroup.items.map((qrCodeTemplateGroup) => {
                        return (
                            <IndentContainer
                                key={qrCodeTemplateGroup.bizProcTemplateName}
                            >
                                <QrCodeTemplateGroup
                                    qrCodeTemplateGroup={qrCodeTemplateGroup}
                                />
                            </IndentContainer>
                        );
                    })}
                </div>
            ),
        };
    });

    return (
        <div style={{ height: "100%", width: "100%", minHeight: 200 }}>
            {!loading ? (
                items.length > 0 ? (
                    <Collapse items={items} />
                ) : (
                    <Empty />
                )
            ) : (
                <QrCodeListSkeleton />
            )}
        </div>
    );
};

export default QrCodeList;
