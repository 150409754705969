import { entityRowConstants } from "@entities/EntityRow";
import { useEffect, useState } from "react";

export const useConfigureFilterInputsModal = (
    entityFields: TEntityField[],
    visibleInputs: TBizProcFormField[],
    closeConfiguringModal: () => void,
    onSubmitFilterFieldsModal: (fieldsKeys: string[]) => void
) => {
    const [choosenFilterInputs, setChoosenFilterInputs] = useState<string[]>([]);
    const filterInputsOptions = entityFields
        .filter((field) => {
            return (
                !entityRowConstants.nowAllowedFilterFields.includes(field.datatype) &&
                // eslint-disable-next-line eqeqeq
                field.is_multiple == false
            );
        })
        .map(({ key, name }) => ({
            value: key,
            label: name,
        }));
    useEffect(() => {
        setChoosenFilterInputs(visibleInputs.map(({ key, name }) => key));
    }, [visibleInputs]);

    const onSelectNewVisibleInputs = (value: string[]) => {
        setChoosenFilterInputs(value);
    };

    const onCancelConfiguringModal = () => {
        setChoosenFilterInputs(visibleInputs.map(({ key, name }) => key));
        closeConfiguringModal();
    };

    const onSubmitNewFilterInputs = () => {
        onSubmitFilterFieldsModal(choosenFilterInputs);
        closeConfiguringModal();
    };

    return {
        choosenFilterInputs,
        filterInputsOptions,
        closeConfiguringModal,
        onSelectNewVisibleInputs,
        onCancelConfiguringModal,
        onSubmitNewFilterInputs,
    };
};
