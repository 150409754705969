import { capabilitiesRolesModel } from "@entities/CapabilitiesRoles";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useDeleteCapabilitiesRolesCell = (
    entityCapabilitiesRolesId: number,
    entityRowCapabiitiesByRole: TCapabilitiesRolesRow
) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const onDelete = () => {
        setIsLoading(true);
        dispatch(
            capabilitiesRolesModel.deleteCapabilitiesRolesThunk({
                entityId: entityCapabilitiesRolesId,
                entityRowId: entityRowCapabiitiesByRole.id!,
            })
        )
            .then(parseFormResponse)
            .then(() => {
                notificationEmit({
                    type: "success",
                    title: "Успешно",
                    description: "Сущность успешно удалена из списка настройки прав",
                });
            })
            .catch((e: any) => {
                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: "Не удалось удалить сущность из списка настройки прав",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    return { onDelete, isLoading };
};
