import {
    capabilitiesRolesConstants,
    capabilitiesRolesModel,
} from "@entities/CapabilitiesRoles";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCapabilitiesRolesDataController = (entityCapabilitiesRolesId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const initialEntityRowsValues = useSelector(
        capabilitiesRolesModel.selectInitialCapabilitiesRoles
    );
    const capabilitiesRoles = useSelector(capabilitiesRolesModel.selectCapabilitiesRoles);
    const editedEntityRows = useSelector(
        capabilitiesRolesModel.selectEditingEntityRowsIds
    );
    const isEditing = !!editedEntityRows.length;

    const onSubmit = () => {
        setIsLoading(true);
        const newInitialValues = capabilitiesRoles.reduce((acc1, entityRow) => {
            return {
                ...acc1,
                [entityRow.id!.toString()]:
                    capabilitiesRolesConstants.capabilitiesTypes.reduce(
                        (acc2, capType) => {
                            return { ...acc2, [capType.key]: entityRow[capType.key] };
                        },
                        {}
                    ),
            };
        }, {});

        const editedEntityRowsPrepared = capabilitiesRoles.filter((entityRow) =>
            editedEntityRows.includes(entityRow.id!)
        );

        dispatch(
            capabilitiesRolesModel.updateCapabilitiesRolesThunk({
                id: entityCapabilitiesRolesId,
                items: editedEntityRowsPrepared,
                newInitialValues,
            })
        )
            .then(parseFormResponse)
            .then(() => {
                notificationEmit({
                    type: "success",
                    title: "Успешно",
                    description: "Настройки прав сохранены",
                });
                dispatch(capabilitiesRolesModel.setEditingCapabilitiesRolesId([]));
            })
            .catch((e: any) => {
                notificationEmit({
                    type: "error",
                    title: "Ошибка сохранения настроек прав",
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const onCancel = () => {
        dispatch(
            capabilitiesRolesModel.setCapabilitiesRoles(
                capabilitiesRoles.map((entityRow) => {
                    return {
                        ...entityRow,
                        ...initialEntityRowsValues[entityRow.id!.toString()],
                    };
                })
            )
        );
        dispatch(capabilitiesRolesModel.setEditingCapabilitiesRolesId([]));
    };

    return {
        isEditing,
        isLoading,
        onSubmit,
        onCancel,
    };
};
