import { CardWithActions } from "@shared/ui/Cards/CardWithActions";
import { TextWithLabel } from "@shared/ui/Text";
import { useVariableItem } from "../model/UseVariableItem";

interface IVariableItem {
    variable: TVariable;
    deleteLoading: boolean;
    onDelete: (entity: TVariable) => void;
    onClick?: () => void;
}

const VariableItem = ({ variable, deleteLoading, onClick, onDelete }: IVariableItem) => {
    const { onSubmitDelete, onEdit } = useVariableItem(variable, onDelete);

    return (
        <CardWithActions
            onClick={onClick}
            onSubmitDelete={onSubmitDelete}
            deleteLoading={deleteLoading}
            onEdit={onEdit}
        >
            <TextWithLabel
                placeholder={"Имя переменной"}
                label={"Наименование"}
                text={variable.name}
            />
            <TextWithLabel
                placeholder={"Тип переменной"}
                label={"Тип"}
                text={variable.datatype ?? ""}
            />
            <TextWithLabel
                placeholder={"Тип переменной"}
                label={"Тип переменной"}
                text={
                    variable.parent_type === "global"
                        ? "Глобальный тип"
                        : variable.parent_type === "biz_proc"
                          ? "Бизнес-процесс"
                          : variable.parent_type === "biz_proc_template"
                            ? "Шаблон бизнес-процесса"
                            : "Сущность"
                }
            />
        </CardWithActions>
    );
};

export default VariableItem;
