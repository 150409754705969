export const EntityRowObserversTypesOptions = [
    {
        value: "on_create",
        label: "При создании",
    },
    {
        value: "on_update",
        label: "При обновлении",
    },
];
