import { DeleteOutlined } from "@ant-design/icons";
import { useStageDelete } from "../model";
import { Button } from "antd";

interface IStageDeleteButton {
    entityId: number;
    categoryId: number;
    stageId: number;
}

const StageDeleteButton = ({ entityId, categoryId, stageId }: IStageDeleteButton) => {
    const { deleteLoading, deleteStage } = useStageDelete(entityId, categoryId, stageId);
    return (
        <Button
            danger
            type="link"
            onClick={deleteStage}
            loading={deleteLoading}
            icon={<DeleteOutlined />}
        />
    );
};

export default StageDeleteButton;
