import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { updateArrByItemId } from "@shared/lib/object-helper";

import { stageBuilder } from "./StageBuilder";
import { reorderStages } from "../lib";

export interface IStage {
    stages: TStageWithCategory;
    getLoading: number[];
    updateLoading: number[];
    createLoading: number[];
    deleteLoading: number[];
}

const initialState: IStage = {
    stages: {},
    getLoading: [],
    updateLoading: [],
    createLoading: [],
    deleteLoading: [],
};

export const stageSlice = createSlice({
    name: "StageSlice",
    initialState,
    reducers: {
        setStages: (state, action: PayloadAction<TStageWithCategory>) => {
            state.stages = action.payload;
        },
        setCategoryStages: (
            state,
            action: PayloadAction<{
                categoryId: number;
                stages: TStage[];
            }>
        ) => {
            state.stages[action.payload.categoryId] = reorderStages(
                action.payload.stages
            );
        },
    },
    extraReducers: (builder) => {
        stageBuilder(builder);
    },
});

export const { setStages, setCategoryStages } = stageSlice.actions;
export default stageSlice.reducer;
