export const isEqualCapabilitiesRolesTableRowProps = (prevProps: any, nextProps: any) => {
    const prevEntityRow = prevProps.entityRow;
    const newEntityRow = nextProps.entityRow;
    const prevInitialValue = prevProps.initialEntityRow;
    const newInitialValue = nextProps.initialEntityRow;

    let isEqualProps: boolean = true;

    Object.entries(prevEntityRow).some(([key, value]) => {
        if (newEntityRow[key as TCapType] !== value) {
            isEqualProps = false;
            return true;
        }
        return false;
    });

    if (isEqualProps) {
        Object.entries(prevInitialValue).some(([key, value]) => {
            if (newInitialValue[key as TCapType] !== value) {
                isEqualProps = false;
                return true;
            }
            return false;
        });
    }

    return isEqualProps;
};

export const isEqualEntityRowToInitialEntityRow = (
    entityRow: TCapabilitiesRolesRow,
    initialEntityRow: TCapabilitiesRolesOnly
) => {
    let isEqual: boolean = true;
    const { id, entity_uuid, role_id, ...entityRowValues } = entityRow;
    Object.entries(initialEntityRow).some(([key, value]) => {
        if (value !== entityRowValues[key as TCapType]) {
            isEqual = false;
            return true;
        }
        return false;
    });

    return isEqual;
};
