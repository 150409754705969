import { FormItem } from "@shared/ui/Form";
import { Input } from "antd";
import React from "react";
import { TFilterInputItem } from "../../FilterKitTypes";

const FilterNumberInputItem = ({ field, onChange }: TFilterInputItem) => {
    const { value, name, label } = field;
    const onChangeNumberInput = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const name = event.target.name;
        const value = event.target.value;
        if (!/^\d*$/g.test(value)) {
            return;
        }
        onChange(name, value);
    };
    return (
        <FormItem label={label}>
            <>
                <Input value={value} name={name} onChange={onChangeNumberInput} />
            </>
        </FormItem>
    );
};

export default FilterNumberInputItem;
