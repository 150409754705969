import { Select } from "antd";

interface ISelectVariable {
    disabled?: boolean;
    templateItem: TBizProcTemplateAction;
    setItem: (templateItem: TBizProcTemplateAction) => void;
    variables: TBizProcVariable[];
}
type VariableOption = { value: string; label: string; variable: TBizProcVariable };

const SelectVariable = ({
    disabled,
    templateItem,
    variables,
    setItem,
}: ISelectVariable) => {
    const templateItemVariable = (templateItem.parameters.variable ??
        null) as TBizProcVariable;

    const onSelectVariable = (value: string, option: any | VariableOption) => {
        setItem({
            ...templateItem,
            parameters: {
                ...(templateItem.parameters ?? {}),
                variable: option.variable,
            },
        });
    };

    return (
        <Select
            style={{ width: "100%" }}
            options={variables.map((item) => ({
                value: `${item.variable_type}-${item.id}`,
                label: item.name,
                variable: item,
            }))}
            disabled={disabled}
            onChange={onSelectVariable}
            value={
                templateItemVariable
                    ? `${templateItemVariable.variable_type}-${templateItemVariable.id}`
                    : null
            }
        />
    );
};

export default SelectVariable;
