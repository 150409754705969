import { FormInputItem } from "@entities/Datatype";
import { reorderStages } from "@entities/Stage/lib";
import { getColor } from "@shared/lib/colorizing";
import { contrastColor } from "@shared/lib/colors";

export const getEntityFieldColumns = (
    entityRowsByEntityField: {
        [key: string]: any;
    },
    choosenEntityField: TEntityField | null,
    groupColors: { [key: string]: string }
) => {
    const orderIds =
        choosenEntityField?.datatype === "stage"
            ? reorderStages(
                  Object.keys(entityRowsByEntityField)
                      .filter((item) => item != "")
                      .map((id) => entityRowsByEntityField[id].option)
              ).map((item) => item.id.toString())
            : Object.keys(entityRowsByEntityField);

    if (choosenEntityField?.datatype === "stage") {
        const undefinedId = Object.keys(entityRowsByEntityField).find(
            (item) => item == ""
        );

        if (undefinedId !== undefined) {
            orderIds.push("");
        }
    }

    return orderIds.map((key: any, index: number) => {
        const value = entityRowsByEntityField[key];
        const backgroundColor = getColor(key + 1, groupColors);

        return {
            value: key === "" ? null : key,
            label: (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "inherit",
                        color: contrastColor(value.background_color ?? backgroundColor),
                        background:
                            choosenEntityField?.datatype === "stage"
                                ? value.background_color ?? backgroundColor
                                : backgroundColor,
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {choosenEntityField?.datatype === "list" ||
                        choosenEntityField?.datatype === "stage" ? (
                            <div>{value.label ?? "Не указано"}</div>
                        ) : (
                            <FormInputItem
                                additionalParameters={
                                    choosenEntityField?.additional_parameters
                                }
                                onlyRead={true}
                                field={choosenEntityField ?? undefined}
                                datatype={key ? choosenEntityField?.datatype! : "string"}
                                value={key ? key : "Не указано"}
                                onChange={() => {}}
                            />
                        )}
                    </div>
                </div>
            ),
            key: key === "" ? null : key,
            isEndedPagination: value.is_ended,
        };
    });
};
