import { UserAccess } from "@entities/User";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface IBizProcHistorySwitcher {
    showHidden: boolean;
    onChecked: (e: CheckboxChangeEvent) => void;
}

const BizProcHistorySwitcher = ({ showHidden, onChecked }: IBizProcHistorySwitcher) => {
    return (
        <UserAccess.AdminAccess>
            <div className="biz-proc-history-switcher">
                <Checkbox checked={showHidden} onChange={onChecked}>
                    Показать скрытые блоки
                </Checkbox>
            </div>
        </UserAccess.AdminAccess>
    );
};

export default BizProcHistorySwitcher;
