import { useEffect, useState } from "react";
import { getBizProcTemplateVersions } from "../api";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import dayjs from "dayjs";

export const useLoadBizProcTemplateVersions = (bizProcTemplateId: number) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<
        (TOption & { item: TBizProcTemplateVersion })[]
    >([]);
    const loadBizProcTemplateVersion = () => {
        setLoading(true);
        getBizProcTemplateVersions(bizProcTemplateId)
            .then((response) => {
                const options = response.data.data.map((version) => ({
                    value: version.version,
                    label: `${version.version} (${dayjs(version.created_at).format("DD.MM.YYYY HH:mm")})`,
                    item: version,
                }));
                setOptions(options);
            })
            .catch((error: any) => {
                notificationEmit({
                    error: error,
                    title: "Не удалось загрузить версии",
                    type: "error",
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        loadBizProcTemplateVersion();
        // eslint-disable-next-line
    }, []);
    return {
        options,
        loading,
    };
};
