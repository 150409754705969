import { FormItem } from "@shared/ui/Form";
import { TFilterInputItem, TInputSelect } from "../../FilterKitTypes";
import { Select } from "antd";

const FilterSelectItem = ({ field, onChange }: TFilterInputItem<TInputSelect>) => {
    const { label, value, name, options } = field;
    const onSelect = (name: string, value: any) => {
        onChange(name, value);
    };
    return (
        <FormItem label={label}>
            <>
                <Select
                    options={options}
                    onChange={(value: any) => {
                        onSelect(name, value);
                    }}
                    value={value}
                    style={{ width: "100%" }}
                />
            </>
        </FormItem>
    );
};

export default FilterSelectItem;
