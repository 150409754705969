import { CheckOutlined } from "@ant-design/icons";
import "./EntityRowTableGroupSelectItem.scss";
interface IEntityRowTableGroupSelectItem {
    field: TEntityField;
    isAdded: boolean;
    onClick: (key: string) => void;
}

const EntityRowTableGroupSelectItem = ({
    field,
    isAdded,
    onClick,
}: IEntityRowTableGroupSelectItem) => {
    const _onClick = () => {
        onClick(field.key);
    };
    return (
        <div className="group-select-item" onClick={_onClick}>
            <div className={`group-select-item__label ${isAdded ? "item-active" : ""}`}>
                {field.name}
            </div>
            {isAdded && (
                <CheckOutlined style={{ color: "var(--primary)", marginRight: 10 }} />
            )}
        </div>
    );
};

export default EntityRowTableGroupSelectItem;
