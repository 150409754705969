import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { estimationBuilders } from "./EstimationBuilder";

export interface IEstimationSlice {
    estimations: TEstimationTemplate[];
    entityFields: TEntityField[];
    isCalculateAdditional: boolean;
    choosenEntityFieldsKeysForEstimations: string[];
    appliedEstimation: TAppliedEstimationTemplate | null;
    additionalAppliedEstimation: TEstimationValues | null;
    additionalApplyingLoading: boolean;
    choosenIdsForAdditionalEstimation: number[];
    creatingEditingEstimation:
        | TCreatingEstimationTemplate
        | TEditingEstimationTemplate
        | null;
    loading: boolean;
    applyingLoading: boolean;
    savingLoading: boolean;
    error: any;
}

const initialState: IEstimationSlice = {
    estimations: [],
    entityFields: [],
    isCalculateAdditional: false,
    choosenEntityFieldsKeysForEstimations: [],
    appliedEstimation: null,
    additionalAppliedEstimation: null,
    additionalApplyingLoading: false,
    choosenIdsForAdditionalEstimation: [],
    creatingEditingEstimation: null,
    applyingLoading: false,
    savingLoading: false,
    loading: false,
    error: null,
};

export const estimationSlice = createSlice({
    name: "EstimationSlice",
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setEstimations: (state, action: PayloadAction<TEstimationTemplate[]>) => {
            state.estimations = action.payload;
        },
        setChoosenEntityFieldsKeysForEstimations: (
            state,
            action: PayloadAction<string[]>
        ) => {
            state.choosenEntityFieldsKeysForEstimations = action.payload;
        },
        setEntityFields: (state, action: PayloadAction<TEntityField[]>) => {
            state.entityFields = action.payload;
        },
        setAppliedEstimation: (
            state,
            action: PayloadAction<TAppliedEstimationTemplate | null>
        ) => {
            state.appliedEstimation = action.payload;
        },
        setAdditionalAppliedEstimation: (
            state,
            action: PayloadAction<TEstimationValues | null>
        ) => {
            state.additionalAppliedEstimation = action.payload;
        },
        setIsCalculateAdditional: (state, action: PayloadAction<boolean>) => {
            state.isCalculateAdditional = action.payload;
        },
        setChoosenIdsForAdditionalEstimation: (
            state,
            action: PayloadAction<number[]>
        ) => {
            state.choosenIdsForAdditionalEstimation = action.payload;
        },
        setCreatingEditingEstimation: (
            state,
            action: PayloadAction<
                TCreatingEstimationTemplate | TEditingEstimationTemplate | null
            >
        ) => {
            state.creatingEditingEstimation = action.payload;
        },
    },
    extraReducers: (builder) => estimationBuilders(builder),
});
export const {
    setLoading,
    setEstimations,
    setChoosenEntityFieldsKeysForEstimations,
    setEntityFields,
    setAppliedEstimation,
    setCreatingEditingEstimation,
    setIsCalculateAdditional,
    setAdditionalAppliedEstimation,
    setChoosenIdsForAdditionalEstimation,
} = estimationSlice.actions;

export default estimationSlice.reducer;
