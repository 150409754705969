import { bizProcModel } from "@entities/BizProc";
import { BizProcsFormsList } from "@features/BizProc/BizProcsFormsList";
import { FillActionForm } from "@features/BizProc/FillActionForm";
import { useHeaderLayout } from "@shared/ui/Header";

const MyFormsPage = () => {
    useHeaderLayout({
        title: "Мои формы",
    });
    bizProcModel.useLoadMyForms(true);

    return (
        <>
            <BizProcsFormsList isMyForms={true} />
            <FillActionForm />
        </>
    );
};

export default MyFormsPage;
