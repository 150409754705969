import { AddEntityField } from "@features/Entity/AddEntityField";
import EntityFieldUpdatingModal from "./EntityFieldUpdatingModal";

interface IUpdateCreateEntityField {
    datatypes: TDatatype[];
    entityId: number | undefined;
    entityUuid?: string | undefined;
}

const UpdateCreateEntityField = ({
    datatypes,
    entityUuid,
    entityId,
}: IUpdateCreateEntityField) => {
    return (
        <>
            <AddEntityField
                datatypes={datatypes}
                entityUuid={entityUuid}
                entityId={entityId}
            />
            <EntityFieldUpdatingModal />
        </>
    );
};

export default UpdateCreateEntityField;
