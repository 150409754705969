import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
    children?: ReactNode;
    errorNode?: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            if (this.props.errorNode) return this.props.errorNode;
            return <div>Что-то пошло не так</div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
