import { contrastColor } from "@shared/lib/colors";
import "./FormReadValue.scss";
import { CSSProperties } from "react";

interface IFormReadValue {
    value: any;
    backgroundColor?: string;
    customClassName?: string;
    readValueTextStyle?: CSSProperties;
    onClick?: (e: any) => void;
}

const FormReadValue = ({
    value,
    customClassName,
    readValueTextStyle,
    backgroundColor,
    onClick,
}: IFormReadValue) => {
    return (
        <div
            onClick={onClick}
            className={`${readValueTextStyle ? "" : "form-read-value"} ${customClassName ?? ""} ${onClick ? "link-style" : ""}`}
            style={{
                ...(backgroundColor
                    ? {
                          backgroundColor: backgroundColor,
                          padding: "3px 7px",
                          borderRadius: 9,
                          color: contrastColor(backgroundColor),
                      }
                    : {}),
                ...(readValueTextStyle ?? {}),
            }}
        >
            {value ?? "-"}
        </div>
    );
};

export default FormReadValue;
