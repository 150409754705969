import { createAsyncThunk } from "@reduxjs/toolkit";
import { createEntity, deleteEntity, getEntities, updateEntity } from "../api";

export const loadEntitiesThunk = createAsyncThunk(
    "EntitySlice/loadEntities",
    async (params: TParams, { rejectWithValue }) => {
        try {
            const response = await getEntities(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const updateEntityThunk = createAsyncThunk(
    "EntitySlice/saveEntity",
    async (entity: TEntity, { rejectWithValue }) => {
        try {
            const response = await updateEntity(entity);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const createEntityThunk = createAsyncThunk(
    "EntitySlice/createEntity",
    async (entity: TEntity, { rejectWithValue }) => {
        try {
            const response = await createEntity(entity);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteEntityThunk = createAsyncThunk(
    "EntitySlice/deleteEntity",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteEntity(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
