import { emitter } from "@shared/emitter";
import { notification } from "antd";
import { useEffect } from "react";

export const useNotification = () => {
    const [apiNotification, contextHolderNotification] = notification.useNotification();

    const handleEventNotification = (value: TNotification) => {
        apiNotification[value.type]({
            message: value.title,
            description: value.description,
            duration: value.duration ? value.duration : 3,
        });
    };

    useEffect(() => {
        emitter.on("notification-emit", handleEventNotification);
        return () => {
            emitter.removeListener("notification-emit", handleEventNotification);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        contextHolderNotification,
    };
};
