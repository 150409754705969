import { DeleteOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Popconfirm, Select, Tooltip } from "antd";
import { useEntityRowsActions } from "../model";
import "./EntityRowsActions.scss";
import EntityRowsActionsChangeStage from "./EntityRowsActionsChangeStage";
interface IEntityRowsActions {
    entityId: number;
    selectedRows: number[];
    levelKey?: number | string;
    onSelectRows: (rows: any[]) => void;
}

const EntityRowsActions = ({
    entityId,
    selectedRows,
    levelKey,
    onSelectRows,
}: IEntityRowsActions) => {
    const {
        entityFieldsOptions,
        allowDelete,
        allowUpdate,
        deleteLoading,
        isCalculateAdditionalEstimation,
        choosenEntityFieldsKeysForEstimation,
        onChooseEntityFieldsKeysForEstimation,
        onDelete,
        onChangeAdditionalCalculation,
    } = useEntityRowsActions(entityId, selectedRows, onSelectRows, levelKey);

    return (
        <div className="rows-actions">
            <div className="rows-actions__count">{`Выделено: ${selectedRows.length}`}</div>
            {allowDelete && (
                <Popconfirm
                    title="Удалить выбранные записи?"
                    okText="Удалить"
                    okButtonProps={{
                        danger: true,
                    }}
                    onConfirm={onDelete}
                    cancelText="Отмена"
                >
                    <Button
                        disabled={selectedRows.length === 0}
                        danger
                        loading={deleteLoading}
                        type="link"
                        icon={<DeleteOutlined />}
                        style={{ marginLeft: 15 }}
                    >
                        Удалить
                    </Button>
                </Popconfirm>
            )}
            <Divider type="vertical" />
            <Tooltip title="Суммировать выбранные строки по полям">
                <Checkbox
                    style={{ width: "fit-content", marginLeft: 8 }}
                    onChange={onChangeAdditionalCalculation}
                    checked={isCalculateAdditionalEstimation}
                >
                    <span style={{ textWrap: "nowrap" }}>Суммировать</span>
                </Checkbox>
            </Tooltip>
            {isCalculateAdditionalEstimation && (
                <Select
                    placeholder="Поля для суммирования"
                    mode="multiple"
                    filterOption={(input: any, option) => {
                        return option?.label?.includes(input) === true;
                    }}
                    value={choosenEntityFieldsKeysForEstimation}
                    options={entityFieldsOptions}
                    style={{ width: 600 }}
                    maxTagTextLength={10}
                    maxTagCount={1}
                    onChange={onChooseEntityFieldsKeysForEstimation}
                />
            )}
            <Divider type="vertical" />
            {allowUpdate && (
                <EntityRowsActionsChangeStage
                    entityId={entityId}
                    selectedRows={selectedRows}
                    onSuccess={() => {
                        onSelectRows([]);
                    }}
                />
            )}
        </div>
    );
};

export default EntityRowsActions;
