import { Typography } from "antd";
import { useEstimationTemplateEditForm } from "../model";
import { viewModeLables } from "../constants";
import EstimationTemplateEditName from "./EstimationTemplateEditName";
import EstimationTemplateEditEntityFields from "./EstimationTemplateEditEntityFields";
import EstimationTemplateEditAccess from "./EstimationTemplateEditAccess";
import EstimationTemplateEditUsers from "./EstimationTemplateEditUsers";
import EstimationTemplateEditActions from "./EstimationTemplateEditActions";

interface IEstimationTemplateEditForm {
    initialEstimation: TCreatingEstimationTemplate | TEstimationTemplate | null;
}

const EstimationTemplateEditForm = ({
    initialEstimation,
}: IEstimationTemplateEditForm) => {
    const { estimationTemplate, viewMode, onChangeEstimationTemplate } =
        useEstimationTemplateEditForm(initialEstimation);
    return (
        <div>
            <Typography.Title level={4}>
                {viewModeLables[viewMode ?? "viewing"]}
                шаблона
            </Typography.Title>
            <EstimationTemplateEditName
                viewMode={viewMode}
                name={estimationTemplate.name}
                onChangeEstimationTemplate={onChangeEstimationTemplate}
            />
            <EstimationTemplateEditEntityFields
                viewMode={viewMode}
                templateEntityFields={estimationTemplate.sum_view_fields}
                onChangeEstimationTemplate={onChangeEstimationTemplate}
            />
            <EstimationTemplateEditAccess
                viewMode={viewMode}
                isPublic={estimationTemplate.is_public}
                onChangeEstimationTemplate={onChangeEstimationTemplate}
            />
            {!estimationTemplate.is_public && (
                <EstimationTemplateEditUsers
                    viewMode={viewMode}
                    users={estimationTemplate.users}
                    onChangeEstimationTemplate={onChangeEstimationTemplate}
                />
            )}
            <EstimationTemplateEditActions
                estimationTemplate={estimationTemplate}
                viewMode={viewMode}
            />
        </div>
    );
};

export default EstimationTemplateEditForm;
