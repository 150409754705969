import { Button } from "antd";
import { useCategoryItemActions } from "../model";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CategoryCreateUpdateModal } from "@features/CategoryFeatures/CategoryCreateUpdate";

interface ICategoryItemActions {
    entityId: number;
    category: TCategory;
}

const CategoryItemActions = ({ entityId, category }: ICategoryItemActions) => {
    const { deleteLoading, isOpen, onCancel, onEdit, onDelete } = useCategoryItemActions(
        entityId,
        category
    );
    return (
        <div>
            <Button onClick={onEdit} icon={<EditOutlined />} type="link" />
            <Button
                type="link"
                onClick={onDelete}
                icon={<DeleteOutlined />}
                loading={deleteLoading}
                danger
            />
            <CategoryCreateUpdateModal
                entityId={entityId}
                category={category}
                isOpen={isOpen}
                onCancel={onCancel}
            />
        </div>
    );
};

export default CategoryItemActions;
