import AntIcon, * as ALLICONS from "@ant-design/icons";
import { get } from "lodash";

const AntdIcon = (props: any) => {
    const theme = get(props, "theme", "outlined");
    const component = get(ALLICONS, `${props.type}`);

    return <AntIcon {...props} theme={theme} component={component} />;
};

export default AntdIcon;
