import { FormInputItem } from "@entities/Datatype";
import dayjs from "dayjs";
import "./CustomEventWrapper.scss";
import { contrastColor } from "@shared/lib/colors";
interface ICustomEventWrapper {
    data: TEventItemDate;
    allDay?: boolean;
    backgroundColor: string;
    openCloseDrawerWithNavigate: (isOpenDrawer: boolean, entityRowId?: number) => void;
}
const CustomEventWrapper = ({
    data,
    allDay,
    backgroundColor,
    openCloseDrawerWithNavigate,
}: ICustomEventWrapper) => {
    const windowWidth = window.innerWidth;
    return (
        <div
            className="custom-event-wrapper"
            style={{
                color: contrastColor(backgroundColor),
            }}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                openCloseDrawerWithNavigate(true, data.id);
            }}
        >
            <div style={{ fontSize: 12 }}>
                {dayjs(data.start).format(
                    allDay
                        ? data.dateStartField?.datatype === "date"
                            ? "DD.MM.YY"
                            : "DD.MM.YY HH:mm"
                        : "HH:mm"
                )}
                -
                {dayjs(data.realDateEnd ?? data.end).format(
                    allDay
                        ? data.dateEndField?.datatype === "date"
                            ? "DD.MM.YY"
                            : "DD.MM.YY HH:mm"
                        : "HH:mm"
                )}
            </div>
            <div className="custom-event-wrapper__content">
                <div
                    style={{
                        width: allDay ? "60%" : "100%",
                        display: "flex",
                        flexDirection: windowWidth > 768 ? "column" : "row",
                    }}
                >
                    <div
                        style={{
                            height: 24,
                            display: "flex",
                            alignItems: "center",
                        }}
                        className="custom-event-wrapper__form-input"
                    >
                        {data.labelField && (
                            <FormInputItem
                                field={data.labelField}
                                datatype={data.labelField.datatype}
                                value={data.value}
                                additionalParameters={
                                    data.labelField?.additional_parameters
                                }
                                onChange={() => {}}
                                onlyRead={true}
                            />
                        )}
                    </div>
                    {data.resource[data.eventDescriptionKey] && (
                        <div
                            className={`${windowWidth < 768 ? "custom-event-wrapper__description-wrapper" : ""}`}
                        >
                            {windowWidth < 768 && (
                                <span style={{ verticalAlign: "bottom" }}>/</span>
                            )}
                            <div
                                style={{
                                    height: 24,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                                className="custom-event-wrapper__form-input"
                            >
                                {data.descriptionField && (
                                    <FormInputItem
                                        field={data.descriptionField}
                                        datatype={data.descriptionField.datatype}
                                        value={data.resource[data.eventDescriptionKey]}
                                        additionalParameters={
                                            data.descriptionField?.additional_parameters
                                        }
                                        onChange={() => {}}
                                        onlyRead={true}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {allDay && (
                    <div className="custom-event-wrapper__additional-info">
                        {data[data.dateEndKey]
                            ? dayjs(data[data.dateEndKey]).format("HH:mm D MMM")
                            : dayjs(data.end).format("HH:mm")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CustomEventWrapper;
