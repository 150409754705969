import React from "react";
import "./FormOptionItem.scss";
interface IFormItemProps {
    label?: string;
    style?: React.CSSProperties | undefined;
    children: React.ReactElement;
}
const FormOptionItem = ({ label, style, children }: IFormItemProps) => {
    return (
        <div className="form-option-item" style={style}>
            {label && <div className="form-option-item__label">{label}</div>}
            <div className="form-option-item__body">{children}</div>
        </div>
    );
};

export default FormOptionItem;
