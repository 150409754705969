import { emitter } from "@shared/emitter";

export const notificationEmit = (value: TNotification) => {
    emitter.emit("notification-emit", value);
};
export const messageEmit = (value: TMessage) => {
    emitter.emit("message-emit", value);
};
export const alertNotificationEmit = (value: TAlertNotification) => {
    emitter.emit("alert-notification-emit", value);
};
