import { entityApi } from "@entities/Entity";
import { getVisibleTimelineItems } from "@widgets/BizProcWidgets/BizProcHistory";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";

export const useBizProcHistoryListWithLoad = (
    entityRowId: number,
    entityId: number | undefined,
    entityTableName: string | undefined
) => {
    const [actions, setActions] = useState<TBizProcAction[]>([]);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (entityRowId && (entityId || entityTableName)) {
            setLoading(true);
            (entityId
                ? entityApi.getHistoryOfEntityRow(entityId!, entityRowId)
                : entityApi.getHistoryOfEntityRowByTableName(
                      entityTableName!,
                      entityRowId
                  )
            )
                .then((response) => {
                    const gettedActions = response.data.data;
                    setActions(getVisibleTimelineItems(gettedActions));
                })
                .catch((e) => {
                    if (e.response.status === 404) setError(e);
                    else
                        notificationEmit({
                            type: "error",
                            title: "Ошибка",
                            description: "Ошибка загрузки истории бизнес-процесса",
                        });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [entityRowId, entityId]);
    return {
        actions,
        error,
        loading,
    };
};
