import { Upload, message } from "antd";
import { apiUrl } from "@shared/api/api";
import { FileUpload } from "@shared/ui/FileUpload";

type FileType = any;

const beforeUpload = (file: FileType) => {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
        message.error("Файл должен быть менее 50MB!");
    }
    return isLt2M || Upload.LIST_IGNORE;
};

interface IFilesInput {
    value: any[];
    onChangeValue: (value: string) => void;
    onlyRead?: boolean;
    field: TEntityField | TVariable;
    additionalParameters: TFileAdditionalParameters;
}

const FilesInput = ({
    value,
    onChangeValue,
    onlyRead,
    field,
    additionalParameters,
}: IFilesInput) => {
    const fileList = (
        field.is_multiple ? value : value ? (Array.isArray(value) ? value : [value]) : []
    )?.map((item: any) => {
        return {
            url: apiUrl + "/api/file?link=" + item.link,
            xhr: {
                headers: {
                    authorization: "sdfsadsfd",
                },
            },
            name: item.filename,
            status: item.status ?? "done",
            ...item,
        };
    });

    const onChangeFileList = (fileList: any) => {
        const fileListFiltered = fileList?.filter(
            (file: any) => file.status !== "removed"
        );

        onChangeValue(
            field.is_multiple ? fileListFiltered : fileListFiltered?.[0] ?? null
        );
    };

    return (
        <>
            <FileUpload
                uploadUrl={`${apiUrl}/api/file/${field.variable_type}/${field.id}`}
                onlyRead={onlyRead}
                smallFormat={additionalParameters?.fileSmallFormat}
                beforeUpload={beforeUpload}
                maxFiles={field.is_multiple ? 10 : 1}
                initialFileList={fileList ?? []}
                onChangeFileList={onChangeFileList}
            />
        </>
    );
};

export default FilesInput;
