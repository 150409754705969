import { DropResult } from "react-beautiful-dnd";

export const useDnDList = <T>(
    data: T[],
    unicKey: keyof T,
    onDragEndFunc?: (item: T, lastColumnValue: any, currentColumnValue: any) => void
) => {
    const handleDragAndDrop = (results: DropResult) => {
        const { source, destination } = results;
        if (!destination) return;
        if (source.droppableId === destination.droppableId) {
            return;
        }
        const currentColumnValue = destination.droppableId;
        const lastColumnValue = source.droppableId;
        const movedElement = data.find(
            (item) => String(item[unicKey]) === String(results.draggableId)
        )!;

        if (onDragEndFunc) {
            onDragEndFunc(
                { ...movedElement },
                lastColumnValue === "null" ? null : lastColumnValue,
                currentColumnValue === "null" ? null : currentColumnValue
            );
        }
    };
    const handleDragStart = (props: any) => {
        const draggableItem = document.querySelectorAll(
            `[data-rbd-draggable-id="${props.draggableId}"]`
        );
        // @ts-ignore
        draggableItem[0].style.background = "#f3c3ff";
    };
    const handleDragEnd = (props: any) => {
        const draggableItem = document.querySelectorAll(
            `[data-rbd-draggable-id="${props.draggableId}"]`
        );
        // @ts-ignore
        draggableItem[0].style.background = "white";
    };
    return {
        handleDragAndDrop,
        handleDragStart,
        handleDragEnd,
    };
};
