export const viewTypeOptions = [
    {
        value: 1,
        label: "Таблица",
        viewType: {
            id: 1,
            label: "Таблица",
            value: "table",
        },
    },
    {
        value: 2,
        label: "Календарь",
        viewType: {
            id: 2,
            label: "Календарь",
            value: "scheduler",
        },
    },
    {
        value: 3,
        label: "Канбан",
        viewType: {
            id: 3,
            label: "Канбан",
            value: "kanban",
        },
    },
];
