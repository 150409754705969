import { Button } from "antd";
import { useExport } from "./model";
import { ExportOutlined } from "@ant-design/icons";

interface IExportButton {
    url: string;
    filename: string;
}

const ExportButton = ({ url, filename }: IExportButton) => {
    const { exportItems } = useExport(url, filename);

    return (
        <Button onClick={exportItems} icon={<ExportOutlined />}>
            Экспорт
        </Button>
    );
};

export default ExportButton;
