import { Select } from "antd";
import { useViewPatternItemCreateSelect } from "../model";
import ViewPatternItemCreateSelectOption from "./ViewPatternItemCreateSelectOption";
import { FormItem } from "@shared/ui/Form";

interface IViewPatternItemCreateSelect {
    entityFields: TEntityField[];
    viewPattern: TViewPattern;
    createLoading: {
        [key: string]: boolean;
        [key: number]: boolean;
    };
}

const ViewPatternItemCreateSelect = ({
    createLoading,
    viewPattern,
    entityFields,
}: IViewPatternItemCreateSelect) => {
    const { selectOptions, values, deleteLoading, onSelect, onDeselect } =
        useViewPatternItemCreateSelect(viewPattern, entityFields);

    return (
        <FormItem label="Выбор видимых полей">
            <Select
                value={values}
                allowClear
                showSearch
                onDeselect={onDeselect}
                onSelect={onSelect}
                mode="multiple"
                filterOption={(input: any, option) => {
                    return (
                        option?.entityField?.name
                            ?.toLowerCase()
                            ?.includes(input.toLowerCase()) === true
                    );
                }}
                style={{ width: "100%" }}
            >
                {selectOptions.map((option) => {
                    return (
                        <Select.Option
                            entityField={option.entityField}
                            key={option.value}
                            value={option.value}
                        >
                            <ViewPatternItemCreateSelectOption
                                option={option}
                                createLoading={createLoading}
                                deleteLoading={deleteLoading}
                            />
                        </Select.Option>
                    );
                })}
            </Select>
        </FormItem>
    );
};

export default ViewPatternItemCreateSelect;
