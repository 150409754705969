import { $api } from "@shared/api/api";
const route = "/api/entity-row-observer";

export const getEntityRowObservers = (entityUuid: string) => {
    return $api.get<TResponseList<TEntityRowObserver>>(route, {
        params: {
            filter: {
                condition_type: "AND",
                conditions: [
                    {
                        key: "entity_uuid",
                        condition: "=",
                        value: `${entityUuid}`,
                    },
                ],
            },
        },
    });
};

export const createEntityRowObserver = (
    entityRowObserver: TCreatingEntityRowObserver
) => {
    return $api.post<TResponse<TEntityRowObserver>>(route, entityRowObserver);
};
export const deleteEntityRowObserver = (entityRowObserverId: number) => {
    return $api.delete<TResponse<TEntityRowObserver>>(`${route}/${entityRowObserverId}`);
};
