import { FormInputItem, datatypeModel } from "@entities/Datatype";
import { variableModel } from "@entities/Variables";
import { FieldOptionEditor } from "@features/FieldOption/FieldOptionEditor";
import { DatatypeDependingInputs } from "@features/FieldsTypes/DatatypeDependingInputs";
import { VariableCreateButton } from "@features/Variables/CreateUpdateVariables";
import { VariableModifyModal } from "@features/Variables/CreateUpdateVariables/ui";
import { VariablesList } from "@features/Variables/VariablesList";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
import { useHeaderLayout } from "@shared/ui/Header";
import { useSelector } from "react-redux";

const VariablePage = () => {
    useHeaderLayout({
        title: "Переменные",
    });
    const { variables } = variableModel.useLoadVariables(true);
    datatypeModel.useLoadDatatypes();

    const editingVariable = useSelector(variableModel.selectEditingVariable);

    return (
        <div>
            <IndentContainer>
                {variables.length > 0 && <VariableCreateButton />}
            </IndentContainer>
            <VariablesList />
            <VariableModifyModal
                editingVariable={editingVariable}
                datatypeDependingInputs={(props) => (
                    <DatatypeDependingInputs
                        editingField={props.editingField as TEntityField}
                        setEditingField={
                            props.setEditingField as (
                                entityField: TEntityField | TVariable
                            ) => void
                        }
                        error={props.error}
                        optionEditor={(fieldId: number, type: TFieldOptionType) => (
                            <FieldOptionEditor fieldId={fieldId} type={type} />
                        )}
                    />
                )}
                formInputItem={(props: TFieldInput) => <FormInputItem {...props} />}
            />
        </div>
    );
};

export default VariablePage;
