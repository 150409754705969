import { Input } from "antd";

import { useBizProcTemplateInputVariables } from "../model";
import InputDropdown from "./InputDropdown";

interface IInputWithVariables {
    itemId: number | string;
    type?: "entity" | "biz_proc";
    onChange: (name: string, value: string) => void;
    value?: string;
    name: string;
    disabled?: boolean;
    isTextArea: boolean;
}

const InputWithVariables = ({
    value,
    itemId,
    type = "biz_proc",
    name,
    isTextArea,
    disabled,
    onChange,
}: IInputWithVariables) => {
    const { inputRef, selectMode, itemsGroups, isLoading, onSelectItem, onChangeInput } =
        useBizProcTemplateInputVariables(
            itemId,
            type,
            name,
            value,
            isTextArea,
            onChange
        );

    const width =
        (!isTextArea
            ? inputRef?.current?.input?.offsetWidth
            : inputRef?.current?.resizableTextArea?.textArea?.offsetWidth) ?? 0;
    return (
        <div>
            {isTextArea ? (
                <Input.TextArea
                    name={name}
                    style={{
                        textWrap: "wrap",
                    }}
                    ref={inputRef}
                    onChange={onChangeInput}
                    value={value}
                    disabled={disabled}
                />
            ) : (
                <Input
                    disabled={disabled}
                    name={name}
                    ref={inputRef}
                    onChange={onChangeInput}
                    value={value}
                />
            )}
            <InputDropdown
                onSelectItem={onSelectItem}
                loading={isLoading}
                items={itemsGroups}
                isOpen={selectMode}
                width={width}
            />
        </div>
    );
};

export default InputWithVariables;
