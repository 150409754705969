import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    loadVariablesThunk,
    updateVariableThunk,
    createVariableThunk,
    deleteVariableThunk,
} from "./VariableThunk";
import { updateArrByItemId } from "@shared/lib/object-helper";

export interface IVariableSlice {
    variables: TVariable[];
    editingVariable: TVariable | null;
    isLoading: boolean;
    error: any;
}

const initialState: IVariableSlice = {
    variables: [],
    editingVariable: null,
    isLoading: false,
    error: null,
};

export const variableSlice = createSlice({
    name: "VariableSlice",
    initialState,
    reducers: {
        setVariables: (state, action: PayloadAction<TVariable[]>) => {
            state.variables = action.payload;
            localStorage.setItem("variablesLength", state.variables.length.toString());
        },
        setEditingVariable: (state, action: PayloadAction<TVariable | null>) => {
            state.editingVariable = action.payload;
        },
        addVariable: (state, action: PayloadAction<TVariable>) => {
            state.variables.unshift(action.payload);
            localStorage.setItem("variablesLength", state.variables.length.toString());
        },
        updateVariable: (state, action: PayloadAction<TVariable>) => {
            state.variables = updateArrByItemId(state.variables, action.payload);
        },
        deleteVariable: (state, action: PayloadAction<TVariable>) => {
            state.variables = state.variables.filter(
                (item) => item.id !== action.payload.id
            );
            localStorage.setItem("variablesLength", state.variables.length.toString());
        },
    },
    extraReducers: (builder) => {
        loadBuilder(builder);
        createBuilder(builder);
        updateBuilder(builder);
        deleteBuilder(builder);
    },
});

const loadBuilder = (builder: ActionReducerMapBuilder<IVariableSlice>) => {
    builder.addCase(loadVariablesThunk.fulfilled, (state, action) => {
        state.variables = action.payload.data;
        localStorage.setItem("variablesLength", state.variables.length.toString());
        state.isLoading = false;
    });
    builder.addCase(loadVariablesThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
    });
    builder.addCase(loadVariablesThunk.rejected, (state, rejectedValue) => {
        state.error = rejectedValue;
        state.isLoading = false;
    });
};
const createBuilder = (builder: ActionReducerMapBuilder<IVariableSlice>) => {
    builder.addCase(createVariableThunk.fulfilled, (state, action) => {
        state.variables.unshift(action.payload.data);
        localStorage.setItem("variablesLength", state.variables.length.toString());
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IVariableSlice>) => {
    builder.addCase(updateVariableThunk.fulfilled, (state, action) => {
        state.variables = updateArrByItemId(state.variables, action.payload.data);
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IVariableSlice>) => {
    builder.addCase(deleteVariableThunk.fulfilled, (state, action) => {
        state.variables = state.variables.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem("variablesLength", state.variables.length.toString());
    });
};

export const {
    setVariables,
    updateVariable,
    deleteVariable,
    setEditingVariable,
    addVariable,
} = variableSlice.actions;
