import { bizProcModel } from "@entities/BizProc";
import { userModel } from "@entities/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const UseBizProcFormWS = () => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        window.Echo.channel(`User.${user.id}.BizProcAction`)
            .listen(
                ".bizProcAction.create",
                ({ bizProcAction }: { bizProcAction: TBizProcAction }) => {
                    if (
                        (bizProcAction.action === "FormAction" &&
                            bizProcAction?.collected_parameters?.userId === user.id) ||
                        (bizProcAction.action === "InitialAction" &&
                            bizProcAction?.collected_parameters?.userId === user.id &&
                            bizProcAction.collected_parameters?.triggerType === "form")
                    ) {
                        dispatch(bizProcModel.addBizProcForm(bizProcAction));
                    }
                }
            )
            .listen(
                ".bizProcAction.update",
                ({ bizProcAction }: { bizProcAction: TBizProcAction }) => {
                    if (
                        (bizProcAction.action === "FormAction" &&
                            bizProcAction?.collected_parameters?.userId === user.id) ||
                        (bizProcAction.action === "InitialAction" &&
                            bizProcAction?.collected_parameters?.userId === user.id &&
                            bizProcAction.collected_parameters?.triggerType === "form")
                    ) {
                        dispatch(bizProcModel.updateBizProcForm(bizProcAction));
                    }
                }
            );

        return () => {
            window.Echo.leave(`User.${user.id}.BizProcAction`);
        };
    }, []);
};
