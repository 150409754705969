export const labeledDatatypes = [
    "float",
    "integer",
    "list",
    "entityList",
    "date",
    "time",
    "datetime",
    "timestamp",
    "boolean",
];
