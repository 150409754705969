import { $api } from "@shared/api/api";
const route = "/api/view-pattern-item";

export const getViewPatternItems = (viewPatternId: number) => {
    return $api.get<TResponseList<TViewPatternItem>>(route, {
        params: {
            filter: {
                value: viewPatternId,
                key: "view_pattern_id",
                condition: "=",
            },
        },
    });
};

export const updateViewPatternItem = (viewPatternItem: TViewPatternItem) => {
    return $api.put<TResponse<TViewPatternItem>>(
        `${route}/${viewPatternItem.id}`,
        viewPatternItem
    );
};
export const massUpdateViewPatternItems = (
    viewPatternItems: { id: number; [key: string]: any }[]
) => {
    return $api.put<TResponseList<TViewPatternItem>>(`${route}/mass-update`, {
        viewPatternItems,
    });
};
export const createViewPatternItem = (viewPatternItem: TViewPatternItemCreating) => {
    return $api.post<TResponse<TViewPatternItem>>(route, viewPatternItem);
};
export const deleteViewPatternItem = (id: number) => {
    return $api.delete<TResponse<TViewPatternItem>>(`${route}/${id}`);
};
