import { Empty } from "antd";
import "./CreateEditEntityRow.scss";
import CreateEditEntityRowActions from "./CreateEditEntityRowActions";
import CreateEditEntityRowForm from "./CreateEditEntityRowForm";
import CreateEditEntityRowHistory from "./CreateEditEntityRowHistory";
import CreateEditEntityRowTabs from "./CreateEditEntityRowTabs";
import { useCreateEditEntityRow } from "./UseCreateEditEntityRow";
import { ReactNode } from "react";

interface ICreateEditEntityRow {
    title?: (props: {
        entityRow: any;
        entity: TEntity;
        isLoading: boolean;
        editableEntityFieldsWithValues: TEntityFieldWithValue[];
        entityRowCapabilities: TEntityRowCapabilities;
        onChangeEntityRow: (entityRow: any) => void;
    }) => ReactNode;
    entityId?: number;
    entityTableName?: string;
    onlyReadMode: boolean;
    entityRowId?: number | null;
    history?: React.ReactNode | null;
    initialValues?: { [key: string]: any } | null;
    hideHistory?: boolean;
    setOnlyReadMode: (item: boolean) => void;
    additionalActions?: () => void;
}

const CreateEditEntityRow = ({
    title,
    entityId,
    entityTableName,
    onlyReadMode,
    entityRowId,
    initialValues,
    history,
    hideHistory,
    setOnlyReadMode,
    additionalActions,
}: ICreateEditEntityRow) => {
    const {
        isLoading,
        isLoadingSubmit,
        isLoadingDelete,
        isDisabledActions,
        error,
        entity,
        fields,
        creatorId,
        entityRowCapabilities,
        editableEntityFieldsWithValues,
        entityRow,
        onChangeEntityRow,
        setIsDisabledActions,
        onChangeFormFields,
        onSave,
        onCancel,
        onDelete,
    } = useCreateEditEntityRow({
        entityId,
        entityTableName,
        entityRowId,
        initialValues,
        onlyReadMode,
        additionalActions,
        setOnlyReadMode,
    });

    const windowWidth = window.innerWidth;
    const canDelete = entityRowCapabilities.delete;
    const canUpdate = entityRowCapabilities.update;
    return (
        <div
            className="create-edit-entity-row"
            style={{
                width: "100%",
                height: "100%",
            }}
        >
            {error ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width:
                            windowWidth > 900 ? (entityRowId ? "100%" : "526px") : "100%",
                    }}
                >
                    <Empty
                        style={{
                            width: "100%",
                        }}
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                    />
                </div>
            ) : (
                <div
                    className="create-edit-entity-row__container"
                    style={{
                        height:
                            isDisabledActions || (!canDelete && !canUpdate)
                                ? "100%"
                                : "calc(100% - 70px)",
                    }}
                >
                    <div className="create-edit-entity-row__body">
                        {entity &&
                            title &&
                            title({
                                entityRow,
                                entity,
                                isLoading,
                                entityRowCapabilities,
                                editableEntityFieldsWithValues,
                                onChangeEntityRow,
                            })}
                        {windowWidth > 900 || !entityRowId ? (
                            <div className="create-edit-entity-row__body__content">
                                <CreateEditEntityRowForm
                                    width={hideHistory ? "100%" : undefined}
                                    error={error}
                                    isLoading={isLoading}
                                    fields={fields}
                                    onlyReadMode={onlyReadMode}
                                    onChangeFormFields={onChangeFormFields}
                                    entityRowId={entityRowId}
                                />
                                {!hideHistory && (
                                    <CreateEditEntityRowHistory
                                        entityRowId={entityRowId}
                                        history={history}
                                    />
                                )}
                            </div>
                        ) : (
                            <CreateEditEntityRowTabs
                                error={error}
                                isLoading={isLoading}
                                setIsDisabledActions={setIsDisabledActions}
                                fields={fields}
                                onlyReadMode={onlyReadMode}
                                entityRowId={entityRowId}
                                onChangeFormFields={onChangeFormFields}
                                history={history}
                            />
                        )}
                    </div>

                    <div className="create-edit-entity-row__actions-container">
                        {!isDisabledActions && (
                            <CreateEditEntityRowActions
                                isLoadingSubmit={isLoadingSubmit}
                                isLoadingDelete={isLoadingDelete}
                                entityRowId={entityRowId}
                                entityRowCapabilities={entityRowCapabilities}
                                creatorId={creatorId}
                                onlyReadMode={onlyReadMode}
                                setOnlyReadMode={setOnlyReadMode}
                                onCancel={onCancel}
                                onSave={onSave}
                                onDelete={onDelete}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateEditEntityRow;
