import { categoryModel } from "@entities/Category";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useCategoriesList = (entityId: number) => {
    const { loading, categories } = categoryModel.useCategoryLoad(entityId);
    const [editingCategory, setEditingCategory] = useState<TCategory | null>(null);
    const onDeleteLoading = useSelector(categoryModel.selectDeleteLoading);
    const dispatch = useDispatch<AppDispatch>();
    const onDelete = (id: number) => {
        dispatch(
            categoryModel.deleteCategoryThunk({
                entityId,
                id,
            })
        );
    };
    const onEdit = (category: TCategory) => {
        setEditingCategory(category);
    };
    return {
        editingCategory,
        loading,
        deleteLoading: onDeleteLoading,
        categories,
        onDelete,
        onEdit,
    };
};
