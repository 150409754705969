import { VariableItemSkeleton } from "@entities/Variables";
import "./VariablesList.scss";

const VariablesSkeletonList = () => {
    const skeletonCounts = Number(localStorage.getItem("variablesLength"));
    const multiply = Array.from({ length: skeletonCounts > 0 ? skeletonCounts : 1 });
    return (
        <div className="variables-list">
            {multiply.map((_, index) => {
                return (
                    <div className="variables-list__item" key={index}>
                        <VariableItemSkeleton />
                    </div>
                );
            })}
        </div>
    );
};

export default VariablesSkeletonList;
