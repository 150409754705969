import { DeleteOutlined } from "@ant-design/icons";
import { FormInputItem } from "@entities/Datatype";
import { InputWithVariables } from "@entities/BizProcTemplate";
import { Button, Checkbox } from "antd";
import "./CreateUpdateDefaultValueInputItem.scss";

interface ICreateUpdateDefaultValueInputItem {
    editingEntityField: TEntityField;
    createUpdateDefaultValue: TEntityFieldCreateUpdateDefault;
    name: "on_create_default" | "on_update_default";
    isMultiple?: boolean;
    key?: number;
    onChangeCalculation: (
        name: keyof TEntityField,
        isMultiple?: boolean,
        key?: number | string
    ) => void;
    onChangeCreateUpdateValue: (
        value: any,
        name: keyof TEntityField,
        isMultiple?: boolean,
        key?: number | string
    ) => void;
    onDelete?: (key: number, name: "on_create_default" | "on_update_default") => void;
}

const CreateUpdateDefaultValueInputItem = ({
    editingEntityField,
    createUpdateDefaultValue,
    name,
    isMultiple,
    key,
    onChangeCalculation,
    onChangeCreateUpdateValue,
    onDelete,
}: ICreateUpdateDefaultValueInputItem) => {
    const disabled = editingEntityField.key === "id";
    return (
        <div className="create-update-defailt-input-container">
            <Checkbox
                style={{ marginBottom: 8 }}
                checked={createUpdateDefaultValue.is_calculation}
                disabled={!createUpdateDefaultValue || disabled}
                onChange={() => onChangeCalculation(name, isMultiple, key)}
            >
                Вычисляемое значение по формуле
            </Checkbox>

            <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                    {createUpdateDefaultValue.is_calculation ? (
                        <InputWithVariables
                            itemId={editingEntityField.entity_uuid!}
                            type="entity"
                            disabled={disabled}
                            isTextArea={true}
                            onChange={(_, value) =>
                                onChangeCreateUpdateValue(value, name, isMultiple, key)
                            }
                            name="on_create_default"
                            value={createUpdateDefaultValue.value}
                        />
                    ) : (
                        <FormInputItem
                            field={{
                                ...editingEntityField,
                                is_multiple: false,
                            }}
                            onlyRead={disabled}
                            datatype={editingEntityField.datatype as unknown as string}
                            value={createUpdateDefaultValue!.value}
                            onChange={(value: any) => {
                                onChangeCreateUpdateValue(value, name, isMultiple, key);
                            }}
                        />
                    )}
                </div>
                {key && (
                    <Button
                        disabled={disabled}
                        type="link"
                        onClick={() => (onDelete ? onDelete(key, name) : undefined)}
                        icon={
                            <DeleteOutlined
                                style={{
                                    color: "red",
                                }}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};

export default CreateUpdateDefaultValueInputItem;
