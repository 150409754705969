import { UploadFile, UploadProps } from "antd";
import { useEffect, useState } from "react";

type FileType = any;

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

interface IUseFileUpload {
    uploadUrl: string;

    initialFileList: UploadFile[];
    onChangeFileList: (fileList: UploadFile[]) => void;
}
export const useFileUpload = ({
    initialFileList,
    uploadUrl,
    onChangeFileList,
}: IUseFileUpload) => {
    const [loadedFiles, setLoadedFiles] = useState(initialFileList);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const accessToken = localStorage.getItem("access_token");
    const handleCancel = () => setPreviewOpen(false);
    const [fileList, setFileList] = useState<UploadFile[]>(initialFileList);
    const handlePreview = async (file: UploadFile) => {
        if (
            (file.name ?? "").includes(".png") ||
            (file.name ?? "").includes(".jpg") ||
            (file.name ?? "").includes(".webp") ||
            (file.name ?? "").includes(".jpeg")
        ) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj as FileType);
            }

            setPreviewImage(file.url || (file.preview as string));
            setPreviewOpen(true);
            setPreviewTitle(
                file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
            );
        }
    };
    useEffect(() => {
        if (initialFileList.length === fileList.length) {
            for (let index = 0; index < initialFileList.length; index++) {
                if (initialFileList[index].url !== fileList[index].url) {
                    setFileList(initialFileList);
                }
            }
        } else {
            setFileList(initialFileList);
        }

        if (initialFileList.length === loadedFiles.length) {
            for (let index = 0; index < initialFileList.length; index++) {
                if (initialFileList[index].url !== loadedFiles[index].url) {
                    setLoadedFiles(initialFileList);
                }
            }
        } else {
            setLoadedFiles(initialFileList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialFileList]);
    useEffect(() => {
        onChangeFileList(loadedFiles);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedFiles.length]);
    const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onRemove = (file: UploadFile) => {
        onChangeFileList(initialFileList.filter((file_) => file_.url !== file.url));
        return true;
    };
    const onDownload = (file: UploadFile) => {
        console.log("onDownload", file);
    };
    const uploadFile = (options: any) => {
        const url = uploadUrl; // File upload web service path
        const file = options.file;
        const formData = new FormData();

        formData.append("file", file);
        formData.append("filename", file.name);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.upload.onprogress = ({ total, loaded }) => {};

        xhr.onload = () => {
            const response = JSON.parse(xhr.response);
            const data = response.data;

            setLoadedFiles((prev) => [...prev, data]);
            setFileList((fileList) =>
                fileList.map((fileItem) =>
                    fileItem.uid === file.uid
                        ? {
                              ...fileItem,
                              status: "done",
                          }
                        : fileItem
                )
            );
        };
        xhr.onerror = function () {
            setFileList(
                fileList.map((fileItem) =>
                    fileItem.uid === file.uid
                        ? {
                              ...fileItem,
                              status: "error",
                          }
                        : fileItem
                )
            );
        };

        xhr.setRequestHeader("authorization", `Bearer ${accessToken}`);
        xhr.send(formData);
    };

    return {
        previewOpen,
        uploadFile,
        onDownload,
        handleChange,
        previewTitle,
        previewImage,
        accessToken,
        fileList,
        onRemove,
        handleCancel,
        handlePreview,
    };
};
