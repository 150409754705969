import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { useEntityRowObserverDelete } from "../model";

interface IDeleteEntityRowObserver {
    item: TEntityRowObserver;
}

const DeleteEntityRowObserver = ({ item }: IDeleteEntityRowObserver) => {
    const { onDelete, loading } = useEntityRowObserverDelete(item);
    return (
        <Popconfirm
            title="Удалить слушатель?"
            okText="Удалить"
            okButtonProps={{
                danger: true,
                loading: loading,
            }}
            onConfirm={onDelete}
            cancelText="Отмена"
        >
            <Button loading={loading} type="link" danger icon={<DeleteOutlined />} />
        </Popconfirm>
    );
};

export default DeleteEntityRowObserver;
