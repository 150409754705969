import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    approveBizProcAgreement,
    deleteBizProc,
    getBizProc,
    getBizProcCounters,
    getBizProcUserActions,
    getBizProcs,
    getBizProcsAgreements,
    getBizProcsForms,
    startBizProc,
    submitBizProcForm,
    updateBizProc,
} from "../api";

export const loadBizProcsThunk = createAsyncThunk(
    "BizProcSlice/loadbizProcTemplates",
    async (params: TParams, { rejectWithValue }) => {
        try {
            const response = await getBizProcs(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadBizProcCountersThunk = createAsyncThunk(
    "BizProcSlice/loadbizProcCounters",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getBizProcCounters();
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadBizProcsAgreementsThunk = createAsyncThunk(
    "BizProcSlice/loadBizProcsAgreementsThunk",
    async (
        props: { isMy: boolean; bizProcTemplateId?: number; add?: boolean },
        { rejectWithValue }
    ) => {
        try {
            const response = await getBizProcsAgreements(props);
            return {
                ...response?.data,
                isMy: props.isMy,
                add: props.add,
            };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadBizProcUserActionsThunk = createAsyncThunk(
    "BizProcSlice/loadBizProcUserActionsThunk",
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await getBizProcUserActions(params);
            return {
                ...response?.data,
            };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const loadBizProcsFormsThunk = createAsyncThunk(
    "BizProcSlice/loadBizProcsFormsThunk",
    async (
        props: { add?: boolean; bizProcTemplateId?: number; isMy?: boolean },
        { rejectWithValue }
    ) => {
        try {
            const response = await getBizProcsForms(props);
            return {
                ...response?.data,
                add: props.add,
            };
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const startBizProcThunk = createAsyncThunk(
    "BizProcSlice/startBizProcThunk",
    async (bizProcTemplateId: number, { rejectWithValue }) => {
        try {
            const response = await startBizProc(bizProcTemplateId);
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const approveBizProcAgreementThunk = createAsyncThunk(
    "BizProcSlice/approveBizProcAgreementThunk",
    async (
        props: {
            agreementId: number;
            status: "reject" | "approve";
            collected_parameters: any;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await approveBizProcAgreement(
                props.agreementId,
                props.status,
                props.collected_parameters
            );
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const submitBizProcFormThunk = createAsyncThunk(
    "BizProcSlice/submitBizProcFormThunk",
    async (
        props: { formId: number; fields: TBizProcFormField[]; onlySave?: boolean },
        { rejectWithValue }
    ) => {
        try {
            const response = await submitBizProcForm(
                props.formId,
                props.fields,
                props.onlySave
            );
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const loadBizProcTemplateThunk = createAsyncThunk(
    "BizProcSlice/loadBizProcTemplateThunk",
    async (params: TParams, { rejectWithValue }) => {
        try {
            const response = await getBizProcs(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const loadBizProcThunk = createAsyncThunk(
    "BizProcSlice/loadbizProcTemplateItem",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await getBizProc(id);
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const updateBizProcThunk = createAsyncThunk(
    "BizProcSlice/saveBizProc",
    async (entity: TBizProc, { rejectWithValue }) => {
        try {
            const response = await updateBizProc(entity);
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);

export const deleteBizProcThunk = createAsyncThunk(
    "BizProcSlice/deleteBizProc",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteBizProc(id);
            return response?.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
