import { ReactNode } from "react";
import Xarrow from "react-xarrows";

interface IArcherElement {
    children: ReactNode;
    relations: any;
    gridBreak?: string;
    id: string;
}

const ArcherElement = ({ children, relations, gridBreak, id }: IArcherElement) => {
    return (
        <>
            <div id={id}>{children}</div>
            {relations.map((relation: any) => {
                return (
                    <Xarrow
                        key={relation.targetId}
                        path="grid"
                        startAnchor={"bottom"}
                        endAnchor={"top"}
                        showHead={false}
                        strokeWidth={1}
                        gridBreak={gridBreak ? gridBreak : "95%"}
                        start={id} //can be react ref
                        end={relation.targetId} //or an id
                    />
                );
            })}
        </>
    );
};

export default ArcherElement;
