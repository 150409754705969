import PortalSettingInputFile from "./PortalSettingInputFile";
import PortalSettingInputString from "./PortalSettingInputString";

interface IPortalSettingInput {
    portalSetting: TPortalSetting;
    onChange: (value: string, key: string) => void;
}

const PortalSettingInput = ({ portalSetting, onChange }: IPortalSettingInput) => {
    if (portalSetting.type === "string")
        return (
            <PortalSettingInputString
                value={portalSetting.value}
                name={portalSetting.value}
                onChange={onChange}
            />
        );
    if (portalSetting.type === "file")
        return (
            <PortalSettingInputFile
                value={portalSetting.value}
                name={portalSetting.value}
                onChange={onChange}
            />
        );
    return <div></div>;
};

export default PortalSettingInput;
