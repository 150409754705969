import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAction, deleteAction, getActions, updateAction } from "../api";

export const loadActionsThunk = createAsyncThunk(
    "ActionSlice/loadAction",
    async (_, { rejectWithValue }) => {
        try {
            const response = await getActions();
            return response.data;
        } catch (error: any) {
            console.log(error);
            return rejectWithValue({
                data: error?.response?.data,
                status: error?.response?.status,
            });
        }
    }
);
export const updateActionThunk = createAsyncThunk(
    "ActionSlice/saveAction",
    async (action: TAction, { rejectWithValue }) => {
        try {
            const response = await updateAction(action);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);

export const createActionThunk = createAsyncThunk(
    "ActionSlice/createAction",
    async (action: TCreateAction, { rejectWithValue }) => {
        try {
            const response = await createAction(action);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
export const deleteActionThunk = createAsyncThunk(
    "ActionSlice/deleteAction",
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await deleteAction(id);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({
                data: error?.response.data,
                status: error?.response.status,
            });
        }
    }
);
