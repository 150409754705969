import { FormInputItem } from "@entities/Datatype";
import TMPBizProcButton from "./TMPBizProcButton";
import { Fragment } from "react";

interface IEntityRowsTablePatternColumn {
    order: any;
    value: any;
    entityId: number;
    column: TPatternColumn;
    entityRow?: any;
}

const EntityRowsTablePatternColumn = ({
    value,
    order,
    entityId,
    column,
    entityRow,
}: IEntityRowsTablePatternColumn) => {
    if (column.type === "entity-field") {
        const entityField = column.entityField;
        if (!entityField) return <></>;
        return (
            <div
                style={{
                    boxSizing: "content-box",
                    minWidth:
                        entityField.datatype === "longText"
                            ? 240
                            : entityField.datatype === "boolean"
                              ? 80
                              : 160,
                    width: "fit-content",
                    maxWidth:
                        entityField.datatype === "longText" ? "none !important" : 400,
                }}
            >
                <FormInputItem
                    field={entityField}
                    entityRow={entityRow}
                    inputId={entityField.key}
                    additionalParameters={entityField.additional_parameters ?? {}} //item.additional_parameters
                    datatype={entityField.datatype as unknown as string}
                    value={value}
                    onlyRead={true}
                    onChange={() => {}}
                />
            </div>
        );
    } else if (column.type === "group") {
        return (
            <>
                {(column.children ?? []).map((column, index, arr) => {
                    if (
                        column.type === "start_child_biz_proc" ||
                        column.type === "start_biz_proc"
                    ) {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: "flex",
                                    // padding: 10, // TODO MAX
                                    width: "100%",
                                    alignItems: "center",
                                    // borderBottom:  // TODO MAX
                                    //     index < arr.length - 1
                                    //         ? "1px solid #f0f0f0"
                                    //         : undefined,
                                }}
                            >
                                <TMPBizProcButton
                                    column={column}
                                    entityId={entityId}
                                    entityRow={order}
                                />
                            </div>
                        );
                    }
                    const entityField = column.entityField;
                    if (!entityField) return <Fragment key={index}></Fragment>;

                    return (
                        <div
                            key={index}
                            style={{
                                display: "flex",
                                minWidth: 300,
                                width: "100%",
                                maxWidth: 450,
                                alignItems: "center",
                                // padding: 10,
                                marginBottom: 5,
                                // borderBottom: "1px solid #f0f0f0", // TODO MAX
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: "400",
                                    color: "#8b8b8b",
                                }}
                            >{`${column.label}`}</div>
                            <div style={{ marginRight: 5 }}>:</div>
                            <FormInputItem
                                entityRow={order}
                                field={entityField}
                                inputId={entityField.key}
                                additionalParameters={
                                    entityField.additional_parameters ?? {}
                                } //item.additional_parameters
                                datatype={entityField.datatype as unknown as string}
                                value={order[entityField.key]}
                                onlyRead={true}
                                onChange={() => {}}
                            />
                        </div>
                    );
                })}
            </>
        );
    } else if (
        column.type === "start_child_biz_proc" ||
        column.type === "start_biz_proc"
    ) {
        return (
            <>
                <TMPBizProcButton column={column} entityId={entityId} entityRow={order} />
            </>
        );
    }

    return <></>;
};

export default EntityRowsTablePatternColumn;
