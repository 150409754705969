import { GroupOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Popover, Space, Tooltip, Typography } from "antd";
import { useEntityRowTableGroupSelect } from "../model";
import EntityRowTableGroupSelectItem from "./EntityRowTableGroupSelectItem";

interface IEntityRowTableGroupSelect {
    entityId: number;
}

const EntityRowTableGroupSelect = ({ entityId }: IEntityRowTableGroupSelect) => {
    const {
        groupingKeys,
        entityFields,
        addedFields,
        open,
        search,
        handleFieldClick,
        handleOpenChange,
        removeAllKeys,
        onChangeSearch,
        onLeave,
        onEnter,
    } = useEntityRowTableGroupSelect(entityId);

    return (
        <Popover
            placement="bottomLeft"
            content={
                <div onMouseLeave={onLeave} onMouseEnter={onEnter}>
                    <div
                        style={{
                            marginBottom: 4,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {addedFields.length > 0 ? (
                            addedFields.map((field) => {
                                return (
                                    <div
                                        key={field.key}
                                        onClick={() => handleFieldClick(field.key)}
                                        style={{
                                            cursor: "pointer",
                                            marginRight: 2,
                                            padding: "2px 5px",
                                            borderRadius: 10,
                                            backgroundColor: "var(--primary-card)",
                                            border: "1px solid var(--border-primary)",
                                        }}
                                    >
                                        {field.name}
                                    </div>
                                );
                            })
                        ) : (
                            <Typography.Text
                                style={{
                                    color: "var(--secondary-text)",
                                }}
                            >
                                Нет добавленных полей
                            </Typography.Text>
                        )}
                    </div>
                    <div>
                        {entityFields.map((field) => {
                            return (
                                <div key={field.key}>
                                    <EntityRowTableGroupSelectItem
                                        field={field}
                                        isAdded={field.isAdded}
                                        onClick={handleFieldClick}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            title={
                <div
                    style={{
                        width: "fit-content",
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>Поля для группировки таблицы</div>
                        <span
                            style={{
                                fontWeight: 400,
                                fontSize: 10,
                                marginLeft: 20,
                                marginBottom: -3,
                            }}
                            className="link-style"
                            onClick={removeAllKeys}
                        >
                            Убрать всё
                        </span>
                    </div>
                    <Input
                        size="small"
                        bordered={false}
                        placeholder="Поиск..."
                        allowClear
                        value={search}
                        onChange={onChangeSearch}
                        style={{ width: "100%" }}
                    />
                </div>
            }
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
        >
            <Space>
                <Tooltip title="Группировка полей">
                    <Button type="link" icon={<GroupOutlined />} />
                    {groupingKeys.length > 0 && (
                        <Badge
                            offset={[0, 0]}
                            style={{
                                boxShadow: "none",
                                marginLeft: 8,
                                position: "absolute",
                                userSelect: "none",
                            }}
                            showZero={false}
                            size={"small"}
                            count={groupingKeys.length}
                        />
                    )}
                </Tooltip>
            </Space>
        </Popover>
    );
};

export default EntityRowTableGroupSelect;
