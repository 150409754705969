import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadEntityRowObservers } from "./EntityRowObserverThunk";
import { selectEntityRowObservers } from "./EntitySelectors";

export const useEntityRowsObservers = (entityUuid: string) => {
    const [loading, setLoading] = useState(false);
    const entityRowObservers = useSelector(selectEntityRowObservers);
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        setLoading(true);
        dispatch(loadEntityRowObservers(entityUuid)).then(() => {
            setLoading(false);
        });
    }, [entityUuid]);
    return {
        loading,
        entityRowObservers,
    };
};
