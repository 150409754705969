import { darkColors } from "@shared/assets/darkColors";

export const getColor = (groupName: any, groupColors: any) => {
    if (groupColors[groupName]) {
        return groupColors[groupName];
    }

    groupColors[groupName] =
        darkColors[Object.keys(groupColors).length % darkColors.length];

    return groupColors[groupName];
};
