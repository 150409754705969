import { estimationLib } from "@entities/Estimations";

export const getEntityRowsCategories = (entityRows: any[]) => {
    const categories: string[] = [];
    entityRows.forEach((row) => {
        if (row.category && !categories.includes(row.category)) {
            categories.push(row.category);
        }
    });

    return categories;
};

export const localStorageDataSaveOrDelete = (condition: boolean, entityId: number) => {
    condition
        ? localStorage.setItem(
              estimationLib.getAdditionalCalculationStorageKey(entityId),
              `${true}`
          )
        : localStorage.removeItem(
              estimationLib.getAdditionalCalculationStorageKey(entityId)
          );
};
