import React from "react";
import "./CardWithAside.scss";
//title
//description?
//actions?
//asideContent?
//colorStatus?
//asideOrientation?
//asideWidth?

interface ICardWithAside {
    title: React.ReactNode;
    description?: React.ReactNode;
    actions?: React.ReactNode;
    asideContent: React.ReactNode;
    asideContentDirection?: "column" | "column-reverse" | "row" | "row-reverse";
    colorStatus?: string;
    asideOrientation?: "right" | "left";
    asideWidth?: number | string;
}

const CardWithAside = ({
    title,
    description,
    actions,
    asideContent,
    colorStatus,
    asideContentDirection,
    asideOrientation,
    asideWidth,
}: ICardWithAside) => {
    return (
        <div className="card-with-aside">
            <div
                className="card-with-aside__container"
                style={{
                    flexDirection: asideOrientation === "right" ? "row" : "row-reverse",
                    justifyContent:
                        asideOrientation === "right" ? "flex-start" : "flex-end",
                }}
            >
                <div className="card-with-aside__body">
                    <div className="card-with-aside__title">{title}</div>
                    <div className="card-with-aside__description">
                        {description}
                        {actions && (
                            <div className="card-with-aside__actions">{actions}</div>
                        )}
                    </div>
                </div>
                <div
                    className="card-with-aside__aside"
                    style={{
                        color: colorStatus ? colorStatus : "",
                        borderColor: colorStatus ? colorStatus : "",
                        width: asideWidth ? asideWidth : "10%",
                        flexDirection: asideContentDirection
                            ? asideContentDirection
                            : "column",
                    }}
                >
                    {asideContent}
                </div>
            </div>
        </div>
    );
};

export default CardWithAside;
