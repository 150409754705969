import { actionModel } from "@entities/Action";
import { bizProcModel } from "@entities/BizProc";
import { bizProcTemplateModel } from "@entities/BizProcTemplate";
import { calculatedValueModel } from "@entities/CalculatedValues";
import { categoryModel } from "@entities/Category";
import { datatypeModel } from "@entities/Datatype";
import { entityModel } from "@entities/Entity";
import { entityFieldModel } from "@entities/EntityField";
import { entityRowModel } from "@entities/EntityRow";
import { estimationModel } from "@entities/Estimations";
import { fieldOptionModel } from "@entities/FieldOption";
import { navigationRouteModel } from "@entities/NavigationRoute";
import { portalSettingModel } from "@entities/PortalSetting";
import { capabilitiesRolesModel } from "@entities/CapabilitiesRoles";
import { stageModel } from "@entities/Stage";
import { userModel } from "@entities/User";
import { variableModel } from "@entities/Variables";
import { viewPatternModel } from "@entities/ViewPattern";
import { viewPatternItemModel } from "@entities/ViewPatternItem";
import { configureStore } from "@reduxjs/toolkit";

export const store = configureStore({
    reducer: {
        user: userModel.userSlice.reducer,
        category: categoryModel.categorySlice.reducer,
        datatype: datatypeModel.datatypeSlice.reducer,
        action: actionModel.actionSlice.reducer,
        stage: stageModel.stageSlice.reducer,
        entity: entityModel.entitySlice.reducer,
        bizProcTemplate: bizProcTemplateModel.bizProcTemplateSlice.reducer,
        bizProc: bizProcModel.bizProcSlice.reducer,
        variable: variableModel.variableSlice.reducer,
        entityField: entityFieldModel.entityFieldSlice.reducer,
        fieldOption: fieldOptionModel.fieldOptionSlice.reducer,
        calculatedValue: calculatedValueModel.calculatedValueSlice.reducer,
        portalSetting: portalSettingModel.portalSettingSlice.reducer,
        navigationRoute: navigationRouteModel.navigationRouteSlice.reducer,
        entityRow: entityRowModel.entityRowSlice.reducer,
        viewPattern: viewPatternModel.viewPatternSlice.reducer,
        viewPatternItem: viewPatternItemModel.viewPatternItemSlice.reducer,
        estimations: estimationModel.estimationSlice.reducer,
        capabilitiesRoles: capabilitiesRolesModel.capabilitiesRolesSlice.reducer,
    },
});
