import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { TextWithLabel } from "@shared/ui/Text";
import { Button, Popconfirm } from "antd";
import { useEntityFieldItem } from "../model/UseEntityFieldItem";
import "./EntityFieldItem.scss";

interface IEntityFIeldItem {
    deleteLoading?: boolean;
    entityField: TEntityField;
    error?: TValidationError;
    onEdit: (field: TEntityField) => void;
    onDelete: (field: TEntityField) => void;
    isColored?: (key: string) => boolean;
}
const EntityFieldItem = ({
    entityField,
    deleteLoading,
    onEdit,
    onDelete,
    isColored,
}: IEntityFIeldItem) => {
    const { onDeleteClick, onClickEdit, name, key, datatype } = useEntityFieldItem({
        entityField,
        onEdit,
        onDelete,
    });

    return (
        <div
            className={`entity-field-item ${isColored && isColored(key) ? "border-color" : ""}`}
        >
            <div className="entity-field-item__text-labels">
                <TextWithLabel
                    // error={error?.data?.['type']}
                    placeholder={"Значение поля"}
                    allowEdit
                    editing={false}
                    label={"Наименование"}
                    text={name}
                />
                <TextWithLabel
                    // error={error?.data?.['name']}
                    placeholder={"Ключ поля"}
                    allowEdit
                    editing={false}
                    label={"Ключ"}
                    text={key}
                />

                <TextWithLabel
                    // error={error?.data?.['name']}
                    placeholder={"Тип"}
                    allowEdit
                    editing={false}
                    label={"Тип"}
                    text={datatype}
                />
            </div>
            {
                <div className="entity-field-item__buttons">
                    <>
                        <Button
                            key={"edit"}
                            onClick={onClickEdit}
                            type="link"
                            icon={<EditOutlined />}
                        />

                        {!entityField.is_system && (
                            <Popconfirm
                                title="Удалить элемент?"
                                okText="Удалить"
                                onConfirm={onDeleteClick}
                                cancelText="Отмена"
                            >
                                <Button
                                    loading={deleteLoading}
                                    type="link"
                                    danger
                                    icon={<DeleteOutlined />}
                                />
                            </Popconfirm>
                        )}
                    </>
                </div>
            }
        </div>
    );
};

export default EntityFieldItem;
