import moment from "moment";
import { newConditionGroup } from "../constants";

interface IUseConditionGroup {
    conditionGroup: TIfElseBranchConditionGroup;
    onChangeParentGroup: (
        conditionGroup: TIfElseBranchCondition | TIfElseBranchConditionGroup
    ) => void;
    onDeleteParentGroup: (
        conditionGroup: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => void;
}

export const useConditionGroup = ({
    conditionGroup,
    onChangeParentGroup,
    onDeleteParentGroup,
}: IUseConditionGroup) => {
    const onAddGroup = () => {
        const id = `id_${moment().valueOf()}`;
        onChangeParentGroup({
            ...conditionGroup,
            conditions: [...conditionGroup.conditions, { ...newConditionGroup, id: id }],
        });
    };

    const onAddIf = () => {
        const id = `id_${moment().valueOf()}`;
        const newGroupCondiitons = [
            ...conditionGroup.conditions,
            { ...newConditionGroup, id: id, type: "conditional" },
        ];
        onChangeParentGroup({
            ...conditionGroup,
            conditions: newGroupCondiitons as any,
        });
    };

    const onDelete = (group: TIfElseBranchConditionGroup | TIfElseBranchCondition) => {
        const newGroupCondiitons = conditionGroup.conditions.filter(
            (item) => item.id !== group.id
        );
        onChangeParentGroup({
            ...conditionGroup,
            conditions: newGroupCondiitons as any,
        });
    };
    const onDeleteGroup = () => {
        onDeleteParentGroup(conditionGroup);
    };

    const onChangeGroup = (
        group: TIfElseBranchConditionGroup | TIfElseBranchCondition
    ) => {
        const newGroupCondiitons = conditionGroup.conditions.map((item) =>
            item.id === group.id ? group : item
        );
        onChangeParentGroup({
            ...conditionGroup,
            conditions: newGroupCondiitons as any,
        });
    };
    const onChangeLogicalComparison = () => {
        onChangeParentGroup({
            ...conditionGroup,
            logicalComparison: conditionGroup.logicalComparison === "and" ? "or" : "and",
        });
    };
    return {
        onAddGroup,
        onChangeLogicalComparison,
        onAddIf,
        onDeleteGroup,
        onChangeGroup,
        onDelete,
    };
};
