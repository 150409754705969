import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import {
    createEntityFieldThunk,
    deleteEntityFieldThunk,
    loadEntityFieldsThunk,
    updateEntityFieldThunk,
} from "./EntityFieldThunk";
import { IEntityFieldSlice } from "./EntityFieldSlice";
import { updateArrByItemId } from "@shared/lib/object-helper";

const loadBuilder = (builder: ActionReducerMapBuilder<IEntityFieldSlice>) => {
    builder.addCase(loadEntityFieldsThunk.fulfilled, (state, action) => {
        state.entityFields = action.payload.data;
        localStorage.setItem("entityFieldsLength", state.entityFields.length.toString());
    });
    builder.addCase(loadEntityFieldsThunk.pending, (state) => {});
    builder.addCase(loadEntityFieldsThunk.rejected, (state, rejectedValue) => {});
};
const createBuilder = (builder: ActionReducerMapBuilder<IEntityFieldSlice>) => {
    builder.addCase(createEntityFieldThunk.fulfilled, (state, action) => {
        state.entityFields.unshift(action.payload.data);
        localStorage.setItem("entityFieldsLength", state.entityFields.length.toString());
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IEntityFieldSlice>) => {
    builder.addCase(updateEntityFieldThunk.fulfilled, (state, action) => {
        state.entityFields = updateArrByItemId(state.entityFields, action.payload.data);
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IEntityFieldSlice>) => {
    builder.addCase(deleteEntityFieldThunk.fulfilled, (state, action) => {
        state.entityFields = state.entityFields.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem("entityFieldsLength", state.entityFields.length.toString());
    });
};

export const entityFieldBuilder = (
    builder: ActionReducerMapBuilder<IEntityFieldSlice>
) => {
    loadBuilder(builder);
    createBuilder(builder);
    updateBuilder(builder);
    deleteBuilder(builder);
};
