import { CategoriesList } from "@features/CategoryFeatures/CategoriesList";
import { CategoryCreateButton } from "@features/CategoryFeatures/CategoryCreateUpdate";
import {
    StageCreateButton,
    StageUpdateButton,
} from "@features/StageFeatures/StageCreateUpdate";
import { StageDeleteButton } from "@features/StageFeatures/StageDelete";
import { StagesCardsItemsByType } from "@features/StageFeatures/StagesCardsItemsByType";
import { Space, Typography } from "antd";
import CategoryItemActions from "./CategoryItemActions";

interface ICategoriesListWithEditing {
    entityId: number;
}

const CategoriesListWithEditing = ({ entityId }: ICategoriesListWithEditing) => {
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Space>
                    <Typography.Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>
                        Категории
                    </Typography.Title>
                    <CategoryCreateButton entityId={entityId} />
                </Space>
            </div>
            <CategoriesList
                entityId={entityId}
                actions={(category) => (
                    <CategoryItemActions entityId={entityId} category={category} />
                )}
                panelChildren={(category) => (
                    <StagesCardsItemsByType
                        entityId={entityId}
                        categoryId={category.id}
                        stageCreateAction={(
                            stage: TStageCreating,
                            entityId: number,
                            categoryId: number
                        ) => {
                            return (
                                <StageCreateButton
                                    stage={stage}
                                    entityId={entityId}
                                    categoryId={categoryId}
                                />
                            );
                        }}
                        actions={(stage, entityId, categoryId, hideDelete) => (
                            <div>
                                <Space>
                                    <StageUpdateButton
                                        stage={stage}
                                        entityId={entityId}
                                        categoryId={categoryId}
                                    />
                                    {!hideDelete && (
                                        <StageDeleteButton
                                            stageId={stage.id}
                                            entityId={entityId}
                                            categoryId={categoryId}
                                        />
                                    )}
                                </Space>
                            </div>
                        )}
                    />
                )}
            />
        </div>
    );
};

export default CategoriesListWithEditing;
