import { Modal } from "antd";
import CapabilitiesRolesConstraintsForm from "./CapabilitiesRolesConstraints/CapabilitiesRolesConstraintsForm";

interface ICapabilitiesRolesRowModal {
    open: boolean;
    onClose: () => void;
    entityCapabilitiesRolesId: number;
    entityRow: TCapabilitiesRolesRow;
    onChangeRow: (capType: TCapType, value: any) => void;
}

const CapabilitiesRolesRowModal = ({
    open,
    onClose,
    entityCapabilitiesRolesId,
    entityRow,
    onChangeRow,
}: ICapabilitiesRolesRowModal) => {
    return (
        <Modal
            maskClosable={false}
            footer={<></>}
            destroyOnClose
            title={"Ограничения"}
            open={open}
            onCancel={onClose}
            width="60%"
        >
            <CapabilitiesRolesConstraintsForm
                entityCapabilitiesRolesId={entityCapabilitiesRolesId}
                entityRow={entityRow}
                additionalAction={onClose}
                value={entityRow.constraints}
                onChangeRow={onChangeRow}
            />
        </Modal>
    );
};

export default CapabilitiesRolesRowModal;
