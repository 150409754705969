import { viewPatternItemModel } from "@entities/ViewPatternItem";
import { useDispatch, useSelector } from "react-redux";

export const useViewPatternItem = (viewPatternItem: TViewPatternItem) => {
    const dispatch = useDispatch<AppDispatch>();
    const deleteLoading = useSelector((state) =>
        viewPatternItemModel.selectDeleteLoading(state, viewPatternItem.id)
    );
    const onDelete = () => {
        dispatch(
            viewPatternItemModel.deleteViewPatternItemThunk({
                viewPatternId: viewPatternItem.view_pattern_id,
                id: viewPatternItem.id,
            })
        );
    };

    const onUpdateLabel = (text: string) => {
        dispatch(
            viewPatternItemModel.updateViewPatternItemThunk({
                ...viewPatternItem,
                label: text,
            })
        );
    };
    return {
        deleteLoading,
        onDelete,
        onUpdateLabel,
    };
};
