import { DiffOutlined } from "@ant-design/icons";
import { entityRowModel } from "@entities/EntityRow";
import { useSelector } from "react-redux";

interface ICopyEntityRow {
    entityRow: { [key: string]: any } | undefined;
    openCloseDrawerWithNavigate?: (
        setIsOpenDrawer: boolean,
        entityRowId: number | undefined | null,
        initialValues?: { [key: string]: any }
    ) => void;
}

const CopyEntityRow = ({ entityRow, openCloseDrawerWithNavigate }: ICopyEntityRow) => {
    const entityFields = useSelector(entityRowModel.selectEntityFields);
    const onReCreateEntityRow = () => {
        if (entityRow && openCloseDrawerWithNavigate) {
            const entityFieldsWithValuesForCopyOfEntityRow: { [key: string]: any } = {};
            Object.entries(entityRow).forEach(([key, value]) => {
                const foundedEntityField = entityFields.find((item) => item.key === key);
                if (!!foundedEntityField) {
                    if (
                        ["file", "entity"].includes(foundedEntityField.datatype) ||
                        ["created_by_id", "id"].includes(key)
                    ) {
                        return;
                    } else entityFieldsWithValuesForCopyOfEntityRow[key] = value;
                }
            });

            openCloseDrawerWithNavigate(
                true,
                null,
                entityFieldsWithValuesForCopyOfEntityRow
            );
        }
    };

    return (
        <div style={{ padding: 6 }} onClick={onReCreateEntityRow}>
            <DiffOutlined style={{ color: "var(--primary)", marginRight: 4 }} />
            Скопировать
        </div>
    );
};

export default CopyEntityRow;
