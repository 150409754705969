import { getNormalizedTime } from "@shared/lib/date";
import { UsersAssygnment } from "../BizProcsUsers";
import "./AgreementListItem.scss";
import AgreementListItemHeader from "./AgreementListItemHeader";

interface IAgreementListItem {
    agreement: TBizProcAction<TBizProcAgreementParameters>;
    onClick?: (agreement: TBizProcAction<TBizProcAgreementParameters>) => void;
    isMyAgreements?: boolean;
    onUserClick?: (user: TUser) => void;
}

const AgreementListItem = (props: IAgreementListItem) => {
    const { agreement, onClick, isMyAgreements, onUserClick } = props;
    const onClick_ = () => {
        onClick && onClick(agreement);
    };
    const parameters = agreement.collected_parameters;
    return (
        <div
            className="agree-item"
            style={{
                border:
                    agreement.deadline_status === "expired" &&
                    agreement.status === "executing"
                        ? "2px solid #d10202"
                        : "",
            }}
        >
            <AgreementListItemHeader
                header={parameters.header}
                deadline_status={agreement.deadline_status}
                status={agreement.status}
            />
            <UsersAssygnment
                users={agreement.collected_parameters.users}
                onUserClick={onUserClick}
                status={agreement.status}
            />
            <div className="agree-item__footer">
                <div className="agree-item__comment-date">
                    <div className="agree-item__date">
                        {agreement.status === "executing" && (
                            <span>
                                Cогласовать до: {getNormalizedTime(agreement.deadline_at)}
                            </span>
                        )}
                        {agreement.status === "approve" && (
                            <span>
                                Согласовано: {getNormalizedTime(agreement.updated_at)}{" "}
                            </span>
                        )}
                        {agreement.status === "reject" && (
                            <span>
                                Отказано: {getNormalizedTime(agreement.updated_at)}
                            </span>
                        )}
                    </div>
                </div>
                <div className="agree-item__additional">
                    <span onClick={onClick ? onClick_ : undefined}>{`${
                        isMyAgreements === false
                            ? "Рассмотреть"
                            : isMyAgreements === true
                              ? "см. подробнее"
                              : ""
                    }`}</span>
                </div>
            </div>
        </div>
    );
};

export default AgreementListItem;
