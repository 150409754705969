import { AntdIcon } from "@shared/ui/AntdIcon";
import "./StartMenuItem.scss";
import { theme } from "antd";
interface IStartMenuItem {
    item: TUserTemplate;
    loading: boolean;
    disabled: boolean;
    onStart: (bizProcTemplateId: number) => void;
}

const StartMenuItem = ({ item, disabled, loading, onStart }: IStartMenuItem) => {
    const { token } = theme.useToken();

    const onClick = () => {
        if (!loading && !disabled) {
            onStart(item.biz_proc_template_id);
        }
    };
    const color = loading ? token.colorPrimary : undefined;

    return (
        <div className="start-menu-item" style={{ color }} onClick={onClick}>
            <AntdIcon
                className="start-menu-item__icon"
                style={{
                    color,
                }}
                type={loading ? "LoadingOutlined" : item.icon}
            />
            <div className="start-menu-item__label">{item.label}</div>
        </div>
    );
};

export default StartMenuItem;
