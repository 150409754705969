import { Collapse, CollapseProps } from "antd";
import { ReactNode } from "react";
import "./CategoriesPanelList.scss";
interface ICategoriesPanelList {
    categories: TCategory[];
    actions?: (category: TCategory) => ReactNode;
    panelChildren: (category: TCategory) => ReactNode;
}

const CategoriesPanelList = ({
    categories,
    actions,
    panelChildren,
}: ICategoriesPanelList) => {
    const items: CollapseProps["items"] = categories.map((category) => {
        return {
            key: category.id,
            className: "category-panel-item",
            label: category.label,
            extra: (
                <div onClick={(e) => e.stopPropagation()}>
                    {actions && actions(category)}
                </div>
            ),
            children: panelChildren(category),
        };
    });

    return (
        <Collapse
            className="category-panel"
            size="large"
            defaultActiveKey={[1]}
            // defaultActiveKey={categories.map((category) => category.id)} не работает мап
            items={items}
        />
    );
};

export default CategoriesPanelList;
