import { useStagesList } from "@features/StageFeatures/StagesList/model";
import { ReactNode } from "react";
import { Xwrapper } from "react-xarrows";
import "./StagesCardsItemsByType.scss";
import StagesGroupCard from "./StagesGroupCard";
interface IStagesCardsItemsByType {
    categoryId: number;
    entityId: number;
    stageCreateAction: (
        stage: TStageCreating,
        entityId: number,
        categoryId: number
    ) => ReactNode;
    actions?: (
        stage: TStage,
        entityId: number,
        categoryId: number,
        hideDelete?: boolean
    ) => ReactNode;
}

const StagesCardsItemsByType = ({
    categoryId,
    entityId,
    actions,
    stageCreateAction,
}: IStagesCardsItemsByType) => {
    const {
        startStage,
        additionalStages,
        successfulStages,
        unsuccessfulStages,
        onChangeItems,
    } = useStagesList(entityId, categoryId);

    return (
        <div>
            <Xwrapper>
                <div id="init" style={{ marginBottom: 50 }}>
                    {startStage && (
                        <StagesGroupCard
                            type="in_progress"
                            stages={[startStage!]}
                            actions={
                                actions
                                    ? (stage, entityId, categoryId) =>
                                          actions(stage, entityId, categoryId, true)
                                    : actions
                            }
                            entityId={entityId}
                            categoryId={categoryId}
                            label={"Начальная стадия"}
                        />
                    )}
                </div>
                <div id="additional" style={{ marginBottom: 50 }}>
                    {startStage && (
                        <StagesGroupCard
                            type="in_progress"
                            stages={additionalStages}
                            latestStage={startStage}
                            actions={actions}
                            entityId={entityId}
                            categoryId={categoryId}
                            allowDragAndDrop
                            onChangeItems={onChangeItems}
                            stageCreateAction={stageCreateAction}
                            label={"Дополнительные стадии"}
                        />
                    )}
                </div>
                <div className="stages-final-cards">
                    <div id="successful" style={{ width: "100%" }}>
                        <StagesGroupCard
                            style={{ width: "100%" }}
                            type="successful"
                            latestStage={
                                additionalStages.length > 0
                                    ? additionalStages[additionalStages.length - 1]
                                    : startStage
                            }
                            stages={successfulStages}
                            actions={
                                actions
                                    ? (stage, entityId, categoryId) =>
                                          actions(
                                              stage,
                                              entityId,
                                              categoryId,
                                              successfulStages.length === 1
                                          )
                                    : actions
                            }
                            entityId={entityId}
                            categoryId={categoryId}
                            label={"Успешные стадии"}
                            stageCreateAction={stageCreateAction}
                        />
                    </div>
                    <div id="unsuccessful" style={{ width: "100%" }}>
                        <StagesGroupCard
                            style={{ width: "100%" }}
                            latestStage={
                                additionalStages.length > 0
                                    ? additionalStages[additionalStages.length - 1]
                                    : startStage
                            }
                            type="unsuccessful"
                            stages={unsuccessfulStages}
                            actions={actions}
                            entityId={entityId}
                            categoryId={categoryId}
                            label={"Неуспешные стадии"}
                            stageCreateAction={stageCreateAction}
                        />
                    </div>
                </div>
                {/* <Xarrow
                    startAnchor={"bottom"}
                    endAnchor={"top"}
                    strokeWidth={1}
                    start={"init"}
                    end="additional"
                />
                <Xarrow
                    startAnchor={"bottom"}
                    endAnchor={"top"}
                    strokeWidth={1}
                    start={"additional"}
                    end="successful"
                    color="rgb(123, 213, 0)"
                />
                <Xarrow
                    startAnchor={"bottom"}
                    endAnchor={"top"}
                    strokeWidth={1}
                    color="#ff115e"
                    start={"additional"}
                    end="unsuccessful"
                /> */}
            </Xwrapper>
        </div>
    );
};

export default StagesCardsItemsByType;
