import React from "react";
import "./FormItem.scss";
interface IFormItemProps {
    label?: React.ReactNode;
    required?: boolean;
    error?: boolean;
    errorText?: string;
    labelStyle?: React.CSSProperties;
    style?: React.CSSProperties | undefined;
    children: React.ReactElement;
}
const FormItem = ({
    label,
    required,
    error,
    errorText,
    style,
    labelStyle,
    children,
}: IFormItemProps) => {
    return (
        <div className="form-item" style={style}>
            {label && (
                <div className="form-item__label" style={{ display: "flex" }}>
                    {required && (
                        <span style={{ color: "red", fontWeight: 700, marginRight: 5 }}>
                            *
                        </span>
                    )}{" "}
                    <div
                        style={{
                            ...(labelStyle ?? {}),
                        }}
                    >
                        {label}
                    </div>
                </div>
            )}
            <div className={`form-item__body`}>{children}</div>
            {error && (
                <div
                    className="form-item__error"
                    style={{ position: errorText ? undefined : "absolute" }}
                >
                    {errorText ?? "Это поле обязательно к заполнению"}
                </div>
            )}
        </div>
    );
};

export default FormItem;
