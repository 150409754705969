import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { updateArrByItemId } from "@shared/lib/object-helper";
import { IEntityRowSlice } from "./EntityRowSlice";
import {
    deleteEntityRowsThunk,
    deleteEntityRowThunk,
    getEntityRowsByDatesThunk,
    getEntityRowsWithPageThunk,
    saveEntityRowsThunk,
} from "./EntityRowThunk";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

const setIsLoading = (state: RootState, key: string | number, isLoading: boolean) => {
    if (state.entityRowsWithPage[key]) {
        state.entityRowsWithPage[key].isLoading = isLoading;
    } else {
        state.entityRowsWithPage[key] = {
            entityRows: [],
            lastPage: 1,
            isLoading: isLoading,
            currentPage: 1,
        };
    }
};
const loadBuilderWithPage = (builder: ActionReducerMapBuilder<IEntityRowSlice>) => {
    builder.addCase(getEntityRowsWithPageThunk.fulfilled, (state, action) => {
        const key = action.meta.arg.key;

        state.entityRowsWithPage[key] = {
            entityRows: action.payload.data.data,
            lastPage: action.payload.data.last_page,
            isLoading: false,
            currentPage: action.payload.data.current_page,
            entityFields: action.payload.data.entity_fields,
        };
        // state.entityRowsWithPage[level].entityRows = action.payload.data.data;
        // state.entityRowsWithPage[level].lastPage = action.payload.data.last_page;
        // state.entityRowsWithPage[level].isLoading = false;
        if (key === 0) {
            state.entityFields = action.payload.data.entity_fields;
            state.entityUserCapabilities = action.payload.data.capabilities;
        }
    });
    builder.addCase(getEntityRowsWithPageThunk.pending, (state, action) => {
        const key = action.meta.arg.key;
        setIsLoading(state, key, true);
    });
    builder.addCase(getEntityRowsWithPageThunk.rejected, (state, rejectedValue) => {
        const key = rejectedValue.meta.arg.key;
        setIsLoading(state, key, false);
    });
};

const loadBuilderByDate = (builder: ActionReducerMapBuilder<IEntityRowSlice>) => {
    builder.addCase(getEntityRowsByDatesThunk.fulfilled, (state, action) => {
        state.entityRows = action.payload.data.data;
        state.entityFields = action.payload.data.entity_fields;
        state.entityUserCapabilities = action.payload.data.capabilities;
        state.lastPage = action.payload.data.last_page;
        state.isLoading = false;
    });
    builder.addCase(getEntityRowsByDatesThunk.pending, (state) => {
        state.isLoading = true;
    });
    builder.addCase(getEntityRowsByDatesThunk.rejected, (state, rejectedValue) => {
        state.isLoading = false;
    });
};
// const createBuilder = (builder: ActionReducerMapBuilder<IFieldOptionSlice>) => {
//     builder.addCase(createFieldOptionThunk.fulfilled, (state, action) => {
//         state.fieldOptions.unshift(action.payload.data);
//         localStorage.setItem("fieldOptionsLength", state.fieldOptions.length.toString());
//     });
// };
const updateBuilder = (builder: ActionReducerMapBuilder<IEntityRowSlice>) => {
    builder.addCase(saveEntityRowsThunk.fulfilled, (state, action) => {
        state.entityRows = updateArrByItemId(state.entityRows, action.payload.data);
    });
};

const deleteEntityRowBuilder = (builder: ActionReducerMapBuilder<IEntityRowSlice>) => {
    builder.addCase(deleteEntityRowThunk.fulfilled, (state, action) => {
        const key = action.meta.arg.key;
        const deletedEntityRowId = action.meta.arg.entityRowId;
        if (key !== undefined) {
            if (state.entityRowsWithPage[key]) {
                state.entityRowsWithPage[key].entityRows = state.entityRowsWithPage[
                    key
                ].entityRows.filter(({ id }) => id !== deletedEntityRowId);
            }
        } else {
            state.entityRows = state.entityRows.filter(
                ({ id }) => id !== deletedEntityRowId
            );
        }
    });
};
const deleteEntityRowsBuilder = (builder: ActionReducerMapBuilder<IEntityRowSlice>) => {
    builder.addCase(deleteEntityRowsThunk.fulfilled, (state, action) => {
        const deletedEntityRowIds = action.meta.arg.entityRowIds;
        const key = action.meta.arg.key;

        const deniedEntityRowIds = action.payload.data.deniedRows;
        if (deniedEntityRowIds.length > 0) {
            notificationEmit({
                type: "error",
                title: "Ошибка",
                description: `Не удалость удалить записи: ${deniedEntityRowIds.join(",")}`,
            });
        }
        if (key !== undefined) {
            if (state.entityRowsWithPage[key]) {
                state.entityRowsWithPage[key].entityRows = state.entityRowsWithPage[
                    key
                ].entityRows.filter(
                    ({ id }) =>
                        !deletedEntityRowIds.includes(id) &&
                        !deniedEntityRowIds.includes(id)
                );
            }
        } else {
            state.entityRows = state.entityRows.filter(
                ({ id }) =>
                    !deletedEntityRowIds.includes(id) && !deniedEntityRowIds.includes(id)
            );
        }
    });
    builder.addCase(deleteEntityRowsThunk.pending, (state) => {});
    builder.addCase(deleteEntityRowsThunk.rejected, (state, rejectedValue) => {
        notificationEmit({
            type: "error",
            title: "Ошибка",
            description: "Не удалость удалить записи сущности",
        });
    });
};

export const entityRowBuilder = (builder: ActionReducerMapBuilder<IEntityRowSlice>) => {
    loadBuilderWithPage(builder);
    loadBuilderByDate(builder);
    deleteEntityRowBuilder(builder);
    deleteEntityRowsBuilder(builder);
    updateBuilder(builder);
};
