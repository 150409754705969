import "./TextStyles.scss";
interface IErrorTextProps {
    text: string | undefined;
    absolute?: boolean;
}
const ErrorText = ({ text, absolute }: IErrorTextProps) => {
    if (!text) return <></>;
    return (
        <div
            className="error-text"
            style={{ position: absolute ? "absolute" : undefined }}
        >
            {text}
        </div>
    );
};

export default ErrorText;
