import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateArrByItemId } from "@shared/lib/object-helper";
import {
    createActionThunk,
    deleteActionThunk,
    loadActionsThunk,
    updateActionThunk,
} from "./ActionThunk";

export interface IActionSlice {
    actions: TAction[];
    isLoading: boolean;
    error: any;
}

const initialState: IActionSlice = {
    actions: [],
    isLoading: false,
    error: null,
};

export const actionSlice = createSlice({
    name: "ActionSlice",
    initialState,
    reducers: {
        setActions: (state, action: PayloadAction<TAction[]>) => {
            state.actions = action.payload;
            localStorage.setItem("actionsLength", state.actions.length.toString());
        },
        addAction: (state, action: PayloadAction<TAction>) => {
            state.actions.unshift(action.payload);
            localStorage.setItem("actionsLength", state.actions.length.toString());
        },
        updateAction: (state, action: PayloadAction<TAction>) => {
            state.actions = updateArrByItemId(state.actions, action.payload);
        },
        deleteAction: (state, action: PayloadAction<TAction>) => {
            state.actions = state.actions.filter((item) => item.id !== action.payload.id);
            localStorage.setItem("actionsLength", state.actions.length.toString());
        },
    },
    extraReducers: (builder) => {
        loadBuilder(builder);
        createBuilder(builder);
        updateBuilder(builder);
        deleteBuilder(builder);
    },
});

const loadBuilder = (builder: ActionReducerMapBuilder<IActionSlice>) => {
    builder.addCase(loadActionsThunk.fulfilled, (state, action) => {
        state.actions = action.payload.data;
        state.isLoading = false;
        localStorage.setItem("actionsLength", state.actions.length.toString());
    });
    builder.addCase(loadActionsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
    });
    builder.addCase(loadActionsThunk.rejected, (state, rejectedValue) => {
        state.error = rejectedValue;
        state.isLoading = false;
    });
};
const createBuilder = (builder: ActionReducerMapBuilder<IActionSlice>) => {
    builder.addCase(createActionThunk.fulfilled, (state, action) => {
        state.actions.unshift(action.payload.data);
        localStorage.setItem("actionsLength", state.actions.length.toString());
    });
};
const updateBuilder = (builder: ActionReducerMapBuilder<IActionSlice>) => {
    builder.addCase(updateActionThunk.fulfilled, (state, action) => {
        state.actions = updateArrByItemId(state.actions, action.payload.data);
    });
};
const deleteBuilder = (builder: ActionReducerMapBuilder<IActionSlice>) => {
    builder.addCase(deleteActionThunk.fulfilled, (state, action) => {
        state.actions = state.actions.filter(
            (item) => item.id !== action.payload.data.id
        );
        localStorage.setItem("actionsLength", state.actions.length.toString());
    });
};
export const { setActions, updateAction, deleteAction, addAction } = actionSlice.actions;
export default actionSlice.reducer;
