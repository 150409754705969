import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Space, Spin } from "antd";
import { useFieldOptionEditor } from "../model";
import AddOptionItem from "./AddOptionItem";
import "./FieldOptionEditor.scss";
import OptionItem from "./OptionItem";
interface IFieldOptionEditor {
    fieldId: number;
    type: TFieldOptionType;
}

const FieldOptionEditor = ({ fieldId, type }: IFieldOptionEditor) => {
    const { options, loading } = useFieldOptionEditor(fieldId, type);

    return (
        <>
            {loading ? (
                <FlexContainer>
                    <Space>
                        <Spin />
                    </Space>
                </FlexContainer>
            ) : (
                <>
                    <AddOptionItem fieldId={fieldId} type={type} />
                    <div className="options-list">
                        {options.map((option) => {
                            return (
                                <Space key={option.id}>
                                    <OptionItem
                                        option={option}
                                        fieldId={fieldId}
                                        type={type}
                                    />
                                </Space>
                            );
                        })}
                    </div>
                </>
            )}
        </>
    );
};

export default FieldOptionEditor;
