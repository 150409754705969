import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { shapes } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import { estimationLib } from "@entities/Estimations";
import { ListWithAnimations } from "@shared/ui/List/ListWithAnimations";
import { Avatar, Button, Flex, Input, Spin } from "antd";
import { useEstimationTemplatesList } from "../model";
import { TListItemAddonNodes } from "@shared/ui/List";

interface IEstimationTemplatesList {
    entityId: number;
    entityUuid: string;
    onClickItem?: () => void;
}

const EstimationTemplatesList = ({
    entityId,
    entityUuid,
    onClickItem,
}: IEstimationTemplatesList) => {
    const {
        user,
        estimations,
        isLoading,
        filterValue,
        onEdit,
        onDelete,
        chooseTemplate,
        onChangeFilterValue,
        createEstimationTemplate,
    } = useEstimationTemplatesList(entityId, entityUuid, onClickItem);

    const baseActions: TListItemAddonNodes = [
        {
            type: "edit",
            node: (
                <Button
                    type="link"
                    style={{ margin: 0, padding: 0 }}
                    icon={<EditOutlined />}
                />
            ),
            handler: onEdit,
        },
    ];

    const resultActions = (item: TEstimationTemplate): TListItemAddonNodes =>
        estimationLib.checkAccessForEditDeleteTemplate(user, item.created_by_id)
            ? [
                  {
                      type: "delete",
                      node: (
                          <Button
                              style={{ margin: 0, padding: 0 }}
                              type="link"
                              danger
                              icon={<DeleteOutlined />}
                          />
                      ),
                      handler: onDelete,
                  },
                  ...baseActions,
              ]
            : baseActions;

    return (
        <Spin spinning={isLoading}>
            <Flex justify="space-between" style={{ marginBottom: 16 }}>
                <Input
                    addonBefore={<SearchOutlined />}
                    onChange={onChangeFilterValue}
                    value={filterValue}
                />
                <Button
                    onClick={createEstimationTemplate}
                    type="primary"
                    style={{ padding: 0, marginLeft: 8, width: 120 }}
                    icon={<PlusOutlined />}
                >
                    Создать
                </Button>
            </Flex>
            <ListWithAnimations<TEstimationTemplate>
                listData={estimations}
                onClickListItem={chooseTemplate}
                listItemAddonNodes={resultActions}
                listItemMetaAvatarNode={(item) => (
                    <Avatar
                        src={createAvatar(shapes, {
                            seed: `${item.id}`,
                        }).toDataUri()}
                    />
                )}
                listItemMetaTitleText={(item, index) => item.name ?? `Шаблон №${index}`}
                listItemMetaDescriptionNode={(item) =>
                    item.sum_view_fields.length > 0
                        ? item.sum_view_fields.map(
                              (field, index, arr) =>
                                  `${field.name}${index !== arr.length - 1 ? "," : ""}`
                          )
                        : "Поля не выбраны"
                }
            />
        </Spin>
    );
};

export default EstimationTemplatesList;
