export const getValidParams = (fields: TFilterValues) => {
    if (fields) {
        const conditionParams: any[] = [];
        fields.forEach((field) => {
            const buff: any = {};
            buff.key = field.key;
            buff.condition = field.condition;
            if ([">", "<", "=", ">=", "<=", "!="].includes(buff.condition)) {
                buff.value = field.value;
                conditionParams.push(buff);
                return;
            }
            if (buff.condition === "like") {
                buff.value = `%${field.value}%`;
                conditionParams.push(buff);
                return;
            }
            if (buff.condition === "between") {
                buff.value = `'${field.value?.[0]}' AND '${field.value?.[1]}'`;
                conditionParams.push(buff);
                return;
            }

            if (Array.isArray(field.value)) {
                buff.value = `(${field.value.join(",")})`;
                conditionParams.push(buff);
                return;
            }
            if (!Array.isArray(field.value)) {
                buff.value = `(${field.value})`;
                conditionParams.push(buff);
                return;
            }
        });
        return conditionParams;
    }
    return [];
};

export const getConditionParameters = (
    mainFiltersConditions: any[],
    additionalCondition: TMultipleCondition | any
) => {
    if (mainFiltersConditions.length > 0 && additionalCondition) {
        return {
            condition_type: "AND",
            conditions: [additionalCondition, ...mainFiltersConditions],
        };
    } else if (mainFiltersConditions.length === 0 && additionalCondition) {
        return additionalCondition;
    } else if (mainFiltersConditions.length === 1 && !additionalCondition) {
        return mainFiltersConditions[0];
    } else if (mainFiltersConditions.length > 1 && !additionalCondition) {
        return {
            condition_type: "AND",
            conditions: mainFiltersConditions,
        };
    }
};

export const getCondition = (
    dateEndKey: string | undefined,
    dateStartKey: string | undefined,
    startValue: string | undefined,
    endValue: string | undefined
): TMultipleCondition => {
    return dateEndKey
        ? {
              condition_type: "OR",
              conditions: [
                  {
                      condition_type: "AND",
                      conditions: [
                          { key: dateStartKey, value: endValue!, condition: "<=" },
                          {
                              condition_type: "OR",
                              conditions: [
                                  {
                                      key: dateEndKey,
                                      value: "null", // null
                                      condition: "null",
                                  },
                                  {
                                      key: dateEndKey,
                                      value: startValue!,
                                      condition: ">",
                                  },
                              ],
                          },
                      ],
                  },
                  {
                      condition_type: "AND",
                      conditions: [
                          { key: dateStartKey, value: startValue!, condition: ">=" },
                          { key: dateStartKey, value: endValue!, condition: "<=" },
                      ],
                  },
                  {
                      condition_type: "AND",
                      conditions: [
                          { key: dateStartKey, value: startValue!, condition: ">=" },
                          { key: dateEndKey, value: endValue!, condition: "<=" },
                      ],
                  },
              ],
          }
        : {
              condition_type: "AND",
              conditions: [
                  { key: dateStartKey, value: startValue!, condition: ">=" },
                  { key: dateStartKey, value: endValue!, condition: "<=" },
              ],
          };
};

export const getPreparedEntityRowsByEntityFieldAfterPagination = (
    entityRowsByEntityField: TEntityRowsByEntityField,
    columnValue: any,
    paginationResponse: TEntityRowsByEntityField
) => ({
    ...entityRowsByEntityField,
    [columnValue]: {
        ...entityRowsByEntityField[columnValue],
        is_ended: paginationResponse[columnValue].is_ended,
        data: [
            ...entityRowsByEntityField[columnValue].data,
            ...paginationResponse[columnValue].data,
        ]
            .reduce((newEntityRows, entityRow) => {
                if (!!newEntityRows.find((item: any) => item.id === entityRow.id))
                    return newEntityRows.map(
                        ({
                            is_invisible_for_pagination,
                            ...rest
                        }: {
                            is_invisible_for_pagination: boolean;
                        }) => rest
                    );
                return [...(newEntityRows as { [key: string]: any }[]), entityRow];
            }, [])
            .sort((a: any, b: any) => b.id - a.id),
    },
});

export const getEntityRowsByEntityFieldWithAdditionalColumns = (
    mainEntityRowsByEntityField: TEntityRowsByEntityField,
    entityField: TEntityField,
    options: { [key: string]: any }[]
) => {
    const entityRowsByEntityFieldWithAdditionalColumns: { [key: string]: any } = {};
    options.forEach((option: { [key: string]: any }) => {
        const entityRowColumn = mainEntityRowsByEntityField[option["value"]];
        entityRowsByEntityFieldWithAdditionalColumns[
            option[entityField.key_value ?? "value"] as string
        ] = {
            option: option,
            label: option["label"],
            data: entityRowColumn?.data ?? [],
            count: entityRowColumn?.count ?? 0,
            background_color: option["background_color"] ?? undefined,
            is_ended: entityRowColumn?.is_ended ?? true,
        };
    });
    const allColumns = {
        ...mainEntityRowsByEntityField,
        ...entityRowsByEntityFieldWithAdditionalColumns,
    };

    return allColumns;
};

export const getParametersForRequest = (filters: TFilterValues) => {
    const conditionsParams = [...getValidParams([...(filters ?? [])])];
    const parameters: TMultipleCondition | undefined =
        conditionsParams.length > 0
            ? {
                  condition_type: "AND",
                  conditions: conditionsParams,
              }
            : undefined;
    return parameters;
};
