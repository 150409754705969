import CreateUpdateDefaultCheckbox from "./CreateUpdateDefaultCheckbox";
import CreateUpdateDefaultValueInput from "./CreateUpdateDefaultValueInput";

interface ICreateUpdateDefaultValueItem {
    editingEntityField: TEntityField;
    isDisabled: boolean;
    label: string;
    buttonLabel: string;
    isMultiple?: boolean;
    name: "on_create_default" | "on_update_default";
    onAddCreateUpdateValue: (name: "on_create_default" | "on_update_default") => void;
    onChangeIsCreateUpdateCheckbox: (
        item: any,
        name: string,
        isMultiple?: boolean | undefined
    ) => void;
    onChangeCalculation: (
        name: keyof TEntityField,
        isMultiple?: boolean,
        key?: number | string
    ) => void;
    onChangeCreateUpdateValue: (
        value: any,
        name: keyof TEntityField,
        isMultiple?: boolean,
        key?: number | string
    ) => void;
    onDelete: (key: number, name: "on_create_default" | "on_update_default") => void;
}

const CreateUpdateDefaultValueItem = ({
    editingEntityField,
    isDisabled,
    label,
    buttonLabel,
    isMultiple,
    name,
    onAddCreateUpdateValue,
    onChangeIsCreateUpdateCheckbox,
    onChangeCalculation,
    onChangeCreateUpdateValue,
    onDelete,
}: ICreateUpdateDefaultValueItem) => {
    const checkBoxValue = Array.isArray(editingEntityField[name])
        ? editingEntityField[name as keyof TEntityField].length > 0
        : !!editingEntityField[name];

    return (
        <div style={{ marginTop: 16 }}>
            <CreateUpdateDefaultCheckbox
                value={checkBoxValue}
                visible={editingEntityField.datatype !== "entity"}
                disabled={isDisabled}
                isMultiple={!!isMultiple}
                name={name}
                label={label}
                buttonLabel={buttonLabel}
                onAddCreateUpdateValue={onAddCreateUpdateValue}
                onChangeIsCreateUpdateCheckbox={onChangeIsCreateUpdateCheckbox}
            />
            <CreateUpdateDefaultValueInput
                name={name}
                editingEntityField={editingEntityField}
                isMultiple={isMultiple}
                label={label}
                onChangeCalculation={onChangeCalculation}
                onChangeCreateUpdateValue={onChangeCreateUpdateValue}
                onDelete={onDelete}
            />
        </div>
    );
};

export default CreateUpdateDefaultValueItem;
