import { entityFieldModel } from "@entities/EntityField";

import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getValidatedEditingEntityField } from "../lib";
import { getUnixTimestamp } from "@shared/lib/date";

export const useEntityFieldUpdatingForm = (
    initialEntityField: TEntityField,
    onSuccessSave?: () => void
) => {
    const [error, setError] = useState<TValidationError>({});
    const [loading, setLoading] = useState(false);
    const [editingEntityField, setEditingEntityField] = useState<
        TEntityField & { is_new?: boolean }
    >(initialEntityField);
    const dispatch = useDispatch<AppDispatch>();
    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditingEntityField({
            ...editingEntityField,
            [event.target.name]: event.target.value,
        });
    };

    const onSave = () => {
        setLoading(true);
        if (editingEntityField.entity_uuid || editingEntityField.entity_id) {
            dispatch(
                !editingEntityField.is_new
                    ? entityFieldModel.updateEntityFieldThunk(
                          getValidatedEditingEntityField(editingEntityField)
                      )
                    : entityFieldModel.createEntityFieldThunk(
                          getValidatedEditingEntityField(editingEntityField)
                      )
            )
                .then(parseFormResponse)
                .then(() => {
                    onSuccessSave && onSuccessSave();
                })
                .catch((e: TActionError) => {
                    setError(e);
                    notificationEmit({
                        error: error,
                        title: error.message ?? "Не удалось обновить поле!",
                        type: "error",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            dispatch(entityFieldModel.updateEntityField(editingEntityField));
            onSuccessSave && onSuccessSave();
        }
    };

    const onChangeView = () => {
        setEditingEntityField({
            ...editingEntityField,
            view: !editingEntityField.view,
        });
    };
    const onChangeUnwritable = () => {
        setEditingEntityField({
            ...editingEntityField,
            is_unwritable: !editingEntityField.is_unwritable,
        });
    };
    const onChangeIsName = () => {
        setEditingEntityField({
            ...editingEntityField,
            is_name: !editingEntityField.is_name,
        });
    };
    const onChangeIsDesc = () => {
        setEditingEntityField({
            ...editingEntityField,
            is_description: !editingEntityField.is_description,
        });
    };

    const onChangeCalculation = (
        name: keyof TEntityField,
        is_multiple?: boolean,
        key?: number | string
    ) => {
        const newValue = is_multiple
            ? editingEntityField[name].map((item: TEntityFieldCreateUpdateDefault) => {
                  return item.id === key
                      ? {
                            ...item,
                            is_calculation: !item.is_calculation,
                            value: undefined,
                        }
                      : item;
              })
            : {
                  ...editingEntityField[name],
                  value: undefined,
                  is_calculation: !editingEntityField[name].is_calculation,
              };
        setEditingEntityField({
            ...editingEntityField,
            [name]: newValue,
        });
    };

    const onChangeCreateUpdateValue = (
        newValue: any,
        name: keyof TEntityField,
        is_multiple?: boolean,
        key?: number | string
    ) => {
        const resultValue = is_multiple
            ? editingEntityField[name].map((item: TEntityFieldCreateUpdateDefault) => {
                  return item.id === key
                      ? {
                            ...item,
                            value: newValue,
                        }
                      : item;
              })
            : {
                  ...editingEntityField[name as keyof TEntityField],
                  value: newValue,
              };
        setEditingEntityField({
            ...editingEntityField,
            [name]: resultValue,
        });
    };

    const onChangeIsCreateUpdateCheckbox = (
        event: any,
        name: string,
        is_multiple?: boolean
    ) => {
        const checked = event.target.checked;
        if (is_multiple) {
            setEditingEntityField({
                ...editingEntityField,
                [name]: checked
                    ? [{ is_calculation: false, id: `key-${getUnixTimestamp()}` }]
                    : [],
            });
        } else
            setEditingEntityField({
                ...editingEntityField,
                [name]: checked
                    ? {
                          id: null,
                          value: undefined,
                          is_calculation: false,
                      }
                    : null,
            });
    };

    return {
        error,
        editingEntityField,
        loading,
        setEditingEntityField,
        onChangeView,
        onSave,
        onChangeInput,
        onChangeCalculation,
        onChangeUnwritable,
        onChangeCreateUpdateValue,
        onChangeIsCreateUpdateCheckbox,
        onChangeIsName,
        onChangeIsDesc,
    };
};
