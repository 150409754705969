import { VariableItem } from "@entities/Variables/ui";
import { useVariableListItem } from "../model";

interface IVariableNormalItem {
    variable: TVariable;
}
const VariableNormalItem = ({ variable }: IVariableNormalItem) => {
    const { onDelete, deleteLoading } = useVariableListItem(variable);

    return (
        <div>
            <VariableItem
                variable={variable}
                onDelete={onDelete}
                deleteLoading={deleteLoading}
            />
        </div>
    );
};

export default VariableNormalItem;
