import { parseErrorMessageByKey } from "@shared/api/ParseResponse";
import { apiUrl } from "@shared/api/api";
import { FormItem } from "@shared/ui/Form";
import { RequestedSelect } from "@shared/ui/RequestedSelect";
import { ErrorText } from "@shared/ui/Text";
import { Checkbox, Select } from "antd";
import { ReactNode } from "react";
import { useDatatypeDependingInputs } from "../model";

interface IDatatypeDependingInputs {
    editingField: TEntityField | TVariable;
    error?: TValidationError;
    setEditingField: (editingField: TEntityField | TVariable) => void;
    optionEditor?: (fieldId: number, type: TFieldOptionType) => ReactNode;
}

const DatatypeDependingInputs = ({
    editingField,
    error,
    setEditingField,
    optionEditor,
}: IDatatypeDependingInputs) => {
    const {
        entityFieldsOptions,
        onSelectEntity,
        onChangeInput,
        onUpdateIsMultiple,
        onSelectEntityField,
    } = useDatatypeDependingInputs(editingField, setEditingField);
    return (
        <>
            {(editingField.datatype === "childEntity" ||
                editingField.datatype === "parentEntity" ||
                editingField.datatype === "entityList" ||
                editingField.datatype === "entity") && (
                <>
                    <FormItem label={"Вспомогательная сущность"}>
                        <div style={{ fontWeight: 500 }}>
                            <RequestedSelect
                                disabled={
                                    editingField.is_system ||
                                    editingField.datatype === "parentEntity"
                                }
                                onSelect={onSelectEntity}
                                values={editingField.related_entity_uuid ?? null}
                                requestUrl={`${apiUrl}/api/entity${editingField.datatype === "childEntity" ? "?filter[condition_type]=AND&filter[conditions][0][key]=is_reference&filter[conditions][0][condition]=LIKE&filter[conditions][0][value]=1" : ""}`} // TODO MAX
                                queryTypedParam="name"
                                queryChoosenParams="uuid"
                                valueLabel="name"
                                valueKey="uuid"
                                skeletonWidth="120px"
                            />
                        </div>
                    </FormItem>
                </>
            )}

            {(editingField.datatype === "file" ||
                editingField.datatype === "entity" ||
                editingField.datatype === "entityList" ||
                editingField.datatype === "parentEntity" ||
                editingField.datatype === "childEntity" ||
                editingField.datatype === "list") && (
                <Checkbox
                    disabled={
                        editingField.is_system ||
                        editingField.datatype === "parentEntity" ||
                        editingField.datatype === "childEntity"
                    }
                    style={{ textWrap: "nowrap" }}
                    onChange={onUpdateIsMultiple}
                    checked={Boolean(editingField.is_multiple)}
                >
                    Множественное значение
                </Checkbox>
            )}
            {editingField.datatype === "list" &&
                editingField.id != undefined &&
                optionEditor &&
                optionEditor(editingField.id, "entity-field")}
            {(editingField.datatype === "parentEntity" ||
                editingField.datatype === "childEntity" ||
                editingField.datatype === "entityList") && (
                <>
                    <FormItem label="Ключ для имени">
                        <>
                            <Select
                                disabled={editingField.is_system}
                                value={editingField.key_label}
                                style={{ width: "100%" }}
                                options={entityFieldsOptions}
                                status={error?.data?.["key_label"] ? "error" : undefined}
                                onSelect={(value) =>
                                    onSelectEntityField("key_label", value)
                                }
                            />
                            <ErrorText
                                text={parseErrorMessageByKey(error, "key_label")}
                            />
                        </>
                    </FormItem>
                    <FormItem label="Ключ для значения">
                        <>
                            <Select
                                disabled={
                                    editingField.is_system ||
                                    editingField.datatype === "parentEntity" ||
                                    editingField.datatype === "childEntity"
                                }
                                value={editingField.key_value}
                                style={{ width: "100%" }}
                                options={entityFieldsOptions}
                                status={error?.data?.["key_value"] ? "error" : undefined}
                                onSelect={(value) =>
                                    onSelectEntityField("key_value", value)
                                }
                            />
                            <ErrorText
                                text={parseErrorMessageByKey(error, "key_value")}
                            />
                        </>
                    </FormItem>
                </>
            )}
        </>
    );
};

export default DatatypeDependingInputs;
