import { userModel } from "@entities/User";
import { alertNotificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useUserNotification = () => {
    const user = useSelector(userModel.selectUser);
    useEffect(() => {
        window.Echo.channel(`Notification.${user.id}`).listen(
            ".notification",
            ({ notification }: { notification: any }) => {
                alertNotificationEmit({
                    title: notification.header ?? "",
                    description: notification.body ?? "",
                    ...notification,
                });
            }
        );

        return () => {
            window.Echo.leave(`Notification.${user.id}`);
        };
    }, [user.id]);
};
