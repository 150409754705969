import { bizProcModel } from "@entities/BizProc";
import { useBizProcsWS } from "@features/BizProc/BizProcWebsocket";
import { BizProcsList } from "@features/BizProc/BizProcsList";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { Empty, Spin } from "antd";

const BizProcGroups = () => {
    const { bizProcs, loading } = bizProcModel.useLoadBizProcs(true, "executing");
    useBizProcsWS();
    return (
        <>
            {loading ? (
                <div style={{ height: 500 }}>
                    <FlexContainer type="center">
                        <Spin size="large" />
                    </FlexContainer>
                </div>
            ) : (
                <>
                    {bizProcs.length === 0 ? (
                        <div style={{ height: 500 }}>
                            <FlexContainer type="center">
                                <Empty description="Нет активных бизнес-процессов" />
                            </FlexContainer>
                        </div>
                    ) : (
                        bizProcs.map((bizProc, index: number) => {
                            return (
                                <div key={index} className="bizprocs">
                                    {bizProc?.biz_procs && (
                                        <div>
                                            <div className="bizprocs__title">
                                                {bizProc.name}
                                            </div>
                                            <BizProcsList bizProcs={bizProc.biz_procs} />
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    )}
                </>
            )}
        </>
    );
};

export default BizProcGroups;
