import { getDatetimePickerHumanFormat, getNormalizedTime } from "@shared/lib/date";
import { CSSProperties } from "react";

interface IUserDateWithTimezone {
    date: string;
    label?: string;
    labelStyle?: CSSProperties;
    dateStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    type?: "normalized" | "default";
}

const UserDateWithTimezone = ({
    date,
    label,
    labelStyle,
    dateStyle,
    containerStyle,
    type = "normalized",
}: IUserDateWithTimezone) => {
    return (
        <div
            style={
                containerStyle
                    ? containerStyle
                    : {
                          display: "flex",
                          flexDirection: "column",
                      }
            }
        >
            {label && (
                <div
                    style={
                        labelStyle
                            ? labelStyle
                            : {
                                  color: "#8f8f8f",
                              }
                    }
                >
                    {label}
                </div>
            )}
            <div style={dateStyle ? dateStyle : {}}>
                {type === "normalized"
                    ? getNormalizedTime(date)
                    : getDatetimePickerHumanFormat(date)}
            </div>
        </div>
    );
};

export default UserDateWithTimezone;
