import { navigationRouteApi, navigationRouteModel } from "@entities/NavigationRoute";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";

export const useSidebarItemEditing = (navigationRoute: TNavigationRoute) => {
    const dispatch = useDispatch();
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const onDelete = () => {
        setDeleteLoading(true);
        navigationRouteApi
            .deleteNavigationRoute(navigationRoute.id!)
            .then(() => {
                dispatch(navigationRouteModel.deleteRoute(navigationRoute.id!));
            })
            .catch((error) => {
                notificationEmit({
                    error: error,
                    title: "Не удалось удалить элемент",
                    type: "error",
                });
            })
            .finally(() => {
                setDeleteLoading(false);
            });
    };
    const onEdit = () => {
        setIsOpen(true);
    };

    const onCancel = () => {
        setIsOpen(false);
    };
    return {
        deleteLoading,
        isOpen,
        onCancel,
        onDelete,
        onEdit,
    };
};
