export const chunkArray = <T>(arr: T[], CHUNK_SIZE: number): T[][] => {
    return arr.reduce<T[][]>((acc, curr, i) => {
        if (i % CHUNK_SIZE === 0) {
            acc.push([curr]);
        } else {
            acc[acc.length - 1].push(curr);
        }
        return acc;
    }, []);
};

export const getHorizontalCountsOfQrs = (
    pdfOrientation: "p" | "l",
    qrCodeSize: number
) => {
    return Math.floor((pdfOrientation === "p" ? 800 : 1064) / (qrCodeSize + 32)) === 0
        ? 1
        : Math.floor((pdfOrientation === "p" ? 800 : 1064) / (qrCodeSize + 32));
};

export const getVerticalCountsOfQrs = (pdfOrientation: "p" | "l", qrCodeSize: number) => {
    return Math.floor(
        (pdfOrientation !== "p" ? 800 : 1064) /
            (qrCodeSize + Math.floor(qrCodeSize * 0.18 * 3))
    ) === 0
        ? 1
        : Math.floor(
              (pdfOrientation !== "p" ? 800 : 1064) /
                  (qrCodeSize + Math.floor(qrCodeSize * 0.18 * 3))
          );
};

export const getChunkSize = (verticalCount: number, horizontalCount: number) =>
    verticalCount * horizontalCount;
