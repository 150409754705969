export const options = [
    { label: "Нет доступа", value: 0 },
    { label: "К своим", value: 2 },
    { label: "Свой отдел(иерархии)", value: 4 },
    { label: "Свои отдел(иерархии) и подотделы", value: 6 },
    { label: "Свои отдел(полной иерархии) и подотделы", value: 8 },
    { label: "Админский доступ", value: 9 },
];
export const DEFAULT_PER_PAGE = 50;

export const entityRolesUuid = "70f9fefa-ec24-4ab6-b611-cc42951beee9";
export const entityCapabilitiesRolesUuid = "d9785f1a-2d68-43f6-b44b-6c8b4b86cd42";
export const localStorageCapabilitiesRolesPerPage = "user_capabilities_per_page";
export const capabilitiesTypes: {
    key: TCapType;
    label: string;
    tagColor?: string;
    isMultiSelect?: boolean;
    color: string;
}[] = [
    {
        key: "read",
        label: "Чтение",
        tagColor: "blue",
        color: "rgb(230, 244, 255)",
    },
    {
        key: "create",
        label: "Создание",
        tagColor: "green",
        color: "rgb(246, 255, 237)",
    },
    {
        key: "update",
        label: "Обновление",
        tagColor: "yellow",
        color: "rgb(255, 251, 230)",
    },
    {
        key: "delete",
        label: "Удаление",
        tagColor: "red",
        color: "rgb(255, 241, 240)",
    },
    {
        key: "read_fields",
        label: "Поля для чтения",
        isMultiSelect: true,
        color: "rgb(249, 240, 255)",
    },
    {
        key: "create_fields",
        label: "Поля создания",
        isMultiSelect: true,
        color: "rgb(249, 240, 255)",
    },
    {
        key: "update_fields",
        label: "Поля обновления",
        isMultiSelect: true,
        color: "rgb(249, 240, 255)",
    },
    {
        key: "hidden_fields",
        label: "Скрытые поля",
        isMultiSelect: true,
        color: "rgb(249, 240, 255)",
    },
    {
        key: "assigned_fields",
        label: "Поля ответственных",
        isMultiSelect: true,
        color: "rgb(249, 240, 255)",
    },
    {
        key: "constraints",
        label: "Ограничения",
        color: "rgb(249, 240, 255)",
    },
];
