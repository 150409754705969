import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../model";

interface ICreatorAccess {
    bizProc: TBizProc;
    children: ReactNode;
}

const CreatorAccess = ({ children, bizProc }: ICreatorAccess) => {
    const user = useSelector(selectUser);
    const currentUserId = user.id;
    if (currentUserId === bizProc.assigned_id || user.isAdmin) return <>{children}</>;
    return <></>;
};

export default CreatorAccess;
