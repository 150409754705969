import { FormReadValue } from "@shared/ui/Form";
import { CSSProperties, Fragment } from "react";

interface IPaginatedSelectReadValue {
    value: any;
    readValueTextStyle?: CSSProperties;
    coloredKey?: string;
    options: TPaginatedSelectOption[];
    onClick?: (value: number | string) => void;
}

const PaginatedSelectReadValue = ({
    value,
    readValueTextStyle,
    options,
    coloredKey,
    onClick,
}: IPaginatedSelectReadValue) => {
    const _onClick = (e: any, valueItem: any) => {
        e?.stopPropagation();
        e?.preventDefault();
        console.log(e.stopPropagation);

        onClick && onClick(valueItem);
    };
    return (
        <div className={`select-value`}>
            {typeof value === "object" ? (
                value?.length > 0 ? (
                    value?.map((valueItem: any, index: number) => {
                        const label =
                            /* eslint eqeqeq: 0 */
                            options.find((option) => option.value == valueItem)?.label ??
                            valueItem;

                        return (
                            <Fragment key={valueItem}>
                                <FormReadValue
                                    onClick={onClick && ((e) => _onClick(e, valueItem))}
                                    readValueTextStyle={readValueTextStyle}
                                    value={label}
                                />
                                {index < value.length - 1 && (
                                    <span style={{ marginRight: 4 }}>,</span>
                                )}
                            </Fragment>
                        );
                    })
                ) : (
                    <FormReadValue
                        onClick={onClick && ((e) => _onClick(e, value))}
                        readValueTextStyle={{
                            marginRight: 10,
                            ...(readValueTextStyle ?? {}),
                        }}
                        key={value}
                        value={"-"}
                    />
                )
            ) : (
                <FormReadValue
                    onClick={onClick && ((e) => _onClick(e, value))}
                    readValueTextStyle={readValueTextStyle}
                    backgroundColor={
                        coloredKey
                            ? /* eslint eqeqeq: 0 */
                              options.find((option) => option.value == value)?.option?.[
                                  coloredKey
                              ]
                            : undefined
                    }
                    value={
                        /* eslint eqeqeq: 0 */
                        options.find((option) => option.value == value)?.label ?? value
                    }
                />
            )}
        </div>
    );
};

export default PaginatedSelectReadValue;
