export const selectAgreements = (
    state: RootState
): (TBizProcTemplateItem & {
    biz_proc_actions: TBizProcAction<TBizProcAgreementParameters>[];
})[] => state.bizProc.bizProcsAgreements;
export const selectMyAgreements = (
    state: RootState
): (TBizProcTemplateItem & {
    biz_proc_actions: TBizProcAction<TBizProcAgreementParameters>[];
})[] => state.bizProc.bizProcsMyAgreements;
export const selectMyForms = (
    state: RootState
): (TBizProcTemplateItem & {
    biz_proc_actions: TBizProcAction<TBizProcFormParameters>[];
})[] => state.bizProc.bizProcsMyForms;
export const isAgreementsLoading = (state: RootState): boolean =>
    state.bizProc.isBizProcsAgreementsLoading;
export const selectUserActions = (state: RootState): TBizProcAction[] =>
    state.bizProc.userActions;
export const selectUserActionsLength = (state: RootState): number =>
    state.bizProc.userActions.length;
export const selectUserAgreements = (state: RootState): TBizProcAction[] =>
    state.bizProc.userAgreements;
export const bizProcAgreementLoadingError = (state: RootState): any =>
    state.bizProc.bizProcAgreementLoadingError;
export const selectLoadBizProcAgreementsIds = (state: RootState): number[] =>
    state.bizProc.loadBizProcAgreementsIds;
export const selectLoadBizProcFormsIds = (state: RootState): number[] =>
    state.bizProc.loadBizProcFormsIds;
export const selectLoadBizProcsIds = (state: RootState): number[] =>
    state.bizProc.loadBizProcsIds;
export const isFormsLoading = (state: RootState): boolean =>
    state.bizProc.isBizProcsFormsLoading;
export const selectTargetAgreement = (
    state: RootState
): TBizProcAction<TBizProcAgreementParameters> | null => state.bizProc.targetAgreement;
export const selectTargetForm = (
    state: RootState
): TBizProcAction<TBizProcFormParameters> | null => state.bizProc.targetForm;
export const selectFormsCounter = (state: RootState): number =>
    state.bizProc.formsCounter;
export const selectAgreementsCounter = (state: RootState): number =>
    state.bizProc.agreementsCounter;
export const selectBizProcs = (state: RootState): TGroupedBizProc[] =>
    state.bizProc.bizProcs;
export const selectEditingTemplateItem = (state: RootState): TBizProc | null =>
    state.bizProc.editingTemplateItem;
