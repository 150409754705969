import { EyeOutlined } from "@ant-design/icons";

interface IViewEntityRow {
    entityRowId: number | undefined;
    openCloseDrawerWithNavigate?: (
        setIsOpenDrawer: boolean,
        entityRowId: number | undefined | null,
        initialValues?: { [key: string]: any }
    ) => void;
}

const ViewEntityRow = ({ entityRowId, openCloseDrawerWithNavigate }: IViewEntityRow) => {
    const onCreateEditRowClick = () => {
        openCloseDrawerWithNavigate &&
            openCloseDrawerWithNavigate(true, entityRowId, undefined);
    };

    return (
        <div style={{ padding: 6 }} onClick={onCreateEditRowClick}>
            <EyeOutlined style={{ color: "var(--primary)", marginRight: 4 }} />
            Открыть
        </div>
    );
};

export default ViewEntityRow;
