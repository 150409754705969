import {
    DeleteOutlined,
    EditOutlined,
    LeftOutlined,
    PlusOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { TListItemAddonNodes } from "@shared/ui/List";
import { ListStatic } from "@shared/ui/List/ListStatic";
import { Button, Flex, Popconfirm, Result, Spin, Typography } from "antd";
import { motion } from "framer-motion";
import "./UserRoles.scss";
import { useUserRoles } from "./model";
import { sidebar } from "./constants";

interface IUserRoles {
    userRolesEntityId: number | undefined;
    setRole: (item: TUserRole | undefined) => void;
    setEntityRowId: (item: number | null) => void;
    setIsOpenDrawer: (item: boolean) => void;
}

const MotionButton = motion(Button);

const UserRoles = ({
    userRolesEntityId,
    setRole,
    setEntityRowId,
    setIsOpenDrawer,
}: IUserRoles) => {
    const {
        userRolesListRef,
        error,
        isOpenSidebar,
        setIsOpenSidebar,
        userRoles,
        loading,
        onDelete,
        onClickUserRole,
    } = useUserRoles(userRolesEntityId, setRole);
    const baseActions: TListItemAddonNodes = [
        {
            type: "edit",
            node: (
                <Button
                    type="link"
                    style={{ margin: 0, padding: 0 }}
                    icon={<EditOutlined />}
                />
            ),
            handler: (item) => {
                setIsOpenDrawer(true);
                setEntityRowId(item.id);
            },
        },
    ];

    const resultActions = (item: TUserRole): TListItemAddonNodes => [
        ...baseActions,
        {
            type: "delete",
            node: (
                <Popconfirm
                    placement="topRight"
                    title="Удалить роль?"
                    okText="Удалить"
                    cancelText="Отмена"
                    onConfirm={() => onDelete(item)}
                >
                    <Button
                        style={{ margin: 0, padding: 0 }}
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            ),
            handler: () => {},
        },
    ];

    return (
        <Flex vertical className="user-roles" ref={userRolesListRef}>
            <motion.nav
                className="user-roles__nav"
                animate={isOpenSidebar ? "open" : "closed"}
                variants={sidebar}
            >
                <MotionButton
                    className="user-roles__motion-button"
                    icon={isOpenSidebar ? <LeftOutlined /> : <RightOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenSidebar(!isOpenSidebar);
                    }}
                />
                <motion.div
                    className="user-roles__list"
                    initial={{ width: 0 }}
                    exit={{ width: 0 }}
                    animate={isOpenSidebar ? "open" : "closed"}
                >
                    <Flex
                        className="user-roles__list-header"
                        justify="space-between"
                        align="center"
                    >
                        <Typography.Title className="user-roles__list-title" level={5}>
                            Выбор роли
                        </Typography.Title>
                        <Button
                            type="link"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "white",
                            }}
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setIsOpenDrawer(true);
                                setEntityRowId(null);
                            }}
                        >
                            Создать
                        </Button>
                    </Flex>
                    <Spin spinning={loading}>
                        {error ? (
                            <Result status="error" title="Ошибка загрузки ролей" />
                        ) : (
                            <ListStatic<TUserRole>
                                listData={userRoles}
                                onClickListItem={(item: any) => {
                                    onClickUserRole(item);
                                }}
                                listItemAddonNodes={resultActions}
                                listItemMetaTitleText={(item: any) => item.name}
                            />
                        )}
                    </Spin>
                </motion.div>
            </motion.nav>
        </Flex>
    );
};

export default UserRoles;
