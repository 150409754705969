import { ReactNode } from "react";
import { Avatar } from "antd";
import "./CardWithAvatar.scss";

interface ICardWithAvatar {
    hideAvatar?: boolean;
    header?: string | ReactNode;
    description?: string | ReactNode;
    actions?: ReactNode;

    onClick?: (e: any) => void;
}

const CardWithAvatar = ({
    header,
    description,
    hideAvatar,
    actions,
    onClick,
}: ICardWithAvatar) => {
    const width = window.screen.width;
    const avatarSize = width < 600 ? 30 : 40;
    const clickable = onClick !== undefined;

    return (
        <div className={`card-avatar ${clickable ? "clickable" : ""}`} onClick={onClick}>
            {!hideAvatar && (
                <Avatar className="card-avatar__avatar" size={avatarSize}>
                    {typeof header === "string" ? header[0] : ""}
                </Avatar>
            )}
            <div className="card-avatar__info">
                {header && <div className="card-avatar__info__header">{header}</div>}
                {description && (
                    <div className="card-avatar__info__desc">{description}</div>
                )}
            </div>
            {actions && (
                <div style={{ position: "absolute", top: 0, right: 15 }}>{actions}</div>
            )}
        </div>
    );
};

export default CardWithAvatar;
