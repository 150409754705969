import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRoles } from "./../api";
import { selectUserRoles } from "./CapabilitiesRolesSelectors";
import { setUserRoles } from "./CapabilitiesRolesSlice";

export const useLoadUserRoles = (userRolesEntityId: number | undefined) => {
    const userRoles = useSelector(selectUserRoles);
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        return () => {
            dispatch(setUserRoles([]));
        };
    }, []);

    useEffect(() => {
        if (userRolesEntityId) {
            setLoading(true);
            getUserRoles(userRolesEntityId)
                .then((response) => {
                    dispatch(setUserRoles(response.data.data.data));
                    setError(null);
                })
                .catch((e) => {
                    setError(e);
                })
                .finally(() => setLoading(false));
        }
    }, [userRolesEntityId]);

    return {
        userRoles,
        loading,
        error,
    };
};
