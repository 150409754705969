import { getUnixTimestamp } from "@shared/lib/date";

export const useCreateUpdateDefaultValue = (
    editingEntityField: TEntityField,
    setEditingEntityField: (item: TEntityField) => void
) => {
    const isDisabled =
        editingEntityField.key === "id" ||
        editingEntityField.datatype === "entity" ||
        editingEntityField.datatype === "entityList"
            ? !editingEntityField.related_entity_uuid ||
              !editingEntityField.key_label ||
              !editingEntityField.key_value
            : false;

    const isMultiple = editingEntityField.is_multiple;

    const onAddCreateUpdateValue = (name: "on_create_default" | "on_update_default") => {
        setEditingEntityField &&
            setEditingEntityField({
                ...editingEntityField,
                [name]: [
                    ...(editingEntityField[name as keyof TEntityField] ?? []),
                    {
                        is_calculation: false,
                        id: `key-${getUnixTimestamp()}`,
                    },
                ],
            });
    };

    const onDelete = (key: number, name: keyof TEntityField) => {
        setEditingEntityField &&
            setEditingEntityField({
                ...editingEntityField,
                [name]: editingEntityField[name as keyof TEntityField].filter(
                    (item: TEntityFieldCreateUpdateDefault) => key !== item.id
                ),
            });
    };

    return {
        isDisabled,
        isMultiple,
        onAddCreateUpdateValue,
        onDelete,
    };
};
